import Dashboard from "views/Dashboard.js";
import AtletaDashboard from 'views/AtletaDashboard.js';
import MercatoDashboard from 'views/MercatoDashboard.js';
import { BiSolidBarChartAlt2 } from 'react-icons/bi'
import { FaUsers } from 'react-icons/fa'
import { MdRocketLaunch } from 'react-icons/md'

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: <BiSolidBarChartAlt2 size="20px" color="white"/>,
    component:  Dashboard,
    layout: "/admin",
  },
  {
    path: "/mercato",
    name: "My Prospects 1",
    rtlName: "الرموز",
    icon: <MdRocketLaunch size="20px" color="white"/>,
    component: MercatoDashboard,
    layout: "/admin",
  },
  {
    path: "/atleta",
    name: "Atletas",
    rtlName: "الرموز",
    icon: <FaUsers size="20px" color="white"/>,
    component: AtletaDashboard,
    layout: "/admin",
  },
  // {
  //   path: "",
  //   name: "Evaluaciones Tecnicas",
  //   rtlName: "خرائط",
  //   icon: "tim-icons icon-paper",
  //   component: null,
  //   layout: "/admin",
  // },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   rtlName: "إخطارات",
  //   icon: "tim-icons icon-bell-55",
  //   component: Notifications,
  //   layout: "/admin",
  // },
  // {
  //   path: "/user-profile",
  //   name: "User Profile",
  //   rtlName: "ملف تعريفي للمستخدم",
  //   icon: "tim-icons icon-single-02",
  //   component: UserProfile,
  //   layout: "/admin",
  // },
  // {
  //   path: "/tables",
  //   name: "Table List",
  //   rtlName: "قائمة الجدول",
  //   icon: "tim-icons icon-puzzle-10",
  //   component: TableList,
  //   layout: "/admin",
  // },
  // {
  //   path: "/typography",
  //   name: "Typography",
  //   rtlName: "طباعة",
  //   icon: "tim-icons icon-align-center",
  //   component: Typography,
  //   layout: "/admin",
  // },
  // {
  //   path: "/rtl-support",
  //   name: "RTL Support",
  //   rtlName: "ار تي ال",
  //   icon: "tim-icons icon-world",
  //   component: Rtl,
  //   layout: "/rtl",
  // },
];
export default routes;
