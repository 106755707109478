import { Row, Col, Button } from "reactstrap";
import { TextSkeleton, ImageSkeleton } from "components/Skeleton";
import overlay from "../../assets/img/flag/escudoOp.png";

const SearchHeader = ({ institutionName, imgLogo }) => {
    return (
        <Row>
            <Row className="headerbackground">
                <img alt="Imagen de perfil" src={overlay} height="200" width="200" />
            </Row>
            <Col md="12">
                <div className="header_navbar">
                    <div className="container__header">
                        <div className="container__header__details">
                            <img
                                src={imgLogo}
                                height="150px"
                                width="150px"
                                className="Imagestyle"
                            />
                            <div>
                                <h1 className="maintitle title-mb">
                                    {institutionName}
                                </h1>
                            </div>
                        </div>

                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default SearchHeader;
