import React, { useState } from "react";
import {
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    UncontrolledTooltip,
    UncontrolledButtonDropdown,
    Input,
    FormText,
    Label,
    Form,
    FormGroup,
} from "reactstrap";
import { useForm } from "react-hook-form";

import overlay from "../../assets/img/flag/escudoOp.png";
import SideLeft from "components/SideLeft/index";
import Divider from "components/Divider";
import Manager from "../../api/main/MainService";
import ManagerSource from "../../source/Main";
import toast from "react-hot-toast";

import { useContext } from "react";


import AtletaHeaderInfo from './AtletaHeaderInfo';
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { TextSkeleton } from "components/Skeleton";
import { ImageSkeleton } from "components/Skeleton";

const api = new Manager();
const apiSource = new ManagerSource();


const Atletaheadercomponent = () => {


    const [openModal, setOpenModal] = React.useState(false);
    // const [position, setPosition] = React.useState([]);

    // const [reportName, setReportName] = React.useState([]);
    const [edadTransform, setEdadTransform] = React.useState([]);

    const queryCliente = useQueryClient();
    ////QUERY INICIAL PARA CARGAR EL CACHE

    const handlerQuery = async () => {
        try {
            let currentUser = window.localStorage.getItem("currentUser");
            let sportPosition = JSON.parse(
                window.localStorage.getItem("globalContext")
            ) || { sportID: 1 };

            let model = {
                sessionID: currentUser,
                sportID: parseInt(sportPosition.sportID), //Innecesarion porque ya se traen todas las posiciones
            };


            if (currentUser !== null) {
                let result = await api.GetUserDetailsAndPosition(model);
                return result.data;
            } else {
                return {
                    message: {

                        sportList: [],
                        infoDetailsAtleta: [],
                        InfoPositionList: [],
                        positions: [],
                        atletaInfo: {
                            nombre: "",
                            imgData: "",
                            clubName: "",
                            posicion: "",
                            edad: "",
                            fisica: 0,
                            tecnica: 0,
                            peso: '',
                            cof: 0,
                            perfil: 'Sin perfil',
                            deportiva: 0,
                            nutricion: 0,
                            prepfisica: 0,
                            medico: 0,
                            psicologia: 0,
                            academica: 0,
                            fisiologico: 0,
                            ovrGeneral: 0
                        }
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const { data, isSuccess, isLoading, isFetching } = useQuery(
        "informacionAtletaHeader",
        () => handlerQuery(),
        {
            refetchOnWindowFocus: false,
        }
    );

    ///FUNCION DE REACT-HOOK-FORM
    const { watch, handleSubmit, setValue } = useForm();
    let formValues = watch();

    ////FUNCION ABRE MODAL EDITAR INFORMACION DEL ATLETA Y CARGA LOS INPUTS
    const openCloseModal = async () => {
        // ABRIMOS EL MODAL
        setOpenModal(!openModal);

        // CARGAMOS LA INFO DEL ATLETA
        const { infoDetailsAtleta } = data.message;

        //// SE CARGAN LOS INPUTS DEL FORMULARIO CON LA INFORMACION DEL ATLETA AL ABRIR EL MODAL
        setValue("atletaSportID", infoDetailsAtleta[0].sportID);

        setValue(
            "peso",
            infoDetailsAtleta[0].peso === NaN || infoDetailsAtleta[0].peso === ""
                ? 0
                : infoDetailsAtleta[0].peso
        );
        setValue(
            "altura",
            infoDetailsAtleta[0].estatura * 100 === NaN ||
                infoDetailsAtleta[0].estatura * 100 === ""
                ? 0
                : infoDetailsAtleta[0].estatura * 100
        );
        setValue(
            "informacionMedica",
            infoDetailsAtleta[0].medicalInfo === null
                ? ""
                : infoDetailsAtleta[0].medicalInfo
        );
        setValue(
            "direccion",
            infoDetailsAtleta[0].address === null ? "" : infoDetailsAtleta[0].address
        );
        setValue(
            "fechaNacimiento",
            infoDetailsAtleta[0].birthdayDate === null
                ? ""
                : infoDetailsAtleta[0].birthdayDate
        );
        setValue(
            "cedulaIdentidad",
            infoDetailsAtleta[0].cdi === null ? "" : infoDetailsAtleta[0].cdi
        );
        setValue(
            "linkVideo",
            infoDetailsAtleta[0].link === null ? "" : infoDetailsAtleta[0].link
        );
        setValue(
            "telefono",
            infoDetailsAtleta[0].mobile === null ? "" : infoDetailsAtleta[0].mobile
        );
        setValue(
            "posicion",
            infoDetailsAtleta[0].sport_positionID === NaN
                ? 0
                : infoDetailsAtleta[0].sport_positionID
        );
        // console.log({ 1:infoDetailsAtleta });
        setValue(
            "perfilDominante",
            infoDetailsAtleta[0].dominancia === null
                ? ""
                : infoDetailsAtleta[0].dominancia
        );
        setValue(
            "fechaIngresoClub",
            infoDetailsAtleta[0].clubInDate === null
                ? ""
                : infoDetailsAtleta[0].clubInDate
        );
        setValue(
            "vigenciaContrato",
            infoDetailsAtleta[0].contractDate === null
                ? ""
                : infoDetailsAtleta[0].contractDate
        );
        setValue(
            "tipoSangre",
            infoDetailsAtleta[0].ts === null ? "" : infoDetailsAtleta[0].ts
        );
        setValue(
            "proveedorSeguro",
            infoDetailsAtleta[0].pds === null ? "" : infoDetailsAtleta[0].pds
        );
        setValue(
            "edad",
            infoDetailsAtleta[0].edad === NaN || infoDetailsAtleta[0].edad === ""
                ? 0
                : infoDetailsAtleta[0].edad
        );
        setValue(
            "contactoEmergencia",
            infoDetailsAtleta[0].contacEmergency === null
                ? ""
                : infoDetailsAtleta[0].contacEmergency
        );
        setValue(
            "numeroContactoEmergencia",
            infoDetailsAtleta[0].contacEmergencyPhone === null
                ? ""
                : infoDetailsAtleta[0].contacEmergencyPhone
        );
    };

    ////FUNCION QUE ACTUALIZA LA INFORMACION DEL ATLETA POR MEDIO DEL FORMULARIO EN EL MODAL
    //     const insertData = async (formValues) => {
    //         let currentUser = window.localStorage.getItem('currentUser');
    //    //     console.log('formValues', formValues)
    //         let model = {
    //             sessionID: currentUser,
    //             peso: parseInt(formValues.peso),
    //             estatura: parseFloat(formValues.altura / 100),
    //             posicion: parseInt(formValues.posicion),
    //             edad: parseInt(formValues.edad),
    //             link: formValues.linkVideo,
    //             mobile: formValues.telefono,
    //             birthdayDate: formValues.fechaNacimiento,
    //             medicalInfo: formValues.informacionMedica,
    //             cdi: formValues.cedulaIdentidad,
    //             address: formValues.direccion,
    //             perfilDominante: formValues.perfilDominante,
    //             clubInDate: formValues.fechaIngresoClub,
    //             contractDate: formValues.vigenciaContrato,
    //             pds: formValues.proveedorSeguro,
    //             ts: formValues.tipoSangre,
    //             contacEmergency: formValues.contactoEmergencia,
    //             contacEmergencyPhone: formValues.numeroContactoEmergencia,
    //             categoryID: 0,
    //             objetivoID: 0
    //         }

    //         // INSERTAMOS
    //        // console.log('model formulario', model)
    //          let result = await apiSource.AddUpdateUserEdit(model);
    //         if (result.success) {
    //             toast.success('Todos los campos se actualizaron correctamente')
    //             setOpenModal(false)
    //         }
    //     }

    ////MUTATION

    const handlerMutationInfo = async (model) => {

        let result = await api.MutationUserDetails(model);
        return result;
    };

    const mutationDataUser = useMutation("informacionAtletaHeader", {
        mutationFn: handlerMutationInfo,
        onSuccess: (response) => {
            toast.success("Todos los campos se actualizaron correctamente");
            setOpenModal(false);
            queryCliente.setQueryData("informacionAtletaHeader", (oldData) => {
                return {
                    ...oldData,
                    message: response.data.message
                };
            });
        },
        onError: (error) => {
            setOpenModal(true);
            toast.error("Cambios no realizados");
            console.log("ERROR ", error);
        },
    });

    const handlerSubmitUpdate = () => {
        let oldData = data

        let currentUser = window.localStorage.getItem("currentUser");
        let sportPosition = JSON.parse(
            window.localStorage.getItem("globalContext")
        ) || { sportID: 1 };

        let modelUpdate = {
            sessionID: currentUser,
            peso: parseInt(formValues.peso),
            estatura: parseFloat(formValues.altura / 100),
            atletaSportID: formValues.atletaSportID === "" || formValues.atletaSportID === null ? 0 : parseInt(formValues.atletaSportID),
            posicion: parseInt(formValues.posicion),
            edad: parseInt(formValues.edad),
            link: formValues.linkVideo,
            mobile: formValues.telefono,
            birthdayDate: formValues.fechaNacimiento,
            medicalInfo: formValues.informacionMedica,
            cdi: formValues.cedulaIdentidad,
            address: formValues.direccion,
            perfilDominante: formValues.perfilDominante,
            clubInDate: formValues.fechaIngresoClub,
            contractDate: formValues.vigenciaContrato,
            pds: formValues.proveedorSeguro,
            ts: formValues.tipoSangre,
            contacEmergency: formValues.contactoEmergencia,
            contacEmergencyPhone: formValues.numeroContactoEmergencia,
            categoryID: 0,
            objetivoID: 0,
            porcentajeGrasa: 0
        };

        let modelApi = {
            oldData: oldData,
            modelUpdate: modelUpdate,
            sessionID: currentUser,
            sportID: parseInt(sportPosition.sportID),
        };

        mutationDataUser.mutate(modelApi);
    };

    // Mutacion Posiciones de acuerdo al Deporte
    const handleMutationPositionList = async (model) => {
        // Resetaer campo
        setValue("posicion", "");
        let result = await api.MutationListPositionsBySport(model);
        return result.data.message;
    }

    const mutationPositionList = useMutation("informacionAtletaHeader", {
        mutationFn: handleMutationPositionList,
        onSuccess: (response) => {
            queryCliente.setQueryData("informacionAtletaHeader", (oldData) => {
                return {
                    ...oldData,
                    message: response.message,
                };
            });
        },
    });

    const handlePositionsBySport = (value) => {
        // Setear input
        setValue("atletaSportID", value);

        let modelApi = {
            oldData: data,
            sportID: parseInt(value),
        };

        mutationPositionList.mutate(modelApi);
    }

    if (isFetching) {
        return (

            <Row>
                <Row className="headerbackground">
                    <img alt="Imagen de perfil" src={overlay} height="200" width="200" />
                </Row>
                <Col md="12">
                    <div className="header_navbar">
                        <div className="container__header">
                            <div className="container__header__details">

                                <ImageSkeleton opacity="0.2" />

                                <div>
                                    <h1 className="maintitle title-mb">

                                        <TextSkeleton height="20px" opacity="0.2" />

                                    </h1>
                                    <h3 className="title-mb">

                                        <TextSkeleton height="20px" opacity="0.2" />

                                    </h3>
                                    <h4 className="title-mb">
                                        <TextSkeleton height="20px" opacity="0.2" />
                                    </h4>

                                    <div className="header__dt_pf">
                                        <TextSkeleton width="100px" height="20px" opacity="0.2" />
                                        <TextSkeleton width="100px" height="20px" opacity="0.2" />

                                    </div>
                                </div>
                            </div>
                            <div className="container__header__ovrs">
                                <>
                                    <TextSkeleton width="200px" height="20px" opacity="0.2" />
                                    <TextSkeleton width="200px" height="20px" opacity="0.2" />
                                    <TextSkeleton width="200px" height="20px" opacity="0.2" />
                                    <TextSkeleton width="200px" height="20px" opacity="0.2" />
                                    <TextSkeleton width="200px" height="20px" opacity="0.2" />
                                    <TextSkeleton width="200px" height="20px" opacity="0.2" />
                                </>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        );
    }

    if (isSuccess) {
        const { infoDetailsAtleta, InfoPositionList, atletaInfo, sportList = [] } = data.message;

        let getSportAndPosition = () => {
            let currentSport = infoDetailsAtleta[0] && infoDetailsAtleta[0].sportID ? infoDetailsAtleta[0].sportID : null;
            if (currentSport) {
                return `${sportList.filter(row => row.sportID === currentSport)[0].sportName} - `
            }
            return ""
        }

        let position = atletaInfo.posicion ? atletaInfo.posicion : "";

        return (
            <>
                <AtletaHeaderInfo
                    cover={atletaInfo.imgData}
                    title={atletaInfo.nombre}
                    subtitle={atletaInfo.clubName}
                    sportAndposition={`${getSportAndPosition()} ${position}`}
                    subtitle1={""}
                    subtitle2={`${atletaInfo.edad === undefined ? edadTransform : atletaInfo.edad
                        } Años`}
                    actionButton={openCloseModal}
                    list={[
                        {
                            title: "Físicas",
                            score: atletaInfo.fisica,
                        },
                        {
                            title: "Técnicas",
                            score: atletaInfo.tecnica,
                        },
                        {
                            title: "Psicologíca",
                            score: atletaInfo.psicologia,
                        },
                        {
                            title: "Antropometría",
                            score: atletaInfo.antropometria,
                        },
                        {
                            title: "Medico",
                            score: atletaInfo.medico,
                        },
                        {
                            title: "Fisiológica",
                            score: atletaInfo.fisiologica,
                        },
                        {
                            title: "Psicosocial",
                            score: atletaInfo.psicosocial,
                        },
                        {
                            title: "General",
                            score: atletaInfo.ovrGeneral,
                        },
                    ]}
                    isLoading={isLoading}
                />
                <SideLeft show={openModal} onClose={() => openCloseModal()}>
                    <div className="container">
                        <Row>
                            <Col>
                                <div>
                                    <h2>Editar Perfil</h2>

                                    <form onSubmit={handleSubmit(handlerSubmitUpdate)}>
                                        <Row>
                                            <Col className="imputStylePerfil">
                                                {/* <pre>{JSON.stringify(formValues, null, 2)}</pre> */}
                                                <FormGroup>
                                                    <Label for="input-peso">Peso</Label>
                                                    <Input
                                                        className="fix-form-control"
                                                        placeholder="Peso (kg)"
                                                        type="number"
                                                        id="input-peso"
                                                        defaultValue={formValues.peso}
                                                        onChange={(e) => setValue("peso", e.target.value)}
                                                        required
                                                    />
                                                    <h5 className="mt-2 text-danger">
                                                        * Este campo es obligatorio *
                                                    </h5>
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label for="input-altura">Talla</Label>
                                                    <Input
                                                        id="input-altura"
                                                        className="fix-form-control"
                                                        type="number"
                                                        placeholder="Altura (cm)"
                                                        defaultValue={formValues.altura}
                                                        onChange={(e) => setValue("altura", e.target.value)}
                                                        required
                                                    />
                                                    <h5 className="mt-2 text-danger">
                                                        * Este campo es obligatorio *
                                                    </h5>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col className="imputStylePerfil">
                                                <FormGroup>
                                                    <Label for="input-medica">Información Médica</Label>
                                                    <Input
                                                        id="input-medica"
                                                        className="fix-form-control"
                                                        placeholder="Información Médica"
                                                        type="textarea"
                                                        defaultValue={formValues.informacionMedica}
                                                        onChange={(e) =>
                                                            setValue("informacionMedica", e.target.value)
                                                        }
                                                        style={{ paddingLeft: "20px" }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label for="input-address">Dirección</Label>
                                                    <Input
                                                        id="input-address"
                                                        className="fix-form-control"
                                                        placeholder="Dirección"
                                                        defaultValue={formValues.direccion}
                                                        type="textarea"
                                                        onChange={(e) =>
                                                            setValue("direccion", e.target.value)
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col className="imputStylePerfil">
                                                <FormGroup>
                                                    <Label>Fecha de nacimiento</Label>
                                                    <Input
                                                        placeholder="Fecha de nacimiento"
                                                        className="fix-form-control"
                                                        defaultValue={formValues.fechaNacimiento}
                                                        type="date"
                                                        onChange={(e) =>
                                                            setValue("fechaNacimiento", e.target.value)
                                                        }
                                                    />
                                                    {/*  <h5 className='mt-2 text-danger'>* Este campo es obligatorio *</h5> */}
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label for="input-cid">Cedula de Identidad</Label>
                                                    <Input
                                                        id="input-cid"
                                                        className="fix-form-control"
                                                        placeholder="Cedula de Identidad"
                                                        defaultValue={formValues.cedulaIdentidad}
                                                        type="text"
                                                        onChange={(e) =>
                                                            setValue("cedulaIdentidad", e.target.value)
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col className="imputStylePerfil">
                                                <FormGroup>
                                                    <Label for="input-video">Url de video</Label>
                                                    <Input
                                                        id="input-video"
                                                        className="fix-form-control"
                                                        placeholder="Url de video"
                                                        defaultValue={formValues.linkVideo}
                                                        type="text"
                                                        onChange={(e) =>
                                                            setValue("linkVideo", e.target.value)
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label for="input-phone">Teléfono</Label>
                                                    <Input
                                                        placeholder="Teléfono"
                                                        className="fix-form-control"
                                                        id="input-phone"
                                                        type="tex"
                                                        defaultValue={formValues.telefono}
                                                        onChange={(e) =>
                                                            setValue("telefono", e.target.value)
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label for="input-position">Deporte</Label>
                                                    <Input
                                                        id="input-atletaSportID"
                                                        className="fix-form-control"
                                                        onChange={(e) => handlePositionsBySport(e.target.value)}
                                                        type="select"
                                                        // value={formValues.atletaSportID}
                                                        value={formValues.atletaSportID}
                                                    // required
                                                    >
                                                        <option value={0} disabled>
                                                            Seleccione un deporte
                                                        </option>
                                                        {sportList.map((row, index) => (
                                                            <option key={`${row.sportID}-${index}`} value={row.sportID}>
                                                                {row.sportName}
                                                            </option>
                                                        ))}
                                                    </Input>
                                                    {/* <h5 className='mt-2 text-danger'>* Este campo es obligatorio *</h5> */}
                                                </FormGroup>
                                            </Col>
                                            <Col className="imputStylePerfil">
                                                <FormGroup>
                                                    <Label for="input-position">Posición</Label>
                                                    <Input
                                                        id="input-position"
                                                        className="fix-form-control"
                                                        onChange={(e) =>
                                                            setValue("posicion", e.target.value)
                                                        }
                                                        type="select"
                                                        value={formValues.posicion}
                                                        required
                                                    >
                                                        <option value={""} disabled>
                                                            Seleccione una posicion
                                                        </option>
                                                        {InfoPositionList.map((task, index) => {
                                                            return (
                                                                <option
                                                                    value={task.sport_positionID}
                                                                    key={index}
                                                                >
                                                                    {task.position}
                                                                </option>
                                                            );
                                                        })}
                                                    </Input>
                                                    <h5 className="mt-2 text-danger">
                                                        * Este campo es obligatorio *
                                                    </h5>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label for="input-perfil">Perfil Dominante</Label>
                                                    <Input
                                                        id="input-perfil"
                                                        className="fix-form-control"
                                                        value={formValues.perfilDominante}
                                                        type="select"
                                                        onChange={(e) =>
                                                            setValue("perfilDominante", e.target.value)
                                                        }
                                                    >
                                                        <option value={""} disabled>
                                                            Seleccione un perfil
                                                        </option>
                                                        <option value={"Derecho"}>Derecho</option>
                                                        <option value={"Izquierdo"}>Izquierdo</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>

                                            <Col className="imputStylePerfil">
                                                <FormGroup>
                                                    <Label for="input-startclub">
                                                        Fecha de Ingreso al Club
                                                    </Label>
                                                    <Input
                                                        id="input-startclub"
                                                        className="fix-form-control"
                                                        placeholder="Fecha de Ingreso al Club"
                                                        type="date"
                                                        defaultValue={formValues.fechaIngresoClub}
                                                        onChange={(e) =>
                                                            setValue("fechaIngresoClub", e.target.value)
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col className="imputStylePerfil">
                                                <FormGroup>
                                                    <Label for="input-contractclub">
                                                        Vigencia de Contrato
                                                    </Label>
                                                    <Input
                                                        id="input-contractclub"
                                                        className="fix-form-control"
                                                        placeholder="Vigencia de Contrato"
                                                        type="tex"
                                                        defaultValue={formValues.vigenciaContrato}
                                                        onChange={(e) =>
                                                            setValue("vigenciaContrato", e.target.value)
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col className="imputStylePerfil">
                                                <FormGroup>
                                                    <Label for="input-ts">Tipo de Sangre</Label>
                                                    <Input
                                                        id="input-ts"
                                                        className="fix-form-control"
                                                        placeholder="Tipo de Sangre"
                                                        defaultValue={formValues.tipoSangre}
                                                        onChange={(e) =>
                                                            setValue("tipoSangre", e.target.value)
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label for="input-eps">Proveedor de Seguro</Label>
                                                    <Input
                                                        id="input-eps"
                                                        className="fix-form-control"
                                                        placeholder="Proveedor de Seguro"
                                                        defaultValue={formValues.proveedorSeguro}
                                                        onChange={(e) =>
                                                            setValue("proveedorSeguro", e.target.value)
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col>
                                                <FormGroup>
                                                    <Label for="input-edad">Edad</Label>
                                                    <Input
                                                        id="input-edad"
                                                        className="fix-form-control"
                                                        placeholder="Edad"
                                                        type="number"
                                                        defaultValue={formValues.edad}
                                                        onChange={(e) => setValue("edad", e.target.value)}
                                                        required
                                                    />
                                                    <h5 className="mt-2 text-danger">
                                                        * Este campo es obligatorio *
                                                    </h5>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col className="imputStylePerfil">
                                                <FormGroup>
                                                    <Label for="input-cen">
                                                        Contacto de Emergencia (Nombre)
                                                    </Label>
                                                    <Input
                                                        id="input-cen"
                                                        className="fix-form-control"
                                                        placeholder="Contacto de Emergencia (Nombre)"
                                                        type="text"
                                                        defaultValue={formValues.contactoEmergencia}
                                                        onChange={(e) =>
                                                            setValue("contactoEmergencia", e.target.value)
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xs="6">
                                                <FormGroup>
                                                    <Label>Número de Contacto de Emergencia</Label>
                                                    <Input
                                                        placeholder="Número de Contacto de Emergencia"
                                                        className="fix-form-control"
                                                        type="text"
                                                        defaultValue={formValues.numeroContactoEmergencia}
                                                        onChange={(e) =>
                                                            setValue(
                                                                "numeroContactoEmergencia",
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col xs={2}>
                                                <Button className="btn-success" type="submit">
                                                    Guardar
                                                </Button>
                                            </Col>
                                            <Col>
                                                <Button
                                                    className="btn-success"
                                                    type="button"
                                                    onClick={() => openCloseModal()}
                                                >
                                                    Cancelar
                                                </Button>
                                            </Col>
                                        </Row>
                                    </form>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </SideLeft>
            </>
        );
    }
};
export default Atletaheadercomponent;
