import React, { useState } from 'react';
import { Button, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledButtonDropdown, Row, Col } from 'reactstrap';

const SingleSelect = ({ title = '', item = [], multiSelect = false, onChange = () => { }, noData = false }) => {
    const [open, setOpen] = useState(false);
    const [selection, setSelection] = useState([]);
    const [selectedItem, setSelectedItem] = useState('');
    const [listDate, setListDate] = useState([])
    const toggle = () => {
        setOpen(prev => !prev)
    };

    React.useEffect(() =>{
       // console.log('itemSelect', item)
        if(item.length > 0){
            setListDate(item)
        }
        
    },[item])

    React.useEffect(() => {
     //  console.log('TTLE ', title)
        setSelectedItem(title)
    }, [title]);
    
    SingleSelect.handleClickOutside = () => setOpen(false);

    const handleOnClick = (item) => {
        //console.log('ITEM ', item)
        if (!selection.some(current => current.id === item.id)) {
            if (!multiSelect) {
                setSelection([item]);
                setSelectedItem(item.value)
            } else if (multiSelect) {
                setSelection([...selection, item]);
                setSelectedItem(item.value)
            }
        } else {
            let selectionAfterRemoval = selection;
            selectionAfterRemoval = selectionAfterRemoval.filter(
                current => current.id != item.id
            );
            setSelection([...selectionAfterRemoval]);
        }
        onChange(item)
    }

    const isItemInSelection = (item) => {
        if (selection.some(current => current.id === item.id)) {
            return true;
        }
        return false;
    }

    // console.log('ITEM CUSTOM SELECT ', item)
    return (
        <UncontrolledButtonDropdown>
            <DropdownToggle caret className="dd-wrapper" disabled={noData}>
                <div className="dd-header">
                    <p className="dd-header__title--bold">{selectedItem}</p>
                    <span
                        className="dd-buttonStyle">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            id="Capa_1"
                            data-name="Capa 1"
                            viewBox="0 0 12.67 10.9"
                            fill="#000"
                            width={10}
                            height={10}>
                            <polygon
                                className="cls-1"
                                points="6.35 10.9 12.64 0 0.06 0 6.35 10.9" /></svg>
                    </span>
                </div>
            </DropdownToggle>
            <DropdownMenu container={'body'}  >
                <Row className='overflow'>
                    {
                        listDate.length <= 0  ?
                            <Row style={{
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                width: '100%'
                            }}>
                                <p> {'No data'}</p>

                            </Row> :
                            listDate.map(task => (
                                <DropdownItem
                                    key={task.id}
                                    onClick={() => handleOnClick(task)}
                                    className="dd-list-item"
                                >
                                    <Row>
                                        <Col xs={10}>
                                            {<span >{task.value}</span>}
                                        </Col>
                                        <Col xs={2} style={isItemInSelection(task) ? { marginLeft: '-12px' } : null}>
                                            <span className='icon-plus-container'>
                                                {
                                                    isItemInSelection(task) &&
                                                    <i className="tim-icons icon-check-2 icon-plus"></i>
                                                }
                                            </span>
                                        </Col>
                                    </Row>
                                </DropdownItem>
                            ))}
                </Row>

            </DropdownMenu>
        </UncontrolledButtonDropdown>
    );
}

export default SingleSelect;