import React from "react";
import CustomizableDataTable from "components/dataTable/dataTable";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import moment from "moment";
import toast from "react-hot-toast";
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { GetTecnicaTabs } from "../../../api/AtletaDashboardTabs/TecnicaTabs"
import { MutationTecnicaTabs } from "../../../api/AtletaDashboardTabs/MutationTecnicaTabsAtleta";
import HelperManagers from "../../../source/handler/index";
import DatetimeCustom from "components/dateTime";
import AreaChartComponent from "components/AreaChart";
import DataTable from "components/dataTable";
import Reportes from "components/DowloadReport/Tecnica/Reportes";
import { useState, useEffect } from "react";
import ModalComponent from "components/DowloadReport/Fisica/Modal";
import { CardSkeleton, TableSkeleton } from "components/Skeleton";
const api = new HelperManagers();

const TecnicasTabs = ({ enabled }) => {
    const chartRef = React.useRef(null);
    const [showModal, setShowModal] = useState(false);
    const [reportData, setReportData] = useState([])
    const [isOpen, setIsOpen] = useState(false)
    const [evaPartidoFilterDatos, setEvaPartidoFilterDatos] = useState({})
    const [evaPartidoUser, setEvaPartidoUser] = useState({
        header: [
            "Nombre del atleta",
            "Puntaje ofensivo",
            "Puntaje defensivo",
            "Puntaje de actitud",
            "Minutos jugados",
            "Goles",
            "Asistencias",
            "Tarjetas Amarillas",
            "Tarjetas Rojas",
            "Paradas de gol",
            "Paradas de Penales",
        ],
        content: [],
    })
    const queryClient = useQueryClient();

    const handlerQuery = async () => {
        let session = window.localStorage.getItem('currentUser')
        let currentCategory = JSON.parse(window.localStorage.getItem('currentCategori'))
        let clubSession = JSON.parse(window.localStorage.getItem('_gHtk'))

        let clubSessionID = window.localStorage.getItem('clubSession')
        let categoryID = window.localStorage.getItem('categoryID')

        let model = {
            sessionID: session,
            categoryID: currentCategory ? currentCategory.categoriesID : categoryID,
            clubSession: clubSession ? clubSession.sessionID : clubSessionID
        }

        let result = await GetTecnicaTabs(model)

        return result
    }

    const { data, isError, isSuccess, isLoading, refetch } = useQuery('tecnicaDataTabsAtleta', () => handlerQuery(), {
        enabled: enabled,
        refetchOnWindowFocus: enabled
    });
    useEffect(() => {
        if (enabled) {
            refetch()
        }
    }, [enabled])

    const handlerFilterChartData = async (item) => {
        try {
            let result = await MutationTecnicaTabs(item)
            return result
        } catch (error) {
            return error
        }
    };

    const changeDataForFilterTableCompeticion = useMutation('tecnicaDataTabsAtleta', {
        mutationFn: handlerFilterChartData,
        onSuccess: (response) => {
            //console.log('RESPONSE ', response)
            queryClient.setQueryData('tecnicaDataTabsAtleta', (oldData) => {
                return {
                    ...oldData,
                    message: response.message
                }
            })
        }
    })

    const ChangeTableCompetenciaFIlter = async (item, ref) => {

        let session = window.localStorage.getItem('currentUser')

        let dataForProcess = data.message
        // console.log('data process', dataForProcess)
        switch (ref) {
            case 'torneo':
                dataForProcess.activeFilter.filterValue.torneID = item.id
                dataForProcess.activeFilter.filterPlacholder.torneo = item.value
                break;

            case 'rival':
                dataForProcess.activeFilter.filterValue.rival = item.id
                dataForProcess.activeFilter.filterPlacholder.rival = item.value
            default:
                break;
        }
        let modelApi = {
            sessionID: session
        }

        let model = {
            oldData: dataForProcess,
            model: modelApi
        }
        //   console.log('model ',model)

        changeDataForFilterTableCompeticion.mutate(model)
    }

    const _loadEvaPartidoByAtletaID = (item) => {
        const { dataForFilter } = data.message
        //console.log('data filer', filterData)
        let result = [];
        let hasEva = {
            count: 0,
            totalEva: 0,
        };
        // console.log('ITEMD FILTER ', item)
        for (let index = 0; index < dataForFilter.length; index++) {
            let element = dataForFilter[index];

            if (element.sessionID === item.sessionID && element.rival === item.rival) {
                let objInfo = {
                    nombre: "",
                    rival: "",
                    torneo: "",
                    fecha: ""
                }
                objInfo.nombre = element.nombreAtleta;
                objInfo.rival = element.rival;
                objInfo.torneo = element.torneoName;

                objInfo.fecha = moment(element.matchDate).format('MMM-DD-YY');
                setEvaPartidoFilterDatos(objInfo);

                // VALIDAMOS QUE LOS CAMPOS NO SEAN NULL
                hasEva.totalEva++;
                let entries = Object.entries(element);
                for (let index = 0; index < entries.length; index++) {
                    let task = entries[index];

                    if (task[0] === "p1") {
                        if (task[1] === null) hasEva.count++;
                    }
                    if (task[0] === "p2") {
                        if (task[1] === null) hasEva.count++;
                    }
                    if (task[0] === "p3") {
                        if (task[1] === null) hasEva.count++;
                    }
                    if (task[0] === "p4") {
                        if (task[1] === null) hasEva.count++;
                    }
                    if (task[0] === "p5") {
                        if (task[1] === null) hasEva.count++;
                    }
                    if (task[0] === "p6") {
                        if (task[1] === null) hasEva.count++;
                    }
                    if (task[0] === "p7") {
                        if (task[1] === null) hasEva.count++;
                    }
                    if (task[0] === "p8") {
                        if (task[1] === null) hasEva.count++;
                    }
                    if (task[0] === "p9") {
                        if (task[1] === null) hasEva.count++;
                    }
                    if (task[0] === "p10") {
                        if (task[1] === null) hasEva.count++;
                    }
                }

                if (hasEva.count >= 10) {
                    hasEva = {
                        count: 0,
                        totalEva: 0,
                    };
                } else {
                    result.push(element);
                    hasEva = {
                        count: 0,
                        totalEva: 0,
                    };
                }
            }
        }

        let header = {
            header: [
                "Nombre del atleta",
                "Puntaje ofensivo",
                "Puntaje defensivo",
                "Puntaje de actitud",
                "Minutos jugados",
                "Goles",
                "Asistencias",
                "Tarjetas Amarillas",
                "Tarjetas Rojas",
                "Paradas de gol",
                "Paradas de Penales",
            ],
            content: [],
        };
        let obj = {
            nombre: "",
            p1: 0,
            p2: 0,
            p3: 0,
            p4: 0,
            p5: 0,
            p6: 0,
            p7: 0,
            p8: 0,
            p9: 0,
            p10: 0,
        };
        obj.nombre = result[0].nombreAtleta;
        obj.p1 = result[0].p1;
        obj.p2 = result[0].p2;
        obj.p3 = result[0].p3;
        obj.p4 = result[0].p4;
        obj.p5 = result[0].p5;
        obj.p6 = result[0].p6;
        obj.p7 = result[0].p7;
        obj.p8 = result[0].p8;
        obj.p9 = result[0].p9;
        obj.p10 = result[0].p10;

        header.content.push(obj);

        // console.log('HEADER ', header)
        setEvaPartidoUser(header);
        setShowModal(true);
    };

    const DowloadExcelForPartido = () => {
        const data = JSON.parse(window.localStorage.getItem('dataForFilter'));
        let session = window.localStorage.getItem('currentUser')

        let dataForFilter = data.filter((task) => {
            if (task.sessionID === session) {
                return task
            }
        });

        let arrayPartido = [];

        let unifyModel = {
            nombre: "",
            posicion: "",
            torneo: "",
            rival: "",
            puntaje_ofensivo: "",
            puntaje_defensivo: "",
            puntaje_actitud: "",
            minutos_jugados: "",
            goles: "",
            asistencias: "",
            tarjertas_amarillas: "",
            tarjetas_rojas: "",
            paradas_de_gol: "",
            paradas_de_penales: "",
        };
        for (let index = 0; index < dataForFilter.length; index++) {
            let element = dataForFilter[index];

            unifyModel.nombre = element.nombreAtleta;
            unifyModel.posicion = element.position;
            unifyModel.torneo = element.torneoName;
            unifyModel.rival = element.rival;
            unifyModel.puntaje_ofensivo = element.p1;
            unifyModel.puntaje_defensivo = element.p2;
            unifyModel.puntaje_actitud = element.p3;
            unifyModel.minutos_jugados = element.p4;
            unifyModel.goles = element.p5;
            unifyModel.asistencias = element.p6;
            unifyModel.tarjertas_amarillas = element.p7;
            unifyModel.tarjetas_rojas = element.p8;
            unifyModel.paradas_de_gol = element.p9;
            unifyModel.paradas_de_penales = element.p10;

            arrayPartido.push(unifyModel);

            unifyModel = {
                nombre: "",
                posicion: "",
                torneo: "",
                rival: "",
                puntaje_ofensivo: "",
                puntaje_defensivo: "",
                puntaje_actitud: "",
                minutos_jugados: "",
                goles: "",
                asistencias: "",
                tarjertas_amarillas: "",
                tarjetas_rojas: "",
                paradas_de_gol: "",
                paradas_de_penales: "",
            };
        }
        let name = "Evaluaciones de Partido";
        //   console.log('DATA ', arrayPartido)
        api.dowloadexcel(arrayPartido, name);
    };


    const dowloaddf = (item) => {
        // TRAER LAS EVALUACIO EN CUSRO 
        // console.log('SESSION ', item)
        const { currentData, evaluacion, flagClub } = data.message.dataEvaForReport
        let list = currentData
        let pdfData = list.filter((task) => {
            if (task.sessionID === item) return task
        })

        console.log('Data for Dowload PDF ', pdfData)

        let component1 = pdfData.filter((task) => {
            if (task.tecnicaTypeID === 1) return task
        })
        let component2 = pdfData.filter((task) => {
            if (task.tecnicaTypeID === 2) return task
        })
        let component3 = pdfData.filter((task) => {
            if (task.tecnicaTypeID === 3) return task
        })

        let currentCategori = JSON.parse(window.localStorage.getItem('currentCategori'));

        setReportData([{
            component1: component1,
            component2: component2,
            component3: component3,
            evaluacion: evaluacion,
            categoryID: currentCategori,
            flag: flagClub
        }])
        setIsOpen(true)

    }


    if (isLoading) {
        return (
            <Row>
                <Col xs="12">
                    <CardSkeleton />
                </Col>
                <Col xs="12">
                    <TableSkeleton title="Evaluaciones de Competencias" />
                </Col>
            </Row>
        )
    }
    if (isError) {
        return (<h3>Lo sentimos no se puede cargar la informacion</h3>)
    }
    if (isSuccess) {
        const { clubTorneoList,
            torneoAtletaList,
            activeFilter,
            evaPartidoRivalList,
            chartData,
            charLabel } = data.message


        return (
            <>
                <Row>
                    <Col xs="12">
                        <Card className="card-chart">
                            <CardHeader>
                                <Row>
                                    <Col className="text-left" sm="3">
                                        <CardTitle tag="h1" className="tittlestyle">
                                            Evaluaciones Técnicas
                                        </CardTitle>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <AreaChartComponent
                                    toolTip={{
                                        current: "Evaluación actual",
                                        last: "Evaluación previa",
                                    }}
                                    head={[

                                        {
                                            name: charLabel.aspect1 === "" ? "Aspecto 1 Ovr" : charLabel.aspect1,
                                            dt1: 0,
                                            dt2: 0,
                                        },
                                        {
                                            name: charLabel.aspect2 === "" ? "Aspecto 2 Ovr" : charLabel.aspect2,
                                            dt1: 0,
                                            dt2: 0,
                                        },
                                        {
                                            name: charLabel.aspect3 === "" ? "Aspecto 3 Ovr" : charLabel.aspect3,
                                            dt1: 0,
                                            dt2: 0,
                                        }
                                    ]}
                                    data={chartData}
                                    idRef={chartRef}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {/* <Row>
                    <Col lg="12" md="12">
                        <Col className="text-left" sm="4">
                            <CardTitle tag="h1" className="tittlestyle">
                                {" "}
                                Evaluaciones Técnicas
                            </CardTitle>
                        </Col>
                        <Row className="mr-1">
                            <DataTable
                                filterTotal={0}
                                idRef={'dataEva'}
                                data={dataEva}
                                handlerButton={(item) => dowloaddf(item)}
                            />

                        </Row>

                    </Col>
                </Row> */}
                <Row>
                    <Col xs="12">
                        <Col className="text-left" sm="4">
                            <CardTitle tag="h1" className="tittlestyle">
                                {" "}
                                Evaluaciones de Competencias
                            </CardTitle>
                        </Col>
                        <Row className="mr-1">
                            <CustomizableDataTable
                                filter={true}
                                filterData={[
                                    {
                                        type: "select",
                                        multiselect: false,
                                        placheolder: activeFilter.filterPlacholder.torneo !== '' ? activeFilter.filterPlacholder.torneo : 'Seleccione un torneo' ,
                                        dataForFilterList: clubTorneoList,
                                        keyExtractor: "torneo",
                                    },
                                    // {
                                    //     type: "select",
                                    //     multiselect: false,
                                    //     placheolder: activeFilter.filterPlacholder.nombre,
                                    //     dataForFilterList: evaPartidoAtletaList,
                                    //     keyExtractor: "sessionID",
                                    // },
                                    {
                                        type: "select",
                                        multiselect: false,
                                        placheolder: activeFilter.filterPlacholder.rival,
                                        dataForFilterList: evaPartidoRivalList,
                                        keyExtractor: "rival",
                                    },
                                ]}
                                tableType="regular"
                                showAction={true}
                                showActionExecel={true}
                                headerData={[
                                    "Nombre del atleta",
                                    "Posicion",
                                    "Torneo",
                                    "Rival",
                                    "Fecha de Partido",
                                    "Action",
                                ]}
                                data={torneoAtletaList}
                                filterComponent={(item, ref) => ChangeTableCompetenciaFIlter(item, ref)
                                }
                                handlerOnClik={(item) => _loadEvaPartidoByAtletaID(item)
                                    //_loadEvaPartidoByAtletaID(item)
                                }
                                handlerActionOnCLick={(item) => {
                                    if (item === "excel") {
                                        DowloadExcelForPartido();
                                    }
                                }}
                            />
                        </Row>
                    </Col>
                </Row>

                {/* <ModalComponent
                    show={isOpen}
                    onClose={() => setIsOpen(false)}
                    height={'100%'}
                    width={'96%'}
                    left={false}
                    top={'2%'}
                    backdropStyle={{ 'backdropFilter': 'blur(6px)' }}

                >
                    <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Reportes props={reportData} />
                    </Row>

                </ModalComponent> */}

                <ModalComponent show={showModal} onClose={() => setShowModal(false)} width={'90%'} height={'90%'}>
                    <div className="row p-5">
                        <div>
                            <h4>{evaPartidoFilterDatos?.nombre}</h4>
                            <small>{evaPartidoFilterDatos?.fecha}</small>
                        </div>
                        <DataTable
                            filterTotal={0}
                            filterLabel={{
                                label: evaPartidoFilterDatos?.torneo,
                                subLabel: evaPartidoFilterDatos?.rival,
                            }}
                            showFilterLabel={true}
                            data={evaPartidoUser}
                            idRef={"evaPartidoUser"}
                        />
                    </div>

                </ModalComponent>
            </>

        )
    }

}

export default TecnicasTabs;
