// import DatetimeCustom from "components/dateTime";
import React from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import CustomizableDataTable from "components/dataTable/dataTable";
import AreaChart from "components/AreaChart";
import HelperManagers from "../../../source/handler/index";
import HelperMapper from "../../../source/Main";
import { ReportTabsModel, MutationFilterReportTableModel } from '../../../api/ReportTabs'
import { useStateCallback } from "hook/useStateCallback";
import { useQuery, useMutation, useQueryClient } from "react-query";
// import LoadingScreen from "components/Loading";
import { CardSkeleton, TableSkeleton } from "components/Skeleton";

const api = new HelperManagers();
const apiMapper = new HelperMapper();

const ReportTabs = ({active }) => {

    //console.log('active tab reportes', active)
    const chartRef2 = React.useRef(null);
    const [filterObj, setFilterObj] = useStateCallback({
        report: {
            isActive: false,
            value: "",
        },
        mes: {
            isActive: false,
            value: "",
        },
        week: {
            isActive: false,
            value: "",
        },
        date: {
            isActive: false,
            value: "",
        },
    });

    // let dwData = []

    const UploadExcelReport = async (key, type) => {
        const { filterActive } = data.message.tableData
        let finalData = [];
        let model = {};
        let modelMapper = [];
        let clubInfo = JSON.parse(window.localStorage.getItem("_gHtk"));
        let clubID = clubInfo.clubID
        let categoria = JSON.parse(window.localStorage.getItem("currentCategori"));
        if (type) {
            // MAPPER MODEL API
            model = {
                model: {
                    clubSession: clubInfo.sessionID,
                    clubID: clubID,
                    FilterObj: filterActive,
                    categoryID: categoria.categoriesID,
                    sessionID: key,
                },
                type: true
            }

            //console.log('FILTER OBJ ', filterObj)
            modelMapper = await apiMapper.MapperModel(model, false);
            finalData = modelMapper.data.message;

        } else {
            // FULL DESCARGA

            // MAPPER MODEL API
            model = {
                model: {
                    clubSession: clubInfo.sessionID,
                    clubID: clubID,
                    reportID: filterActive.report.value.id,
                    reportName: filterActive.report.value.value,
                    categoryID: categoria.categoriesID,
                },
                type: false,
            };
            modelMapper = await apiMapper.MapperModel(model, false);
            finalData = modelMapper.data.message;
        }


        console.log('FINAL DATA ', finalData)

        api.dowloadexcel(finalData, "Estadísticas de Reportes");
    };



    // React Query
    const handlerQuery = async () => {
        let clubID = JSON.parse(window.localStorage.getItem('_gHtk'));
        let categoria = JSON.parse(window.localStorage.getItem('currentCategori'));
        // console.log('CLUBID ', categoria)
        let model = {
            clubID: clubID.clubID,
            categoryID: categoria.categoriesID,
            startDate: '00:00:00',
            endDate: '00:00:00'
        }
        let result = await ReportTabsModel(model)
        //  console.log({ "MAIN_RESULT1": result });
        return result
    }

    const { data, isSuccess, isIdle, refetch, isLoading, isFetching, isError } = useQuery('reportTabs', () => handlerQuery(), {
        refetchOnWindowFocus: false,
        enabled: !active ? false : true
    });

    let queryClient = useQueryClient();

    const handlerReportEstadisticasFilter = async (model) => {
        try {
            let result = await MutationFilterReportTableModel(model)
            return result
        } catch (error) {
            console.log({ error });
        }
    }

    const reportEstadisticaFilterMutation = useMutation('reportTabs', {
        mutationFn: handlerReportEstadisticasFilter,
        onSuccess: (response) => {
            queryClient.setQueryData('reportTabs', (oldData) => {
                return {
                    ...oldData,
                    message: response.message.message
                }
            })
        }
    })

    const ChangeDataForFilterReportTable = (value, ref) => {
        let oldData = { ...data }
        switch (ref) {
            case "reportID":
                oldData.message.tableData.filterActive.report.isActive = true;
                oldData.message.tableData.filterActive.report.value = value;

                if (oldData.message.tableData.filterActive.report.isActive) {
                    oldData.message.tableData.filterActive.week.isActive = false
                }
                break;
            case "week":
                oldData.message.tableData.filterActive.week.isActive = true
                oldData.message.tableData.filterActive.week.value = JSON.parse(value.id);
                break;
            default:
                break;
        }


        let clubID  = JSON.parse(window.localStorage.getItem('_gHtk'));;
        let categoria = JSON.parse(window.localStorage.getItem('currentCategori'));

        let model = {
            oldData: oldData,
            modelApi: {
                clubID:clubID.clubID,
                categoryID: categoria.categoriesID
            }
        }
        reportEstadisticaFilterMutation.mutate(model);
    };


    if (isSuccess) {

        const { tableData, chartData } = data.message
        const { dataForChart, labelForChart } = chartData
        const { monthArr, weekArr, dateArr, reportArr, filterData, filterActive } = tableData

        let filterArr = {
            weekFilter: weekArr,
            monthFilter: monthArr,
            dateFilter: dateArr,
            reportFilter: reportArr,
        }

        return (

            <>
                <Row>
                    <Col xs="12">
                        <Card className="card-chart">
                            <CardHeader>
                                <Row>
                                    <Col className="text-left" sm="3">
                                        <CardTitle tag="h1" className="tittlestyle">
                                            Reportes
                                        </CardTitle>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <AreaChart
                                    head={labelForChart}
                                    data={dataForChart}
                                    idRef={chartRef2}
                                />
                            </CardBody>
                        </Card>

                        <>
                            <Col className="text-left" sm="4">
                                <CardTitle tag="h1" className="tittlestyle">
                                    Estadísticas de Reportes
                                </CardTitle>
                            </Col>
                            <Col lg="12" md="12">
                                <CustomizableDataTable
                                    filter={true}
                                    filterData={[
                                        {
                                            type: "select",
                                            multiselect: false,
                                            placheolder: filterActive.report.isActive
                                                ? filterActive.report.value.value
                                                : 'Técnico deportivo',
                                            filterActive: filterActive.report.isActive ? filterActive.report.value : {},
                                            dataForFilterList: filterArr.reportFilter,
                                            keyExtractor: "reportID",
                                        },

                                        {
                                            type: "select",
                                            multiselect: false,
                                            placheolder: filterActive.week.isActive ? typeof filterActive.week.value === 'object' ? `${filterActive.week.value.startDate} - ${filterActive.week.value.endDate}` : filterActive.week.value : "Seleccione Semana",
                                            filterActive: filterActive.week,
                                            dataForFilterList: filterArr.weekFilter,
                                            keyExtractor: "week",
                                        },
                                        //   { type: 'select', multiselect: false, placheolder: 'Seleccione Fecha', dataForFilterList: filterArr?.dateFilter, keyExtractor: 'date' }
                                    ]}
                                    tableType="regular"
                                    showAction={true}
                                    showActionExecel={true}
                                    headerData={["Nombre", "Reporte", "Mes", "Resultado", "Action"]}
                                    data={filterData}
                                    idRef={"tableReporte"}
                                    filterComponent={(item, ref) =>
                                        ChangeDataForFilterReportTable(item, ref)
                                    }
                                    handlerOnClik={(item) => UploadExcelReport(item, true)}
                                    handlerActionOnCLick={(item) => {
                                        if (item === "excel") {
                                            UploadExcelReport(item, false);
                                        }
                                    }}
                                />
                            </Col>
                        </>

                    </Col>
                </Row>
            </>
        )
    }

    if (isFetching) {
        return (
            <Row>
                <Col xs="12">
                    <CardSkeleton />
                </Col>
                <Col xs="12">
                    <TableSkeleton title="Estadísticas de Reportes" />
                </Col>
            </Row>
        )
    }

    if (isIdle) {

        if (active) {
            refetch()
        }
        return (
            <div>
            </div>
        )
    }

}


export default ReportTabs;