import DiagnosticTable from "components/DiagnosticTable";
import React, { useState, useEffect } from "react";
import { CardTitle, Col, Row } from "reactstrap";
import DataTable from "components/dataTable";
import { TableSkeleton } from "components/Skeleton";
import Proptypes from 'prop-types';
import { useQuery } from "react-query";
import { GetGeneralTabs } from "api/AtletaDashboardTabs/GeneralTabs";

const GeneralTabs = ({ enabled }) => {

    const handlerQuery = async () => {

        let session = window.localStorage.getItem('currentUser');

        let model = {
            sessionID: session
        }
        //console.log('MODEL ', model)
        try {
            let result = await GetGeneralTabs(model)
            return result
        } catch (error) {
            return error
        }
    }

    const { data, isSuccess, isError, isLoading, refetch } = useQuery('generalData', () => handlerQuery(), {
        enabled: enabled,
        refetchOnWindowFocus: enabled
    });

    useEffect(() => {
       
        if (enabled) {
            refetch()
        }
    }, [enabled])
    
    if (isLoading) {
        return (<Row>
            <Col xs={12}>
                <TableSkeleton title="Estadísticas de reportes" />
                <TableSkeleton title="Perfil de Personalidad" />
            </Col>
        </Row>)

    }

    if (isSuccess) {
        let generalData = data.message.data
        const { tableMental, tableReport } = generalData

        return (
            <>
                <Row>
                    <Col xs={12}>
                        <DiagnosticTable
                            title="Estadísticas de reportes"
                            filterArr={[
                                {
                                    id: "atleta",
                                    placheolder: "Selecciona una atleta",
                                    data: tableReport.map((row) => ({
                                        id: row.nombre,
                                        value: row.nombre,
                                        key: "nombre",
                                    })),
                                    multiselect: false,
                                }
                            ]}
                            header={[
                                { id: "nombre", label: "Atleta", minWidth: 170 },
                                {
                                    id: "fisica",
                                    label: "Fisica",
                                    format: (value) => `${Math.round(value)}%`,
                                    minWidth: 170
                                },
                                {
                                    id: "tecnica",
                                    label: "Técnica",
                                    format: (value) => `${Math.round(value)}%`,
                                    minWidth: 170
                                },

                                {
                                    id: "psicologia",
                                    label: "Psicología",
                                    format: (value) => `${Math.round(value)}%`,
                                    minWidth: 170,
                                },
                                {
                                    id: "antropometria",
                                    label: "Antropometría",
                                    format: (value) => `${Math.round(value)}%`,
                                    minWidth: 170,
                                },
                                {
                                    id: "medica",
                                    label: "Médica",
                                    format: (value) => `${Math.round(value)}%`,
                                    minWidth: 170
                                },
                                {
                                    id: "fisiologia",
                                    label: "Fisiológica",
                                    format: (value) => `${Math.round(value)}%`,
                                    minWidth: 170,
                                },
                                {
                                    id: "psicosocial",
                                    label: "Psicosocial",
                                    format: (value) => `${Math.round(value)}%`,
                                    minWidth: 170,
                                },
                                {
                                    id: "ovr_general",
                                    label: "OVR General",
                                    format: (value) => `${Math.round(value)}%`,
                                    minWidth: 170,
                                }
                            ]}
                            content={tableReport}
                            idRef="test_table_ovr"
                        />
                    </Col>
                </Row>
            </>
        )
    }
}


// TIPADO DE LOS PROPS
GeneralTabs.prototype = {
    enabled: Proptypes.bool
}

// SE DECLARA LA DATA POR DEFECTO
GeneralTabs.defaultProps = {
    enabled: false
}

export default GeneralTabs;