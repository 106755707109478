import React, { useState } from "react";
import AreaChartComponent from "components/AreaChart";
import EvalucionTable from "components/FisicalTab/dataTable";
import DataTable from "components/dataTable";
// import DatetimeCustom from "components/dateTime";
import Monthpicker from "components/monthpicker";
import ProgressCircle from "components/progressCircle";
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Row } from "reactstrap";
import Highcharts from "components/chart3D/index";
import dowload from "../../../assets/Iconos/Download.svg";
import Proptypes from 'prop-types';
import HelperManagers from "../../../source/handler";
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { GetFisicalTabModel, MutationsFisicalTabModel, MutationsInfoAtletaModel, MutationFisicaTabsChart, mutationExportablePdf } from "api/FisicaTabs";
import ModalComponent from "components/DowloadReport/Fisica/Modal";
import ReportFisic from 'components/DowloadReport/Fisica/ReportFisic';
import SingleSelect from "components/customSelect/singleSelect";
import { CardSkeleton, TableSkeleton } from "components/Skeleton";
import HelperManager from "../../../api/main/MainService";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { BsFillCloudArrowDownFill } from "react-icons/bs"
import Loading from "react-loading";
import moment from "moment";
const apiHelper = new HelperManagers();
const api = new HelperManager();

const FisicaTabs = ({ lesionList, handlerChangeData, active }) => {
    //console.log('asctive fisicas', active)
    const [dowloadEmpty, setDowloadEmpty] = useState(false);
    const [load, setIsLoad] = useState(true);
    const [loadingEvaluations, setLoadingEvaluations] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [reportData, setReportData] = useState({
        infoAtleta: {
            nombre: "",
            ovr: 0,
            logoClub: ""
        },
        evaluacionInfo: [],
        recomendations: [],
        nutricionInfo: []
    })
    const chartRef = React.useRef(null);


    let sport = JSON.parse(window.localStorage.getItem('globalContext'))
    let clubIDStorage = JSON.parse(window.localStorage.getItem('_gHtk'))
    let currentCategory = JSON.parse(window.localStorage.getItem('currentCategori'))
    let clubIDlocal = clubIDStorage.clubID
    let clubSession = JSON.parse(window.localStorage.getItem('_gHtk'))
    const queryClient = useQueryClient();

    const handlerQuery = async () => {
        let currentSessionID = JSON.parse(window.localStorage.getItem('_gHtk'))
        let currentCategory = JSON.parse(window.localStorage.getItem('currentCategori'))

        let model = {
            sessionID: currentSessionID.sessionID,
            categoryID: currentCategory.categoriesID,
            clubID: currentSessionID.clubID,
            startDate: "00:00:00",
            endDate: "00:00:00"
        }

        let result = await GetFisicalTabModel(model)
        // console.log('result', result)
        return result
    }

    // CREAMOS EL ESTADO
    const { data, refetch, isError, isIdle, isSuccess, isLoading, isFetching, error } = useQuery('fisicaDataTabs', () => handlerQuery(), {
        refetchOnWindowFocus: false,
        enabled: !active ? false : true
    })

    // MUTAMOS LA INFORMACION

    // SE FILTRA LA LISTA DE LOS REPORTE DE EVALUCION POR FECHA
    const FilterEvaluacionesForDate = async (date) => {
        setLoadingEvaluations(true)

        let model = {
            mutateModel: "evaluaciones",
            oldModel: data,
            date: date,
            categoryID: currentCategory.categoriesID,
            clubID: parseInt(clubIDlocal),
            clubSession: clubSession.sessionID
        }
        try {
            let result = await MutationsFisicalTabModel(model)
            return result
        } catch (error) {
            return error
        }
    }

    const FilterChartForDate = async (date) => {
        let model = {
            oldModel: data,
            date: date.value,
            categoryID: currentCategory.categoriesID,
            clubID: parseInt(clubIDlocal),
            clubSession: clubSession.sessionID
        }
        try {
            let result = await MutationFisicaTabsChart(model)
            return result
        } catch (error) {
            return error
        }
    }

    const FilterInfoAtleta = async (newData, key) => {

        let model = {
            oldModel: newData,
            sessionID: key.extractor,
            categoryID: currentCategory.categoriesID,
            clubID: parseInt(clubIDlocal),
        }
        try {
            let result = await MutationsInfoAtletaModel(model)
            // console.log({ result });
            return result
        } catch (error) {
            return error
        }
        // return {}
    }

    async function FilterChartDataForDate(weekRange) {
        let userToken = JSON.parse(window.localStorage.getItem("_gHtk"));

        let currentCategori = JSON.parse(
            window.localStorage.getItem("currentCategori")
        );
        let category = currentCategori.categoriesID;

        let modelApi = {
            sessionID: userToken.sessionID,
            categoryID: category,
            startDate: weekRange.startDate,
            endDate: weekRange.endDate,
        };

        let model = {
            mutateModel: "chart",
            oldModel: data,
            apiModel: modelApi
        }


        try {
            let result = await MutationsFisicalTabModel(model)
            //  console.log('fisical tabs', result)
            return result
        } catch (error) {
            return error
        }

    }

    // FUNCIONES DE MUTACION
    const reportFilter = useMutation('fisicaDataTabs', {
        mutationFn: FilterEvaluacionesForDate,
        onSuccess: (response) => {
            queryClient.setQueryData('fisicaDataTabs', (oldData) => {
                return {
                    ...oldData,
                    message: response.message.message
                }
            })

            setLoadingEvaluations(false)
        }
    })

    const infoAtletaModel = useMutation("infoAtletaFisicaDataTabs", {
        mutationFn: FilterInfoAtleta,
        onSuccess: (response) => {
            queryClient.setQueryData('infoAtletaFisicaDataTabs', (oldData) => {
                return {
                    ...oldData,
                    message: response.message.message
                }
            })
        }
    })

    const chartFilter = useMutation('fisicaDataTabs', {
        mutationFn: FilterChartForDate,
        onSuccess: (response) => {
            queryClient.setQueryData('fisicaDataTabs', (oldData) => {
                return {
                    ...oldData,
                    message: response.message
                }
            })
        }
    })


    const excelDowlLesionados = () => {
        let model = [];
        if (lesionList.length > 0) {
            model = lesionList;
            // console.log('modelLesionados', model)
            let result = apiHelper.dowloadexcel(model, "Lista de lesionados");
            return result;
        } else {
            setDowloadEmpty(true);
            setTimeout(() => {
                setDowloadEmpty(false);
            }, 1200);
        }
    };

    const handlerButton = async (key) => {
        //console.log('type ', type , 'key ', key)


        changeDataExportableMutation(key)

        setIsLoad(true);
        setIsOpen(true);
        setTimeout(() => {
            setIsLoad(false);
        }, 1000);
    }


    // HANDLER DE MUTACION
    const handlerFilterForDate = (date) => {
        // console.log('estoy en el filtro de fecha', date)
        reportFilter.mutate(date.value)
    }

    const getModelInfoAtleta = (data, key) => {
        infoAtletaModel.mutate(data, key)
    }

    const handlerFilterChartData = (weekRange) => {
        chartFilter.mutate(weekRange)
    }

    //HANDLER MUTATION DATA PDF

    const handlerExportablePdf = async (model) => {

        let result = await mutationExportablePdf(model)

        let dwData = result.message.message.evaluacion.dwData

        //SE VALIDA QUE LLEGUE LA INFO PARA CARGAR EL PDF Y SE PROCESA
        if (dwData.length > 0) {

            let result = dwData.filter(row => row.key === model.key.extractor);

            let { img = "", clubFlag = "" } = result[0].infoAtleta;

            let imgUrl = ""; //Initialize image
            let clubFlagUrl = ""; //Initialize image

            if (img.length > 0) {
                let imgB64 = await api.GetImageB64({
                    url: img,
                });
                imgUrl = imgB64.message
            }
            if (clubFlag.length > 0) {
                let clubFlagB64 = await api.GetImageB64({
                    url: clubFlag,
                });
                clubFlagUrl = clubFlagB64.message
            }

            //SE PASA EL SPORT AL QUE PERTENECE
            let global = JSON.parse(window.localStorage.getItem("globalContext"));

            setReportData({
                infoAtleta: { ...result[0].infoAtleta, imgB64: imgUrl, clubFlagB64: clubFlagUrl },
                evaluacionInfo: result[0].evaDatails,
                recomendations: result[0].recomendacion,
                nutricionInfo: result[0].nutricion,
                sportID: parseInt(global.sportID)
            })
        } else {
            return false
        }

        return result.message
    }

    const changeDataExportablePdf = useMutation('fisicaDataTabs', {
        mutationFn: handlerExportablePdf,
        onSuccess: (response) => {
            queryClient.setQueryData('fisicaDataTabs', (oldData) => {
                return {
                    ...oldData,
                    message: response.message
                }
            })
        }
    })

    const changeDataExportableMutation = (key) => {

        let club = JSON.parse(window.localStorage.getItem('_gHtk'))
        let currentCategory = JSON.parse(window.localStorage.getItem('currentCategori'))
        let dateActive = data.message.evaluacion.arrDate

        let model = {
            oldData: data,
            clubID: club.clubID,
            category: currentCategory.categoriesID,
            key: key,
            clubSessionID: club.sessionID,
            date: dateActive.active

        }

        changeDataExportablePdf.mutate(model)
    }

    if (isIdle) {

        if (active) {
            refetch()
        }

        return (
            <div>
            </div>
        )
    }

    // MENSAJE CUANDO EL COMPONENTE ESTE CARGADON
    if (isFetching) {
        return (

            <Row>
                <Col xs="12">
                    <CardSkeleton />
                </Col>
                <Col xs="12">
                    <TableSkeleton title="Reportes de evaluaciones físicas" />
                </Col>
                <Col xs="12">
                    <TableSkeleton title="Composición Corporal" />
                </Col>
                <Col xs="12">
                    <CardSkeleton />
                </Col>
            </Row>



        )
    }

    // VALIDACION CUANDO LA CARGA SEA SUCCESS 
    if (isSuccess) {
        const { tableData, arrDate } = data.message.evaluacion
        const { microData, chartInfo, corpData, weekRange } = data.message
        const { chartData, labelData } = chartInfo;


        return (
            <>
                <Row>
                    <Col xs="12">
                        <Card className="card-chart">
                            <CardHeader>
                                <Row>
                                    <Col className="text-left" sm="3">
                                        <CardTitle tag="h1" className="tittlestyle">
                                            Evaluaciones Físicas
                                        </CardTitle>
                                    </Col>
                                    <Col sm="9">
                                        <Row className="mr-1">
                                            <Col xs="12">
                                                <SingleSelect title={weekRange.length > 0 ? weekRange[0].value : "Seleccione fecha"} item={weekRange} onChange={(item) => handlerFilterChartData(item)} />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <AreaChartComponent
                                    head={labelData}
                                    data={chartData}
                                    idRef={chartRef}
                                    toolTip={{
                                        current: "Evaluación actual",
                                        last: "Evaluación previa",
                                    }}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-left" sm="4">
                        <CardTitle tag="h1" className="tittlestyle">
                            Reportes de evaluaciones físicas
                        </CardTitle>
                    </Col>
                    {
                        loadingEvaluations ? (
                            <Col xs="12">
                                <TableSkeleton />
                            </Col>
                        ) : (
                            <Col lg="12" md="12">
                                <EvalucionTable
                                    filterTotal={1}
                                    filterType={[
                                        {
                                            type: 'select',
                                            multiselect: true,
                                            placheolder: arrDate.active === "" ? "Seleccione una fecha" : arrDate.active,
                                            data: arrDate.arr
                                        }
                                    ]}
                                    idRef={"fisicas"}
                                    dataTable={tableData}
                                    handlerButton={(index) => handlerButton(index)}
                                    handlerOnChage={(date) => handlerFilterForDate(date)}
                                />
                            </Col>
                        )
                    }

                </Row>
                <Row>
                    <Col className="text-left" sm="4">
                        <CardTitle tag="h1" className="tittlestyle">
                            Composición Corporal
                        </CardTitle>
                    </Col>
                    <Col lg="12" md="12">
                        <DataTable
                            filterTotal={0}
                            data={corpData}
                            idRef={"tableCorpInfo"}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col className="text-left" sm="2">
                                        <CardTitle tag="h2" className="tittlestyle">
                                            Manejo de Carga
                                        </CardTitle>
                                    </Col>
                                    <Col sm="10">
                                        <Row className="">
                                            <Monthpicker
                                                handler={(item) =>
                                                    handlerChangeData(2, item)
                                                }
                                            />
                                        </Row>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Row className="align-items-center">
                                    <Col xs="3">
                                        <ProgressCircle data={[microData.microcicloOvr]} />
                                    </Col>
                                    <hr className="hrDivider" />
                                    <Col xs="8">
                                        <Highcharts data={microData.microciclo} />
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter style={{ display: "flex" }}>
                                <button
                                    className="btnDowload"
                                    style={{ marginRight: "10px" }}
                                    onClick={() => excelDowlLesionados()}
                                >
                                    <span style={{ paddingLeft: "9px" }}>
                                        Lista de Lesionados
                                    </span>
                                    <div className="btnImg">
                                        <img
                                            src={dowload}
                                            style={{
                                                width: 20,
                                                height: 20,
                                            }}
                                        />
                                    </div>
                                </button>
                                {dowloadEmpty && (
                                    <div
                                        style={{ marginLeft: "15px", marginTop: "11px" }}
                                    >
                                        <span
                                            style={{
                                                background: "#ff8d7296",
                                                color: "#000",
                                                padding: "8px",
                                                borderRadius: "8px",
                                                fontSize: "10px",
                                            }}
                                        >
                                            No existen datos para exportar
                                        </span>
                                    </div>
                                )}
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
                <ModalComponent
                    show={isOpen}
                    onClose={() => setIsOpen(false)}
                    height={'90%'}
                    width={'90%'}
                    backdropStyle={{ 'backdropFilter': 'blur(6px)' }}
                >
                    <PDFDownloadLink
                        document={<ReportFisic data={reportData} />}
                        fileName={`report_${reportData.infoAtleta.nombre}_${moment.utc(new Date()).format("YYYY-MM-DD")}.pdf`}>
                        <button style={{
                            border: 'none',
                            borderRadius: '20px',
                            background: '#0C162B',
                            color: 'white',
                            padding: '5px 15px',
                            position: 'absolute',
                            top: '5px',
                            left: '10px',
                            fontWeight: 'bold',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px'
                        }}>
                            <BsFillCloudArrowDownFill size="20px" color="white"/>
                            PDF
                        </button>
                    </PDFDownloadLink>
                    {
                        changeDataExportablePdf.isLoading ?
                            <Row style={{ justifyContent:'center'}}>
                                <text className="mr-2">Cargando Información</text>
                               
                                 <Loading type="spin" width="20px" height="20px" color="blue"/>
                            </Row>
                            :
                            <PDFViewer style={{ width: '100%', height: '100%' }} showToolbar={false} >
                                <ReportFisic data={reportData} />
                            </PDFViewer>

                    }

                </ModalComponent>
            </>
        )
    }

    if (isError) {
        return (<div><h3>Ocurrio un error {error?.message}</h3></div>)
    }

}

FisicaTabs.prototype = {

    lesionList: Proptypes.array.isRequired,
    posicion: Proptypes.array.isRequired,
    handlerChangeData: Proptypes.func
}
FisicaTabs.defaultProps = {
    posicion: [],
    lesionList: [],

    handlerChangeData: () => { }
}

export default FisicaTabs;