import React from "react";
import { Row, Col, Button, Input } from "reactstrap";
import DowloadPDF from '../../../assets/Iconos/PDF.svg';
import HelperManager from "../../../api/main/MainService";
import flags from "../../../assets/img/flag/escudo.png"
import Avatar from "../../../assets/img/default-avatar.png"
import ProgressBar from "@ramonak/react-progress-bar";
import {
    CircularProgressbar,
    CircularProgressbarWithChildren,
    buildStyles
} from "react-circular-progressbar";
import "./report.css"
import "react-circular-progressbar/dist/styles.css";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import moment from "moment"
import HandlerManager from '../../../source/handler'
import Loading from "react-loading";
const api = new HelperManager();
const apiHandler = new HandlerManager()

function Reportes({ props }) {

    const [loading, setLoading] = React.useState(false);
    const [component1, setComponent1] = React.useState({
        compName: '',
        fundament1: {
            name: '',
            item: []
        },
        fundament2: {
            name: '',
            item: []
        }
    });
    const [component2, setComponent2] = React.useState({
        compName: '',
        fundament1: {
            name: '',
            item: []
        },
        fundament2: {
            name: '',
            item: []
        }
    });
    const [component3, setComponent3] = React.useState({
        compName: '',
        fundament1: {
            name: '',
            item: []
        },
        fundament2: {
            name: '',
            item: []
        }
    });
    const [statistics, setStatistics] = React.useState({
        amejorar: "",
        enfoque: "",
        deMoejora: ""
    })
    const [evaluacion, setEvaluacion] = React.useState([]);
    const [atletaInfo, setAtletaInfo] = React.useState([]);
    const [flag, setFlag] = React.useState('')
    const [observacion, setObservacion] = React.useState('')
    const [imgDefault, setImgDefault] = React.useState()
    const [generatePdf, setGemeratePdf] = React.useState(false);

    const dowloadPDF = async (nombre) => {
        setGemeratePdf(true)
        let refElemt = document.querySelector('#area')
        let name = nombre
        let blod1 = refElemt.children[0]
        let blod2 = refElemt.children[1]

        const pdf = new jsPDF("p", "mm", "letter", false);

        // SE carga la primera pagina
        const canvas = await html2canvas(blod1);
        const data = canvas.toDataURL('image/png');
        let date = moment.utc(new Date()).format('YYYY-MM-DD');
        let filename = `report_${name}_${date}.pdf`;

       // Se carga la segunda pagina
        const canvas2 = await html2canvas(blod2);
        const data2 = canvas2.toDataURL('image/png');

       // const imgProperties = pdf.getImageProperties(data);

        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (pdf.internal.pageSize.getHeight()) //(((imgProperties.height * pdfWidth) / imgProperties.width));
        
        // Se agrega la primera pagina
        pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.addPage();
        
        // Se agrega la segunda pagina
        pdf.addImage(data2, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save(filename);
        
        setGemeratePdf(false)
    }

    React.useEffect(() => {
        (async () => {
            if (props.length > 0) {

                setLoading(true)
                setEvaluacion(props[0].evaluacion)

                let model = {
                    url: props[0].component1[0].imgData
                }

                let flagModel = {
                    url: props[0].flag
                }
                /// SE VALIDA QUE EL URL SEA EL ESPERADO
                const validateOne = apiHandler.checkURL(model.url)
                const validateTwo = apiHandler.checkURL(flagModel.url)


                let img = await api.GetImageB64(model);
                let flag = await api.GetImageB64(flagModel);

                ///SE CARGA IMAGEN DEL CLUB, SE VALIDA QUE EXISTA, EN CASO CONTRARIO SE LE ASIGNA POR DEFECTO 'flags'
                if (flag.hasOwnProperty('message')) {
                    setFlag(() => validateTwo ? flag.message : flags)
                } else {
                    setFlag(null)
                }

                let obj = {
                    nombre: props[0].component1[0].nombre,
                    ovr: Math.round(props[0].component1[0].ovr),
                    img: !validateOne ? Avatar : img.message, //SE VALIDA QUE EL URL DE LA IMG SEA CORRECTO, EN CASO CONTRARIO SE LE ASIGNA UNA POR DEFECTO 'Avatar'
                    edad: props[0].component1[0].edad,
                    peso: Math.round(props[0].component1[0].peso),
                    categoria: props[0].component1[0].categoriesName,
                    clubName: props[0].component1[0].clubName
                }

                setObservacion(props[0].component1[0].observacion)
                setAtletaInfo(obj)

                let evaluacion = []
                let media = 0

                for (let index = 0; index < props[0].evaluacion.length; index++) {
                    let elem = props[0].evaluacion[index];
                    let category = props[0].categoryID
                    let position = props[0].sportPosition
                    let typeEva = elem.filterSelected

                    if (typeEva === "Categoría") {

                        if (elem.filterCriterial[0].filterItem.indexOf(category.categoriesID) !== -1) {
                            evaluacion.push(elem)
                        }
                    }

                    if (typeEva === "Posición") {

                        if (elem.filterCriterial[0].filterItem.indexOf(position) !== -1) {
                            evaluacion.push(elem)
                        }
                    }
                }


                //OBTNER VALOR DE COMONENTE 1
                let objStatistic = []

                let comp1 = {
                    compName: '',
                    ovr: '',
                    fundament1: {
                        name: '',
                        ovr: 0,
                        item: []
                    },
                    fundament2: {
                        name: '',
                        ovr: 0,
                        item: []
                    }
                }

                //console.log('EVALUACION ', evaluacion)
                comp1.compName = evaluacion[0].aspect[0].component[0].value
                comp1.fundament1.name = evaluacion[0].aspect[0].fundamento1[0].fundamento[0].value
                comp1.fundament1.item = evaluacion[0].aspect[0].fundamento1[0].item
                comp1.fundament2.name = evaluacion[0].aspect[0].fundamento2[0].fundamento[0].value
                comp1.fundament2.item = evaluacion[0].aspect[0].fundamento2[0].item


                let component1 = props[0].component1
                comp1.ovr = Math.round(component1[0].promCog)
                let fundament1 = component1.filter((task) => {
                    if (parseInt(task.field) === 1) return task
                })
                let fundament2 = component1.filter((task) => {
                    if (parseInt(task.field) === 2) return task
                })

                comp1.fundament1.item.filter((task, index) => {
                    let item = index + 1

                    //OBTENEMOS LA MEDIA 
                    let val = 0
                    media = fundament1.map((task) => {
                        val = val + Math.round(task.percent)
                        return val
                    })

                    for (let index = 0; index < fundament1.length; index++) {
                        let elem = fundament1[index];
                        if (elem.option === item) {
                            task['percent'] = Math.round(elem.percent)
                            task['media'] = elem.media
                            task['diff'] = elem.diff
                        }
                    }

                    return task
                })

                comp1.fundament1.ovr = parseInt(media[fundament1.length - 1] / fundament1.length)
                media = 0
                comp1.fundament2.item.filter((task, index) => {
                    let item = index + 1
                    //OBTENEMOS LA MEDIA 
                    let val = 0
                    media = fundament2.map((task) => {
                        val = val + Math.round(task.percent)
                        return val
                    })

                    for (let index = 0; index < fundament2.length; index++) {
                        let elem = fundament2[index];
                        if (elem.option === item) {
                            task['percent'] = Math.round(elem.percent)
                            task['media'] = elem.media
                            task['diff'] = elem.diff
                        }
                    }

                    return task
                })

                comp1.fundament2.ovr = parseInt(media[fundament2.length - 1] / fundament2.length)
                objStatistic.push(comp1)
                setComponent1(comp1)

                comp1 = {
                    compName: '',
                    ovr: '',
                    fundament1: {
                        name: '',
                        ovr: 0,
                        item: []
                    },
                    fundament2: {
                        name: '',
                        ovr: 0,
                        item: []
                    }
                }
                component1 = []
                fundament1 = []
                fundament2 = []
                comp1.compName = evaluacion[0].aspect[1].component[0].value

                comp1.fundament1.name = evaluacion[0].aspect[1].fundamento1[0].fundamento[0].value
                comp1.fundament1.item = evaluacion[0].aspect[1].fundamento1[0].item
                comp1.fundament2.name = evaluacion[0].aspect[1].fundamento2[0].fundamento[0].value
                comp1.fundament2.item = evaluacion[0].aspect[1].fundamento2[0].item

                component1 = props[0].component2
                comp1.ovr = Math.round(component1[0].promDef)


                fundament1 = component1.filter((task) => {
                    if (parseInt(task.field) === 1) return task
                })


                fundament2 = component1.filter((task) => {
                    if (parseInt(task.field) === 2) return task
                })

                media = 0
                comp1.fundament1.item.filter((task, index) => {
                    let item = index + 1
                    //OBTENEMOS LA MEDIA 
                    let val = 0
                    media = fundament1.map((task) => {
                        val = val + Math.round(task.percent)
                        return val
                    })
                    for (let index = 0; index < fundament1.length; index++) {
                        let elem = fundament1[index];
                        if (elem.option === item) {
                            task['percent'] = Math.round(elem.percent)
                            task['media'] = elem.media
                            task['diff'] = elem.diff
                        }
                    }

                    return task
                })
                comp1.fundament1.ovr = parseInt(media[fundament1.length - 1] / fundament1.length)
                media = 0
                comp1.fundament2.item.filter((task, index) => {
                    let item = index + 1
                    //OBTENEMOS LA MEDIA 
                    let val = 0
                    media = fundament2.map((task) => {
                        val = val + Math.round(task.percent)
                        return val
                    })
                    for (let index = 0; index < fundament2.length; index++) {
                        let elem = fundament2[index];
                        if (elem.option === item) {
                            task['percent'] = Math.round(elem.percent)
                            task['media'] = elem.media
                            task['diff'] = elem.diff
                        }
                    }

                    return task
                })
                comp1.fundament2.ovr = parseInt(media[fundament2.length - 1] / fundament2.length)
                objStatistic.push(comp1)
                setComponent2(comp1)


                comp1 = {
                    compName: '',
                    ovr: '',
                    fundament1: {
                        name: '',
                        ovr: 0,
                        item: []
                    },
                    fundament2: {
                        name: '',
                        ovr: 0,
                        item: []
                    }
                }
                component1 = []
                fundament1 = []
                fundament2 = []

                comp1.compName = evaluacion[0].aspect[2].component[0].value
                comp1.fundament1.name = evaluacion[0].aspect[2].fundamento1[0].fundamento[0].value
                comp1.fundament1.item = evaluacion[0].aspect[2].fundamento1[0].item
                comp1.fundament2.name = evaluacion[0].aspect[2].fundamento2[0].fundamento[0].value
                comp1.fundament2.item = evaluacion[0].aspect[2].fundamento2[0].item

                component1 = props[0].component3
                comp1.ovr = Math.round(component1[0].promOf)


                fundament1 = component1.filter((task) => {
                    if (parseInt(task.field) === 1) return task
                })
                fundament2 = component1.filter((task) => {
                    if (parseInt(task.field) === 2) return task
                })

                media = 0
                comp1.fundament1.item.filter((task, index) => {
                    let item = index + 1
                    //OBTENEMOS LA MEDIA 
                    let val = 0
                    media = fundament1.map((task) => {
                        val = val + Math.round(task.percent)
                        return val
                    })
                    for (let index = 0; index < fundament1.length; index++) {
                        let elem = fundament1[index];
                        if (elem.option === item) {
                            task['percent'] = Math.round(elem.percent)
                            task['media'] = elem.media
                            task['diff'] = elem.diff

                        }
                    }

                    return task
                })
                comp1.fundament1.ovr = parseInt(media[fundament1.length - 1] / fundament1.length)
                media = 0
                comp1.fundament2.item.filter((task, index) => {
                    let item = index + 1
                    //OBTENEMOS LA MEDIA 
                    let val = 0
                    media = fundament2.map((task) => {
                        val = val + Math.round(task.percent)
                        return val
                    })
                    for (let index = 0; index < fundament2.length; index++) {
                        let elem = fundament2[index];
                        if (elem.option === item) {
                            task['percent'] = Math.round(elem.percent)
                            task['media'] = elem.media
                            task['diff'] = elem.diff
                        }
                    }

                    return task
                })
                comp1.fundament2.ovr = parseInt(media[fundament2.length - 1] / fundament2.length)
                objStatistic.push(comp1)
                setComponent3(comp1)


                // OBTENEMOS LOS VALORES ESTADISTICOS 

                // A MEJORAR
                let aMejorar = objStatistic.reduce((a, b) => {
                    if (a.ovr > b.ovr) {
                        aMejorar = b
                    } else {
                        aMejorar = a
                    }

                    return aMejorar
                })

                // A MEJORAR
                let enfoque = ""

                if (aMejorar.fundament1.ovr > aMejorar.fundament2.ovr) {
                    enfoque = aMejorar.fundament1
                } else {
                    enfoque = aMejorar.fundament2
                }
                let items = enfoque.item
                let deMoejora = items.reduce((a, b) => {
                    if (a.percent === b.percent) {
                        deMoejora = a
                    } else {
                        if (a.percent > b.percent) {
                            deMoejora = b
                        } else {
                            deMoejora = a
                        }
                    }
                    return deMoejora
                })

                setStatistics(prev => {
                    prev.amejorar = aMejorar.compName
                    prev.enfoque = enfoque.name
                    prev.deMoejora = deMoejora.value + ' ' + deMoejora.definition
                    return prev
                })

                //console.log('DEMEJORRA ', deMoejora)
                // ENFOQUE

                setLoading(false)
            }
        })();

    }, [props])

    const pdfDowload = () => {
        dowloadPDF(atletaInfo.nombre);
    };

    return (
        <Row>
            <Col xs={12} style={{ marginBottom: "10px" }}>
                <Row style={{ alignItems: "center", justifyContent: "space-between", display: "flex", flexDirection: "row", width: "90em" }}>
                    <Button onClick={() => pdfDowload()} style={{ marginLeft: !loading ? "50px" : "0px" }}>
                        {
                            generatePdf ?
                                <Row style={{ justifyContent: 'center' }}>
                                    <Loading type="spin" width="20px" height="20px" color="white" />
                                </Row> : `Descargar PDF`
                        }
                    </Button>
                    {
                        loading ?

                            <Row style={{ justifyContent: 'center' }}>
                                <h4 className="mr-2">Cargando Información</h4>
                                <Loading type="spin" width="20px" height="20px" color="blue" />
                            </Row>
                            : null
                    }
                </Row>
            </Col>
            {
                !loading ?
                    <Col xs={12} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <div id={"area"}>
                            <div className="ContainerBck sheet" style={{ marginBottom: '20px' }} >
                                <div className="headerstyle">
                                    <Row>
                                        <Col xs="2" >
                                            <div className='escudoReport'>
                                                <img src={atletaInfo?.img} style={{ marginLeft: '0px', marginTop: '1px', width: '125px', height: '162px' }} />
                                            </div>
                                        </Col>
                                        <Col xs="10">
                                            <Row className="mt-4">
                                                <Col md={9}>
                                                    <Row>
                                                        <h2 className='headertextmain'>{atletaInfo?.nombre}</h2>
                                                    </Row>
                                                    <Row>

                                                        <span className='headertext'> {atletaInfo?.ovr}% OVR</span>
                                                    </Row>
                                                </Col>
                                                <Col md={3} style={{ display: 'flex', alignItems: "flex-end", flexDirection: 'column', justifyContent: "flex-end", marginLeft: '-37px' }}>
                                                    <img src={flag === null ? flags : flag} style={{
                                                        width: '80px',
                                                        height: '80px',
                                                    }} />
                                                    <h4 className='headertext'>{atletaInfo?.clubName}</h4>
                                                </Col>
                                            </Row>

                                            <Row className="mt-2">
                                                <Col md='8'>
                                                    <Row>
                                                        <Col className="text-center">
                                                            <span className='headertext headertextlow mb-2' style={{ fontSize: "11px" }}> Area a mejorar</span>
                                                            <span className='headertext headertextlow' style={{ fontSize: "14px" }}>{statistics.amejorar}</span>
                                                        </Col>
                                                        <Col className="text-center">
                                                            <span className='headertext headertextlow mb-2' style={{ fontSize: "11px" }}> Enfoque particular</span>
                                                            <span className='headertext headertextlow' style={{ fontSize: "14px" }}>{statistics.enfoque}</span>
                                                        </Col>
                                                        <Col className="text-center">
                                                            <span className='headertext headertextlow mb-2' style={{ fontSize: "11px" }}> Punto critico de mejora</span>
                                                            <span className='headertext Rheadertextlow' style={{ fontSize: "14px" }}>{statistics.deMoejora}</span>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col className='headercolmid'>
                                                    <span className='headertextlow' style={{ fontSize: "12px" }}>{atletaInfo?.categoria}</span>
                                                </Col>

                                                <Col>
                                                    <span className='headertextlow' style={{ fontSize: "12px" }}> {atletaInfo?.edad} Años</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                                {

                                    component1.compName !== "" &&
                                    <div className='resultstyle'>
                                        <Row className="componet-container mb-2 h-50">
                                            <Col md={6}>
                                                <span className='resultmain'>{component1.compName}</span>
                                            </Col>
                                            <Col md={6}>
                                                <ProgressBar completed={component1.ovr} maxCompleted={100} bgColor={'linear-gradient(to bottom left, #00f2c3, #0098f0, #00f2c3)'} />

                                            </Col>
                                        </Row>

                                        <Row className="componet-container paddingFix">
                                            <Col md={6} className='divider-vertical col-fix-h'>
                                                <Row className='resultcategory mb-4'>
                                                    <Col xs="12">
                                                        <span className='resultlabelcat'>{component1.fundament1.name}</span>
                                                        <div style={{ marginTop: 15, display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                                                            <div style={{ width: "25%" }}>
                                                                <CircularProgressbar
                                                                    value={component1.fundament1.ovr}
                                                                    text={`${component1.fundament1.ovr}%`}
                                                                    circleRatio={0.75}
                                                                    styles={buildStyles({
                                                                        rotation: 1 / 2 + 1 / 8,
                                                                        strokeLinecap: "butt",
                                                                        trailColor: "#eee",
                                                                        pathColor: "#0ce78c",
                                                                        textColor: "#fff"
                                                                    })}
                                                                />
                                                            </div>
                                                            <span className="span-divider"></span>
                                                        </div>

                                                    </Col>
                                                </Row>

                                                {
                                                    component1.fundament1.item.map((task, index) => {

                                                        if (task.percent > 0) {
                                                            return (
                                                                <Row className='mb-3' key={parseInt(Math.random(100 * 5).toString().replace('.', 0))} style={{ alignItems: 'center', justifyContent: 'center' }}>
                                                                    <Col md={4}>
                                                                        <Row style={{ alignItems: 'center', justifyContent: 'space-around' }}>
                                                                            <span className='text-white' style={{ flexWrap: 'wrap', textAlign: 'center', fontSize: '14px' }}>{task.value} {task.definition}</span>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        {
                                                                            task.media > 0 && <Row style={{ marginLeft: '15px' }}>
                                                                                <span style={{ color: '#fff', fontSize: '10px' }}>{task.media}%</span>
                                                                                <span style={{ color: '#d5d5d5', fontSize: '9px' }}>(m)</span>
                                                                            </Row>
                                                                        }


                                                                        <ProgressBar completed={task.percent < 20 ? 26 : task.percent} maxCompleted={100} bgColor={'linear-gradient(to bottom left, #00f2c3, #0098f0, #00f2c3)'}
                                                                            customLabelStyles={{ fontSize: '14px' }} />
                                                                    </Col>
                                                                    <Col md={2} >
                                                                        {
                                                                            task.diff < 0 && <Row>
                                                                                <img src={require('../../../assets/Iconos/2x/south@2x.png')} style={{
                                                                                    width: '10px',
                                                                                    height: '10px'
                                                                                }} />
                                                                                <span style={{ color: "red", fontSize: '12px' }}>{task.diff}%</span>
                                                                            </Row>
                                                                        }
                                                                        {
                                                                            task.diff > 0 && <Row>
                                                                                <img src={require('../../../assets/Iconos/2x/north@2x.png')} style={{
                                                                                    width: '10px',
                                                                                    height: '10px'
                                                                                }} />
                                                                                <span style={{ color: "#4FFF0A", fontSize: '12px' }}>{task.diff}%</span>
                                                                            </Row>
                                                                        }
                                                                        {
                                                                            task.diff === 0 && <Row>
                                                                                <img src={require('../../../assets/Iconos/2x/power_input@2x.png')} style={{
                                                                                    width: '10px',
                                                                                    height: '10px'
                                                                                }} />

                                                                            </Row>
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                            )
                                                        }
                                                    })
                                                }

                                            </Col>
                                            <Col md={6} className='col-fix-h'>
                                                <Row className='resultcategory mb-4'>
                                                    <Col xs="12">
                                                        <span className='resultlabelcat'>{component1.fundament2.name}</span>
                                                        <div style={{ marginTop: 15, display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                                                            <div style={{ width: "25%" }}>
                                                                <CircularProgressbar
                                                                    value={component1.fundament2.ovr}
                                                                    text={`${component1.fundament2.ovr}%`}
                                                                    circleRatio={0.75}
                                                                    styles={buildStyles({
                                                                        rotation: 1 / 2 + 1 / 8,
                                                                        strokeLinecap: "butt",
                                                                        trailColor: "#eee",
                                                                        pathColor: "#0ce78c",
                                                                        textColor: "#fff"
                                                                    })}
                                                                />

                                                            </div>
                                                            <span className="span-divider"></span>
                                                        </div>

                                                    </Col>
                                                </Row>

                                                {
                                                    component1.fundament2.item.map((task, index) => {
                                                        if (task.percent > 0) {
                                                            return (
                                                                <Row className='mb-3' key={parseInt(Math.random(100 * 5).toString().replace('.', 0))}>
                                                                    <Col md={4}>
                                                                        <Row style={{ alignItems: 'center', justifyContent: 'space-around' }}>
                                                                            <span className='text-white' style={{ flexWrap: 'wrap', textAlign: 'center', fontSize: '14px' }}>{task.value} {task.definition}</span>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        {
                                                                            task.media > 0 && <Row style={{ marginLeft: '15px' }}>
                                                                                <span style={{ color: '#fff', fontSize: '10px' }}>{task.media}%</span>
                                                                                <span style={{ color: '#d5d5d5', fontSize: '9px' }}>(m)</span>
                                                                            </Row>
                                                                        }
                                                                        <ProgressBar
                                                                            customLabelStyles={{ fontSize: '14px' }}
                                                                            completed={task.percent < 20 ? 26 : task.percent}
                                                                            maxCompleted={100}
                                                                            bgColor={'linear-gradient(to bottom left, #00f2c3, #0098f0, #00f2c3)'} />
                                                                    </Col>
                                                                    <Col md={2}>
                                                                        {
                                                                            task.diff < 0 && <Row>
                                                                                <img src={require('../../../assets/Iconos/2x/south@2x.png')} style={{
                                                                                    width: '10px',
                                                                                    height: '10px'
                                                                                }} />
                                                                                <span style={{ color: "red", fontSize: '12px' }}>{task.diff}%</span>
                                                                            </Row>
                                                                        }
                                                                        {
                                                                            task.diff > 0 && <Row>
                                                                                <img src={require('../../../assets/Iconos/2x/north@2x.png')} style={{
                                                                                    width: '10px',
                                                                                    height: '10px'
                                                                                }} />
                                                                                <span style={{ color: "#4FFF0A", fontSize: '12px' }}>{task.diff}%</span>
                                                                            </Row>
                                                                        }
                                                                        {
                                                                            task.diff === 0 && <Row>
                                                                                <img src={require('../../../assets/Iconos/2x/power_input@2x.png')} style={{
                                                                                    width: '10px',
                                                                                    height: '10px'
                                                                                }} />

                                                                            </Row>
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                            )
                                                        }
                                                    })
                                                }

                                            </Col>
                                        </Row>
                                    </div>

                                }
                                {

                                    component2.compName !== "" &&
                                    <div className='resultstyle' style={{ marginTop: '-45px' }}>
                                        <Row className="componet-container mb-2 h-50">
                                            <Col md={6}>
                                                <span className='resultmain'>{component2.compName}</span>
                                            </Col>
                                            <Col md={6}>
                                                <ProgressBar completed={component2.ovr} maxCompleted={100} bgColor={'linear-gradient(to bottom left, #00f2c3, #0098f0, #00f2c3)'} />
                                            </Col>


                                        </Row>

                                        <Row className="componet-container paddingFix">
                                            <Col md={6} className='divider-vertical col-fix-h'>
                                                <Row className='resultcategory mb-4'>
                                                    <Col xs="12">
                                                        <span className='resultlabelcat'>{component2.fundament1.name}</span>
                                                        <div style={{ marginTop: 15, display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                                                            <div style={{ width: "25%" }}>
                                                                <CircularProgressbar
                                                                    value={component2.fundament1.ovr}
                                                                    text={`${component2.fundament1.ovr}%`}
                                                                    circleRatio={0.75}
                                                                    styles={buildStyles({
                                                                        rotation: 1 / 2 + 1 / 8,
                                                                        strokeLinecap: "butt",
                                                                        trailColor: "#eee",
                                                                        pathColor: "#0ce78c",
                                                                        textColor: "#fff"
                                                                    })}
                                                                />
                                                            </div>
                                                            <span className="span-divider"></span>
                                                        </div>

                                                    </Col>
                                                </Row>

                                                {
                                                    component2.fundament1.item.map((task, index) => {
                                                        if (task.percent > 0) {
                                                            return (
                                                                <Row className='mb-3' key={parseInt(Math.random(100 * 5).toString().replace('.', 0))}>
                                                                    <Col md={4}>
                                                                        <Row style={{ alignItems: 'center', justifyContent: 'space-around' }}>
                                                                            <span className='text-white' style={{ flexWrap: 'wrap', textAlign: 'center', fontSize: '14px' }}>{task.value} {task.definition}</span>

                                                                        </Row>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        {
                                                                            task.media > 0 && <Row style={{ marginLeft: '15px' }}>
                                                                                <span style={{ color: '#fff', fontSize: '10px' }}>{task.media}%</span>
                                                                                <span style={{ color: '#d5d5d5', fontSize: '9px' }}>(m)</span>
                                                                            </Row>
                                                                        }
                                                                        <ProgressBar completed={task.percent < 20 ? 26 : task.percent} maxCompleted={100} bgColor={'linear-gradient(to bottom left, #00f2c3, #0098f0, #00f2c3)'}
                                                                            customLabelStyles={{ fontSize: '14px' }} />
                                                                    </Col>
                                                                    <Col md={2}>
                                                                        {
                                                                            task.diff < 0 && <Row>
                                                                                <img src={require('../../../assets/Iconos/2x/south@2x.png')} style={{
                                                                                    width: '10px',
                                                                                    height: '10px'
                                                                                }} />
                                                                                <span style={{ color: "red", fontSize: '12px' }}>{task.diff}%</span>
                                                                            </Row>
                                                                        }
                                                                        {
                                                                            task.diff > 0 && <Row>
                                                                                <img src={require('../../../assets/Iconos/2x/north@2x.png')} style={{
                                                                                    width: '10px',
                                                                                    height: '10px'
                                                                                }} />
                                                                                <span style={{ color: "#4FFF0A", fontSize: '12px' }}>{task.diff}%</span>
                                                                            </Row>
                                                                        }
                                                                        {
                                                                            task.diff === 0 && <Row>
                                                                                <img src={require('../../../assets/Iconos/2x/power_input@2x.png')} style={{
                                                                                    width: '10px',
                                                                                    height: '10px'
                                                                                }} />

                                                                            </Row>
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                            )
                                                        }
                                                    })
                                                }

                                            </Col>
                                            <Col md={6} className='col-fix-h'>
                                                <Row className='resultcategory mb-4'>
                                                    <Col xs="12">
                                                        <span className='resultlabelcat'>{component2.fundament2.name}</span>
                                                        <div style={{ marginTop: 15, display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                                                            <div style={{ width: "25%" }}>
                                                                <CircularProgressbar
                                                                    value={component2.fundament2.ovr}
                                                                    text={`${component2.fundament2.ovr}%`}
                                                                    circleRatio={0.75}
                                                                    styles={buildStyles({
                                                                        rotation: 1 / 2 + 1 / 8,
                                                                        strokeLinecap: "butt",
                                                                        trailColor: "#eee",
                                                                        pathColor: "#0ce78c",
                                                                        textColor: "#fff"
                                                                    })}
                                                                />

                                                            </div>
                                                            <span className="span-divider"></span>
                                                        </div>

                                                    </Col>
                                                </Row>

                                                {
                                                    component2.fundament2.item.map((task, index) => {
                                                        if (task.percent > 0) {
                                                            return (
                                                                <Row className='mb-3' key={parseInt(Math.random(100 * 5).toString().replace('.', 0))}>
                                                                    <Col md={4}>
                                                                        <Row style={{ alignItems: 'center', justifyContent: 'space-around' }}>
                                                                            <span className='text-white' style={{ flexWrap: 'wrap', textAlign: 'center', fontSize: '14px' }}>{task.value} {task.definition}</span>

                                                                        </Row>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        {
                                                                            task.media > 0 && <Row style={{ marginLeft: '15px' }}>
                                                                                <span style={{ color: '#fff', fontSize: '10px' }}>{task.media}%</span>
                                                                                <span style={{ color: '#d5d5d5', fontSize: '9px' }}>(m)</span>
                                                                            </Row>
                                                                        }
                                                                        <ProgressBar completed={task.percent <= 20 ? 26 : task.percent} maxCompleted={100} bgColor={'linear-gradient(to bottom left, #00f2c3, #0098f0, #00f2c3)'}
                                                                            customLabelStyles={{ fontSize: '14px' }} />
                                                                    </Col>
                                                                    <Col md={2}>
                                                                        {
                                                                            task.diff < 0 && <Row>
                                                                                <img src={require('../../../assets/Iconos/2x/south@2x.png')} style={{
                                                                                    width: '10px',
                                                                                    height: '10px'
                                                                                }} />
                                                                                <span style={{ color: "red", fontSize: '12px' }}>{task.diff}%</span>
                                                                            </Row>
                                                                        }
                                                                        {
                                                                            task.diff > 0 && <Row>
                                                                                <img src={require('../../../assets/Iconos/2x/north@2x.png')} style={{
                                                                                    width: '10px',
                                                                                    height: '10px'
                                                                                }} />
                                                                                <span style={{ color: "#4FFF0A", fontSize: '12px' }}>{task.diff}%</span>
                                                                            </Row>
                                                                        }
                                                                        {
                                                                            task.diff === 0 && <Row>
                                                                                <img src={require('../../../assets/Iconos/2x/power_input@2x.png')} style={{
                                                                                    width: '10px',
                                                                                    height: '10px'
                                                                                }} />

                                                                            </Row>
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                            )
                                                        }
                                                    })
                                                }

                                            </Col>
                                        </Row>
                                    </div>
                                }
                            </div>

                            <div className="ContainerBck sheet">
                                {

                                    component3.compName !== "" &&
                                    <div className='resultstyle mt-3'>
                                        <Row className="componet-container mb-2 h-50">
                                            <Col md={6}>
                                                <span className='resultmain'>{component3.compName}</span>
                                            </Col>
                                            <Col md={6}>
                                                <ProgressBar completed={component3.ovr} maxCompleted={100} bgColor={'linear-gradient(to bottom left, #00f2c3, #0098f0, #00f2c3)'} />
                                            </Col>


                                        </Row>

                                        <Row className="componet-container paddingFix">
                                            <Col md={6} className='divider-vertical col-fix-h'>
                                                <Row className='resultcategory mb-4'>
                                                    <Col xs="12">
                                                        <span className='resultlabelcat'>{component3.fundament1.name}</span>
                                                        <div style={{ marginTop: 15, display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                                                            <div style={{ width: "25%" }}>
                                                                <CircularProgressbar
                                                                    value={component3.fundament1.ovr}
                                                                    text={`${component3.fundament1.ovr}%`}
                                                                    circleRatio={0.75}
                                                                    styles={buildStyles({
                                                                        rotation: 1 / 2 + 1 / 8,
                                                                        strokeLinecap: "butt",
                                                                        trailColor: "#eee",
                                                                        pathColor: "#0ce78c",
                                                                        textColor: "#fff"
                                                                    })}
                                                                />
                                                            </div>
                                                            <span className="span-divider"></span>
                                                        </div>

                                                    </Col>
                                                </Row>

                                                {
                                                    component3.fundament1.item.map((task, index) => {
                                                        if (task.percent > 0) {
                                                            return (
                                                                <Row className='mb-3' key={parseInt(Math.random(100 * 5).toString().replace('.', 0))}>
                                                                    <Col md={4}>
                                                                        <Row style={{ alignItems: 'center', justifyContent: 'space-around' }}>
                                                                            <span className='text-white' style={{ flexWrap: 'wrap', textAlign: 'center', fontSize: '14px' }}>{task.value} {task.definition}</span>

                                                                        </Row>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        {
                                                                            task.media > 0 && <Row style={{ marginLeft: '15px' }}>
                                                                                <span style={{ color: '#fff', fontSize: '10px' }}>{task.media}%</span>
                                                                                <span style={{ color: '#d5d5d5', fontSize: '9px' }}>(m)</span>
                                                                            </Row>
                                                                        }
                                                                        <ProgressBar completed={task.percent < 20 ? 20 : task.percent} maxCompleted={100} bgColor={'linear-gradient(to bottom left, #00f2c3, #0098f0, #00f2c3)'}
                                                                            customLabelStyles={{ fontSize: '14px' }} />
                                                                    </Col>
                                                                    <Col md={2}>
                                                                        {
                                                                            task.diff < 0 && <Row>
                                                                                <img src={require('../../../assets/Iconos/2x/south@2x.png')} style={{
                                                                                    width: '10px',
                                                                                    height: '10px'
                                                                                }} />
                                                                                <span style={{ color: "red", fontSize: '12px' }}>{task.diff}%</span>
                                                                            </Row>
                                                                        }
                                                                        {
                                                                            task.diff > 0 && <Row>
                                                                                <img src={require('../../../assets/Iconos/2x/north@2x.png')} style={{
                                                                                    width: '10px',
                                                                                    height: '10px'
                                                                                }} />
                                                                                <span style={{ color: "#4FFF0A", fontSize: '12px' }}>{task.diff}%</span>
                                                                            </Row>
                                                                        }
                                                                        {
                                                                            task.diff === 0 && <Row>
                                                                                <img src={require('../../../assets/Iconos/2x/power_input@2x.png')} style={{
                                                                                    width: '10px',
                                                                                    height: '10px'
                                                                                }} />

                                                                            </Row>
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                            )
                                                        }
                                                    })
                                                }

                                            </Col>
                                            <Col md={6} className='col-fix-h'>
                                                <Row className='resultcategory mb-4'>
                                                    <Col xs="12">
                                                        <span className='resultlabelcat'>{component3.fundament2.name}</span>
                                                        <div style={{ marginTop: 15, display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                                                            <div style={{ width: "25%" }}>
                                                                <CircularProgressbar
                                                                    value={component3.fundament2.ovr}
                                                                    text={`${component3.fundament2.ovr}%`}
                                                                    circleRatio={0.75}
                                                                    styles={buildStyles({
                                                                        rotation: 1 / 2 + 1 / 8,
                                                                        strokeLinecap: "butt",
                                                                        trailColor: "#eee",
                                                                        pathColor: "#0ce78c",
                                                                        textColor: "#fff"
                                                                    })}
                                                                />

                                                            </div>
                                                            <span className="span-divider"></span>
                                                        </div>

                                                    </Col>
                                                </Row>

                                                {
                                                    component3.fundament2.item.map((task, index) => {
                                                        if (task.percent > 0) {
                                                            return (
                                                                <Row className='mb-3' key={parseInt(Math.random(100 * 5).toString().replace('.', 0))}>
                                                                    <Col md={4}>
                                                                        <Row style={{ alignItems: 'center', justifyContent: 'space-around' }}>
                                                                            <span className='text-white' style={{ flexWrap: 'wrap', textAlign: 'center', fontSize: '14px' }}>{task.value} {task.definition}</span>

                                                                        </Row>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        {
                                                                            task.media > 0 && <Row style={{ marginLeft: '15px' }}>
                                                                                <span style={{ color: '#fff', fontSize: '10px' }}>{task.media}%</span>
                                                                                <span style={{ color: '#d5d5d5', fontSize: '9px' }}>(m)</span>
                                                                            </Row>
                                                                        }
                                                                        <ProgressBar completed={task.percent < 20 ? 20 : task.percent} maxCompleted={100} bgColor={'linear-gradient(to bottom left, #00f2c3, #0098f0, #00f2c3)'}
                                                                            customLabelStyles={{ fontSize: '14px' }} />
                                                                    </Col>
                                                                    <Col md={2}>
                                                                        {
                                                                            task.diff < 0 && <Row>
                                                                                <img src={require('../../../assets/Iconos/2x/south@2x.png')} style={{
                                                                                    width: '10px',
                                                                                    height: '10px'
                                                                                }} />
                                                                                <span style={{ color: "red", fontSize: '12px' }}>{task.diff}%</span>
                                                                            </Row>
                                                                        }
                                                                        {
                                                                            task.diff > 0 && <Row>
                                                                                <img src={require('../../../assets/Iconos/2x/north@2x.png')} style={{
                                                                                    width: '10px',
                                                                                    height: '10px'
                                                                                }} />
                                                                                <span style={{ color: "#4FFF0A", fontSize: '12px' }}>{task.diff}%</span>
                                                                            </Row>
                                                                        }
                                                                        {
                                                                            task.diff === 0 && <Row>
                                                                                <img src={require('../../../assets/Iconos/2x/power_input@2x.png')} style={{
                                                                                    width: '10px',
                                                                                    height: '10px'
                                                                                }} />

                                                                            </Row>
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                            )
                                                        }
                                                    })
                                                }

                                            </Col>
                                        </Row>
                                    </div>
                                }
                                <Row className='resultstyle' style={{ position: "absolute", bottom: '4px', width: '100%', left: '15px' }}>
                                    <Col xs={12}>
                                        <div className="obsContainer">
                                            <h3 className="bluetext text-white">Observación</h3>
                                            <Row className="obs">
                                                <p>{observacion}</p>
                                            </Row>
                                        </div>

                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col> : null

            }


        </Row >
    )
}

export default Reportes;