import React from "react";
import styled from "styled-components";
import moment from "moment";

const CalenderBox = styled.div`
background: transparent;
border: none;
position: absolute;
top: 7px;
left: 2px;
z-index: 1000;
width: 425px;
-webkit-flex-direction: row;
-ms-flex-direction: row;
-webkit-flex-direction: row;
-ms-flex-direction: row;
flex-direction: row;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
border-radius: 5px;
}

`
const CalenderMonth = styled.div`
background: white;
border: #eee 1px solid;
position: absolute;
top: 46px;
right: 0;
z-index: 99999;
padding: 12px;
-webkit-flex-direction: column;
-ms-flex-direction: column;
-webkit-flex-direction: column;
-ms-flex-direction: column;
flex-direction: column;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
border-radius: 5px;
box-shadow: 0 0 10px rgba(0,0,0,0.2);
}


}`
const CalenderItemContainer = styled.div`
display: grid;
grid-template-columns: 1fr 1fr 1fr 1fr;
gap: 5px;
margin-top: 10px;
`
const CalenderItem = styled.button`
border: #eee 1px solid;
padding: 5px;
border-radius: 5px;
background: white;
text-align: center;
pointer-events: auto;
opacity: 1;
cursor: pointer;
-webkit-transition: .2s;
transition: .2s;
color:#000;
font-size: 12px;
`
const CalenderInput = styled.div`
border-radius: 50px;
height: 39px;
width: 190px;
margin-top: 1%;
border: 1px solid #ccc;
box-shadow: 0 0 2px #ccc;
`
const CalenderButton = styled.button`
width: 30px;
height: 30px;
border-radius: 39px;
border: none;
background: #ccc;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
margin-top: 4px;
padding: 0;
margin-left: -28px;
`
const CalenderHeader = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
margin: 11px;
`
const CalenderHeaderTitle = styled.p`
font-size: 11px;
`
const Monthpicker = ({handler =()=>{}}) => {
    const [monthSelected, setMonthSelected] = React.useState('Enero')
    const [month, setMonth] = React.useState(['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'])
    const [currentMonth, setCurrentMonth] = React.useState(null)
    const [show, setShow] = React.useState(false)
    React.useEffect(() => {
        let monthInCurse = moment().month()
        setCurrentMonth(monthInCurse)
        setMonthSelected(month[monthInCurse]);
    }, [])

    const showModal = () => {
        setShow(o => !o)
    }
    const changeMonth = (i)=>{
       let selected = month[i]
       let monthStart = moment().month(i).startOf('month').format('YYYY-MM-DD')
       let monthEnd = moment().month(i).endOf('month').format('YYYY-MM-DD')
       setMonthSelected(selected)
       handler({startDate: monthStart, endDate: monthEnd})
       setShow(o => !o)
     }
    return (
        <CalenderBox>
            <CalenderInput>
                <div className="row">
                    <div className="col-10">
                        <CalenderHeader>
                            <CalenderHeaderTitle> {monthSelected} </CalenderHeaderTitle>
                        </CalenderHeader>
                    </div>
                    <div className="col-2">
                        <CalenderButton onClick={()=> showModal()}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                id="Capa_1"
                                data-name="Capa 1"
                                viewBox="0 0 12.67 10.9"
                                fill={"#000"}
                                width={10}
                                height={10}>
                                <polygon
                                    className="cls-1"
                                    points="6.35 10.9 12.64 0 0.06 0 6.35 10.9" /></svg>
                        </CalenderButton>
                    </div>
                </div>

            </CalenderInput>
            {
                show && <CalenderMonth>
                    <div>
                        <h4 className="mb-1">Seleccione un mes</h4>
                    </div>
                    <CalenderItemContainer>
                        {
                            month.map((task, i) => {
                                return (<CalenderItem key={i} className={monthSelected === task ? "itemActive btn s" :
                                    i > currentMonth ? "itemDisabled btn s" : "btn s"
                                }
                                onClick={()=> changeMonth(i)}
                                >{task}</CalenderItem>)
                            })
                        }
                    </CalenderItemContainer>


                </CalenderMonth>
            }

        </CalenderBox>
    )
}

export default Monthpicker;