import React from "react";
import {
  BrowserRouter as Router,
  useHistory
} from "react-router-dom";
import uuid from "react-uuid";
import IMG from '../../assets/img/default-avatar.png'
import Manager from "../../source/SingIn"
import toast from "react-hot-toast";
import SearchAthletes from "./SearchAthletes";
import MainManager from "../../api/main/MainService";
import ReactLoading from "react-loading";
import SearchAthletesIsEpecial from "./SearchAthletesIsEspecial";
import { RiLogoutCircleLine } from 'react-icons/ri';
import { TbListSearch } from 'react-icons/tb';


const main = new MainManager();
const api = new Manager()

const IsSuper = ({ clubList = [] }) => {

  const history = useHistory();
  const [showModalSearchAthletes, setShowModalSearchAthletes] = React.useState(false)
  const [showModalSearchAthletesIsEspecial, setShowModalSearchAthletesIsEspecial] = React.useState(false)
  const [needShow, setNeedShow] = React.useState(false)
  const [showLoading, setShowLoading] = React.useState(false);

  const LogOut = () => {
    window.localStorage.clear();
    window.location.href = "/auth/login/";
    // history.replace({ pathname: "/auth/login" });
  };

  const handleSearchAthletes = () => {
    let userSession = JSON.parse(window.localStorage.getItem('isSuper'))
    let isEspeciais = userSession.especialSessionID ? true : false;

    //SE VALIDA EL TIPO DE ROLE, Y SE ABRE EL SEARCH CORRESPONDIENTE
    if (isEspeciais) {
      setNeedShow(!needShow)
      setShowModalSearchAthletesIsEspecial(true)
      setShowModalSearchAthletes(false)
    }

    if (!isEspeciais) {
      setNeedShow(!needShow)
      setShowModalSearchAthletesIsEspecial(false)
      setShowModalSearchAthletes(true)
    }

  }

  const handlerSession = async (session) => {
    setShowLoading(true)

    let model = {
      sessionID: session,
      type: false,
      validateRol: false
    }

    let globalContextRole = window.localStorage.getItem('globalContext')
    let sessionIDUser = window.localStorage.getItem('_gHtk')

    if (globalContextRole !== null && sessionIDUser !== null) {
      model = {
        sessionID: session,
        type: false,
        validateRol: globalContextRole.isEspecial ? true : false
      }
    }

    let singInResult = await api.SingIn(model);


    if (singInResult.message.success) {

      //CREAMOS LOS STORAGE CON LA DATA DEL CLUB
      let localResult = JSON.parse(window.localStorage.getItem('isSuper'));

      let selectedClubInfo = localResult.clubInfo.filter((task) => {
        if (task.sessionID === session) {
          return task
        }
      })

      let infoClub = await main.GetClubInfoBuSessionID({ sessionID: selectedClubInfo[0].sessionID });

      if (infoClub.data.message.length > 0) {

        let sessionID = infoClub.data.message[0].sessionID

        let isEspecialUser = localResult.especialSessionID

        let resultLocalStorage = {}
        if (isEspecialUser != '' && isEspecialUser != undefined) {
          resultLocalStorage = await api.GetLoadEspecialistData({ sessionID: isEspecialUser, selectedClub: sessionID });
        } else {
          resultLocalStorage = await api.GetLoadDataInstitution({ sessionID: sessionID });
        }


        let club = resultLocalStorage.message.data[0].clubID

        let initModel = {
          "sessionID": sessionID,
          "clubID": club,
          "categoryID": resultLocalStorage.message.categoryID,
          "startDate": 0,
          "endDate": 0
        }

        // SE CREA LOCAL STORAGE _GHTK
        window.localStorage.setItem('_gHtk', JSON.stringify(initModel))

        let globalContext = {
          isCouch: false,
          isClub: true,
          isSuper: false,
          isEspecial: false,
          categoryID: resultLocalStorage.message.categoryID,
          sportID: parseInt(resultLocalStorage.message.data[0].sportID)
        }
        // SE CREA LOCAL STORAGE GLOBAL CONTEX
        window.localStorage.setItem('globalContext', JSON.stringify(globalContext))

        // SE CREA LOCAL STORAGE CURRENT TORNEO 
        window.localStorage.setItem('curretTorneo', JSON.stringify(resultLocalStorage.message.currentTorneo))

        // Se crea el currentCategori 
        window.localStorage.setItem('currentCategori', JSON.stringify(resultLocalStorage.message.categoryID[0]))

        let { from } = { from: { pathname: "/admin/dashboard" } };

        history.replace(from);
      } else {
        toast.error('Este club no contiene categoria asociadas ')
        return false
      }


    } else {
      toast.error('Este club no contiene categoria asociadas ')
      return false
    }
  }

  return (
    <>
      {
        needShow ?
          showModalSearchAthletesIsEspecial ?
            <SearchAthletesIsEpecial handleSearchAthletes={handleSearchAthletes} />
            :
            showModalSearchAthletes ?
              <SearchAthletes handleSearchAthletes={handleSearchAthletes} />
              :
              <div className="outer">
                <div className="teams-content">
                  <div className="d-flex justify-content-between align-content-center">
                    <button className="logout-btn" onClick={handleSearchAthletes}>
                    <TbListSearch size="20px" color="white" />
                      Buscador de atletas
                    </button>
                    {
                      showLoading ?
                        <div style={{ marginRight: "5px", height: '30px', marginTop: '20px', display: "flex", flexDirection: "row" }}>
                          <ReactLoading type="spin" color="#ffffff" width="30px" />
                          <h2 style={{ color: "#fff", marginLeft: '10px' }}>Cargando...</h2>
                        </div>
                        :
                        <h3 className="font-weight-bold text-white p-4">Seleccione un club</h3>
                    }

                    <button className="logout-btn" onClick={() => LogOut()}>
                    <RiLogoutCircleLine size="20px" color="white" />
                      Cerrar sesión
                    </button>
                  </div>
                  <div className="d-flex justify-content-center align-items-start flex-wrap"
                    style={{ maxHeight: "100%", gap: "30px", overflowY: "scroll", padding: "10px 40px" }}>
                    {clubList.length > 0 &&
                      clubList.map((task, index) => {
                        return (
                          <div key={uuid()} onClick={() => handlerSession(task.sessionID)} className="team-card">
                            <img style={{
                              borderRadius: "50%",
                              boxShadow: "0 0 4px rgba(0 ,0 ,0 , 0.4)",
                              width: "120px",
                              height: "120px",
                              backgroundColor: "#c1c1c1"
                            }}
                              key={uuid()}
                              src={task.imgData === "" || task.imgData === undefined ? IMG : task.imgData}
                              alt="Card imagen" />
                            <p style={{
                              height: "80px",
                              display: "flex",
                              alignItems: "center",
                              color: "white",
                              fontWeight: "bold"
                            }}>
                              {task.nombre}
                            </p>
                          </div>
                        )
                      })
                    }
                    <div style={{ height: "350px" }}></div>
                  </div>
                </div>
              </div> :
          <div className="outer">
            <div className="teams-content">
              <div className="d-flex justify-content-between align-content-center">
                <button className="logout-btn" onClick={handleSearchAthletes}>
                <TbListSearch size="20px" color="white" />
                  Buscador de atletas
                </button>
                {
                  showLoading ?
                    <div style={{ marginRight: "5px", height: '30px', marginTop: '20px', display: "flex", flexDirection: "row" }}>
                      <ReactLoading type="spin" color="#ffffff" width="30px" />
                      <h2 style={{ color: "#fff", marginLeft: '10px' }}>Cargando...</h2>
                    </div>
                    :

                    <h3 className="font-weight-bold text-white p-4">Seleccione un club</h3>
                }

                <button className="logout-btn" onClick={() => LogOut()}>
                <RiLogoutCircleLine size="20px" color="white" />
                  Cerrar sesión
                </button>
              </div>
              <div className="d-flex justify-content-center align-items-start flex-wrap"
                style={{ maxHeight: "100%", gap: "30px", overflowY: "scroll", padding: "10px 40px" }}>
                {clubList.length > 0 &&
                  clubList.map((task, index) => {
                    return (
                      <div key={uuid()} onClick={() => handlerSession(task.sessionID)} className="team-card">
                        <img style={{
                          borderRadius: "50%",
                          boxShadow: "0 0 4px rgba(0 ,0 ,0 , 0.4)",
                          width: "120px",
                          height: "120px",
                          backgroundColor: "#c1c1c1"
                        }}
                          key={uuid()}
                          src={task.imgData === "" || task.imgData === undefined ? IMG : task.imgData}
                          alt="Card imagen" />
                        <p style={{
                          height: "80px",
                          display: "flex",
                          alignItems: "center",
                          color: "white",
                          fontWeight: "bold"
                        }}>
                          {task.nombre}
                        </p>
                      </div>
                    )
                  })
                }
                <div style={{ height: "350px" }}></div>
              </div>
            </div>
          </div>
      }

    </>
  )
}

export default IsSuper
