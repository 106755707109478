import React, { useState } from "react";
import { Button, Card, CardBody, CardSubtitle, CardText, CardTitle, Col, Row } from "reactstrap";
import { useQuery, useQueryClient, useMutation } from 'react-query'
import Manager from '../../source/Main'
import uuid from "react-uuid"
import IMG from '../../assets/img/default-avatar.png'
const api = new Manager()

const CardTouchable = ({onClick}) => {


    ///CARGA INICIAL DE LA CACHE
    const handlerQuery = async () => {
        let userToken = JSON.parse(window.localStorage.getItem('_gHtk'));
        let currentCategori = JSON.parse(window.localStorage.getItem('currentCategori'));
        let category = currentCategori.categoriesID

        let model = {
            "sessionID": userToken.sessionID,
            "categoryID": category,
            "startDate": "00:00:00",
            "endDate": "00:00:00"
        }

        let result = await api.GetLoadInitialData(model)
        
        return { corporalInfo: result.message.data.corporalInfo }

    }

    //// FUNCION QUE CREA LA CACHE
    const { data, isLoading, isSuccess } = useQuery('corporalInfo', () => handlerQuery(), {
        refetchOnWindowFocus: false
    })


    if (isLoading) {
        return (
            <div></div>
        )
    }

    const handlerSession = (task) => {
          onClick(task)
    }

    if (isSuccess) {
        const { corporalInfo } = data
        console.log(corporalInfo)
        return (
            <Row style={{ alignItems: "center", justifyContent: 'flex-start', display: 'flex' }}>
                {
                    corporalInfo.map((task) => {
                        return (
                            <Col xs={3} key={uuid()}>
                                <Card onClick={() => handlerSession(task.sessionID)} style={{
                                    cursor: "pointer",
                                    height: "250px",
                                    background: "#f8f8f8",
                                    boxShadow: "1px 1px 1px rgba(0,0,0,0.5)",
                                    padding: "10px",
                                }}
                                    key={uuid()}>
                                    <CardBody className='modalCardStyle'>
                                        <Row className="justify-content-center mb-2">
                                            <img style={{
                                                borderRadius: "50%",
                                                boxShadow: "1px 4px 4px rgba(0 ,0 ,0 , 0.7)",
                                                width: "120px",
                                                height: "120px",
                                                backgroundColor: "#c1c1c1"
                                            }}
                                                key={uuid()}
                                                src={task.imgData === "" || task.imgData === undefined ? IMG : task.imgData} alt="Card imagen" />
                                        </Row>
                                        <Row className="justify-content-center">
                                            <Col>
                                                <h4 className="mb-1" style={{ color: "#212121" }} key={uuid()}>{task.nombre}</h4>

                                                {
                                                    task.position === '' || task.position === undefined ? null : <span className="text-lg font-thin leading-relaxed" key={uuid()}> {task.position} </span>
                                                    // <h5 style={{ color: "#212121" }}>{label1}: {dtInfo}</h5>
                                                }
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        )
                    })
                }
            </Row>
        )
    }


}

export default CardTouchable;