import React from "react";

// nodejs library that concatenates classes
import classNames from "classnames";
import styled from 'styled-components'

// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  Navbar,
  NavLink,
  CustomInput,
  Nav,
  Container,
  Modal,
  NavbarToggler,
  ModalHeader,
  UncontrolledButtonDropdown,
  FormGroup,
  Row,
  Col,
  Table,
} from "reactstrap";

import SideLeft from "components/SideLeft/index";
import MainService from "../../source/Main";
import moment from "moment";
import toast from "react-hot-toast";
import { useStateCallback } from "hook/useStateCallback";
import ReactLoading from "react-loading";
import { useQuery, useQueryClient, useMutation } from 'react-query'
const api = new MainService();
const Label = styled.div`
background: #fff;
display: flex;
align-items: center;
justify-content: center;
justify-content: space-between;
border-radius: 8px;
`

const SCWIDTH = window.innerWidth;
function AdminNavbar(props) {

  // Get QueryClient from the context
  const queryClient = useQueryClient()
  const [showModalAsistencia, setShowModalAsistencia] = React.useState(false);
  const [collapseOpen, setcollapseOpen] = React.useState(false);
  const [evaExists, setEvaExists] = React.useState(false);
  const [modalSearch, setmodalSearch] = React.useState(false);
  const [color, setcolor] = React.useState("navbar-transparent");
  const [selectedItem, setSelectedItem] = React.useState({})
  const [location, setLocation] = React.useState(false);
  const [isCouch, setIsCouh] = React.useState(false);
  const [isEspecial, setIsEspecial] = React.useState(false);
  const [isClub, setIsClub] = React.useState(false);
  const [currentCategori, setCurrentCategorie] = React.useState([]);
  const [labelCategorie, setLabelCategorie] = React.useState([]);
  const [showMoreFilter, setShowMoreFilter] = React.useState(false);
  const [disablemin, setDisbleMin] = React.useState(true);
  const [disableMax, setDisableMax] = React.useState(false);
  const [addInProcess, setAddInProcess] = React.useState(false);
  const [item, setItem] = React.useState(0)
  const [filterAsistencia, setFilterAsistencia] = React.useState({
    fecha: moment(new Date()).format('YYYY-MM-DD'),
    session: 0,
    torneo: 0,
    rival: ""
  })
  const [atletaList, setAtletaList] = React.useState({
    content: []
  });

  const [states, setState] = useStateCallback({
    page: 0,
    perPage: 5,
    numberOfPages: 0
  });
  //// SE CREA CACHE, SE CARGA LISTA DE CATEGORIAS, LISTA DE TORNEOS Y LABEL PARA EL FILTRO DE CATEGORIAS
  const handlerQuery = async () => {
    let globalContextRole = JSON.parse(window.localStorage.getItem('globalContext'))
    let userToken = JSON.parse(window.localStorage.getItem('_gHtk'))
    let category = JSON.parse(window.localStorage.getItem('currentCategori'))

    let model = {
      "sessionID": userToken.sessionID,
      "sessionRole": globalContextRole.isCouch ? userToken.sessionCouch : false,
      "sessionIDEspecial": globalContextRole.isEspecial ? userToken.sessionUser : false,
      "categoryID": category.categoriesID,
      "startDate": '0',
      "endDate": '0'
    }

    let result = await api.GetDataInitialAdminNavbar(model)
    console.log('result navbar', result)
    return result.data
  }
  const { data, isSuccess, isLoading } = useQuery('DataNavbar', () => handlerQuery(), {
    refetchOnWindowFocus: false,
  })

  //////

  React.useEffect(() => {
    let resultCouh = JSON.parse(window.localStorage.getItem('globalContext'));
    //SE AGREGA CONDICION PARA EVITAR  ERROR EN EL REDIRECT
    if (resultCouh !== undefined) {
      if (resultCouh.isCouch) {
        setIsCouh(true)
      }

      if (resultCouh.isEspecial) {
        setIsEspecial(true)
      }

      if (resultCouh.isSuper || resultCouh.isClub) {
        setIsClub(true)
      }
    }
  }, [])

  React.useEffect(() => {
    if (window.location.pathname === '/admin/atleta') {
      setLocation(true)
    }
    window.addEventListener("resize", updateColor);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.removeEventListener("resize", updateColor);
    };
  });

  const ChangeCategoryData = (item) => {

    const { categoryList } = data.message

    let categori = categoryList.filter((task) => {
      if (task.categoriesID === item) return task
    })

    if (isCouch) {
      let resultCouh = JSON.parse(window.localStorage.getItem('globalContext'));
      setItem(item)

      let filterCategoryCouch = resultCouh.categoryID.filter((task) => {
        if (task.categoriesID === parseInt(item)) {

          return task
        }
      })
      window.localStorage.removeItem('currentCategori')
      window.localStorage.setItem('currentCategori', JSON.stringify(filterCategoryCouch[0]))
      queryClient.invalidateQueries({ queryKey: ['fisicaDataTabs'] });
      queryClient.invalidateQueries({ queryKey: ['tecnicaDataTabs'] });
      queryClient.invalidateQueries({ queryKey: ['reportTabs'] });
      queryClient.invalidateQueries({ queryKey: ["generalTabs"] });
      queryClient.invalidateQueries({ queryKey: ["headerClub"] });
    } else {
      if (isEspecial) {
        let resultEspecial = JSON.parse(window.localStorage.getItem('globalContext'));
        let filterCategoryEspecial = resultEspecial.categoryID.filter((task) => {
          if (task.categoriesID === parseInt(item)) {
            return task
          }
        })

        window.localStorage.removeItem('currentCategori')
        window.localStorage.setItem('currentCategori', JSON.stringify(filterCategoryEspecial[0]))
        queryClient.invalidateQueries({ queryKey: ['fisicaDataTabs'] });
        queryClient.invalidateQueries({ queryKey: ['tecnicaDataTabs'] });
        queryClient.invalidateQueries({ queryKey: ['reportTabs'] });
        queryClient.invalidateQueries({ queryKey: ["generalTabs"] });
        queryClient.invalidateQueries({ queryKey: ["headerClub"] });
      }
      setItem(item)
      window.localStorage.removeItem('currentCategori')
      window.localStorage.setItem('currentCategori', JSON.stringify(categori[0]))
      queryClient.invalidateQueries({ queryKey: ['fisicaDataTabs'] });
      queryClient.invalidateQueries({ queryKey: ['tecnicaDataTabs'] });
      queryClient.invalidateQueries({ queryKey: ['reportTabs'] });
      queryClient.invalidateQueries({ queryKey: ["generalTabs"] });
      queryClient.invalidateQueries({ queryKey: ["headerClub"] });
    }

    changeDataCategoryMutation(item)
  }

  /*
  FUNCIONES PARA MUTAR FILTRO DE CATEGORIA
  */
  const handlerMutationChangeCategory = async (model) => {
    try {

      let result = await api.MutateDataCategoryNavbar(model)
      return result.data.message

    } catch (error) {
      return error
    }
  }

  const changeDataForFilterCategory = useMutation('DataNavbar', {
    mutationFn: handlerMutationChangeCategory,
    onSuccess: (response) => {
      queryClient.setQueryData('DataNavbar', (oldData) => {
        return {
          ...oldData,
          message: response.message
        }
      })
    }
  })

  const changeDataCategoryMutation = (item) => {
    let resultCouh = JSON.parse(window.localStorage.getItem('globalContext'));
    let model = {
      oldData: data,
      category: item,
      categoryCouch: resultCouh.categoryID
    }

    changeDataForFilterCategory.mutate(model)
  }
  ///////////////////////////////////////


  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && collapseOpen) {
      setcolor("bg-white");
    } else {
      setcolor("navbar-transparent");
    }
  };

  const toggleCollapse = () => {
    if (collapseOpen) {
      setcolor("navbar-transparent");
    } else {
      setcolor("bg-white");
    }
    setcollapseOpen(!collapseOpen);
  };

  // this function is to open the Search modal
  const toggleModalSearch = () => {
    setmodalSearch(!modalSearch);
  };

  const changeAtleta = async () => {
    window.localStorage.removeItem('currentUser')
    setTimeout(() => {
      window.location.reload()
    }, 1200);
  }

  const closeModal = () => {
    let obj = {
      fecha: moment(new Date()).format('YYYY-MM-DD'),
      session: 0,
      torneo: 0,
      rival: ""
    }
    setShowModalAsistencia(!showModalAsistencia)
    setEvaExists(false)
    setFilterAsistencia({})
    setFilterAsistencia(obj)
  }

  const showModal = () => {
    let globalContext = JSON.parse(window.localStorage.getItem('globalContext'))

    if (globalContext.isCouch) {
      let corpInfo = JSON.parse(window.localStorage.getItem('corpInfo'))
      let obj = {
        nombre: '',
        asistencia: "",
        tardanza: '',
        inasistencia: '',
        action: {
          type: 'noShow',
          key: ""
        }
      }

      let modelMental = {
        content: []
      }


      for (let index = 0; index < corpInfo.length; index++) {
        let elem = corpInfo[index];

        obj.nombre = elem.nombre
        obj.asistencia = ''
        obj.tardanza = ''
        obj.inasistencia = ''
        obj.action = {
          type: 'noShow',
          key: elem.sessionID
        }
        modelMental.content.push(obj)

        obj = {
          nombre: '',
          asistencia: "",
          tardanza: '',
          inasistencia: '',
          action: {
            type: 'noShow',
            key: ""
          }
        }
      }

      // console.log('ATLETA LIST ', modelMental)
      setState((prev) => {
        prev.numberOfPages = Math.ceil((modelMental.content.length) / 5)
        // console.log('PREV ', prev)
        return prev
      })
      //
      setAtletaList(modelMental)
    }
    setTimeout(() => {
      setShowModalAsistencia(!showModalAsistencia)
    }, 1250);

  }

  const AddAsistencia = async (item, sessionID) => {
    //console.log('FILTER ASISTENIA ', filterAsistencia)
    if (filterAsistencia.session <= 0) {
      toast.error('Favor seleccionar una sesión antes de crear la asistencia')
      return false
    } else {
      if (filterAsistencia.session === 4) {
        if (filterAsistencia.rival === "" || filterAsistencia.torneo === 0) {
          toast.error('Favor seleccionar una rival y un torneo antes de crear la asistencia')
          return false
        }
      }
      let club = JSON.parse(window.localStorage.getItem('_gHtk'))
      let model = {
        sessionID: sessionID,
        clubID: club.sessionID,
        asistencia: item === "checkbox1" ? 1 : 0,
        tardanza: item === "checkbox2" ? 1 : 0,
        falta: item === "checkbox3" ? 1 : 0,
        session: filterAsistencia.session,
        torneo: filterAsistencia.torneo,
        rival: filterAsistencia.rival,
        fecha: filterAsistencia.fecha
      }

      let result = await api.AddAsistencia(model)

      if (result.success) {
        setTimeout(() => {
          setAddInProcess(false)
          toast.success('Se guardó correctamente')
        }, 2200);
      } else {
        setTimeout(() => {
          setAddInProcess(false)
          toast.error('Los sentimos ha ocurrido un error')
        }, 2200);

      }
    }

  }

  const CheckListAsistencia = async () => {
    let club = JSON.parse(window.localStorage.getItem('_gHtk'))

    let model = {
      clubSession: club.sessionID,
      session: filterAsistencia.session,
      fecha: filterAsistencia.fecha,
      torneo: filterAsistencia.torneo,
      rival: filterAsistencia.rival
    }

    let result = await api.CheckAsistenciaList(model)
    //console.log('RESULT CHECK LIST MODEL ', model)
    if (result.success && result.data.message[0].length > 0) {
      setEvaExists(true)
      let data = result.data.message[0]

      let newArr = { ...atletaList }
      //console.log('NEW ARR ', newArr)
      for (let index = 0; index < data.length; index++) {
        let element = data[index];
        let sessionForProcess = element.sessionID

        //
        for (let i = 0; i < newArr.content.length; i++) {
          let elem = newArr.content[i];
          if (elem.action.key === sessionForProcess) {
            elem.asistencia = element.asistencia > 0 ? true : false
            elem.inasistencia = element.falta > 0 ? true : false
            elem.tardanza = element.tardanza > 0 ? true : false
          }

        }
      }
      // console.log('RESULT CHECK LIST ', newArr)
      setAtletaList(newArr)
    } else {
      setEvaExists(false)
    }


  }

  const _SetFilterAsistencia = async (type, item) => {
    // console.log('TYPE ', type, 'ITEM ', item)
    switch (type) {
      case 1:
        setFilterAsistencia(prev => {
          prev.fecha = item
          return prev
        })

        setShowMoreFilter((prev) => {
          if (prev) {
            return !prev
          }
          return prev
        })
        break;
      case 2:

        setFilterAsistencia(prev => {
          prev.session = parseInt(item)
          return prev
        })

        if (parseInt(item) === 4) {
          setShowMoreFilter(true)
        } else {
          setShowMoreFilter(false)
        }

        // CHECK IF EXISTS ASISTENCIA
        CheckListAsistencia()
        break;
      case 3:
        setFilterAsistencia(prev => {
          prev.rival = item
          return prev
        })
        break;
      case 4:
        setFilterAsistencia(prev => {
          prev.torneo = parseInt(item)
          return prev
        })
        // CHECK IF EXISTS ASISTENCIA
        CheckListAsistencia()
        break;
      default:
        break;
    }
  }

  const paginations = (action) => {
    let page = states.page
    let totalIndex = states.numberOfPages - 1


    if (action) {
      page = page + 1
      page = page >= totalIndex ? totalIndex : page
    } else {
      page = page - 1
      page = page <= 0 ? 0 : page
    }

    if (page <= 0) {
      setDisbleMin(true)
      setDisableMax(false)
    }
    if (page >= totalIndex) {
      setDisbleMin(false)
      setDisableMax(true)
    }
    if (page >= 0 && page < totalIndex) {
      setDisbleMin(false)
      setDisableMax(false)
    }
    //console.log('Total Index',totalIndex,'PAGE',page)
    setState({ ...states, page });

  }

  const _setCheckItem = (key, d, value) => {
    let attList = { ...atletaList }
    //console.log('KEY ', key)
    for (let index = 0; index < attList.content.length; index++) {
      let element = attList.content[index];

      if (element.action.key === key) {
        switch (d) {
          case 1:
            element.asistencia = value
            break;
          case 2:
            element.tardanza = value
            break;
          case 3:
            element.inasistencia = value
            break;
          default:
            break;
        }
      }
    }
    setAtletaList(attList)
    //setCheckBoxSelected("checkbox" + d)
    AddAsistencia("checkbox" + d, key)
    setAddInProcess(true)

  }

  const _returnTr = (data) => {
    let item = Object.entries(data);
    let key = 0
    return (
      <tr key={parseInt(Math.random(100 * 5).toString().replace('.', 0))}>
        {
          item.map((task, d) => {
            if (typeof task[1] === 'object') {
              key = task[1].key
              return (
                <td key={parseInt(Math.random(100 * 5).toString().replace('.', 0))} className="text-center">

                </td>)
            } else {
              if (task[0] === 'nombre') {
                return (
                  <td key={parseInt(Math.random(100 * 5).toString().replace('.', 0))} className="text-center">
                    <span className={'text-center'}>{task[1]}</span>
                  </td>
                )

              } else {

                return (
                  <td key={parseInt(Math.random(100 * 5).toString().replace('.', 0))} className="text-center">
                    <FormGroup check key={parseInt(Math.random(100 * 5).toString().replace('.', 0))}>
                      <CustomInput type="checkbox" id={parseInt(Math.random(100 * 5).toString().replace('.', 0)) + "checkbox" + d} onChange={(item) => _setCheckItem(key, d, item.target.checked)} checked={task[1]} />
                    </FormGroup>
                  </td>
                )
              }

            }
          })
        }
      </tr>

    )




  }

  if (isLoading) {
    return (
      <div></div>
    )
  }

  if (isSuccess) {
    const { categoryList, torneoList, categoryLabel, categoryListCouch,
      categoryCouchLabel, categoryListIsEspecial, categoryIsEspecialLabel } = data.message


    return (
      <>
        <Navbar className={classNames("navbar-absolute fixed-top-stick", color)} expand="lg">
          <Container fluid>

            <div className="navbar-wrapper">
              <div
                className={classNames("navbar-toggle d-inline", {
                  toggled: props.sidebarOpened,
                })}
              >
                <NavbarToggler onClick={props.toggleSidebar}>
                  <span className="navbar-toggler-bar bar1" />
                  <span className="navbar-toggler-bar bar2" />
                  <span className="navbar-toggler-bar bar3" />
                </NavbarToggler>
              </div>
            </div>

            <NavbarToggler onClick={toggleCollapse}>
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
            </NavbarToggler>

            <Collapse navbar isOpen={collapseOpen}>
              <Nav className="ml-auto" navbar>{
                isCouch && <UncontrolledButtonDropdown style={{ alignItems: "center" }}>
                  <Button size="sm" className="buttonStyleAsistencia" onClick={() => showModal()}>
                    <i className="tim-icons icon-notes"></i>
                    Asistencias
                  </Button>
                </UncontrolledButtonDropdown>
              }

                {
                  location ?
                    <UncontrolledDropdown nav>
                      <DropdownToggle
                        caret
                        color="default"
                        nav
                        onClick={(e) => e.preventDefault()}
                        style={{ width: '135px' }}
                      >
                        <Label className="row" style={{ justifyContent: 'center' }} onClick={() => changeAtleta()}>
                          <button style={{
                            background: '#fff',
                            border: '#fff',
                            textAlign: 'center'
                          }}>Cambiar Atleta</button>
                        </Label>
                        {/* <p className="d-lg-none">Log out</p> */}
                      </DropdownToggle>
                    </UncontrolledDropdown> :

                    <UncontrolledDropdown nav>
                      <DropdownToggle
                        caret
                        color="default"
                        nav
                        onClick={(e) => e.preventDefault()}
                        style={{ width: '135px' }}
                      >
                        <Label className="row">
                          <img
                            style={{
                              width: '20px',
                              height: '20px',
                              margin: '4px'
                            }}
                            alt="..."
                            src={require("../../assets/img/category.png")}
                          />
                          {

                            isCouch &&
                            <>
                              <p className="" style={{ width: '120px' }}>{categoryCouchLabel[0].categoriesName}</p>
                              <b className="caret d-none d-lg-block d-xl-block" />
                            </>
                          }

                          {
                            isEspecial &&
                            <>
                              <p className="" style={{ width: '120px' }}>{categoryIsEspecialLabel[0].categoriesName}</p>
                              <b className="caret d-none d-lg-block d-xl-block" />
                            </>
                          }

                          {
                            isClub &&
                            <>
                              <p className="" style={{ width: '120px' }}>{categoryLabel[0].categoriesName}</p>
                              <b className="caret d-none d-lg-block d-xl-block" />
                            </>
                          }




                        </Label>

                      </DropdownToggle>
                      <DropdownMenu className="dropdown-navbar" right tag="ul">
                        <div style={{ maxHeight: 200, overflowY: "scroll" }}>
                          {
                            isCouch &&
                            categoryListCouch.map((task) => {
                              return (
                                <NavLink tag="li" key={task.categoriesID}>
                                  <DropdownItem className={item === task.categoriesID || categoryCouchLabel[0].categoriesName === task.categoriesName ? "nav-item itemActive" : "nav-item"} onClick={() => ChangeCategoryData(task.categoriesID)}>{task.categoriesName}</DropdownItem>
                                </NavLink>
                              )
                            })
                          }

                          {
                            isEspecial &&
                            categoryListIsEspecial.map((task) => {
                              return (
                                <NavLink tag="li" key={task.categoriesID}>
                                  <DropdownItem className={item === task.categoriesID || categoryIsEspecialLabel[0].categoriesName === task.categoriesName ? "nav-item itemActive" : "nav-item"} onClick={() => ChangeCategoryData(task.categoriesID)}>{task.categoriesName}</DropdownItem>
                                </NavLink>
                              )

                            })
                          }
                          {
                            categoryList.map((task) => {
                              return (
                                <NavLink tag="li" key={task.categoriesID}>
                                  <DropdownItem className={item === task.categoriesID || categoryLabel[0].categoriesName === task.categoriesName ? "nav-item itemActive" : "nav-item"} onClick={() => ChangeCategoryData(task.categoriesID)}>{task.categoriesName}</DropdownItem>
                                </NavLink>
                              )

                            })
                          }


                        </div>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                }

                <li className="separator d-lg-none" />
              </Nav>
            </Collapse>

          </Container>
        </Navbar>

        <SideLeft show={showModalAsistencia} onClose={() => closeModal()}>
          <div>
            <h3>Lista de Asistencia</h3>
          </div>
          <Row className="mb-3">
            <Col xs={3}>
              <Input
                defaultValue={filterAsistencia.fecha}
                type="date"
                placeholder="Fecha de partido"
                style={{ width: (SCWIDTH / 2) / 3, border: "0.5 solid #000" }}
                onChange={(e) => _SetFilterAsistencia(1, e.target.value)}
                className="form-control-v2"
              />
            </Col>
            <Col xs={3}>
              <Input
                defaultValue={filterAsistencia.session}
                disabled={evaExists}
                type="select"
                placeholder="Seleccione sesión"
                style={{ width: (SCWIDTH / 2) / 3 }}
                onChange={(e) => _SetFilterAsistencia(2, e.target.value)}
                className="form-control-v2"
              >
                <option disabled value={0}>Seleccione sesión</option>
                <option value={1}>AM</option>
                <option value={2}>PM</option>
                <option value={3}>ADICIONAL</option>
                <option value={4}>PARTIDO</option>
              </Input>
            </Col>
            {
              showMoreFilter && <>
                <Col xs={3}>
                  <Input
                    defaultValue={filterAsistencia.rival}
                    disabled={evaExists}
                    placeholder="Rival"
                    style={{ width: (SCWIDTH / 2) / 3 }}
                    onChange={(e) => _SetFilterAsistencia(3, e.target.value)}
                    className="form-control-v2"
                  />
                </Col>
                <Col xs={3}>
                  <Input
                    defaultValue={filterAsistencia.torneo}
                    disabled={evaExists}
                    type="select"
                    placeholder="Torneo"
                    style={{ width: (SCWIDTH / 2) / 3 }}
                    onChange={(e) => _SetFilterAsistencia(4, e.target.value)}
                    className="form-control-v2"
                  >
                    {
                      torneoList.length > 0 ? <>
                        <option disabled value={0}>Seleccione un torneo</option>
                        {torneoList.map((task) => {
                          return (
                            <option value={task.id} key={task.id}>{task.value}</option>
                          )

                        })}
                      </>
                        : <option>Sin data</option>
                    }
                  </Input>
                </Col>
              </>
            }


          </Row>
          <Row className="mr-1">
            <Col xs='12'>
              <Row style={{ float: "right", position: "absolute", right: "96px" }}>
                {
                  addInProcess && <div className="d-flex" style={{ flexDirection: "column", alignItems: "center" }}>
                    <ReactLoading type={'spinningBubbles'} color="#ff8d72" height={'25px'} width={'25px'} />
                    <span style={{ fontSize: '12px', color: "#ccc" }}>Cargando...</span>
                  </div>
                }
              </Row>
              <div className="table-card">
                <div className="table-card-warpper">
                  <div>
                    <Row>
                      <Col sm="12">
                        <Row className="flex-row-center ml-3">
                          <Col className="mb-5 col-3"></Col>
                        </Row>
                      </Col>
                    </Row>
                    <div className="tab-content-table">
                      <Table className="tablesorter" responsive>
                        <thead className="text-primary">
                          <tr>
                            <th className="text-center">Nombre</th>
                            <th className="text-center">Asistencia</th>
                            <th className="text-center">Tardanza</th>
                            <th className="text-center">Ausencia</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            atletaList.content.length > 0 ?
                              atletaList.content.slice(states.perPage * states.page, states.perPage * (states.page + 1)).map(task => _returnTr(task))
                              :
                              <tr>
                                <td colSpan={5}>
                                  <Row className="justify-content-center align-items-center w-100">
                                    <span style={{ background: '#ff8d7296', color: '#000', padding: '8px', borderRadius: '8px', fontSize: '10px' }}>Sin Data</span>
                                  </Row>
                                </td>
                              </tr>
                          }


                        </tbody>
                      </Table>
                      <Row style={{ padding: "10px" }}>
                        <Col className="pagination-Col-Right">
                          <div className="pagination-warpper">
                            <button className="pagination-btn" onClick={() => paginations(false)}>
                              {`<`}
                            </button>
                            <span>{`${(states.page + 1) === 0 ? 1 : (states.page + 1)} of ${states.numberOfPages === 0 ? 1 : states.numberOfPages}`}</span>
                            <button className="pagination-btn" onClick={() => paginations(true)}>
                              {`>`}
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>

                </div>
              </div>
            </Col>
          </Row>
        </SideLeft>

      </>

    )
  }

  // RENDER SECTION
  return (
    <>
      <Modal
        modalClassName="modal-search"
        isOpen={modalSearch}
        toggle={toggleModalSearch}
      >
        <ModalHeader>
          <Input placeholder="SEARCH" type="text" />
          <button
            aria-label="Close"
            className="close"
            onClick={toggleModalSearch}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
        </ModalHeader>
      </Modal>


    </>
  );
}

export default AdminNavbar;

