import Manager from '../api/main/MainService';
import MapperManger from "../api/main/MapperService";
class MainService {
    constructor() {
        this.api = new Manager();
        this.apiMapper = new MapperManger()
    }

    async GetPosition(model) {
        try {
            let result = await this.api.GetPosition(model)
            return result
        } catch (error) {
            return error
        }
    }

    async GetLoadInitialData(model) {
        try {
            let result = await this.api.GetInitialData(model)
            return result
        } catch (error) {
            return error
        }
    }

    async GetReportClubAndDetailsSource(model) {
        try {
            let result = await this.api.GetReportClubAndDetailsService(model)
            return result
        } catch (error) {
            return error
        }
    }

    async GetReportsByClubIDSource(model) {
        try {
            let result = await this.api.GetReportsByClubIDService(model)
            return result
        } catch (error) {
            return error
        }
    }

    async GetAtletaInfos(model) {
        try {
            let result = await this.api.GetAtletaInfo(model)
            return result
        } catch (error) {
            return error
        }
    }

    async GetAtletasForMercato(model) {
        try {
            let result = await this.api.GetAtletasForMercatoService(model)
            return result
        } catch (error) {
            return error
        }
    }

    async UpdateAtletaForMercato(model) {
        try {
            let result = await this.api.UpdateAtletaForMercatoService(model)
            return result
        } catch (error) {
            return error
        }
    }

    async MemberInfoByDte(model) {
        try {

            let result = await this.api.GetMemberInfoByDate(model)
            return result
        } catch (error) {
            return error
        }
    }

    async GetLoadMicroByDate(model) {
        try {

            let result = await this.api.GetMicrocicloInfoByDate(model)
            return result
        } catch (error) {
            return error
        }
    }

    async GetEvaluaInfo(model) {
        try {
            let result = await this.api.GetEvaluaciones(model)
            return result
        } catch (error) {
            return error
        }
    }

    async GetEvaluaPartidoSession(model) {
        try {
            let result = await this.api.GetEvaluacionPartidoSession(model)
            return result
        } catch (error) {
            return error
        }
    }

    async AddAsistencia(model) {
        try {
            let result = await this.api.AddAsistencia(model)
            return result
        } catch (error) {
            return error
        }
    }

    async CheckAsistenciaList(model) {
        try {
            let result = await this.api.CheckList(model)
            return result
        } catch (error) {
            return error
        }
    }

    async AddUpdateUserEdit(model) {
        try {
            let result = await this.api.AddUpdateUser(model)
            // console.log('result main', result)
            return result
        } catch (error) {
            return error
        }
    }

    async GetExtraInfoAtleta(model) {
        try {
            let result = await this.api.GetAtletInfoExtra(model)
            return result
        } catch (error) {
            return error
        }
    }

    async GetReportBySession(model) {
        try {
            let result = await this.api.GetReportBySession(model)
        } catch (error) {
            return error
        }
    }

    async GetReportClub(model) {
        try {
            let result = await this.api.GetReportByClubID(model)
            return result
        } catch (error) {
            return error
        }
    }
    
    async MapperModel(model){
        try {
            let result = await this.apiMapper.MapperModel(model)
            return result
        } catch (error) {
            return error
        }
    }

    async GetDataInitialAdminNavbar(model){
        try {
            let result = await this.api.GetDataAdminNavbar(model)
            return result
        } catch (error) {
            return error
        }
    }

    async MutateDataCategoryNavbar(model){
        try {
            let result = await this.api.MutateDataCategorieNavbar(model)
            return result
        } catch (error) {
            return error
        }
    }
}


export default MainService;