import { useEffect, useState } from "react"
import IMG from '../../assets/img/atleta.png'
import "./styles.css"


const DragAndDrop = ({ data, UpdateShowInMercato, loadingID }) => {
    const [athletes, setAthletes] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [filteredAthletes, setFilteredAthletes] = useState([]);

    useEffect(() => {
        setAthletes(data)
    }, [data])

    const getList = (show_in_mercato) => {
        if (filteredAthletes.length > 0) {
            return filteredAthletes.filter(item => item.show_in_mercato === show_in_mercato)
        } else {
            return athletes.filter(item => item.show_in_mercato === show_in_mercato)
        }
    }

    const startDrag = (evt, item) => {
        evt.dataTransfer.setData('itemID', item.sessionID)
    }

    const draggingOver = (evt) => {
        evt.preventDefault();
    }

    const onDrop = (evt, show_in_mercato) => {
        const itemID = evt.dataTransfer.getData('itemID');
        const item = athletes.find(item => item.sessionID == itemID);

        if(item.show_in_mercato !== show_in_mercato){
            item.show_in_mercato = show_in_mercato;

            const newState = athletes.map(task => {
                if (task.sessionID === itemID) return item;
                return task
            })

            let model = {
                sessionID: itemID,
                show_in_mercato: show_in_mercato
            }

            setAthletes(newState);
            UpdateShowInMercato(model)
        }
    }

    const onChangeSearchAthlete = (e) => {

        setFilteredAthletes(() => {
            return athletes.filter(athlete => (athlete.nombre).toLowerCase().includes((e.target.value).toLowerCase()))
        })

        setSearchValue(e.target.value)
    }

    return (
        <>
            <div className="drag-and-drop-header">
                <div className="drag-and-drop-header-title">
                    Administrar Atletas para Mercato <br/>
                    <p>(Arrastra y suelta para actualizar el estado del atleta en mercato)</p> 
                </div>
                <div className="dand-input-group">
                    <input
                        type="search"
                        className="drag-and-drop-search"
                        placeholder="Buscar un atleta..."
                        value={searchValue}
                        onChange={onChangeSearchAthlete}/>
                </div>
            </div>

            <div className='drag-and-drop'>

                <div className='column column--1'>
                    <h3 className="column-title">
                        Atletas en progreso<br/>
                        <p>
                            (Atletas que aún no estan listos para ser publicados)
                        </p> 
                    </h3>
                    <div className='dd-zone' droppable="true" onDragOver={(evt => draggingOver(evt))} onDrop={(evt => onDrop(evt, 0))}>
                        {
                            searchValue && filteredAthletes.length === 0 ? (
                                <p>No se encontraron atletas</p>
                            ) :
                                getList(0).map(item => (
                                    <div className='dd-element' key={item.sessionID} draggable onDragStart={(evt) => startDrag(evt, item)}>
                                        <div className="dd-element-profile">
                                            <img src={item.imgData || IMG} alt="img" />
                                            <p className='name'>{item.nombre}</p>
                                        </div>
                                        <span className='age'>{item.edad} años</span>
                                    </div>
                                ))
                        }
                    </div>
                </div>

                <div className='column column--2'>
                    <h3 className="column-title">
                        Atletas listos<br/>
                        <p>
                            (Atletas que estan publicados en mercato)
                        </p> 
                    </h3>
                    <div className='dd-zone' droppable="true" onDragOver={(evt => draggingOver(evt))} onDrop={(evt => onDrop(evt, 1))}>
                        {
                            searchValue && filteredAthletes.length === 0 ? (
                                <p>No se encontraron atletas</p>
                            ) :
                                getList(1).map(item => (
                                    <div className='dd-element' key={item.sessionID} draggable onDragStart={(evt) => startDrag(evt, item)}>
                                        <div className="dd-element-profile">
                                            <img src={item.imgData || IMG} alt="img" />
                                            <p className='name'>{item.nombre}</p>
                                        </div>
                                        <span className='age'>{item.edad} años</span>
                                    </div>
                                ))
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default DragAndDrop;
