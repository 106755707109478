import React from "react";
// reactstrap components
import {
  Row,
  Col,
  Nav,
  NavLink,
  NavItem,
  TabContent,
  TabPane
} from "reactstrap";


import TabsComponent from "components/Tabs";

import GeneralTabs from "components/ContentTabs/General";
import FisicaTabs from "components/ContentTabs/Fisicas";
import TecnicasTabs from "components/ContentTabs/Tecnicas";
import ClubHeader from "components/Header/ClubHeader";
import ReportTabs from "components/ContentTabs/Reportes";
import uuid from "react-uuid";


function Dashboard(props) {

  const [tabHeader, setTabHeader] = React.useState(["Generales", "Físicas", "Técnicas", "Aspectos Complementarios"])

  let [states, setStates] = React.useState({
    activeTab: '0',
  });


  React.useEffect(() => {
    let controller = new AbortController();
    (async () => {
      let currentAtleta = window.localStorage.getItem("currentUser");
      // let categoryInContext = window.localStorage.getItem("currentCategori");
      if (currentAtleta !== null || currentAtleta === undefined) {
        window.localStorage.removeItem("currentUser");
        window.location.reload();
      }

      let category = 0
      let globalContext = JSON.parse(window.localStorage.getItem('globalContext'))
      let currentCategori = window.localStorage.getItem('currentCategori')
      //console.log('GLOBAL CONTET ', globalContext)
      if (globalContext.isCouch) {
        if (currentCategori !== null) {
          currentCategori = JSON.parse(currentCategori)
          category = currentCategori.categoriesID
        } else {
          category = globalContext.categoryID[0].categoriesID
        }

      //  window.localStorage.removeItem('currentCategori')
       // window.localStorage.setItem('currentCategori', JSON.stringify({ categoriesID: globalContext.categoryID[0].categoriesID }))
      } else {
        if (currentCategori !== null) {
          currentCategori = JSON.parse(currentCategori)
          category = currentCategori.categoriesID
        }
      }


      // setTimeout(() => {
      //   setLoading(false);
      // }, 2000);
    })();
    return () => {
      controller.abort();
    };
  }, []);

  //Funcion que indica el index actual del tab
  let toggle = (tab) => {
    if (states.activeTab !== tab) {
      setStates({
        activeTab: tab,
      });
    }
  };

  return (
    <>
      <div className="content">

        <Row>
          <Col xs="12">
            <ClubHeader />
          </Col>
        </Row>

        {/* <TabsComponent
          header={["Generales", "Físicas", "Técnicas", "Aspectos Complementarios"]}
          content={

            <div>

              <GeneralTabs diagnosticData={clubDetails} perfilData={tablaMetalInfo} isLoading={isFetching} />

              <FisicaTabs load={isFetching} />

              <TecnicasTabs dataForFilter={dataForFilter} load={isFetching} />

              <ReportTabs chartRef2={chartRef2} load={isFetching} />

            </div>
          }
        /> */}

        <div>
          <Nav tabs>
            {
              tabHeader.map((row, index) => {
                return (
                  <NavItem key={uuid()}>
                    <NavLink
                      className={states.activeTab === index.toString() ? "active" : "disactive"}
                      onClick={() => { toggle(index.toString()); }}
                    >
                      {row}
                    </NavLink>
                  </NavItem>
                )
              })
            }

          </Nav>
          <br />
          <TabContent activeTab={states.activeTab}>
            <TabPane tabId="0">
              <GeneralTabs active={states.activeTab === "0" ? true : false} />
            </TabPane>
            <TabPane tabId="1">
              <FisicaTabs active={states.activeTab === "1" ? true : false} />
            </TabPane>
            <TabPane tabId="2">
              <TecnicasTabs active={states.activeTab === "2" ? true : false} />
            </TabPane>
            <TabPane tabId="3">
              <ReportTabs active={states.activeTab === "3" ? true : false} />
            </TabPane>
          </TabContent>
        </div>

      </div>
    </>
  );
}

export default Dashboard;
