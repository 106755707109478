import { Document, Page, View, Text, Image, StyleSheet } from '@react-pdf/renderer'
import uuid from 'react-uuid';
import InputRange from './inputRange';

const styles = StyleSheet.create({
    container: {
        width: '100%',
        height: '100%',
        backgroundColor: '#0C162B',
        color: 'white'
    },
    header: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: "center",
        padding: "20px"
    },
    table: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    tableHeaderText: {
        width: '100%',
        fontSize: "12px",
        textTransform: "uppercase",
        color: "#FFF",
        fontWeight: "bold",
        textAlign: 'center'
    },
    tableBody: {
        width: '100%',
        display: "flex",
        flexDirection: 'column',
        padding: "5px 20px"
    },
    tableBodyItem: {
        width: '100%',
        paddingVertical: '8px',
        display: 'flex',
        flexDirection: 'row'
    },
    tableBodyText: {
        width: '100%',
        fontSize: "10px",
        color: "#FFF",
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px'
    },
    footer: {
        display: "flex",
        flexDirection: 'row',
        justifyContent: "space-around",
        alignItems: 'center',
        fontSize: "10px",
        color: "#FFF",
        paddingVertical: '10px'
    },
    tableHeader: {
        width: '100%',
        padding: "8px 20px",
        display: "flex",
        flexDirection: 'row',
        alignItems: "center",
        borderTop: "2px solid red",
        borderBottom: "1px solid white",
    },
    recomendations: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    recomendationsBody: {
        width: '100%',
        display: "flex",
        flexDirection: 'row',
        flexWrap: 'wrap',
        padding: "10px"
    },
    recomendationItem: {
        width: '25%',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        color: 'white',
        padding: '10px 5px'
    },
    recomendationTitle: {
        color: 'white',
        backgroundColor: '#405a6626',
        fontSize: '10px',
        borderRadius: "3px",
        padding: '5px 10px'
    },
    recomendationContent: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        color: 'white',
        backgroundColor: '#405a6626',
        fontSize: '10px',
        borderRadius: "3px",
        padding: '10px 20px'
    }
});

const ReportFisic = ({ data }) => {
  //  console.log('data',data)
    const { infoAtleta, evaluacionInfo, nutricionInfo, recomendations, sportID } = data;
   // console.log('infoAtleta',infoAtleta)
    const {
        nombre,
        ovr,
        clubFlagB64,
        imgB64,
        edad,
        cofR,
        msg,
        posicionSugeridad,
        pesoIdeal,
        posicion,
    } = infoAtleta;
    const { listEva = [], VO2Max, aMejorar } = evaluacionInfo;
    const { amejorar, aspect1, example1, aspect2, example2, aimentacion, plan } = recomendations;

    return (
        <Document title='Reporte Evaluaciónes Fisicas'>
            <Page size="A4">
                <View style={styles.container}>
                    <View style={styles.header}>
                        <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '20px' }}>
                            <Image
                                src={imgB64 === undefined ? "" : imgB64}
                                alt="foto"
                                style={{ width: '100px', height: '100px', borderRadius: '50px' }} />
                            <View style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                <Text style={{ fontSize: '18px' }}>{nombre}</Text>
                                <Text style={{ fontSize: '10px' }}>Edad: {edad} años</Text>
                                <Text style={{ fontSize: '10px' }}>Ovr: {ovr}%</Text>
                            </View>
                        </View>
                        <Image
                            src={clubFlagB64}
                            alt="club"
                            style={{ maxWidth: '80px', maxHeight: '80px' }} />
                    </View>
                    <View style={styles.table}>
                        <View style={styles.tableHeader}>
                            <Text style={styles.tableHeaderText}>Performance</Text>
                            <Text style={styles.tableHeaderText}>Resultado</Text>
                            <Text style={styles.tableHeaderText}>Ideal</Text>
                            <Text style={styles.tableHeaderText}>Porcentaje</Text>
                        </View>
                        <View style={styles.tableBody}>
                            {
                                listEva !== undefined && listEva.map((task) => (
                                    <View key={uuid()} style={styles.tableBodyItem}>
                                        <Text style={styles.tableBodyText}>
                                            {task.test}
                                        </Text>
                                        <Text style={styles.tableBodyText}>
                                            {`${task.result} ${task.um}`}
                                        </Text>
                                        <Text style={styles.tableBodyText}>
                                            {`${task.ideal} ${task.um}`}
                                        </Text>
                                        <View style={{ ...styles.tableBodyText, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                            <Text>
                                                {task.percent}%
                                            </Text>
                                            <Text style={{ fontWeight: 'extrabold', color: 'yellow' }}>
                                                {task.diff === 0 ? '>' : task.diff < 0 ? 'v' : '^'}
                                            </Text>
                                            <Text>
                                                {task.diff}%
                                            </Text>
                                        </View>
                                    </View>
                                ))
                            }
                        </View>
                    </View>
                    {/* Footer */}
                    <View style={styles.footer}>
                        <View style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                            <Text>VO2max:</Text>
                            <Text style={{ color: "#fefed3" }}>{VO2Max}</Text>
                        </View>
                        <View style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                            <Text>A Mejorar:</Text>{" "}
                            <Text style={{ color: "#fefed3" }}>{aMejorar}</Text>
                        </View>
                    </View>
                    {/* Recomendaciones */}
                    <View style={styles.recomendations}>
                        <View style={styles.tableHeader}>
                            <Text style={{ fontWeight: 'bold', fontSize: '12px' }}>Recomendaciones:</Text>
                        </View>
                        <View style={styles.recomendationsBody}>
                            <View style={styles.recomendationItem}>
                                <Text style={styles.recomendationTitle}>Aspecto a mejorar</Text>
                                <Text style={{ fontWeight: 'normal', fontSize: '10px' }}>{amejorar}</Text>
                            </View>
                            <View style={styles.recomendationItem}>
                                <Text style={styles.recomendationTitle}>Especifico</Text>
                                <Text style={{ fontWeight: 'normal', fontSize: '10px' }}>{aspect1}</Text>
                            </View>
                            <View style={styles.recomendationItem}>
                                <Text style={styles.recomendationTitle}>Ejemplo</Text>
                                <Text style={{ fontWeight: 'normal', fontSize: '10px' }}>{example1}</Text>
                            </View>
                            <View style={styles.recomendationItem}>
                                <Text style={styles.recomendationTitle}>Especifico</Text>
                                <Text style={{ fontWeight: 'normal', fontSize: '10px' }}>{aspect2}</Text>
                            </View>
                            <View style={styles.recomendationItem}>
                                <Text style={styles.recomendationTitle}>Ejemplo</Text>
                                <Text style={{ fontWeight: 'normal', fontSize: '10px' }}>{example2}</Text>
                            </View>
                            <View style={styles.recomendationItem}>
                                <Text style={styles.recomendationTitle}>Plan de alimentacion Fitwave</Text>
                                <Text style={{ fontWeight: 'normal', fontSize: '10px' }}>{plan}</Text>
                            </View>
                            <View style={styles.recomendationItem}>
                                <Text style={styles.recomendationTitle}>Plan de trabajo APP</Text>
                                <Text style={{ fontWeight: 'normal', fontSize: '10px' }}>{aimentacion}</Text>
                            </View>
                        </View>
                    </View>
                    {/* Nutricion */}
                    <View style={styles.table}>
                        <View style={styles.tableHeader}>
                            <Text style={styles.tableHeaderText}>Nutrición</Text>
                            <Text style={styles.tableHeaderText}>Resultado</Text>
                            <Text style={styles.tableHeaderText}>Ideal</Text>
                            <Text style={styles.tableHeaderText}>Diferencia</Text>
                        </View>
                        <View style={styles.tableBody}>
                            {
                                nutricionInfo.map((task, index) => (
                                    <View key={uuid()} style={styles.tableBodyItem}>
                                        <Text style={styles.tableBodyText}>
                                            {task.fiel}
                                        </Text>
                                        <Text style={styles.tableBodyText}>
                                            {parseFloat(task.value).toFixed(2)}
                                        </Text>
                                        <Text style={styles.tableBodyText}>
                                            {index === 0 ? pesoIdeal : null}
                                        </Text>
                                        <Text style={styles.tableBodyText}>
                                            {index === 0 ? "-" : null}
                                        </Text>
                                    </View>
                                )
                                )
                            }
                        </View>
                    </View>
                    {/* Footer */}
                    {
                        cofR > 0 && sportID === 1 && listEva.length <= 6 && (
                            <View style={styles.recomendationsBody}>
                                <View style={{ ...styles.recomendationItem, width: '33.33%' }}>
                                    <View style={styles.recomendationContent}>
                                        <Text style={{ fontWeight: 'bold' }}>Posición</Text>
                                        <Text style={{ fontWeight: 'normal' }}>{posicion}</Text>
                                    </View>
                                    <View style={styles.recomendationContent}>
                                        <Text style={{ fontWeight: 'bold' }}>Posición sugerida</Text>
                                        <View style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                            {
                                                posicionSugeridad.map((task, index) => (
                                                    <Text style={{ fontWeight: 'normal' }} key={uuid()}>
                                                        {`${index + 1} - ${task}`}
                                                    </Text>
                                                ))
                                            }
                                        </View>
                                    </View>
                                    <View style={styles.recomendationContent}>
                                        <Text style={{ fontWeight: 'bold' }}>Coeficiente(R)</Text>
                                        <Text style={{ fontWeight: 'normal' }}>{cofR}</Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.recomendationItem, width: '66.66%' }}>
                                    <InputRange ratio={cofR} msg={msg} />
                                </View>
                            </View>
                        )
                    }
                </View>
            </Page>
            {
                cofR > 0 && sportID === 1 && listEva.length > 6 && (
                    <Page size="A4">
                        <View style={styles.container}>
                            <View style={styles.recomendationsBody}>
                                <View style={{ ...styles.recomendationItem, width: '33.33%' }}>
                                    <View style={styles.recomendationContent}>
                                        <Text style={{ fontWeight: 'bold' }}>Posición</Text>
                                        <Text style={{ fontWeight: 'normal' }}>{posicion}</Text>
                                    </View>
                                    <View style={styles.recomendationContent}>
                                        <Text style={{ fontWeight: 'bold' }}>Posición sugerida</Text>
                                        <View style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                            {
                                                posicionSugeridad.map((task, index) => (
                                                    <Text style={{ fontWeight: 'normal' }} key={uuid()}>
                                                        {`${index + 1} - ${task}`}
                                                    </Text>
                                                ))
                                            }
                                        </View>
                                    </View>
                                    <View style={styles.recomendationContent}>
                                        <Text style={{ fontWeight: 'bold' }}>Coeficiente(R)</Text>
                                        <Text style={{ fontWeight: 'normal' }}>{cofR}</Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.recomendationItem, width: '66.66%' }}>
                                    <InputRange ratio={cofR} msg={msg} />
                                </View>
                            </View>
                        </View>
                    </Page>
                )
            }
        </Document>
    )
}

export default ReportFisic;