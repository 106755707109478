import httpClient from '../service/index';
import GetDefinition from "../definition";


class EvaluacionServices {
    constructor() {
        this.response = {
            success: true,
            error: '',
            data: []
        }
    }

    GetInitialData = async (model) => {
        // console.log('MODEL ', model)
        try {
            let url = GetDefinition('LoadInitData')

            let result = await this.getData(url[0], model)

            // console.log('Result BD ', result)

            return result;

        } catch (error) {
            console.log('Error en service GetPosition ', error)
            this.response.error = `Error en service GetPosition ${error}`
            this.response.success = false
            return this.response;
        }
    }

    GetReportClubAndDetailsService = async (model) => {
        try {
            let url = GetDefinition('GetReportClubAndDetails');
            let result = await this.postData(url[0], model);
            return result;
        } catch (error) {
            console.log('Error en service GetPosition ', error)
            this.response.error = `Error en service GetPosition ${error}`
            this.response.success = false
            return this.response;
        }
    }

    GetReportsByClubIDService = async (model) => {
        try {
            let url = GetDefinition('GetReportsByClubID');
            let result = await this.postData(url[0], model);
            return result;
        } catch (error) {
            console.log('Error en service GetPosition ', error)
            this.response.error = `Error en service GetPosition ${error}`
            this.response.success = false
            return this.response;
        }
    }

    async GetPosition(model) {
        try {
            let url = GetDefinition('GetPosition')

            let result = await this.getData(url[0], model)

            return result;

        } catch (error) {
            console.log('Error en service GetPosition ', error)
            this.response.error = `Error en service GetPosition ${error}`
            this.response.success = false
            return this.response;
        }
    }
    async GetAtletaInfo(model) {
        try {
            let url = GetDefinition('GetAtletaInfoBySession')

            let result = await this.getData(url[0], model)

            //console.log('Result atleta info ', result)

            return result;

        } catch (error) {
            console.log('Error en service GetAtletaInfo ', error)
            this.response.error = `Error en service GetAtletaInfo ${error}`
            this.response.success = false
            return this.response;
        }

    }

    async GetAtletasForMercatoService(model) {
        try {
            let url = GetDefinition('GetAtletasForMercato')

            let result = await this.getData(url[0], model)

            //console.log('Result atleta info ', result)

            return result;

        } catch (error) {
            console.log('Error en service GetAtletasForMercato ', error)
            this.response.error = `Error en service GetAtletasForMercatoService ${error}`
            this.response.success = false
            return this.response;
        }

    }

    async UpdateAtletaForMercatoService(model) {
        try {
            let url = GetDefinition('UpdateAtletaForMercato')

            let result = await this.postData(url[0], model)

            return result;

        } catch (error) {
            console.log('Error en service UpdateAtletaForMercato ', error)
            this.response.error = `Error en service UpdateAtletaForMercatoService ${error}`
            this.response.success = false
            return this.response;
        }

    }

    async GetMemberInfoByDate(model) {
        try {
            let url = GetDefinition('GetMemberInfoByDate')
            let result = await this.getData(url[0], model)

            // console.log('Result BD', result)
            return result
        } catch (error) {
            console.log('Error en service RecoveryProcess ', error)
            this.response.error = `Error en service RecoveryProcess ${error}`
            this.response.success = false
            return this.response;
        }
    }

    async GetMicrocicloInfoByDate(model) {
        try {
            let url = GetDefinition('GetMicrocicloByDate')
            let result = await this.getData(url[0], model)

            // console.log('Result BD', result)
            return result
        } catch (error) {
            console.log('Error en service RecoveryProcess ', error)
            this.response.error = `Error en service RecoveryProcess ${error}`
            this.response.success = false
            return this.response;
        }
    }

    async GetEvaluaciones(model) {
        try {
            let url = GetDefinition('GetEvaluacion')

            let result = await this.getData(url[0], model)

            return result;

        } catch (error) {
            console.log('Error en service GePuntuacion ', error)
            this.response.error = `Error en service GePuntuacion ${error}`
            this.response.success = false
            return this.response;
        }
    }

    async GetEvaluacionPartidoSession(model) {
        try {
            let url = GetDefinition('GetEvaluacionPartido')
            let result = await this.postData(url[0], model)
            // console.log('resultPartidoMain', result)
            return result;
        } catch (error) {
            console.log('Error en service GetEvaluacionPartido ', error)
            this.response.error = `Error en service GetEvaluacionPartido ${error}`
            this.response.success = false
            return this.response;
        }
    }

    async AddAsistencia(model) {
        try {
            let url = GetDefinition('AddAsistencia')
            let result = await this.postData(url[0], model)
            return result;
        } catch (error) {
            console.log('Error en service GetEvaluacionPartido ', error)
            this.response.error = `Error en service GetEvaluacionPartido ${error}`
            this.response.success = false
            return this.response;
        }
    }

    async CheckList(model) {
        try {
            let url = GetDefinition('CheckAsistenciaList')
            let result = await this.postData(url[0], model)
            return result;
        } catch (error) {
            console.log('Error en service CheckList ', error)
            this.response.error = `Error en service CheckList ${error}`
            this.response.success = false
            return this.response;
        }
    }

    async AddUpdateUser(model) {
        try {
            let url = GetDefinition('AddUpdateByUserID')
            let result = await this.postData(url[0], model)
            return result
        } catch (error) {
            console.log('Error en service GetEvaluacionPartido ', error)
            this.response.error = `Error en service GetEvaluacionPartido ${error}`
            this.response.success = false
            return this.response;
        }
    }

    async GetAtletInfoExtra(model) {
        try {
            let url = GetDefinition('GetExtraAtletaInfo')
            let result = await this.postData(url[0], model)
            return result
        } catch (error) {
            console.log('Error en service GetAtletInfoExtra ', error)
            this.response.error = `Error en service GetAtletInfoExtra ${error}`
            this.response.success = false
            return this.response;

        }
    }

    async GetReportBySession(model) {
        try {
            let url = GetDefinition('GetReportBySession')
            let result = await this.postData(url[0], model)
            return result
        } catch (error) {
            console.log('Error en service GetReportBySession ', error)
            this.response.error = `Error en service GetReportBySession ${error}`
            this.response.success = false
            return this.response;

        }
    }


    async GetReportByClubID(model) {
        try {
            let url = GetDefinition('getReportByClub')
            let result = await this.postData(url[0], model)
            return result
        } catch (error) {
            console.log('Error en service GetReportByClubID ', error)
            this.response.error = `Error en service GetReportByClubID ${error}`
            this.response.success = false
            return this.response;

        }
    }

    async GetAllReportsClubID(model) {
        try {
            let url = GetDefinition('GetAllReportsClubID')
            let result = await this.postData(url[0], model)
            // console.log('result', result)
            return result
        } catch (error) {
            console.log('Error en service GetReportByClubID ', error)
            this.response.error = `Error en service GetReportByClubID ${error}`
            this.response.success = false
            return this.response;

        }
    }

    async GetUserDetailsAndPosition(model) {
        try {
            let url = GetDefinition('GetUserAndPosition')
            let result = await this.postData(url[0], model)
            // console.log('result', result)
            return result
        } catch (error) {
            console.log('Error en service GetReportByClubID ', error)
            this.response.error = `Error en service GetReportByClubID ${error}`
            this.response.success = false
            return this.response;

        }
    }

    async MutationUserDetails(model) {
        try {
            let url = GetDefinition('MutationDetailUsers')
            let result = await this.postData(url[0], model)
            ///console.log('result', result)
            return result
        } catch (error) {
            console.log('Error en service GetReportByClubID ', error)
            this.response.error = `Error en service GetReportByClubID ${error}`
            this.response.success = false
            return this.response;

        }
    }

    async MutationListPositionsBySport(model){
        try {
            let url = GetDefinition('MutationListPositionsBySport')
            let result = await this.postData(url[0], model)
           console.log('TEST', result)
            return result
        } catch (error) {
            this.response.error = `Error en service Mutation Position By Sport ${error}`
            this.response.success = false
            return this.response;
        
        }
    }

    async GetImageB64(model) {
        try {
            let url = GetDefinition('GetImgData')

            let result = await this.getDataImg(url[0], model)
            return result;
        } catch (error) {
            console.log('Error en EvaluacionServices Metodo GetImageB64', error)
            this.response.error = `Error en el metodo GetImageB64 ${error}`
            this.response.success = false
            return this.response;
        }
    }

    async GetDataAdminNavbar(model){
        try {
            let url = GetDefinition('getDataAdminNavbar')
            let result = await this.postData(url[0], model)
            return result
        } catch (error) {
            this.response.error = `Error en service Mutation Position By Sport ${error}`
            this.response.success = false
            return this.response;
        
        }
    }

    async MutateDataCategorieNavbar(model){
        try {
            let url = GetDefinition('mutateDataCategory')
            let result = await this.postData(url[0], model)
            return result
        } catch (error) {
            this.response.error = `Error en service Mutation Position By Sport ${error}`
            this.response.success = false
            return this.response;
        
        }
    }
    
    async GetClubInfoBuSessionID(model){
        try {
            let url = GetDefinition('GetClubInfoBysessionClubID')
            let result = await this.postData(url[0], model)
            return result
        } catch (error) {
            this.response.error = `Error en service Mutation Position By Sport ${error}`
            this.response.success = false
            return this.response;
        
        }
    }
    // HTTP SERVICE
    postData(url, model) {
        // console.log(`URL `, url , 'Model ', model)
        return new Promise((resolve) => {
            httpClient.post(url, model)
                .then((result) => {
                    //console.log('API RESULT ', result)
                    resolve(
                        {
                            success: true,
                            error: '',
                            data: result
                        }
                    )
                })
                .catch(error => {
                    console.log('ERROR', error)
                    resolve({
                        success: false,
                        error: error,
                        data: []
                    })
                })
        })
    }

    getData(url, req) {
        return new Promise((resolve) => {
            httpClient.get(url, req)
                .then((result) => {
                    if (result.message.success) {
                        resolve(result)
                    } else {
                        resolve(result)
                    }

                })
                .catch(error => {
                    console.log('ERROR', error)
                    resolve({
                        success: false,
                        error: error,
                        data: []
                    })
                })
        })
    }

    putData(url, model) {
        return new Promise((resolve) => {
            httpClient.put(url, model)
                .then((result) => {
                    //console.log('API RESULT ', result)
                    resolve(
                        {
                            success: true,
                            error: '',
                            data: result
                        }
                    )
                })
                .catch(error => {
                    console.log('ERROR', error)
                    resolve({
                        success: false,
                        error: error,
                        data: []
                    })
                })
        })
    }

    getDataImg(url, model) {
        // console.log('url', url, 'model get img', model)
        return new Promise((resolve) => {
            httpClient.get(url, model)
                .then((result) => {
                    // console.log('RESULT ', result)
                    resolve(result)
                })
                .catch(error => {
                    console.log('ERROR', error)
                    resolve({
                        success: false,
                        error: error,
                        data: []
                    })
                })
        })
    }
}


export default EvaluacionServices;
