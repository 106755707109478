import { useState, useEffect } from "react"
import { Table, Row, Col, Button, UncontrolledTooltip, Spinner } from "reactstrap"
import CustomizableSelect from "components/customSelect/customSelect"
import HelperManager from "../source/handler/index"
import DowloadExcel from "assets/Iconos/Excel.svg"

const api = new HelperManager()

const DiagnosticTable = ({
                           title = "",
                           header = [],
                           content = [],
                           filterArr = [],
                           idRef = "",
                         }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [rows, setRows] = useState([])
  const [currentFilter, setCurrentFilter] = useState({})
  const [state, setState] = useState({
    page: 0,
    perPage: 5,
    numberOfPages: Math.ceil(content.length / 5),
  })

  const [dowloadEmpty, setDowloadEmpty] = useState(false)
  const [customMessage, setCustomMessage] = useState(
      "No existen datos para cargar"
  )
  const [disabledExcel, setDisabledExcel] = useState(false)

  useEffect(() => {
    if (content.length > 0) {
      state.numberOfPages = Math.ceil(content.length / 5)
      setDisabledExcel(true)
    } else {
      state.numberOfPages = 0
    }
  }, [content])

  let excelDowload = () => {
    let name = ''
    if (idRef === 'test_table_ovr') {
      name = 'Estadisticas de reportes'
    }
    let array = []
    let modelTable = {
      atleta: '',
      fisica: '',
      tecnica: '',
      psicologia: '',
      antropometria: '',
      medica: '',
      fisiologica: '',
      psicosocial: '',
      ovrGeneral: ''
    }
    //  console.log('filter array', content)
    for (let index = 0; index < content.length; index++) {
      let element = content[index]
      // console.log('eleme', element)
      modelTable.atleta = element.nombre === '' || element.nombre === null ? 0 : element.nombre
      modelTable.fisica = element.fisica === '' || element.fisica === null ? 0 : element.fisica
      modelTable.psicologia = element.psicologia === '' || element.psicologia === null ? 0 : element.psicologia
      modelTable.antropometria = element.antropometria === null || element.antropometria === '' ? 0 : element.antropometria
      modelTable.medica = element.medica === null || element.medica === '' ? 0 : element.medica
      modelTable.fisiologica = element.fisiologia === null || element.fisiologia === '' ? 0 : element.fisiologia
      modelTable.psicosocial = element.psicosocial === null || element.psicosocial === '' ? 0 : element.psicosocial
      modelTable.ovrGeneral = Math.round(element.ovr_general === null || element.ovr_general === '' ? 0 : element.ovr_general)

      array.push(modelTable)

      modelTable = {
        atleta: '',
        fisica: '',
        tecnica: '',
        psicologia: '',
        antropometria: '',
        medica: '',
        fisiologica: '',
        psicosocial: '',
        ovrGeneral: ''
      }
      //  console.log('array', array)
    }
    api.dowloadexcel(array, name)
    // window.alert(value);
  }

  let changeData = (value) => {
    // window.alert(JSON.stringify(value, null, 2))
    // console.log('value', value)
    setCurrentFilter({
      ...currentFilter,
      [value.key]: value,
    })
  }

  let handlePagination = (value) => {
    let page = state.page

    let totalIndex = state.numberOfPages - 1

    if (value) {
      page = page + 1
      page = page >= totalIndex ? totalIndex : page
    } else {
      page = page - 1
      page = page <= 0 ? 0 : page
    }

    setState({ ...state, page })
  }

  useEffect(() => {
    if (Object.keys(currentFilter).length > 0) {
      setRows(content.filter(row => row[currentFilter.nombre.key] == currentFilter.nombre.value))
    } else {
      setRows(content)
    }
  }, [content, currentFilter])

  return (
      <>
        {isLoading ? (
            <Row>
              <Col sm="12 p-4">
                <Spinner
                    color="primary"
                    style={{
                      height: '3rem',
                      width: '3rem'
                    }}
                >
                  Loading...
                </Spinner>
              </Col>
            </Row>
        ) : (
            <>
              <h1 className="tittlestyle card-title">{title}</h1>
              <div className="table-card">
                <div className="table-card-warpper">
                  <div>
                    <Row>
                      <Col sm="12">
                        <Row className="flex-row-center ml-3">
                          {filterArr.map((row) => (
                              <Col xs="3" key={row.id}>
                                <CustomizableSelect
                                    title={row.placheolder}
                                    item={row.data}
                                    multiselect={row.multiselect}
                                    noData={row.data.length === 0 ? true : false}
                                    onChange={(el) => changeData(el)}
                                />
                              </Col>
                          ))}
                        </Row>
                      </Col>
                    </Row>

                    <div className="tab-content-table" id={idRef}>
                      <Table className="tablesorter" responsive>
                        <thead className="text-primary">
                        <tr>
                          {header.map((row, index) => (
                              <th key={index} className="text-center">
                                {row.label}
                              </th>
                          ))}
                        </tr>
                        </thead>

                        <tbody>
                        {
                          rows.length > 0 ?

                              rows.slice(state.perPage * state.page, state.perPage * (state.page + 1)).map((row, index) => {
                                return (
                                    <tr key={index}>
                                      {header.map((col, indexCol) => {
                                        const value = row[col.id]
                                        let format = col.format
                                        let isButton = col.type == "button"
                                        return (
                                            <td
                                                key={indexCol + index}
                                                className={col?.position ? "pl-4" : "text-center"}
                                            >
                                              {
                                                isButton
                                                    ? <button>{col.label}</button>
                                                    : (
                                                        <div>
                                                          {
                                                            value !== 0
                                                                ? typeof value === "string"
                                                                    ? value
                                                                    : `${value} %`
                                                                : '-'
                                                          }
                                                        </div>
                                                    )
                                              }
                                            </td>
                                        )
                                      })}
                                    </tr>
                                )
                              })
                              :
                              <tr>
                                <td colSpan={header.length}>
                                  <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    width: '100%'
                                  }}>
                                    <span style={{
                                      background: '#ff8d7296',
                                      color: '#000',
                                      padding: '8px',
                                      borderRadius: '8px',
                                      fontSize: '10px',
                                      marginLeft: '20px'
                                    }}>{customMessage}</span>
                                  </div>
                                </td>
                              </tr>
                        }
                        </tbody>
                      </Table>
                      <Row style={{ padding: "10px" }}>
                        <Col>
                          <div style={{ display: "flex" }}>
                            {disabledExcel &&
                                <>

                                  <Button
                                      className="pagination-btn-filter"
                                      id={"Tooltip-Excel"}
                                      onClick={() => excelDowload()}
                                  >
                                    <img
                                        src={DowloadExcel}
                                        style={{ width: "20px", height: "20px" }}
                                    />
                                  </Button>
                                  <UncontrolledTooltip
                                      placement="top"
                                      target={"Tooltip-Excel"}
                                  >
                                    Descargar Excel
                                  </UncontrolledTooltip>
                                </>
                            }

                            {dowloadEmpty && (
                                <div
                                    style={{ marginRight: "5px", marginTop: "11px" }}
                                >
                                                        <span
                                                            style={{
                                                              background: "#ff8d7296",
                                                              color: "#000",
                                                              padding: "8px",
                                                              borderRadius: "8px",
                                                              fontSize: "10px",
                                                            }}
                                                        >
                                                            {customMessage}
                                                        </span>
                                </div>
                            )}
                          </div>
                        </Col>
                        <Col className="pagination-Col-Right">
                          <div className="pagination-warpper">
                            <button
                                className="pagination-btn"
                                onClick={() => handlePagination(false)}
                            >
                              {`<`}
                            </button>
                            <span>{`${state.page + 1 === 0 ? 1 : state.page + 1
                            } of ${state.numberOfPages === 0 ? 1 : state.numberOfPages
                            }`}</span>
                            <button
                                className="pagination-btn"
                                onClick={() => handlePagination(true)}
                            >
                              {`>`}
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            </>
        )
        }
      </>
  )
}

export default DiagnosticTable
