import React, { useState, useEffect } from "react";


// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import {
  useHistory,
  useLocation
} from "react-router-dom";
// core components


import Atletaheadercomponent from "components/Header/Atleta-header";

import Modal from '../components/Modal/index';
import CardTouchable from "components/Card/clickeable";
import Manager from '../source/Main'
import TabsComponent from "components/Tabs";
import GeneralTabs from "components/ContentTabsAtleta/GeneralTabs";
import FisicaTabs from "components/ContentTabsAtleta/FisicaTabs";
import TecnicasTabs from "components/ContentTabsAtleta/TecnicaTabs";
import ReportTabs from "components/ContentTabsAtleta/ReporteTabs";

const api = new Manager();

function AtletaDashboard() {

  const [isOpen, setIsOpen] = React.useState(true)
  const [enabled, setEnabled] = useState(false)


  const handlerCardClick = (task) => {
    window.localStorage.setItem('currentUser', task)
    setEnabled(true)
    setIsOpen(false)
  }
 

  const redirect = () => {
    window.location.href = '/admin/dashboard';
  }

  
  if (isOpen) {
    return (
      <Modal show={isOpen} height={'85%'} width={'90%'} onClose={() => redirect()} className='clickeableModal' modalStyle={{ backgroundColor: "#F0F1F7" }}>
        <Row>
          <h2>Seleccione un atleta</h2>
        </Row>
        <CardTouchable onClick={(item) => handlerCardClick(item)} />
      </Modal>
    )
  }
  return (
    <>
      <div className="content">
        <Row>
          <Col xs="12">
            <Atletaheadercomponent/>
          </Col>
        </Row>
        <TabsComponent
          header={["Generales", "Físicas", "Técnicas", "Reportes"]}
          content={
            <div>
              <GeneralTabs enabled={enabled} />
              <FisicaTabs enabled={enabled} />
              <TecnicasTabs enabled={enabled} />
              <ReportTabs enabled={enabled} />
            </div>
          }
        />
      </div>
    </>
  );

}

export default AtletaDashboard;
