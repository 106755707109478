import React from "react";
import ReactLoading from "react-loading";
import styled from "styled-components";
const Overlay = styled.div`
position: absolute;
    background: rgba(0,0,0,0.8);
    width: 100%;
    height: 100vh;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(6px);
`;
const Loading = styled.div`
display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
const LoadingScreen = ({ type = 'spinningBubbles' }) => {
    return (
        <Overlay>
            <Loading>
                <ReactLoading type={type} color="#fff" />
                <h2 style={{color:'#fff'}} className='text-white mt-3'>Cargando información...</h2>
            </Loading>
        </Overlay>
    )
}


export default LoadingScreen;