import React from 'react'
import GlobalTable from "../../Tables/GlobalTable"
import { Card, CardBody, Col, Row } from "reactstrap"
import SearchHeader from 'components/Header/SearchHeader'
import { useQuery } from 'react-query'
import { GetListAtletasForEspecial } from 'api/AtletaDashboardTabs/GeneralTabs'
import ReactLoading from 'react-loading';

const SearchAthletesIsEpecial = ({ handleSearchAthletes }) => {
  const [headerTable, setHeaderTable] = React.useState([
    {
      id: "nombre",
      label: 'Nombre',
      position: "left"
    },
    {
      id: 'clubName',
      label: 'Club'
    },
    {
      id: 'categoriesName',
      label: 'Categoria'
    },
    {
      id: 'edad',
      label: 'Edad'
    },
    {
      id: 'ovr',
      label: 'Ovr'
    },
    {
      id: 'position',
      label: 'Posición',
      position: "left"
    },
    {
      id: 'button',
      label: ''
    }
  ])

  const [institutionName, setInstitutionName] = React.useState('')
  const imgLogoDefault = 'https://fitwavedevimg.s3.us-east-2.amazonaws.com/logo-fitwave.png'

  const handleQuery = async () => {
    // Cargamos el LocalStorage
    let name = window.localStorage.getItem('isSuper');

    // Se valida si existe el Local
    if (name != null) {
      name = JSON.parse(window.localStorage.getItem('isSuper'))
      setInstitutionName(name.clubInfo[0].nombre)
    }
    
    let model = {
      sessionID: name.especialSessionID
    }
    
    let result = await GetListAtletasForEspecial(model)
    //console.log('result',result)
    return result
  }

  const { data, isFetching, isSuccess } = useQuery('athletesListEspecialist', () => handleQuery(), {
    refetchOnWindowFocus: false
  })

  if (isFetching) {
    return (
      <div className="outer mx-auto">
        <div className="inner justify-content-center" style={{ width: '36%', height: '115px' }}>
          <Card style={{ background: "transparent", boxShadow: "none" }}>
            <CardBody>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', marginTop: '-26px' }}>
                <div>
                  <h3>Cargando información...</h3>
                </div>
                <ReactLoading type='bubbles' color='#fff' width={80} />
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    )
  }
  if (isSuccess) {
    console.log('data', data)
    const { athletesList } = data.message
    
    return (
      <div className="wrapper" style={{ padding: 15 }}>
        <div className="main-panel" style={{ borderTop: "none" }}>
          <button
            style={{
              background: '#fff',
              textAlign: 'center',
              margin: '15px 5px',
              padding: '6px',
              borderRadius: '18px',
              border: '1px solid lightgray',
            }}
            onClick={handleSearchAthletes}>
            <i className="tim-icons icon-minimal-left" />
            Regresar
          </button>
          <div>
            <div className="content">
              <Row>
                <Col xs="12">
                  <SearchHeader institutionName={institutionName} imgLogo={imgLogoDefault} />
                </Col>
              </Row>
              <GlobalTable
                headerData={headerTable}
                data={athletesList}
                listTitle="Atletas"
                searchField="nombre"
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

}

export default SearchAthletesIsEpecial