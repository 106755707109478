import { useEffect, useState } from "react";
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
} from "recharts";


let primaryColor = "#1f8ef1";
let secondaryColor = "teal";

const AreaChartComponent = ({
    data = {},
    toolTip = {
        current: "Reporte actual",
        last: "Reporte previo"
    },
    idRef = "",
    defaultValues = [{
        name: "",
        dt1: 0,
        dt2: 0
    }]
}) => {
    const [body, setBody] = useState([]);

    useEffect(() => {
        let deleteDataWithZero = data.filter(row => row.dt1 > 0 || row.dt2 > 0)
        if (deleteDataWithZero.length > 0) {
            setBody(deleteDataWithZero);
        } else {
            setBody(defaultValues);
        }
    }, [data])


    return (
        <div ref={idRef} style={{ width: "100%", height: 360 }}>
           {/* <pre>{JSON.stringify(body, null, 2)}</pre> */}
            <ResponsiveContainer>
                <AreaChart
                    data={body}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={primaryColor} stopOpacity={0.5} />
                            <stop offset="95%" stopColor={primaryColor} stopOpacity={0} />
                        </linearGradient>
                        <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={secondaryColor} stopOpacity={0.5} />
                            <stop offset="95%" stopColor={secondaryColor} stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis
                        tickFormatter={(decimal) => `${decimal}%`}
                        domain={[0, 100]}
                        ticks={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                    />
                    <Tooltip
                        content={({ active, content, label, payload, ...rest }) => {
                            if (active) {
                                return (
                                    <div
                                        style={{
                                            background: "#FFF",
                                            padding: "10px",
                                        }}
                                    >
                                        <b>{label}</b>
                                        {payload.map((row, index) => (
                                            <div
                                                key={index}
                                                style={{
                                                    color:
                                                        row.name == "dt1" ? secondaryColor : primaryColor,
                                                }}
                                            >
                                                <span>
                                                    {row.name == "dt1" ?
                                                        toolTip.current : toolTip.last
                                                    }:
                                                </span>{" "}
                                                &nbsp;
                                                <span>{row.value}%</span>
                                            </div>
                                        ))}
                                    </div>
                                );
                            }

                            return null;
                        }}
                    />
                    <Area
                        type="monotone"
                        dataKey="dt2"
                        fillOpacity={1}
                        fill="url(#colorUv)"
                        stroke={primaryColor}
                        strokeWidth={1.5}
                        activeDot={{ r: 8 }}
                    />
                    <Area
                        type="monotone"
                        dataKey="dt1"
                        fillOpacity={1}
                        fill="url(#colorPv)"
                        stroke={secondaryColor}
                        strokeWidth={1.5}
                        activeDot={{ r: 8 }}
                    />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    );
};

export default AreaChartComponent;
