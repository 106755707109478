"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStateCallback = void 0;
var react_1 = require("react");
function useStateCallback(initialState) {
    var _a = (0, react_1.useState)(initialState), state = _a[0], setState = _a[1];
    var callbacksRef = (0, react_1.useRef)([]);
   
    var callbacks = callbacksRef.current;
    // call stored callbacks if any exist
    (0, react_1.useEffect)(function () {
        // call all stored callbacks
        callbacks.forEach(function (callback) { return callback(state); });
        // clear them
        callbacksRef.current = [];
    }, [state, callbacksRef, callbacks]);
    // add 3rd argument to setState
    var setStateWithCallback = (0, react_1.useCallback)(function (newStateAction, callback) {
        // call the original setter
        setState(newStateAction);
        // if defined, store the callback
        if (callback) {
            callbacksRef.current.push(callback);
        }
    }, [setState, callbacksRef]);
    return [state, setStateWithCallback];
}
exports.useStateCallback = useStateCallback;