import { createContext } from "react";

export const backgroundColors = {
  primary: "#0C162B"
};

export const BackgroundColorContext = createContext({
  color: backgroundColors.primary,
  changeColor: (color) => {},
});
