import React, { useReducer } from "react";
import GlobalContext from "../context";
import Reducer from '../reducer';
import asyncDispatch from "redux/middelware";
import Manager from '../../source/Main'
import moment from "moment";
import "moment/locale/es"


const api = new Manager();

const GlobalProvider = ({ children }) => {
    const initialState = {
        isFetching: true,
        userInfo: [],
        clubDetails: [],
        positionList: [],
        rangeDateCurrent: [],
        weekRange: {},
        fisicaEvaluacionList: [{
            dt1: [0, 0, 0, 0, 0, 0, 0],
            dt2: [0, 0, 0, 0, 0, 0, 0]
        }],
        categoryListClub: [],
        noCategory: false,
        evaluacionesAtletasOver: [],
        corpInfo: [],
        corpInfoAtleta: [],
        evaluaTecAtleta: [],
        widGetAtleta: [{
            dt1: [0, 0, 0, 0, 0, 0, 0],
            dt2: [0, 0, 0, 0, 0, 0, 0]
        }],
        mentalInfo: [],
        atletaInfo: [],
        microciclo: {
            microcicloOvr: 0,
            microciclo: []
        },
        lesionadosList: [],
        sportID: 1,
        evaluacionInfo: [],
        evaluacionesPartidosClub: [],
        evaluacionesPartidosUser: [],
        dataForFilter: {
            fisica: [],
            tecnica: []
        },
        reporteArr: {
            monthArr: [],
            weekArr: [],
            dateArr: [],
            reportArr: [],
            filterData: [],
            filterActive: {},
            dwData: []
        },
        chartReports: {
            dt1: [0, 0, 0, 0, 0, 0, 0, 0],
            dt2: [0, 0, 0, 0, 0, 0, 0, 0]
        }
    }
    // userInfo
    const [state, dispatch] = useReducer(Reducer, initialState);

    const GetInitialData = async (model) => {

        // console.log('MODEL INITIAL  ', model)
        dispatch({ type: 'FETCHING_INITIAL_DATA' });

        // DATA INICIAL 
        let loadDataResult = await api.GetLoadInitialData(model)

        // console.log('RESULT STATE ', loadDataResult)
        let sportID = parseInt(loadDataResult.message.data.clubInfo[0].sportID)
        let clubID = loadDataResult.message.data.clubInfo[0].clubID

        localStorage.setItem("clubID", clubID);

        if (loadDataResult.message.success) {

            // ACTUALIZAR INFORMACION DE CLUB 
            asyncDispatch(
                dispatch({
                    type: 'FETCHING_GET_INFO_CLUB',
                    payload: loadDataResult.message.data.clubInfo
                })
            )

            // ACTUALIZAR SPORTID
            asyncDispatch(
                dispatch({
                    type: 'FETCHING_GET_SPORTID',
                    payload: parseInt(loadDataResult.message.data.clubInfo[0].sportID)
                })
            )

            // ACTUALIZA WEEK RANGE 
            asyncDispatch(
                dispatch({
                    type: 'FETCHING_GET_RANGE_WEEK_RANGE',
                    payload: loadDataResult.message.data.weekRange
                })
            )

            // ACTUALIZA COMPARACION DE EVALUACIONES
            let fisical = loadDataResult.message.data.fisicaEvaluacionList
            //   console.log('FISIACAL EVALUACION RESULT STATE ', fisical)

            // EXTRAER LA ULTIMA EVALUACION Y LA ANTERIO

            let currentDayEva = ''
            let dt1 = []
            let beforeDayEva = ''
            let dt2 = []
            for (let index = 0; index < fisical.length; index++) {
                let task = fisical[index];
                if (currentDayEva === "") {
                    currentDayEva = task.date
                }

                if (task.date !== currentDayEva) {
                    if (beforeDayEva === "") {
                        beforeDayEva = task.date
                    }
                }

                if (task.date === currentDayEva) {
                    dt1.push(task)
                }

                if (task.date === beforeDayEva) {
                    dt2.push(task)
                }

            }

            // INFORMACION DE LA PRIMERA EVALUACION
            let modelNew = [{
                dt1: [0, 0, 0, 0, 0, 0, 0],
                dt2: [0, 0, 0, 0, 0, 0, 0]
            }]
            // CFUERZA
            let f = 0
            let lenF = 0

            // VELOCIDAD
            let v = 0
            let lenV = 0

            //RESISTENCIA 
            let r = 0
            let lenR = 0

            // POTENCIA 
            let p = 0
            let lenP = 0

            // FLEXIBILIDAD 
            let ff = 0
            let lenFF = 0

            // AGILIDAD
            let a = 0
            let lenA = 0

            // CALCULAMOS EL CORE 
            let c = 0
            let lenC = 0

            // CALCULAMOS FUERZA
            dt1.filter((task) => {
                if (task.fisical_testID === 5) {
                    f = parseInt(f + task.percent)
                    lenF++
                }
            })

            // CALCULAMOS VELOCIDAD
            dt1.filter((task) => {
                if (task.fisical_testID === 2) {
                    v = parseInt(v + task.percent)
                    lenV++
                }
            })

            //CALCULAMOS RESISTENCIA 
            dt1.filter((task) => {
                if (task.fisical_testID === 1) {
                    r = parseInt(r + task.percent)
                    lenR++
                }
            })

            // CALCULAMOS POTENCIA 
            dt1.filter((task) => {
                if (task.fisical_testID === 4) {
                    p = parseInt(p + task.percent)
                    lenP++
                }
            })

            // CALCULAMOS FLEXIBILIDAD 
            dt1.filter((task) => {
                if (task.fisical_testID === 7) {
                    ff = parseInt(ff + task.percent)
                    lenFF++
                }
            })

            // CALCULAMOS AGILIDAD
            dt1.filter((task) => {
                if (task.fisical_testID === 3) {
                    a = parseInt(a + task.percent)
                    lenA++
                }
            })

            // CALCULAMOS EL CORE 
            dt1.filter((task) => {
                if (task.fisical_testID === 6) {
                    c = parseInt(c + task.percent)
                    lenC++
                }
            })

            modelNew[0].dt1 = [
                isNaN(Math.round(f / lenF)) ? 0 : Math.round(f / lenF),
                isNaN(Math.round(v / lenV)) ? 0 : Math.round(v / lenV),
                isNaN(Math.round(r / lenR)) ? 0 : Math.round(r / lenR),
                isNaN(Math.round(p / lenP)) ? 0 : Math.round(p / lenP),
                isNaN(Math.round(ff / lenFF)) ? 0 : Math.round(ff / lenFF),
                isNaN(Math.round(a / lenA)) ? 0 : Math.round(a / lenA),
                isNaN(Math.round(c / lenC)) ? 0 : Math.round(c / lenC)
            ]

            // CALCULAMOS VELOCIDAD
            dt2.filter((task) => {
                if (task.fisical_testID === 2) {
                    v = parseInt(v + task.percent)
                    lenV++
                }
            })

            //CALCULAMOS RESISTENCIA 
            dt2.filter((task) => {
                if (task.fisical_testID === 1) {
                    r = parseInt(r + task.percent)
                    lenR++
                }
            })

            // CALCULAMOS POTENCIA 
            dt2.filter((task) => {
                if (task.fisical_testID >= 4 && task.fisical_testID <= 5) {
                    p = parseInt(p + task.percent)
                    lenP++
                }
            })

            // CALCULAMOS FLEXIBILIDAD 
            dt2.filter((task) => {
                if (task.fisical_testID === 7) {
                    ff = parseInt(ff + task.percent)
                    lenFF++
                }
            })

            // CALCULAMOS AGILIDAD
            dt2.filter((task) => {
                if (task.fisical_testID === 3) {
                    a = parseInt(a + task.percent)
                    lenA++
                }
            })

            // CALCULAMOS EL CORE 
            dt2.filter((task) => {
                if (task.fisical_testID === 6) {
                    c = parseInt(c + task.percent)
                    lenC++
                }
            })

            modelNew[0].dt2 = [
                isNaN(Math.round(f / lenF)) ? 0 : Math.round(f / lenF),
                isNaN(Math.round(v / lenV)) ? 0 : Math.round(v / lenV),
                isNaN(Math.round(r / lenR)) ? 0 : Math.round(r / lenR),
                isNaN(Math.round(p / lenP)) ? 0 : Math.round(p / lenP),
                isNaN(Math.round(ff / lenFF)) ? 0 : Math.round(ff / lenFF),
                isNaN(Math.round(a / lenA)) ? 0 : Math.round(a / lenA),
                isNaN(Math.round(c / lenC)) ? 0 : Math.round(c / lenC)
            ]
            // console.log('New Model ', modelNew)

            asyncDispatch(
                dispatch({
                    type: 'FETCHING_GET_FISICAL_TEST',
                    payload: modelNew
                })
            )





            // CLUB CATEGORY LIST 
            /// console.log('CURRENT CATEGORIE ', loadDataResult.message.data.clubCategoryList)
            asyncDispatch(
                dispatch({
                    type: 'GET_NO_CATEGORY',
                    payload: loadDataResult.message.data.clubCategoryList.length <= 0 ? true : false
                })
            )

            asyncDispatch(
                dispatch({
                    type: 'FETCHING_GET_CATEGORI_LIST',
                    payload: loadDataResult.message.data.clubCategoryList
                })
            )

            //EVALUACION
            let modelEvaluaciones = {
                "clubID": model.sessionID,
                "evaluacionType": 'Técnica'
            }
            let resultEval = await api.GetEvaluaInfo(modelEvaluaciones)
            asyncDispatch(
                dispatch({
                    type: 'FETCHING_GET_EVALUACION',
                    payload: resultEval.message
                })
            )



            // EVALUACIONES Atletas
            let evaluacionFisica = loadDataResult.message.data.fisicaEvaluacionList
            let evaluacionesTecnicas = loadDataResult.message.data.tecnicaEvaluacionList
            // console.log('evalacionTec', evaluacionesTecnicas)
            let atetaInfo = loadDataResult.message.data.corporalInfo

            let modelEva = {
                sessionID: '',
                nombre: '',
                edad: "",
                peso: "",
                position: "",
                ovrF: 0,
                ovrT: 0,
                cof: 0

            }
            let newArray = []

            // CARGAMOS INFORMACION DE LOS ATLETAS 
            let userForProcess = []
            let imc = 0
            let grasa_percent = 0
            for (let index = 0; index < atetaInfo.length; index++) {
                let elem = atetaInfo[index];

                if (userForProcess.indexOf(elem.sessionID) === -1) {
                    let nombre = atetaInfo.map((task) => {
                        if (task.sessionID === elem.sessionID) {
                            imc = task.bmi
                            grasa_percent = task.porcentajeGrasa
                            return task.nombre
                        }
                    }).filter((task) => task != undefined)

                    modelEva.nombre = nombre[0]

                    let edad = atetaInfo.map((task) => {
                        if (task.sessionID === elem.sessionID) {
                            return task.edad
                        }
                    }).filter((task) => task != undefined)
                    modelEva.edad = edad[0]

                    let peso = atetaInfo.map((task) => {
                        if (task.sessionID === elem.sessionID) {
                            return task.peso
                        }
                    }).filter((task) => task != undefined)

                    modelEva.peso = peso[0]

                    let position = ''
                    for (let index = 0; index < atetaInfo.length; index++) {
                        let element = atetaInfo[index];
                        if (element.sessionID === elem.sessionID) {
                            position = element.position
                        }
                    }
                    /*  atetaInfo.map((task) => {
                        // console.log('position', task)
                         if (task.sessionID === elem.sessionID) {
                             return task.position
                         } 
                     }).filter((task) => task != undefined
                     ) */

                    modelEva.position = position
                    modelEva.sessionID = elem.sessionID
                    newArray.push(modelEva)

                    modelEva = {
                        sessionID: '',
                        nombre: '',
                        edad: "",
                        peso: "",
                        ovrF: 0,
                        ovrT: 0,
                        cof: 0,
                        position: ""
                    }
                    //   console.log('modelEva', modelEva)
                    userForProcess.push(elem.sessionID)
                }

            }



            if (evaluacionFisica.length > 0) {

                let pots = 0
                let ress = 0
                let cofP = 0
                let cofD = 0
                for (let index = 0; index < evaluacionFisica.length; index++) {
                    let elem = evaluacionFisica[index];
                    // console.log('evaluacion', evaluacionFisica)
                    let sessionForProcess = elem.sessionID
                    let ovrF = 0
                    let fL = 0
                    evaluacionFisica.map(task => {
                        // CARGAMOS EL OVR 
                        if (task.sessionID === sessionForProcess) {
                            ovrF = ovrF + task.percent
                            fL++
                        }
                    })
                    evaluacionFisica.map(task => {
                        // CARGAMOS EL OVR 
                        if (task.sessionID === sessionForProcess) {
                            // POTENCIA
                            if (task.fisical_testID >= 4 && task.fisical_testID <= 5) {
                                pots = pots + parseInt(task.percent);
                            }

                            //RESITENCIA
                            if (task.fisical_testID == 1) {
                                ress = task.percent;
                            }
                        }
                    })

                    let ptr = parseFloat(((pots)) / 10)
                    let rtr = parseFloat(((ress)) / 10)

                    cofP = parseFloat(ptr - rtr)
                    imc = parseInt(imc)
                    cofD = (imc - grasa_percent)
                    let cofR = ((cofP - cofD))
                    for (let index = 0; index < newArray.length; index++) {
                        let item = newArray[index];
                        if (item.sessionID === sessionForProcess) {
                            newArray[index].ovrF = Math.round(ovrF / fL)
                            newArray[index].cof = cofR
                        }
                    }
                }
            }

            if (evaluacionesTecnicas.length > 0) {
                for (let index = 0; index < evaluacionesTecnicas.length; index++) {
                    let elem = evaluacionesTecnicas[index];
                    // console.log('elem evaluatec', elem)
                    let sessionForProcess = elem.sessionID
                    for (let index = 0; index < newArray.length; index++) {
                        let elem2 = newArray[index];
                        // console.log('newArray',elem2)
                        if (elem2.sessionID === sessionForProcess) {
                            newArray[index].ovrT = elem.ovr
                        }
                    }
                }
            }

            //console.log('evalacionTec', newArray)
            asyncDispatch(
                dispatch({
                    type: 'FETCHING_GET_RESUM_EVA',
                    payload: newArray
                })
            )


            // CORP INF 
            asyncDispatch(
                dispatch({
                    type: 'FETCHING_GET_CORP_INFO',
                    payload: atetaInfo
                })
            )

            window.localStorage.removeItem('corpInfo')
            window.localStorage.setItem('corpInfo', JSON.stringify(atetaInfo));

            // PERFIL MENTAL 
            let mi = loadDataResult.message.data.mentalInfo
            asyncDispatch(
                dispatch({
                    type: 'FETCHING_GET_MENTAL_INFO',
                    payload: mi
                })
            )

            // MICROCILO 
            let microciclo = loadDataResult.message.data.cargaInfo

            let weekStart = moment().startOf('month').format('YYYY-MM-DD');
            let endSTart = moment(weekStart).add(6, 'days').format('YYYY-MM-DD');

            let monoCiclo = {
                microciclo: [],
                microcicloOvr: 0
            }
            let val = 0
            //PRIMER WEEK 
            for (let index = 0; index < microciclo.length; index++) {
                let items = microciclo[index];

                if (items.rowCreate >= weekStart && items.rowCreate <= endSTart) {
                    val = Math.round(parseInt(val) + parseInt(items.percent_volum))
                }
            }

            monoCiclo.microciclo.push(Math.round(val / 7));
            monoCiclo.microcicloOvr = Math.round((monoCiclo.microcicloOvr + Math.round(val / 7)) / 4) === undefined ||
                isNaN(Math.round((monoCiclo.microcicloOvr + Math.round(val / 7)) / 4)) ? 0 : Math.round((monoCiclo.microcicloOvr + Math.round(val / 7)) / 4)


            //SEGUNDO WEEK
            weekStart = endSTart
            endSTart = moment(weekStart).add(6, 'days').format('YYYY-MM-DD');
            val = 0
            for (let index = 0; index < microciclo.length; index++) {
                let items = microciclo[index];

                if (items.rowCreate >= weekStart && items.rowCreate <= endSTart) {
                    val = Math.round(parseInt(val) + parseInt(items.percent_volum))
                }
            }

            monoCiclo.microciclo.push(Math.round(val / 7));
            monoCiclo.microcicloOvr = Math.round((monoCiclo.microcicloOvr + Math.round(val / 7)) / 4)


            //Tercer WEEK
            weekStart = endSTart
            endSTart = moment(weekStart).add(6, 'days').format('YYYY-MM-DD');
            val = 0
            for (let index = 0; index < microciclo.length; index++) {
                let items = microciclo[index];

                if (items.rowCreate >= weekStart && items.rowCreate <= endSTart) {
                    val = Math.round(parseInt(val) + parseInt(items.percent_volum))
                }
            }

            monoCiclo.microciclo.push(Math.round(val / 7));
            monoCiclo.microcicloOvr = Math.round((monoCiclo.microcicloOvr + Math.round(val / 7)) / 4)


            //Curto WEEK
            weekStart = endSTart
            endSTart = moment(weekStart).add(6, 'days').format('YYYY-MM-DD');
            val = 0
            for (let index = 0; index < microciclo.length; index++) {
                let items = microciclo[index];

                if (items.rowCreate >= weekStart && items.rowCreate <= endSTart) {
                    val = Math.round(parseInt(val) + parseInt(items.percent_volum))
                }
            }

            monoCiclo.microciclo.push(Math.round(val / 7));
            monoCiclo.microcicloOvr = Math.round((monoCiclo.microcicloOvr + Math.round(val / 7)) / 4)
            if (monoCiclo.microcicloOvr === undefined) monoCiclo.microcicloOvr = 0
            asyncDispatch(
                dispatch({
                    type: 'FETCHING_GET_MONOCICLO_DATA',
                    payload: monoCiclo
                })
            )



            // traer las posiciones
            let sportPosition = JSON.parse(window.localStorage.getItem('globalContext'))
            //console.log('prueba', sportPosition)
            let sportModel = {
                sportID: parseInt(sportPosition.sportID)
            }
            //  console.log('sportModel', sportModel)

            let result = await api.GetPosition(sportModel)
            //console.log('position', result.message.data)
            asyncDispatch(
                dispatch({
                    type: 'FETCHING_GET_POSITION',
                    payload: result.message.data
                })
            )
            let lesionArray = loadDataResult.message.data.lesionadosList

            asyncDispatch(
                dispatch({
                    type: 'FETCHING_GET_LESIONADOS_LIST',
                    payload: lesionArray
                })
            )

            //EVALUACION PARTIDO

            let clubTorneoList = [];
            let torneoAtletaList = []
            let torneoForProcess = [];
            let currentCategoriForProcess = model.categoryID === 0 ? loadDataResult.message.data.clubCategoryList[0].categoriesID : model.categoryID

            let modelP = {
                clubSessionID: model.sessionID
            }

            let modelPartido = {
                nombreAtleta: '',
                posicion: '',
                torneo: '',
                rival: '',
                fechaPartido: '',
                sessionID: ''
            }
            let objectPartido = {
                label: '',
                value: ''
            };

            let curretTorneo = 0
            let resultPartido = await api.GetEvaluaPartidoSession(modelP)
            //console.log('partido result', resultPartido)
            if (resultPartido.success) {
                //filtrar lista de torneo
                let data = resultPartido.data.message[0]
                //   console.log('data Partido', data)
                for (let index = 0; index < data.length; index++) {
                    let element = data[index];
                    //   console.log('elemt', element)
                    if (element.categoryID === currentCategoriForProcess) {
                        if (torneoForProcess.indexOf(element.torneoID) === -1) {
                            if (curretTorneo === 0) {
                                curretTorneo = element.torneoID
                            }
                            objectPartido.label = element.torneoName
                            objectPartido.value = element.torneoID
                            clubTorneoList.push(objectPartido)
                            torneoForProcess.push(element.torneoID)
                            objectPartido = {
                                label: '',
                                value: ''
                            };
                        }

                        window.localStorage.removeItem('curretTorneo')
                        window.localStorage.setItem('curretTorneo', JSON.stringify(curretTorneo));

                    }

                }

                // FILTRAR LA LISTA DE ATLETAS SEGUN TORNEO 

                if (data.length > 0) {
                    for (let index = 0; index < data.length; index++) {
                        let elem = data[index];

                        //   console.log('elem state', elem.torneoID, 'category inicial', curretTorneo)
                        if (elem.torneoID === curretTorneo && elem.categoryID === currentCategoriForProcess) {

                            modelPartido.nombreAtleta = elem.nombreAtleta
                            modelPartido.posicion = elem.position
                            modelPartido.torneo = elem.torneoName
                            modelPartido.rival = elem.rival
                            modelPartido.fechaPartido = (moment(new Date(elem.matchDate)).format('YYYY-MM-DD'))
                            modelPartido.sessionID = elem.sessionID
                            torneoAtletaList.push(modelPartido)

                            modelPartido = {
                                nombreAtleta: '',
                                posicion: '',
                                torneo: '',
                                rival: '',
                                fechaPartido: '',
                                sessionID: ''
                            }
                        }

                    }
                }

                asyncDispatch(
                    dispatch({
                        type: 'FETCHING_GET_EVALUACION_PARTIDO',
                        payload: { clubTorneoList: clubTorneoList, torneoAtletaList: torneoAtletaList }
                    })
                )

                asyncDispatch(
                    dispatch({
                        type: 'FETCHING_GET_EVALUACION_PARTIDO_USER',
                        payload: data
                    })
                )

                // CARGAMOS LOS REPORTES 

                // let resultReport = await api.GetReportClub({ sessionID: model.sessionID, categoryID: model.categoryID })
                // let data_report = []
                // // console.log('MODEL ', resultReport)
                // let filterData = []
                // let monthArr = []
                // let weekRange = []
                // let reportArr = reportJson
                // let activeFilter = {
                //     report: {
                //         isActive: true,
                //         value: {
                //             id: 1,
                //             value: 'Deportiva'
                //         }
                //     },
                //     mes: {
                //         isActive: false,
                //         value: ''
                //     },
                //     week: {
                //         isActive: false,
                //         value: ''
                //     },
                //     date: {
                //         isActive: false,
                //         value: ''
                //     }
                // }
                // if (resultReport.success && resultReport.data.message[0].length > 0) {

                //     let data_report = resultReport.data.message[0]
                //     let currentMonth = moment(data_report[0].date).format('MMMM')
                //     let currentReport = data_report[0].reportID
                //     let reportName = reportJson.filter((task) => {
                //         if (task.id === currentReport) {
                //             return task
                //         }
                //     })

                //     activeFilter.report.value.id = currentReport
                //     activeFilter.report.value.value = reportName[0].value

                //     let Date = []

                //     // CARGAMOS LOS MESES EXISTENTES
                //     let monthProcess = []
                //     let initDate = 0
                //     let monthStartDate = ""
                //     let monthEndDate = ""
                //     for (let index = 0; index < data_report.length; index++) {
                //         let elem = data_report[index];
                //         let processMont = moment(data_report[0].date).format('MMMM');
                //         if (initDate === 0) {
                //             initDate = data_report[0].date
                //             monthStartDate = moment(initDate).startOf('month').format('YYYY-MM-DD')
                //             monthEndDate = moment(initDate).endOf('month').format('YYYY-MM-DD')
                //         }
                //         if (monthProcess.indexOf(processMont) === -1) {
                //             monthArr.push({
                //                 id: moment(data_report[0].date).format('M'),
                //                 value: moment(data_report[0].date).format('MMMM').charAt(0).toUpperCase() + moment(data_report[0].date).format('MMMM').slice(1)
                //             })

                //             monthProcess.push(processMont)
                //         }

                //     }

                //     // CARGAMOS LA SEMANA 
                //     let startDate = ""//moment(initDate).startOf('month').format('YYYY-MM-DD')
                //     let endDate = ""//moment(initDate).endOf('month').format('YYYY-MM-DD')

                //     let objWeek = {
                //         id: "",
                //         value: ""
                //     }
                //     let weekInProcess = []
                //     let obDateRange = {
                //         startDate: "",
                //         endDate: ""
                //     }
                //     ///
                //     for (let index = 0; index < data_report.length; index++) {
                //         let elem = data_report[index]
                //         startDate = moment(elem.date).startOf('isoWeek').format('YYYY-MM-DD')
                //         endDate = moment(elem.date).endOf('isoWeek').format('YYYY-MM-DD')
                //         if (weekInProcess.indexOf(startDate) === -1) {
                //             obDateRange.startDate = startDate
                //             obDateRange.endDate = endDate
                //             objWeek.id = JSON.stringify(obDateRange)
                //             objWeek.value = (startDate + ' - ' + endDate)
                //             weekRange.push(objWeek)
                //             objWeek = {
                //                 id: "",
                //                 value: ""
                //             }
                //             obDateRange = {
                //                 startDate: "",
                //                 endDate: ""
                //             }
                //             weekInProcess.push(startDate)
                //             startDate = ''
                //             endDate = ''
                //         }
                //     }

                //     // CARGA INFORMACION DE LA TABLA 

                //     // SE CARGA LA INFO SEGUN EL MES EN CURSO
                //     // console.log('Start Date ', monthStartDate , 'End Date ', monthEndDate , 'CURRENT REPORT ', currentReport)
                //     let dataFilter = data_report.filter((task) => {
                //         if (task.date >= monthStartDate && task.date <= monthEndDate && task.reportID === currentReport) {
                //             return task
                //         }
                //     })

                //     // TRAEMOS A LOS ATLETAS
                //     let userInProcess = []
                //     let obj = {
                //         nombre: "",
                //         fecha: "",
                //         result: 0,
                //         action: {
                //             type: "buttom",
                //             key: ""
                //         }
                //     }

                //     for (let index = 0; index < dataFilter.length; index++) {
                //         let element = dataFilter[index];
                //         if (userInProcess.indexOf(element.sessionID) === -1) {
                //             // PROCESAMOS LA INFO 
                //             let user = element.sessionID
                //             let divider = 0
                //             obj.nombre = element.nombre
                //             obj.fecha = element.date
                //             obj.action.key = element.sessionID
                //             // SUMAMOS LOS VALORES
                //             for (let i = 0; i < dataFilter.length; i++) {
                //                 let datainProcess = dataFilter[i];
                //                 if (datainProcess.sessionID === user) {
                //                     obj.result += parseInt(datainProcess.value)
                //                     divider++
                //                 }
                //             }
                //             obj.result = `${parseInt(obj.result / divider)}%`
                //             filterData.push(obj)
                //             user = ""
                //             obj = {
                //                 nombre: "",
                //                 fecha: "",
                //                 result: 0,
                //                 action: {
                //                     type: "buttom",
                //                     key: ""
                //                 }
                //             }
                //             userInProcess.push(element.sessionID)
                //         }
                //     }

                //     // console.log('FILTER DATA ', filterData)

                // }


                // asyncDispatch(
                //     dispatch({
                //         type: 'FETCHING_LOAD_REPORT_DATA',
                //         payload: {
                //             monthArr: monthArr,
                //             weekArr: weekRange,
                //             dateArr: [],
                //             reportArr: reportArr,
                //             filterData: filterData,
                //             filterActive: activeFilter,
                //             dwData: resultReport.data.message[0]
                //         }
                //     })
                // )
            }
        }

        return { success: true }
    }

    const GetClubCredential = async (model) => {
        let data = model.data[0]
        console.log('data', data)
        // CLUB CREDENTIAL
        dispatch({ type: 'FETCHING_INITIAL_DATA' })

        asyncDispatch(
            dispatch({
                type: 'FETCHING_CREDENTIAL_CLUB_DATA',
                payload: data
            })
        )
        
       
        
        let initModel = {
            "sessionID": data.sessionID,
            "categoryID": data.categories[0].categoriesID,
            "startDate": 0,
            "endDate": 0
        }

      
        let current = window.localStorage.getItem("currentCategori");
        let currentCategory = current ? JSON.parse(current) : { categoriesID: null };

        let globalContext = {
            isCouch: model.isCouch,
            isClub: model.isClub,
            isSuper: model.isSuper,
            categoryID: model.categoryID ? model.categoryID : currentCategory.categoriesID,
            sportID: data.sportID
        }

        // Create LocalSession 

        //VERIFICAMOS SI ES UN SUPER PARA MANTENER EL LOCAL
        const isSuper = window.localStorage.getItem('isSuper');

        window.localStorage.clear();
        if (isSuper != null) {
            window.localStorage.setItem('isSuper', isSuper)
        }
        window.localStorage.setItem('globalContext', JSON.stringify(globalContext))
        window.localStorage.setItem('_gHtk', JSON.stringify(initModel))
        return { success: true }
    }

    const GetAtletaInfo = async (model) => {
        // console.log('MODEL INITIAL  ', model)
        dispatch({ type: 'FETCHING_INITIAL_DATA' });

        let result = await api.GetAtletaInfos(model)
        // console.log('prueba', result)

        // DATA INICIAL 
        if (result.message.success) {
            let fisical = result.message.data[0]
            //   console.log({ fisical });
            let info = result.message.data[2]
            //   console.log({ info })
            let evaluainfo = result.message.data[1]
            //  console.log({ evaluainfo })
            let weekRange = result.message.data[3]
            let weekRangeFisicas = result.message.data[6]
            //  console.log('wekkRangeState', weekRange)
            let corp = [];

            let corpObj = {
                nombre: '',
                edad: 0,
                peso: 0,
                altura: 0,
                grasa: 0,
                bmi: 0
            };

            let corpInfo = JSON.parse(window.localStorage.getItem('corpInfo'))

            let resultInfo = await api.GetExtraInfoAtleta({ sessionID: corpInfo[0].sessionID });
            // console.log('RESULT ', result)
            let dataUser = resultInfo.data.message[0]
            let hoy = new Date();
            let nacimiento = new Date(dataUser.birthdayDate);
            /// SE RESTA EL AÑO DE NACIMIENTO CON EL AÑO ACTUAL
            let edadActual = hoy.getFullYear() - nacimiento.getFullYear();
            /// SE RESTA EL DIA DE NACIMIENTO CON EL DIA ACTUAL
            let meses = hoy.getMonth() - nacimiento.getMonth();

            if (meses <= 0 && hoy.getDate() < nacimiento.getDate()) {
                edadActual--;
            }

            corpObj.nombre = info[0].nombre;
            corpObj.edad = dataUser.birthdayDate === null ? info[0].edad : edadActual;
            corpObj.peso = info[0].peso;
            corpObj.bmi = info[0].bmi === null || info[0].bmi === "" || info[0].bmi === undefined ? 0 : (info[0].bmi).toFixed(2); // CALCULO DE IMC
            corpObj.grasa = info[0].porcentajeGrasa;
            corpObj.altura = info[0].estatura
            corp.push(corpObj);

            asyncDispatch(
                dispatch({
                    type: 'FETCHING_GET_CORP_INFO_ATLETA',
                    payload: corp
                })
            )

            let newArr = []
            let tecOvr = evaluainfo.length <= 0 ? 0 : evaluainfo[0].ovr

            let fiscalOvr = 0
            let i = 0
            let pot = 0
            let res = 0

            if (fisical.length > 0) {
                for (let index = 0; index < fisical.length; index++) {
                    let elem = fisical[index];
                    i = i + elem.percent
                }

                fiscalOvr = parseInt(i / fisical.length)

                // POTENCIA
                for (let index = 0; index < fisical.length; index++) {
                    if (fisical[index].fisical_testID >= 4 && fisical[index].fisical_testID <= 5) {
                        pot = pot + fisical[index].percent;
                    }
                }
                //RESITENCIA
                for (let index = 0; index < fisical.length; index++) {
                    if (fisical[index].fisical_testID == 1) {
                        res = fisical[index].percent;
                    }

                }
            }

            let cof = Math.round(parseInt(((parseInt(pot / 2))) - ((parseInt(res)))) / 10)
            // console.log('COF ', cof, 'POT ', pot, 'RES ', res, 'Fisical Eva ', fisical)

            let extraInfoResult = await api.GetExtraInfoAtleta({ sessionID: model.sessionID })
            // console.log('EXTRA INFOR RESULTV ',extraInfoResult)
            let dataFilter = extraInfoResult.data.message[0] === undefined ? [] : extraInfoResult.data.message[0]
            // console.log('EXTRA INFOR RESULTV ',extraInfoResult)
            let data = {
                nombre: "",
                imgData: "",
                clubName: "",
                posicion: "",
                edad: "",
                fisica: 0,
                tecnica: 0,
                peso: '',
                cof: 0,
                perfil: 'Sin perfil',
                deportiva: 0,
                nutricion: 0,
                prepfisica: 0,
                medico: 0,
                psicologia: 0,
                academica: 0,
                fisiologico: 0,
            };

            // console.log({ dataFilter });

            data.nombre = info[0].nombre
            data.imgData = info[0].imgData
            data.edad = info[0].edad
            data.peso = info[0].peso
            data.talla = parseFloat((info[0].estatura * 100) / 1)
            data.posicion = info[0].position
            data.clubName = info[0].clubName
            data.cof = cof
            data.tecnica = tecOvr
            data.fisica = fiscalOvr
            data.perfil = 'Sin perfil'
            data.deportiva = dataFilter.deportiva
            data.nutricion = dataFilter.antropometria
            data.prepfisica = dataFilter.fisica
            data.medico = dataFilter.medico
            data.psicologia = dataFilter.psicologica
            data.academica = dataFilter.educativa
            data.fisiologico = dataFilter.fisiologica

            newArr.push(data)
            //  console.log('data cof', data.cof)
            asyncDispatch(
                dispatch({
                    type: 'FETCHING_GET_ATLETA_INFO',
                    payload: newArr
                })
            )

            // INFORMACION DE LA PRIMERA EVALUACION

            // ORDEN LOGICO DEL LAS EVALUACIONES 
            /*
                CODIGO  - BASE DE DATOS
                ASPECT1    promCog
                ASPECT2    promDef
                ASPECT3    promOf
            */

            let evaluaTec = []


            let evaluaTecObj = {
                nombre: '',
                edad: 0,
                categoria: 0,
                ovrGeneral: 0,
                aspecto1Ovr: 0,
                aspecto2Ovr: 0,
                aspecto3Ovr: 0,
                fecha: ""
            };
            let start = weekRange[0].startWeek
            let dateforProcess = []
            // console.log('user evaluaciinfo START DATE ', evaluainfo)
            for (let index = 0; index < evaluainfo.length; index++) {
                let element = evaluainfo[index];

                //   console.log('elemEva', element)

                if (element.date >= start && element.date <= start) {
                    if (dateforProcess.indexOf(start) === -1) {
                        evaluaTecObj.nombre = element.nombre;
                        evaluaTecObj.edad = info[0].edad;
                        evaluaTecObj.categoria = info[0].categoriesName;
                        evaluaTecObj.ovrGeneral = element.promCog;
                        evaluaTecObj.aspecto1Ovr = element.promCog;
                        evaluaTecObj.aspecto2Ovr = element.promDef;
                        evaluaTecObj.aspecto3Ovr = element.promOf;
                        evaluaTecObj.fecha = element.date
                        evaluaTec.push(evaluaTecObj)
                        dateforProcess.push(start)
                    }
                }

            }

            asyncDispatch(
                dispatch({
                    type: 'FETCHING_GET_EVALUATEC',
                    payload: evaluaTec
                })
            )
            //console.log('evaluatec', evaluaTec)
            let modelNewB = [{
                dt1: [0, 0, 0, 0, 0, 0, 0],
                dt2: [0, 0, 0, 0, 0, 0, 0]
            }]
            // console.log('fisical', fisical)
            // ACTUALIZA COMPARACION DE EVALUACIONES

            let currentDayEva = ''
            let dt1A = []
            let beforeDayEva = ''
            let dt2A = []
            for (let index = 0; index < fisical.length; index++) {
                let task = fisical[index];
                if (currentDayEva === "") {
                    currentDayEva = task.date
                }

                if (task.date !== currentDayEva) {
                    if (beforeDayEva === "") {
                        beforeDayEva = task.date
                    }
                }

                if (task.date === currentDayEva) {
                    dt1A.push(task)
                }

                if (task.date === beforeDayEva) {
                    dt2A.push(task)
                }

            }
            // console.log('currentDayEva', currentDayEva, 'beforeDayEva', beforeDayEva, 'dt1A', dt1A, 'dt2A', dt2A)
            // INFORMACION DE LA PRIMERA EVALUACION

            // CFUERZA
            let f = 0
            let lenF = 0

            // VELOCIDAD
            let v = 0
            let lenV = 0

            //RESISTENCIA 
            let r = 0
            let lenR = 0

            // POTENCIA 
            let p = 0
            let lenP = 0

            // FLEXIBILIDAD 
            let ff = 0
            let lenFF = 0

            // AGILIDAD
            let a = 0
            let lenA = 0

            // CALCULAMOS EL CORE 
            let c = 0
            let lenC = 0

            // CALCULAMOS FUERZA
            dt1A.filter((task) => {
                if (task.fisical_testID === 5) {
                    f = parseInt(f + task.percent)
                    lenF++
                }
            })

            // CALCULAMOS VELOCIDAD
            dt1A.filter((task) => {
                if (task.fisical_testID === 2) {
                    v = parseInt(v + task.percent)
                    lenV++
                }
            })

            //CALCULAMOS RESISTENCIA 
            dt1A.filter((task) => {
                if (task.fisical_testID === 1) {
                    r = parseInt(r + task.percent)
                    lenR++
                }
            })

            // CALCULAMOS POTENCIA 
            dt1A.filter((task) => {
                if (task.fisical_testID === 4) {
                    p = parseInt(p + task.percent)
                    lenP++
                }
            })

            // CALCULAMOS FLEXIBILIDAD 
            dt1A.filter((task) => {
                if (task.fisical_testID === 7) {
                    ff = parseInt(ff + task.percent)
                    lenFF++
                }
            })

            // CALCULAMOS AGILIDAD
            dt1A.filter((task) => {
                if (task.fisical_testID === 3) {
                    a = parseInt(a + task.percent)
                    lenA++
                }
            })

            // CALCULAMOS EL CORE 
            dt1A.filter((task) => {
                if (task.fisical_testID === 6) {
                    c = parseInt(c + task.percent)
                    lenC++
                }
            })

            modelNewB[0].dt1 = [
                isNaN(Math.round(f / lenF)) ? 0 : Math.round(f / lenF),
                isNaN(Math.round(v / lenV)) ? 0 : Math.round(v / lenV),
                isNaN(Math.round(r / lenR)) ? 0 : Math.round(r / lenR),
                isNaN(Math.round(p / lenP)) ? 0 : Math.round(p / lenP),
                isNaN(Math.round(ff / lenFF)) ? 0 : Math.round(ff / lenFF),
                isNaN(Math.round(a / lenA)) ? 0 : Math.round(a / lenA),
                isNaN(Math.round(c / lenC)) ? 0 : Math.round(c / lenC)
            ]
            //console.log('modelnew', modelNewB)

            // CFUERZA
            f = 0
            lenF = 0

            // VELOCIDAD
            v = 0
            lenV = 0

            //RESISTENCIA 
            r = 0
            lenR = 0

            // POTENCIA 
            p = 0
            lenP = 0

            // FLEXIBILIDAD 
            ff = 0
            lenFF = 0

            // AGILIDAD
            a = 0
            lenA = 0

            // CALCULAMOS EL CORE 
            c = 0
            lenC = 0

            ///////////////////////////////////////////////////////////////

            // CALCULAMOS FUERZA
            dt2A.filter((task) => {
                if (task.fisical_testID === 5) {
                    f = parseInt(f + task.percent)
                    lenF++
                }
            })

            // CALCULAMOS VELOCIDAD
            dt2A.filter((task) => {
                if (task.fisical_testID === 2) {
                    v = parseInt(v + task.percent)
                    lenV++
                }
            })

            //CALCULAMOS RESISTENCIA 
            dt2A.filter((task) => {
                if (task.fisical_testID === 1) {
                    r = parseInt(r + task.percent)
                    lenR++
                }
            })

            // CALCULAMOS POTENCIA 
            dt2A.filter((task) => {
                if (task.fisical_testID === 4) {
                    p = parseInt(p + task.percent)
                    lenP++
                }
            })

            // CALCULAMOS FLEXIBILIDAD 
            dt2A.filter((task) => {
                if (task.fisical_testID === 7) {
                    ff = parseInt(ff + task.percent)
                    lenFF++
                }
            })

            // CALCULAMOS AGILIDAD
            dt2A.filter((task) => {
                if (task.fisical_testID === 3) {
                    a = parseInt(a + task.percent)
                    lenA++
                }
            })

            // CALCULAMOS EL CORE 
            dt2A.filter((task) => {
                if (task.fisical_testID === 6) {
                    c = parseInt(c + task.percent)
                    lenC++
                }
            })

            modelNewB[0].dt2 = [
                isNaN(Math.round(f / lenF)) ? 0 : Math.round(f / lenF),
                isNaN(Math.round(v / lenV)) ? 0 : Math.round(v / lenV),
                isNaN(Math.round(r / lenR)) ? 0 : Math.round(r / lenR),
                isNaN(Math.round(p / lenP)) ? 0 : Math.round(p / lenP),
                isNaN(Math.round(ff / lenFF)) ? 0 : Math.round(ff / lenFF),
                isNaN(Math.round(a / lenA)) ? 0 : Math.round(a / lenA),
                isNaN(Math.round(c / lenC)) ? 0 : Math.round(c / lenC)
            ]
            // console.log('New ModelB.2 ', modelNewB)

            asyncDispatch(
                dispatch({
                    type: 'FETCHING_GET_WIDGET',
                    payload: modelNewB
                })
            )

            // ACTUALIZA WEEK RANGE 
            let week = {
                startDate: weekRangeFisicas[0].startWeek,
                endDate: weekRangeFisicas[0].endWeek,
                dateListEvatec: result.message.data[4],
                dateListFisical: result.message.data[5]
            }
            //    console.log('weekRangeATleta', week)
            asyncDispatch(
                dispatch({
                    type: 'FETCHING_GET_RANGE_WEEK_RANGE',
                    payload: week
                })
            )


            //  MODELOS DE FILTRADO 

            let objModalData = {
                fisicas: result.message.data[0],
                tecnica: result.message.data[1]
            }

            asyncDispatch(
                dispatch({
                    type: 'FETCHING_LOAD_DATA_FOR_FILTER',
                    payload: objModalData
                })
            )
        }
        return { success: true }

    }

    const GetMemberInfoByDate = async (model) => {
        //console.log('modelMenber', model)
        let loadDataResult = await api.MemberInfoByDte(model)
        // console.log('RESULT STATE', loadDataResult)
        asyncDispatch(
            dispatch({
                type: 'FETCHING_GET_RANGE_WEEK_RANGE',
                payload: { startDate: model.startDate, endDate: model.endDate }
            })
        )
        if (loadDataResult.message.success) {
            let fisical = loadDataResult.message.data[0]
            // console.log('fsical', fisical)
            // ACTUALIZA COMPARACION DE EVALUACIONES

            let currentDayEva = ''
            let dt1 = []
            let beforeDayEva = ''
            let dt2 = []
            for (let index = 0; index < fisical.length; index++) {
                let task = fisical[index];
                // console.log('taskRange', task)
                if (currentDayEva === "") {
                    currentDayEva = task.date
                }

                if (task.date !== currentDayEva) {
                    if (beforeDayEva === "") {
                        beforeDayEva = task.date
                    }
                }

                if (task.date === currentDayEva) {
                    dt1.push(task)
                }

                if (task.date === beforeDayEva) {
                    dt2.push(task)
                }

            }

            //  console.log('Date 1', currentDayEva, 'Before ', beforeDayEva)

            // INFORMACION DE LA PRIMERA EVALUACION
            let modelNew = [{
                dt1: [0, 0, 0, 0, 0, 0, 0],
                dt2: [0, 0, 0, 0, 0, 0, 0]
            }]
            // CFUERZA
            let f = 0
            let lenF = 0

            // VELOCIDAD
            let v = 0
            let lenV = 0

            //RESISTENCIA 
            let r = 0
            let lenR = 0

            // POTENCIA 
            let p = 0
            let lenP = 0

            // FLEXIBILIDAD 
            let ff = 0
            let lenFF = 0

            // AGILIDAD
            let a = 0
            let lenA = 0

            // CALCULAMOS EL CORE 
            let c = 0
            let lenC = 0

            // CALCULAMOS FUERZA
            dt1.filter((task) => {
                if (task.fisical_testID === 5) {
                    f = parseInt(f + task.percent)
                    lenF++
                }
            })

            // CALCULAMOS VELOCIDAD
            dt1.filter((task) => {
                if (task.fisical_testID === 2) {
                    v = parseInt(v + task.percent)
                    lenV++
                }
            })

            //CALCULAMOS RESISTENCIA 
            dt1.filter((task) => {
                if (task.fisical_testID === 1) {
                    r = parseInt(r + task.percent)
                    lenR++
                }
            })

            // CALCULAMOS POTENCIA 
            dt1.filter((task) => {
                if (task.fisical_testID === 4) {
                    p = parseInt(p + task.percent)
                    lenP++
                }
            })

            // CALCULAMOS FLEXIBILIDAD 
            dt1.filter((task) => {
                if (task.fisical_testID === 7) {
                    ff = parseInt(ff + task.percent)
                    lenFF++
                }
            })

            // CALCULAMOS AGILIDAD
            dt1.filter((task) => {
                if (task.fisical_testID === 3) {
                    a = parseInt(a + task.percent)
                    lenA++
                }
            })

            // CALCULAMOS EL CORE 
            dt1.filter((task) => {
                if (task.fisical_testID === 6) {
                    c = parseInt(c + task.percent)
                    lenC++
                }
            })

            modelNew[0].dt1 = [
                isNaN(Math.round(f / lenF)) ? 0 : Math.round(f / lenF),
                isNaN(Math.round(v / lenV)) ? 0 : Math.round(v / lenV),
                isNaN(Math.round(r / lenR)) ? 0 : Math.round(r / lenR),
                isNaN(Math.round(p / lenP)) ? 0 : Math.round(p / lenP),
                isNaN(Math.round(ff / lenFF)) ? 0 : Math.round(ff / lenFF),
                isNaN(Math.round(a / lenA)) ? 0 : Math.round(a / lenA),
                isNaN(Math.round(c / lenC)) ? 0 : Math.round(c / lenC)
            ]

            // CALCULAMOS FUERZA
            dt2.filter((task) => {
                if (task.fisical_testID === 5) {
                    f = parseInt(f + task.percent)
                    lenF++
                }
            })

            // CALCULAMOS VELOCIDAD
            dt2.filter((task) => {
                if (task.fisical_testID === 2) {
                    v = parseInt(v + task.percent)
                    lenV++
                }
            })

            //CALCULAMOS RESISTENCIA 
            dt2.filter((task) => {
                if (task.fisical_testID === 1) {
                    r = parseInt(r + task.percent)
                    lenR++
                }
            })

            // CALCULAMOS POTENCIA 
            dt2.filter((task) => {
                if (task.fisical_testID === 4) {
                    p = parseInt(p + task.percent)
                    lenP++
                }
            })

            // CALCULAMOS FLEXIBILIDAD 
            dt2.filter((task) => {
                if (task.fisical_testID === 7) {
                    ff = parseInt(ff + task.percent)
                    lenFF++
                }
            })

            // CALCULAMOS AGILIDAD
            dt2.filter((task) => {
                if (task.fisical_testID === 3) {
                    a = parseInt(a + task.percent)
                    lenA++
                }
            })

            // CALCULAMOS EL CORE 
            dt2.filter((task) => {
                if (task.fisical_testID === 6) {
                    c = parseInt(c + task.percent)
                    lenC++
                }
            })

            modelNew[0].dt2 = [
                isNaN(Math.round(f / lenF)) ? 0 : Math.round(f / lenF),
                isNaN(Math.round(v / lenV)) ? 0 : Math.round(v / lenV),
                isNaN(Math.round(r / lenR)) ? 0 : Math.round(r / lenR),
                isNaN(Math.round(p / lenP)) ? 0 : Math.round(p / lenP),
                isNaN(Math.round(ff / lenFF)) ? 0 : Math.round(ff / lenFF),
                isNaN(Math.round(a / lenA)) ? 0 : Math.round(a / lenA),
                isNaN(Math.round(c / lenC)) ? 0 : Math.round(c / lenC)
            ]
            //   console.log('New Model ', modelNew)
            asyncDispatch(
                dispatch({
                    type: 'FETCHING_GET_FISICAL_TEST',
                    payload: modelNew
                })
            )

        } else {
            let newModelEmrty = [{
                dt1: [0, 0, 0, 0, 0, 0, 0],
                dt2: [0, 0, 0, 0, 0, 0, 0]
            }]

            asyncDispatch(
                dispatch({
                    type: 'FETCHING_GET_FISICAL_TEST',
                    payload: newModelEmrty
                })
            )
        }

        return { success: true }
    }

    const GetMicrocicloInfoByDate = async (model) => {
        let result = await api.GetLoadMicroByDate(model)
        let monoCiclo = {
            microciclo: [],
            microcicloOvr: 0
        }
        if (result.message.success) {
            //MICROCILO 
            let microciclo = result.message.data[0]
            let monthDate = result.message.data[1]
            let lesionArray = result.message.data[2]

            let weekStart = moment(monthDate[0].startMonth).format('YYYY-MM-DD');
            let endSTart = moment(weekStart).add(6, 'days').format('YYYY-MM-DD');


            let val = 0
            //PRIMER WEEK 
            for (let index = 0; index < microciclo.length; index++) {
                let items = microciclo[index];

                if (items.rowCreate >= weekStart && items.rowCreate <= endSTart) {
                    val = Math.round(parseInt(val) + parseInt(items.percent_volum))
                }
            }

            monoCiclo.microciclo.push(Math.round(val / 7));
            monoCiclo.microcicloOvr = Math.round((monoCiclo.microcicloOvr + Math.round(val / 7)) / 4) === undefined ||
                isNaN(Math.round((monoCiclo.microcicloOvr + Math.round(val / 7)) / 4)) ? 0 : Math.round((monoCiclo.microcicloOvr + Math.round(val / 7)) / 4)


            //SEGUNDO WEEK
            weekStart = endSTart
            endSTart = moment(weekStart).add(6, 'days').format('YYYY-MM-DD');
            val = 0
            for (let index = 0; index < microciclo.length; index++) {
                let items = microciclo[index];

                if (items.rowCreate >= weekStart && items.rowCreate <= endSTart) {
                    val = Math.round(parseInt(val) + parseInt(items.percent_volum))
                }
            }

            monoCiclo.microciclo.push(Math.round(val / 7));
            monoCiclo.microcicloOvr = Math.round((monoCiclo.microcicloOvr + Math.round(val / 7)) / 4)


            //Tercer WEEK
            weekStart = endSTart
            endSTart = moment(weekStart).add(6, 'days').format('YYYY-MM-DD');
            val = 0
            for (let index = 0; index < microciclo.length; index++) {
                let items = microciclo[index];

                if (items.rowCreate >= weekStart && items.rowCreate <= endSTart) {
                    val = Math.round(parseInt(val) + parseInt(items.percent_volum))
                }
            }

            monoCiclo.microciclo.push(Math.round(val / 7));
            monoCiclo.microcicloOvr = Math.round((monoCiclo.microcicloOvr + Math.round(val / 7)) / 4)


            //Curto WEEK
            weekStart = endSTart
            endSTart = moment(weekStart).add(6, 'days').format('YYYY-MM-DD');
            val = 0
            for (let index = 0; index < microciclo.length; index++) {
                let items = microciclo[index];

                if (items.rowCreate >= weekStart && items.rowCreate <= endSTart) {
                    val = Math.round(parseInt(val) + parseInt(items.percent_volum))
                }
            }

            monoCiclo.microciclo.push(Math.round(val / 7));
            monoCiclo.microcicloOvr = Math.round((monoCiclo.microcicloOvr + Math.round(val / 7)) / 4)
            if (monoCiclo.microcicloOvr === undefined) monoCiclo.microcicloOvr = 0

            //console.log('Microciclo ', monoCiclo)
            asyncDispatch(
                dispatch({
                    type: 'FETCHING_GET_MONOCICLO_DATA',
                    payload: monoCiclo
                })
            )
            asyncDispatch(
                dispatch({
                    type: 'FETCHING_GET_LESIONADOS_LIST',
                    payload: lesionArray
                })
            )
        } else {
            asyncDispatch(
                dispatch({
                    type: 'FETCHING_GET_MONOCICLO_DATA',
                    payload: monoCiclo
                })
            )

            asyncDispatch(
                dispatch({
                    type: 'FETCHING_GET_LESIONADOS_LIST',
                    payload: []
                })
            )
        }

    }
    const GetReportBySession = async (model) => {

    }

    /* const UpdateReportTable = async (filterObj, sessionID, categoryID) => {

        let activeFilter = Object.entries(filterObj)
        let currentFilterData = []
        let filterTotal = 0

        let resultApi = await api.GetReportClub({ sessionID: sessionID, categoryID: categoryID })
        let data = resultApi.data.message[0]
        // SE FILTRA POR REPORTID
        if (activeFilter[0][1].isActive) {
            currentFilterData = data.filter(task => {
                if (task.reportID === activeFilter[0][1].value.id) {
                    return task
                }
            })
        }

        //console.log('FILTER ', currentFilterData)
        // SE FILTRA POR MESS
        if (activeFilter[1][1].isActive) {
            currentFilterData = currentFilterData.filter(task => {
                let monthNum = (moment(task.date).format('M'))
                if (parseInt(monthNum) === parseInt(activeFilter[1][1].value.id)) {
                    return task
                }
            })
        }

        // SE FILTRA POR RANGO DE FECHA 
        if (activeFilter[2][1].isActive) {
            currentFilterData = currentFilterData.filter(task => {
                if (task.date >= activeFilter[2][1].value.startDate &&
                    task.date <= activeFilter[2][1].value.endDate) {
                    return task
                }
            })
        }

        // SE FILTRA POR FECHA
        if (activeFilter[3][1].isActive) {
            currentFilterData = currentFilterData.filter(task => {
                if (moment(task.date).format('YYYY-MM-DD') >= activeFilter[3][1].value.value &&
                    moment(task.date).format('YYYY-MM-DD') <= activeFilter[3][1].value.value) {
                    return task
                }
            })
        }


        // PROCESAMOS LA DATA FILTRADA 

        let data_report = currentFilterData

        let filterData = []
        let monthArr = []
        let weekRange = []
        let Date = []
        let reportArr = reportJson

        //console.log('CURRE ', currentFilterData)
        if (currentFilterData.length > 0) {
            let currentMonth = moment(data_report[0].date).format('MMMM')
            // CARGAMOS LOS MESES EXISTENTES
            let monthProcess = []
            let initDate = 0
            for (let index = 0; index < data_report.length; index++) {
                let elem = data_report[index];
                let processMont = moment(elem.date).format('MMMM');
                if (initDate === 0) {
                    initDate = elem.date
                }
                if (monthProcess.indexOf(processMont) === -1) {
                    monthArr.push({
                        id: moment(elem.date).format('M'),
                        value: moment(elem.date).format('MMMM').charAt(0).toUpperCase() + moment(elem.date).format('MMMM').slice(1)
                    })

                    monthProcess.push(processMont)
                }

            }

            // CARGAMOS LA SEMANA 
            let startDate = ""
            let endDate = ""
            let objWeek = {
                id: "",
                value: ""
            }
            let obDateRange = {
                startDate: "",
                endDate: ""
            }
            let weekInProcess = []
            ///console.log('Start Date ', startDate , 'End Date ', endDate)
            for (let index = 0; index < data_report.length; index++) {
                let elem = data_report[index]
                startDate = moment(elem.date).startOf('isoWeek').format('YYYY-MM-DD')
                endDate = moment(elem.date).endOf('isoWeek').format('YYYY-MM-DD')
                if (weekInProcess.indexOf(startDate) === -1) {
                    obDateRange.startDate = startDate
                    obDateRange.endDate = endDate
                    objWeek.id = JSON.stringify(obDateRange)
                    objWeek.value = (startDate + ' - ' + endDate)
                    weekRange.push(objWeek)
                    objWeek = {
                        id: "",
                        value: ""
                    }
                    obDateRange = {
                        startDate: "",
                        endDate: ""
                    }
                    weekInProcess.push(startDate)
                    startDate = ''
                    endDate = ''
                }
            }

            // CARGA INFORMACION DE LA TABLA 

            // TRAEMOS A LOS ATLETAS
            let userInProcess = []
            // SE PROCESA LA INFO 
            let obj = {
                nombre: "",
                fecha: "",
                result: 0,
                action: {
                    type: "buttom",
                    key: ""
                }
            }

            for (let index = 0; index < data_report.length; index++) {
                let element = data_report[index];
                if (userInProcess.indexOf(element.sessionID) === -1) {
                    // PROCESAMOS LA INFO 
                    let user = element.sessionID
                    let divider = 0
                    obj.nombre = element.nombre
                    obj.fecha = element.date
                    obj.action.key = element.sessionID
                    // SUMAMOS LOS VALORES
                    for (let i = 0; i < data_report.length; i++) {
                        let datainProcess = data_report[i];
                        if (datainProcess.sessionID === user) {
                            obj.result += parseInt(datainProcess.value)
                            divider++
                        }
                    }
                    obj.result = `${parseInt(obj.result / divider)}%`
                    filterData.push(obj)
                    user = ""
                    obj = {
                        nombre: "",
                        fecha: "",
                        result: 0,
                        action: {
                            type: "buttom",
                            key: ""
                        }
                    }
                    userInProcess.push(element.sessionID)
                }
            }

            //console.log('FILTER DATA ', filterData)
        }

        asyncDispatch(
            dispatch({
                type: 'FETCHING_LOAD_REPORT_DATA',
                payload: {
                    monthArr: monthArr,
                    weekArr: weekRange,
                    dateArr: [],
                    reportArr: reportArr,
                    filterData: filterData,
                    filterActive: filterObj,
                    dwData: data
                }
            })
        )


    } */


    const GetReportClubAndDetails = async (model) => {
        let result = await api.GetReportClubAndDetailsSource(model);
        //  console.log('result state', result)
        asyncDispatch(
            dispatch({
                type: 'GET_REPORT_BY_CLUB_DETAILS',
                payload: result.data.message.data
            })
        );

    }

    const GetReportsByClubIDForChart = async (model) => {
        let result = await api.GetReportsByClubIDSource(model);
        console.log({ result });
        asyncDispatch(
            dispatch({
                type: 'GET_REPORTS_BY_CLUBID_FOR_CHART',
                payload: result.data.message
            })
        );
    }

    return (
        <GlobalContext.Provider value={{
            state,
            GetClubCredential,
            GetInitialData,
            GetAtletaInfo,
            GetMemberInfoByDate,
            GetMicrocicloInfoByDate,
            //  UpdateReportTable,
            GetReportClubAndDetails,
            GetReportsByClubIDForChart
        }}>
            {children}
        </GlobalContext.Provider>
    )
}




export default GlobalProvider;
