import React from "react";
import { Row, Col, Table } from "reactstrap";
import CustomizableSelect from "components/customSelect/customSelect";
import ButtomCustom from "components/buttom/index";
import { Button, UncontrolledTooltip } from "reactstrap";
import "@trendmicro/react-paginations/dist/react-paginations.css";
// import Filter from "../../assets/Iconos/Filtros.svg";
import DowloadExcel from "../../../assets/Iconos/Excel.svg";
// import DowloadPDF from "../../assets/Iconos/PDF.svg";
import HelperManager from "../../../source/handler";
// import { element } from "prop-types";
import ReactLoading from "react-loading";
import uuid from "react-uuid";
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { MdPictureAsPdf } from 'react-icons/md'
import { exportableDataInitial } from '../../../api/FisicaTabs/index'
import moment from 'moment'
const api = new HelperManager();

const DataTable = ({
    filterTotal = 4,
    label,
    filterType = [],
    dataTable = { header: [], content: [] },
    idRef = "",
    handlerOnChage = () => { },
    handlerButton = () => { },
}) => {
    const [element, setElement] = React.useState(null);
    const [disableExcel, setDisableExcel] = React.useState(false);
    const [disablemin, setDisbleMin] = React.useState(true);
    const [disableMax, setDisableMax] = React.useState(false);
    const [disabledSelect, setDisabledSelect] = React.useState(false);
    const [loadingExcel, setLoadingExcel] = React.useState(false);
    const [itemData, setItemData] = React.useState({ header: [], content: [] });
    const [totalFilter, setTotalFilter] = React.useState(() => {
        if (filterTotal > 4) filterTotal = 4;
        let array = [];
        for (let index = 0; index < filterTotal; index++) {
            array.push(index);
        }
        return array;
    });

    const [msg, setMsg] = React.useState("No existe datos para exportar...");
    const [dowloadEmpty, setDowloadEmpty] = React.useState(false);

    const [states, setState] = React.useState({
        page: 0,
        perPage: 5,
        numberOfPages: Math.ceil(dataTable.content.length / 5),
    });

    const [tooltipOpen, setToolTipOpen] = React.useState({
        f: false,
        e: false,
        p: false,
    });

    React.useEffect(() => {
        setItemData(dataTable);
        if (dataTable.content.length <= 0) {
            setDisabledSelect(true);
        } else {
            setDisableExcel(true)
        }

        setState({
            page: 0,
            perPage: 5,
            numberOfPages: Math.ceil(dataTable.content.length / 5),
        });
    }, [dataTable.content]);

    const excelDowload = async () => {

        setLoadingExcel(true)

        let currentSessionID = JSON.parse(window.localStorage.getItem('_gHtk'))
        let currentCategory = JSON.parse(window.localStorage.getItem('currentCategori'))

        let clubSession = window.localStorage.getItem('clubSession')
        let categoryID = window.localStorage.getItem('categoryID')

        let date = window.localStorage.getItem('date')
        let currentUser = window.localStorage.getItem('currentUser')

        let model = {
            sessionID: currentSessionID ? currentSessionID.sessionID : clubSession,
            categorieID: currentCategory ? currentCategory.categoriesID : categoryID,
            date: filterType.length > 0 ? filterType[0].placheolder : date,
            userSession: currentUser
        }

        let result = await exportableDataInitial(model)

        let dataList = result.message

        let name = "";

        switch (idRef) {
            case "dataEva":
                name = "Informacion de evaluaciones";
                break;
            case "fisicas":
                name = "Informacion E. Fisicas";
                // excelModel = data.content
                break;
            default:
                return;
        }

        if (dataList.length <= 0) {
            setDowloadEmpty(true);
            setTimeout(() => {
                setDowloadEmpty(false);
            }, 2000);
        } else {
            api.dowloadexcel(dataList, name);
        }

        setLoadingExcel(false)
    };

    const ChangeData = (dataTable) => {

        handlerOnChage(dataTable, idRef);
    };

    const returnTr = (dataTable) => {
        dataTable = Object.entries(dataTable);
        //   console.log({ data });
        return (
            <tr
                key={parseInt(
                    Math.random(100 * 5)
                        .toString()
                        .replace(".", 0)
                )}
            >
                {dataTable.map((task, d) => {
                    if (typeof task[1] === "object") {
                        let current = task[1];
                        return (
                            <td
                                key={parseInt(
                                    Math.random(100 * 5)
                                        .toString()
                                        .replace(".", 0)
                                )}
                                className="text-center"
                            >

                                {current.length > 0 && current !== null && current !== undefined &&
                                    current.map((row) => {
                                        let classBtn = row.classBtn
                                            ? row.classBtn
                                            : "btn-icon btn-success btn-round";

                                        return (
                                            <Button
                                                onClick={() => handlerButton(row.key, row.type)}
                                                className="btn-icon btn-black btn-round"
                                                size="sm"
                                                key={uuid()}
                                            >
                                                <MdPictureAsPdf size="15px" color="white" />
                                            </Button>
                                        );
                                    })}
                            </td>
                        );
                    } else {
                        if (d === 4) {
                            return (
                                <td
                                    key={parseInt(
                                        Math.random(100 * 5)
                                            .toString()
                                            .replace(".", 0)
                                    )}
                                    className="text-center"
                                >
                                    <span
                                        className={
                                            parseInt(task[1]) <= 54
                                                ? "text-center bad spanLabel"
                                                : parseInt(task[1]) >= 55 && parseInt(task[1]) <= 74
                                                    ? "text-center medium spanLabel"
                                                    : parseInt(task[1]) >= 75 && parseInt(task[1]) <= 89
                                                        ? "text-center little spanLabel"
                                                        : "text-center good spanLabel"
                                        }
                                    >
                                        {task[1]}
                                    </span>
                                </td>
                            );
                        } else {
                            return (
                                <td
                                    key={parseInt(
                                        Math.random(100 * 5)
                                            .toString()
                                            .replace(".", 0)
                                    )}
                                    className={task[0] === "nombre" ? "pl-4" : "text-center"}
                                >
                                    {label ? (
                                        <span className={"text-center"}>{task[1]}</span>
                                    ) : (
                                        task[1]
                                    )}
                                </td>
                            );
                        }
                    }
                })}
            </tr>
        );
    };

    const paginations = (action) => {
        let page = states.page;
        let totalIndex = states.numberOfPages - 1;

        if (action) {
            page = page + 1;
            page = page >= totalIndex ? totalIndex : page;
        } else {
            page = page - 1;
            page = page <= 0 ? 0 : page;
        }

        if (page <= 0) {
            setDisbleMin(true);
            setDisableMax(false);
        }
        if (page >= totalIndex) {
            setDisbleMin(false);
            setDisableMax(true);
        }
        if (page >= 0 && page < totalIndex) {
            setDisbleMin(false);
            setDisableMax(false);
        }
        //console.log('Total Index',totalIndex,'PAGE',page)
        setState({ ...states, page });
    };

    return (
        <div className="table-card">
            <div className="table-card-warpper">
                <div>
                    <Row>
                        <Col sm="12">
                            <Row className="flex-row-center">
                                {totalFilter.length <= 0 ? (
                                    <Col xs={3} className="mb-5"></Col>
                                ) : (
                                    totalFilter.map((task, i) => {
                                        if (filterType.length > 0) {
                                            let config = filterType[i];
                                            if (config.type === "select") {
                                                return (
                                                    <Col
                                                        key={uuid()}
                                                        xs="3"
                                                    >
                                                        <CustomizableSelect
                                                            title={config.placheolder}
                                                            item={config.data}
                                                            multiselect={config.multiselect}
                                                            onChange={(item, ref) => ChangeData(item, ref)}
                                                        />
                                                    </Col>
                                                );
                                            }
                                            if (config.type === "buttom") {
                                                return (
                                                    <Col
                                                        key={uuid()}
                                                        xs="3"
                                                    >
                                                        <ButtomCustom title={config.placheolder} />
                                                    </Col>
                                                );
                                            }
                                        } else {
                                            return (
                                                <Col
                                                    key={uuid()}
                                                    xs="3"
                                                >
                                                    <CustomizableSelect
                                                        title="Selecione un item"
                                                        item={
                                                            filterType.length > 0 ? filterType[0].data : []
                                                        }
                                                    />
                                                </Col>
                                            );
                                        }
                                    })
                                )}
                            </Row>
                        </Col>
                    </Row>

                    <div className="tab-content-table" id={idRef}>
                        <Table className="tablesorter" responsive>
                            <thead className="text-primary">
                                {
                                    itemData.header.length > 0 ?
                                        <tr>
                                            {
                                                itemData.header.map((task, i) => {
                                                    return (
                                                        <th key={uuid()} className="text-center">{task}</th>
                                                    )
                                                })
                                            }
                                        </tr> :
                                        <tr>
                                            <th className="text-center">Name</th>
                                            <th className="text-center">Country</th>
                                            <th className="text-center">City</th>
                                            <th className="text-center">Salary</th>
                                        </tr>
                                }

                            </thead>
                            <tbody>
                                {itemData.content.length > 0 ? (
                                    itemData.content
                                        .slice(
                                            states.perPage * states.page,
                                            states.perPage * (states.page + 1)
                                        )
                                        .map((task) => returnTr(task))
                                ) : (
                                    <tr>
                                        <td colSpan={dataTable.header.length}>
                                            <Row className="justify-content-center align-items-center w-100">
                                                <span className="spanNoData">
                                                    Sin datos para mostrar
                                                </span>
                                            </Row>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                        <Row style={{ padding: "10px" }}>
                            <Col>
                                <div style={{ display: "flex" }}>
                                    {/* <div style={{ marginRight: "5px" }}>
                                        <Button className="pagination-btn-filter" id={'Tooltip-Excel'} onClick={() => excelDowload()}>
                                            <img src={DowloadExcel} style={{ width: "20px", height: "20px" }} />
                                        </Button>
                                        <UncontrolledTooltip placement="top" target={'Tooltip-Excel'}  >
                                            Descargar Excel
                                        </UncontrolledTooltip >
                                    </div> */}

                                    {
                                        disableExcel && !loadingExcel &&
                                        <div style={{ marginRight: "5px" }}>
                                            <Button
                                                className="pagination-btn-filter"
                                                id={"Tooltip-Excel"}
                                                onClick={() => excelDowload()}
                                            >
                                                <img
                                                    src={DowloadExcel}
                                                    style={{ width: "20px", height: "20px" }}
                                                />
                                            </Button>
                                            <UncontrolledTooltip
                                                placement="top"
                                                target={"Tooltip-Excel"}
                                            >
                                                Descargar Excel
                                            </UncontrolledTooltip>
                                        </div>
                                    }
                                    {loadingExcel &&
                                        <div style={{ marginRight: "5px", height: '30px' }}>
                                            <ReactLoading type="spin" color="#1a1a1a" width="30px" />
                                        </div>
                                    }


                                    {dowloadEmpty && (
                                        <div style={{ marginRight: "5px", marginTop: "11px" }}>
                                            <span
                                                style={{
                                                    background: "#ff8d7296",
                                                    color: "#000",
                                                    padding: "8px",
                                                    borderRadius: "8px",
                                                    fontSize: "10px",
                                                }}
                                            >
                                                {msg}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </Col>
                            <Col className="pagination-Col-Right">
                                <div className="pagination-warpper">
                                    <button
                                        className="pagination-btn"
                                        onClick={() => paginations(false)}
                                    >
                                        {`<`}
                                    </button>
                                    <span>{`${states.page + 1 === 0 ? 1 : states.page + 1} of ${states.numberOfPages === 0 ? 1 : states.numberOfPages
                                        }`}</span>
                                    <button
                                        className="pagination-btn"
                                        onClick={() => paginations(true)}
                                    >
                                        {`>`}
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DataTable;
