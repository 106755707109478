import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from "recharts";

import { useEffect, useState } from "react";

let primaryColor = "#1f8ef1";
let secondaryColor = "teal";

const AreaChartComponent = ({
    head = [],
    data = {},
    toolTip = {
        current: "Reporte actual",
        last: "Reporte previo"
    },
    idRef = "",
    defaultValues = [{
        name: "",
        dt1: 0,
        dt2: 0
    }]
}) => {
    const [headerData, setHeaderData] = useState([]);

    const StateModelChart = (model) => {
      
        let header = model.head;
        let currentData = model.data.dt1;
        let dataComparison = model.data.dt2;
        let deleteZerosForProcess = [];

        
        for (let index = 0; index < currentData.length; index++) {
            let element = currentData[index];
         //   console.log('element',currentData)
       //  console.log('header', header[index])
            header[index].dt1 = element === null ? 0 : element;

        }

        for (let index = 0; index < dataComparison.length; index++) {
            let element = dataComparison[index];
            header[index].dt2 = element === null ? 0 : element;
        }

       
        for (let index = 0; index < header.length; index++) {
            let element = header[index];

            deleteZerosForProcess.push(element);
        }

        if (deleteZerosForProcess.length === 0) {
            return defaultValues
        }

        return deleteZerosForProcess
    }

    useEffect(() => {
        if (head.length > 0) {
            let result = StateModelChart({ head, data })
            setHeaderData(result)
        }
    }, [head, data])

    return (
        <div ref={idRef} style={{ width: "100%", height: 360 }}>
            <ResponsiveContainer>
                <AreaChart
                    data={headerData}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={primaryColor} stopOpacity={0.5} />
                            <stop offset="95%" stopColor={primaryColor} stopOpacity={0} />
                        </linearGradient>
                        <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={secondaryColor} stopOpacity={0.5} />
                            <stop offset="95%" stopColor={secondaryColor} stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis
                        tickFormatter={(decimal) => `${decimal}%`}
                        domain={[0, 100]}
                        ticks={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                    />
                    <Tooltip
                        content={({ active, content, label, payload, ...rest }) => {
                            if (active) {
                                // console.log(payload)
                                return (
                                    <div
                                        style={{
                                            background: "#FFF",
                                            padding: "10px",
                                        }}
                                    >
                                        <b>{label}</b>
                                        {(payload != null && payload.length > 0) ?
                                            payload.map((row, index) => (
                                                <div
                                                    key={index}
                                                    style={{
                                                        color:
                                                            row.name == "dt1" ? secondaryColor : primaryColor,
                                                    }}
                                                >
                                                    <span>
                                                        {row.name == "dt1" ?
                                                            toolTip.current : toolTip.last
                                                        }:
                                                    </span>{" "}
                                                    &nbsp;
                                                    <span>{row.value}%</span>
                                                </div>
                                            )) : null
                                        }
                                    </div>
                                );
                            }

                            return null;
                        }}
                    />
                    <Area
                        type="monotone"
                        dataKey="dt2"
                        fillOpacity={1}
                        fill="url(#colorUv)"
                        stroke={primaryColor}
                        strokeWidth={1.5}
                        activeDot={{ r: 8 }}
                    />
                    <Area
                        type="monotone"
                        dataKey="dt1"
                        fillOpacity={1}
                        fill="url(#colorPv)"
                        stroke={secondaryColor}
                        strokeWidth={1.5}
                        activeDot={{ r: 8 }}
                    />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    );
};

export default AreaChartComponent;
