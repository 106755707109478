const GetDefinition = (e) => {
    let module = [
        {
            link: 'Login',
            redirect: "auth/SingIn"
        }, {
            link: 'Recovery',
            redirect: 'auth/Recovery'
        }, {
            link: 'GetPosition',
            redirect: "main/GetPosition"
        }, {
            link: 'LoadInitData',
            redirect: "main/getInitialData"
        }, {
            link: "GetReportClubAndDetails",
            redirect: "main/GetReportClubAndDetails"
        }, {
            link: "GetReportsByClubID",
            redirect: "main/GetReportsByClubID"
        }, {
            link: 'GetAtletaInfoBySession',
            redirect: "main/GetInfoAtleta"
        }, {
            link: 'GetAtletasForMercato',
            redirect: "main/GetAtletasForMercato"
        }, {
            link: 'UpdateAtletaForMercato',
            redirect: "main/UpdateAtletaForMercato"
        }, {
            link: 'GetMemberInfoByDate',
            redirect: 'main/MemberInfoDate'
        }, {
            link: 'GetMicrocicloByDate',
            redirect: "main/MicrocicloByDate"
        }, {
            link: 'GetEvaluacion',
            redirect: 'main/GetEvaluacion'
        }, {
            link: 'GetEvaluacionPartido',
            redirect: 'main/GetEvaluacionPartidoBySessionID'
        }, {
            link: "AddAsistencia",
            redirect: "main/AddAsistencia"
        }, {
            link: "CheckAsistenciaList",
            redirect: "main/CheckAsistencia"
        }, {
            link: "AddUpdateByUserID",
            redirect: "main/addUpdateUserEdit"
        }, {
            link: "GetExtraAtletaInfo",
            redirect: "main/GetUserInfoExtra"
        }, {
            link: "GetReportBySession",
            redirect: "main/GetReportBySession"

        }, {
            link: "getReportByClub",
            redirect: "main/getReportByClub"
        }, {
            link: "MapperRoute",
            redirect: "main/MapperModelExcel"
        },
        {
            link: "GetAllReportsClubID",
            redirect: "main/allReportsClubID"
        }, {
            link: "FisicalTabModel",
            redirect: "main/FisicalTabsModel"
        }, {
            link: "MutationFisicalTab",
            redirect: "main/FisicalTabsModelMutation"
        }, {
            link: "TecnicaTabsModel",
            redirect: "main/TecnicaTabsModel"
        }, {
            link: "TecnicaModelMutation",
            redirect: "main/TecnicaTabsModelMutation"
        }, {
            link: 'GetImgData',
            redirect: "main/image-proxy"
        }, {
            link: "FisicalInfoAtletaMutation",
            redirect: "main/fisical-info-atleta"
        }, {
            link: "ReportTabsModel",
            redirect: "main/ReportTabsModel"
        }, {
            link: "ReportTableModel",
            redirect: "main/ReportTableModel"
        }, {
            link: "GeneralAtletaTabsModel",
            redirect: "main/GeneralAtletaTabsModel"
        }, {
            link: "FisicaAtletaTabsModel",
            redirect: "main/FisicaAtletaTabsModel"
        }, {
            link: "TecnicaAtletaTabsModel",
            redirect: "main/TecnicaAtletaTabsModel"
        }, {
            link: "GetReportTabsAtleta",
            redirect: "main/GetReportTabsAtleta"
        }, {
            link: "MutationFisicalTabsAtleta",
            redirect: "main/MutationFisicalTabsAtleta"
        }, {
            link: "MutationTecnicaTabsAtleta",
            redirect: "main/MutationTecnicaTabsAtleta"
        }, {
            link: "MutationReportTabs",
            redirect: "main/MutationReportTabs"
        }, {
            link: "MutationReportTabsAtleta",
            redirect: "main/MutationReportTabsAtleta"
        }, {
            link: "MutationFisicaTabsChart",
            redirect: "main/MutationFisicaTabsChart"
        }, {
            link: "MutationTecnicasChangeDate",
            redirect: "main/MutationTecnicasChangeDate"
        }, {
            link: "GetGeneralTabsLoad",
            redirect: "main/GetGeneralTabsLoad"
        }, {
            link: "GetUserAndPosition",
            redirect: "main/GetUserDetailsAndPosition"
        }, {
            link: "MutationListPositionsBySport",
            redirect: "main/MutationListPositionsBySport"
        }, {
            link: "MutationDetailUsers",
            redirect: "main/MutationDetailsUser"
        }, {
            link: "GetDataHeaderClub",
            redirect: "main/GetHeaderClubInfo"
        }, {
            link: "MutationUsersGraph",
            redirect: "main/MutationGraphUsers"
        }, {
            link: "exportableFisicas",
            redirect: "main/fisicalExportable"
        }, {
            link: "exportableTecnicas",
            redirect: "main/tecnicaExportable"
        }, {
            link: "getDataAdminNavbar",
            redirect: "main/getDataAdminNavbar"
        }, {
            link: "mutateDataCategory",
            redirect: "main/mutateDataCategory"
        }, {
            link: "MutationFisicalPdfExportable",
            redirect: "main/mutateFisicalPdf"
        }, {
            link: "GetDataCreateLocalStorage",
            redirect: "auth/dataCreateLocalStorage"
        }, {
            link: "GetClubInfoBysessionClubID",
            redirect: "main/getClubInfoBySessionID"
        }, {
            link: "GetLoadEspecialistData",
            redirect: "auth/getLoadEspecialistData"
        }, {
            link: "GetLoadDataForInstitution",
            redirect: "auth/getLoadDataForInstitution"
        },{
            link:"getAthletesByInstitutionSession",
            redirect:"main/getAtletasByInstitutionSession"
        },{
            link: "GetListAtletasForEspecial",
            redirect: "main/listAtletasForEspecial"
        }

    ]

    let info = module.map(task => {
        if (task.link === e) {
            return task.redirect
        }
        return undefined
    }).filter(task => task !== undefined)

    return info;
}


export default GetDefinition;