import DatetimeCustom from "components/dateTime";
import React from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import CustomizableDataTable from "components/dataTable/dataTable";
import AreaChart from "components/AreaChart";
import HelperManagers from "../../../source/handler/index";
import HelperMapper from "../../../source/Main";
import { GetReportTabs } from '../../../api/AtletaDashboardTabs/ReportTabs'
import { MutationReportTabsAtleta } from "../../../api/AtletaDashboardTabs/MutationReportTabsAtleta";
import { useStateCallback } from "hook/useStateCallback";
import { useQuery, useMutation, useQueryClient } from "react-query";
import LoadingScreen from "components/Loading";
import { CardSkeleton } from "components/Skeleton";
import { TableSkeleton } from "components/Skeleton";
import { useRef, useState, useEffect } from "react";

const api = new HelperManagers();
const apiMapper = new HelperMapper();

const ReportTabs = ({ enabled }) => {
    const chartRef2 = useRef(null)
    const [reportWeeks, setReportWeeks] = React.useState({
        endDate: "0",
        startDate: "0"
    });
    const [filterObj, setFilterObj] = useStateCallback({
        report: {
            isActive: false,
            value: "",
        },
        mes: {
            isActive: false,
            value: "",
        },
        week: {
            isActive: false,
            value: "",
        },
        date: {
            isActive: false,
            value: "",
        },
    });
    let dwData = []

    const queryClient = useQueryClient();
    const handlerQuery = async () => {
        let session = window.localStorage.getItem('currentUser');

        let model = {
            sessionID: session
        }
        let result = await GetReportTabs(model)

        return result
    }

    const { data, isSuccess, isLoading, isError, refetch } = useQuery('reportTabsAtleta', () => handlerQuery(), {
        enabled: enabled,
        refetchOnWindowFocus: enabled
    });

    useEffect(() => {
        if (enabled) {
            refetch()
        }
    }, [enabled])

    // MUTATAION FUNCTION
    const handlerReportEstadisticasFilter = async (model) => {
        try {
            let result = await MutationReportTabsAtleta(model)
            return result
        } catch (error) {
            console.log({ error });
        }
    }
    const reportEstadisticaFilterMutation = useMutation('reportTabsAtleta', {
        mutationFn: handlerReportEstadisticasFilter,
        onSuccess: (response) => {
            queryClient.setQueryData('reportTabsAtleta', (oldData) => {
                return {
                    ...oldData,
                    message: response.message.message
                }
            })
        }
    })
    // FUCTION

    const UploadExcelReport = async (key, type) => {
        const { filterActive } = data.message.tableData
        let finalData = [];
        let model = {};
        let modelMapper = [];
        let clubInfo = JSON.parse(window.localStorage.getItem("_gHtk"));
        let clubID = clubInfo.clubID
        let categoria = JSON.parse(window.localStorage.getItem("currentCategori"));
        if (type) {
            // MAPPER MODEL API
            model = {
                model: {
                    clubSession: clubInfo.sessionID,
                    clubID: clubID,
                    FilterObj: filterActive,
                    categoryID: categoria.categoriesID,
                    sessionID: key,
                },
                type: true
            }

            //console.log('FILTER OBJ ', filterObj)
            modelMapper = await apiMapper.MapperModel(model, false);
            finalData = modelMapper.data.message;

        } else {
            // FULL DESCARGA

            // MAPPER MODEL API
            model = {
                model: {
                    clubSession: clubInfo.sessionID,
                    clubID: clubID,
                    reportID: filterActive.report.value.id,
                    reportName: filterActive.report.value.value,
                    categoryID: categoria.categoriesID,
                },
                type: false,
            };
            modelMapper = await apiMapper.MapperModel(model, false);
            finalData = modelMapper.data.message;
        }

        api.dowloadexcel(finalData, "Estadísticas de Reportes");
    };

    const ChangeDataForFilterReportTable = (value, ref) => {
        let session = window.localStorage.getItem('currentUser');
        let olData = { ...data }
        switch (ref) {
            case "reportID":
                olData.message.tableData.filterActive.report.isActive = true;
                olData.message.tableData.filterActive.report.value = value;

                if (olData.message.tableData.filterActive.report.isActive) {
                    olData.message.tableData.filterActive.week.isActive = false
                }
                break;
            case "week":
                olData.message.tableData.filterActive.week.isActive = true;
                olData.message.tableData.filterActive.week.value = JSON.parse(value.id);
                break;
            default:
                break;
        }
        let modelApi = {
            sessionID: session
        }

        let modelMutation = {
            oldData: olData,
            modelApi: modelApi
        }


        reportEstadisticaFilterMutation.mutate(modelMutation)
    };


    if (isSuccess) {

        const { tableData, chartData } = data.message
        const { monthArr, weekArr, dateArr, reportArr, filterData, filterActive } = tableData

        let filterArr = {
            weekFilter: weekArr,
            monthFilter: monthArr,
            dateFilter: dateArr,
            reportFilter: reportArr,
        }
        return (

            <>
                <Row>
                    <Col xs="12">
                        <Card className="card-chart">
                            <CardHeader>
                                <Row>
                                    <Col className="text-left" sm="3">
                                        <CardTitle tag="h1" className="tittlestyle">
                                            Aspectos Complementarios
                                        </CardTitle>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <AreaChart
                                    head={[
                                        {
                                            name: "Deportiva",
                                            dt1: 0,
                                            dt2: 0,
                                        },
                                        {
                                            name: "Fisica",
                                            dt1: 0,
                                            dt2: 0,
                                        },
                                        {
                                            name: "Psicologia",
                                            dt1: 0,
                                            dt2: 0,
                                        },
                                        {
                                            name: "Antropometria",
                                            dt1: 0,
                                            dt2: 0,
                                        },
                                        {
                                            name: "Medica",
                                            dt1: 0,
                                            dt2: 0,
                                        },
                                        {
                                            name: "Fisiologia",
                                            dt1: 0,
                                            dt2: 0,
                                        },
                                        {
                                            name: "Educativa",
                                            dt1: 0,
                                            dt2: 0,
                                        },
                                        {
                                            name: "Psicosocial",
                                            dt1: 0,
                                            dt2: 0,
                                        }, {
                                            name: "Fisioterapia",
                                            dt1: 0,
                                            dt2: 0,
                                        }
                                    ]}
                                    data={chartData}
                                    idRef={chartRef2}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-left" sm="4">
                        <CardTitle tag="h1" className="tittlestyle">
                            Estadísticas de Reportes
                        </CardTitle>
                    </Col>
                    <Col lg="12" md="12">
                        <CustomizableDataTable
                            filter={true}
                            filterData={[
                                {
                                    type: "select",
                                    multiselect: false,
                                    placheolder: filterActive.report.isActive
                                        ? filterActive.report.value
                                        : 'Seleccione un reporte',
                                    filterActive: filterActive.report.isActive ? filterActive.report.value : {},
                                    dataForFilterList: filterArr.reportFilter,
                                    keyExtractor: "reportID",
                                },
                                {
                                    type: "select",
                                    multiselect: false,
                                    placheolder: filterActive.week.isActive ? typeof filterActive.week.value === 'object' ? `${filterActive.week.value.startDate} - ${filterActive.week.value.endDate}` : filterActive.week.value : "Seleccione Semana",
                                    filterActive: filterActive.week,
                                    dataForFilterList: filterArr.weekFilter,
                                    keyExtractor: "week",
                                }
                            ]}
                            tableType="regular"
                            showAction={true}
                            showActionExecel={true}
                            headerData={["Nombre", "Reporte", "Mes", "Resultado", "Action"]}
                            data={filterData}
                            idRef={"tableReporte"}
                            filterComponent={(item, ref) =>
                                ChangeDataForFilterReportTable(item, ref)
                            }
                            handlerOnClik={(item) => UploadExcelReport(item, true)}
                            handlerActionOnCLick={(item) => {
                                if (item === "excel") {
                                    UploadExcelReport(item, false);
                                }
                            }}
                        />
                    </Col>

                </Row>
            </>
        )
    }

    if (isLoading) {
        return (
            <Row>
                <Col xs="12">
                    <CardSkeleton />
                </Col>
                <Col xs="12">
                    <TableSkeleton title="Estadísticas de Reportes" />
                </Col>
            </Row>
        )
    }

}


export default ReportTabs;