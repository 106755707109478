import DiagnosticTable from "components/DiagnosticTable";
import React from "react";
import { CardTitle, Col, Row } from "reactstrap";
import DataTable from "components/dataTable";
import { TableSkeleton } from "components/Skeleton";
import { GetGeneralTabModel } from "../../../api/GeneralTabs";

import { useQuery } from "react-query";

const GeneralTabs = ({active }) => {

    const handlerQuery = async () => {
        let clubID = JSON.parse(window.localStorage.getItem('_gHtk'));
        let categoryID = JSON.parse(window.localStorage.getItem('currentCategori'))
        let model = {
            clubID: clubID.clubID,
            categoryID: categoryID.categoriesID
        }
        try {
            let result = await GetGeneralTabModel(model)

            return result
        } catch (error) {
            return error
        }
    }

    const { data, isLoading, refetch, isIdle, isSuccess, isFetching } = useQuery('generalTabs', () => handlerQuery(), {
        refetchOnWindowFocus: false,
        enabled: !active ? false : true
    })

    if (isIdle) {
        if (active) {
            refetch()
        }
        return (
            <div></div>
        )
    }
    if (isFetching) {
        return (
            <Row>
                <Col xs={12}>
                    <TableSkeleton title="Resumen General" />
                </Col>
            </Row>
        )
    }
    if (isSuccess) {
        const { tableReport, tableMental } = data.message
        // console.log('DATA TABLE ',tableReport)
        return (
            <>
                <Row>
                    <Col xs={12}>

                        <DiagnosticTable
                            title="Resumen General"
                            filterArr={[
                                {
                                    id: "atleta",
                                    placheolder: "Selecciona una atleta",
                                    data: tableReport.map((row) => ({
                                        id: row.nombre,
                                        value: row.nombre,
                                        key: "nombre",
                                    })),
                                    multiselect: false,
                                }
                            ]}
                            header={[
                                { id: "nombre", label: "Atleta", minWidth: 170, position: "left" },
                                {
                                    id: "fisica",
                                    label: "Física",
                                    format: (value) => `${Math.round(value)}%`,
                                    minWidth: 170
                                },
                                {
                                    id: "tecnica",
                                    label: "Técnica",
                                    format: (value) => `${Math.round(value)}%`,
                                    minWidth: 170
                                },
                                {
                                    id: "psicologia",
                                    label: "Psicología",
                                    format: (value) => `${Math.round(value)}%`,
                                    minWidth: 170,
                                },
                                {
                                    id: "antropometria",
                                    label: "Antropometría",
                                    format: (value) => `${Math.round(value)}%`,
                                    minWidth: 170,
                                },
                                {
                                    id: "medica",
                                    label: "Médica",
                                    format: (value) => `${Math.round(value)}%`,
                                    minWidth: 170
                                },
                                {
                                    id: "fisiologia",
                                    label: "Fisiológica",
                                    format: (value) => `${Math.round(value)}%`,
                                    minWidth: 170,
                                },
                                {
                                    id: "psicosocial",
                                    label: "Psicosocial",
                                    format: (value) => `${Math.round(value)}%`,
                                    minWidth: 170,
                                },
                                {
                                    id: "ovr_general",
                                    label: "OVR General",
                                    format: (value) => `${Math.round(value)}%`,
                                    minWidth: 170,
                                }
                            ]}
                            content={tableReport}
                            idRef="test_table_ovr"
                        />


                        {/* <>
                            <Col className="text-left" sm="4">
                                <CardTitle
                                    tag="h1"
                                    className="tittlestyle"
                                    style={{ justifyContent: "center" }}
                                >
                                    Perfil de Personalidad
                                </CardTitle>
                            </Col>
                            <Col lg="12" md="12">
                                <Row>
                                    <Col xs="12">
                                        <DataTable
                                            filterTotal={4}
                                            filterType={[
                                                {
                                                    type: "buttom",
                                                    multiselect: false,
                                                    placheolder: "Dominante",
                                                    data: [],
                                                },
                                                {
                                                    type: "buttom",
                                                    multiselect: false,
                                                    placheolder: "Influyente",
                                                    data: [],
                                                },
                                                {
                                                    type: "buttom",
                                                    multiselect: false,
                                                    placheolder: "Estable",
                                                    data: [],
                                                },
                                                {
                                                    type: "buttom",
                                                    multiselect: false,
                                                    placheolder: "Consiensudo",
                                                    data: [],
                                                },
                                            ]}
                                            data={tableMental}
                                            idRef={"tableMetalInfo"}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </> */}

                    </Col>
                </Row>
            </>
        )
    }

}

export default GeneralTabs;