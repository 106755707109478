import React from "react";
import { BarChart, Bar, Cell, ResponsiveContainer, XAxis, LabelList, Legend, Tooltip } from 'recharts';
import uuid from "react-uuid"
const BarcharComponet = ({
    dataConten = []
}) => {

    const [activeIndex, setActiveIndex] = React.useState(0);
    const [dataTecnica, setDataTecnica] = React.useState([])
    const [nameAtleta, setNameAtleta] = React.useState('')
   
    const handleClick = (index) => {
        //    console.log({ index })
        setActiveIndex(index)
    };

    React.useEffect(() => {
        // console.log({dataConten})
        if (dataConten[0].data.length > 0) {
            setDataTecnica(dataConten[0].data)
        }else{
            setDataTecnica([])
        }
    }, [dataConten[0].data])

    React.useEffect(() => {
        if (dataConten[0].hasOwnProperty('nameAtleta')) {
            setNameAtleta(dataConten[0].nameAtleta)
        }
    }, [dataConten[0]])

 
    return (
        <div style={{ width: '82%', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
            <p style={{ marginLeft: '25px', marginBottom: '30px', color: "#000" , fontWeight:'bold'  }}>{nameAtleta}</p>
            <ResponsiveContainer width="100%" height={100}>
                <BarChart width={150} height={40} data={dataTecnica} barCategoryGap={45} barGap={2}>
                    <XAxis dataKey="name" />
                    <Tooltip />
                    <Legend dataKey="name" />
                    <Bar dataKey="Global" radius={4} fill="#82ca9d" >

                        <LabelList dataKey="Global" position="center" fill="#000000" />
                    </Bar>
                    <Bar dataKey="Atleta" fill="#8884d8" radius={4}>
                        <LabelList dataKey="Atleta" position="center" fill="#000000" />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
}





export default BarcharComponet;

