import React from "react"
import CustomizableDataTable from "components/dataTable/dataTable"
import { Card, CardBody, CardHeader, CardTitle, Col, Row, Spinner } from "reactstrap"
import { useQuery, useMutation, useQueryClient, QueryClient } from 'react-query'
import {
  GetTecnicaTabsModel,
  MutationTecnicaTabsContent,
  MutationTecnicasTableChangeDate,
  MutationTecnicasGraph
} from "../../../api/TecnicaTabs"
import HelperManagers from "../../../source/handler/index"
import DataTable from "components/ContentTabs/Tecnicas/table"
import Reportes from "components/DowloadReport/Tecnica/Reportes"
import { useState } from "react"
import ModalComponent from "components/DowloadReport/Fisica/Modal"
import { CardSkeleton, TableSkeleton } from "components/Skeleton"
import BarcharComponent from "../../BarchartTecnicas/index"
import Manager from "../../../source/Main"

const api = new HelperManagers()

const apiMain = new Manager()

const TecnicasTabs = ({ active }) => {
  const chartRef = React.useRef(null)
  const [showModal, setShowModal] = React.useState(false)
  const [reportData, setReportData] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const queryClient = useQueryClient()
  const [evaPartidoFilterDatos, setEvaPartidoFilterDatos] = React.useState({
    nombre: "",
    rival: "",
    torneo: "",
    fecha: "",
  })
  const [ovrAthlete, setOvrAthlete] = React.useState(0)
  const [evaPartidoUser, setEvaPartidoUser] = React.useState({
    header: [
      "Nombre del atleta",
      "Puntaje ofensivo",
      "Puntaje defensivo",
      "Puntaje de actitud",
      "Minutos jugados",
      "Goles",
      "Asistencias",
      "Tarjetas Amarillas",
      "Tarjetas Rojas",
      "Paradas de gol",
      "Paradas de Penales",
    ],
    content: [
      {
        nombre: "",
        p1: 0,
        p2: 0,
        p3: 0,
        p4: 0,
        p5: 0,
        p6: 0,
        p7: 0,
        p8: 0,
        p9: 0,
        p10: 0,
      },
    ],
  })
  const [isReportLoading, setIsReportLoading] = React.useState(true);
  const [sessionAtleta, setSessionAtleta] = React.useState('')
  const [userForExcel, setUserForExecel] = React.useState({});
  
  const handlerQuery = async () => {
    let clubSession = JSON.parse(window.localStorage.getItem('_gHtk'))
    let currentCategory = JSON.parse(window.localStorage.getItem('currentCategori'))

    let model = {
      clubSession: clubSession.sessionID,
      categoryID: currentCategory.categoriesID,
    }

    let result = await GetTecnicaTabsModel(model)
    // console.log('result', result)
    return result
  }

  const {
    data,
    isError,
    refetch,
    isIdle,
    isSuccess,
    isLoading,
    isFetching,
    error
  } = useQuery('tecnicaDataTabs', () => handlerQuery(), {
    refetchOnWindowFocus: false,
    enabled: !active ? false : true
  })

  const handlerFilterChartData = async (model) => {
    try {
      let result = await MutationTecnicaTabsContent(model)
      //   console.log('result', result)
      return result
    } catch (error) {
      return error
    }
  }

  const handlerDateTableChange = async (model) => {
    try {
      let result = await MutationTecnicasTableChangeDate(model)

      return result
    } catch (error) {
      return error
    }
  }

  const _loadEvaPartidoByAtletaID = async (item) => {
    let session = JSON.parse(window.localStorage.getItem("_gHtk"))

    let model = {
      clubSessionID: session.sessionID
    }
    let resultPartido = await apiMain.GetEvaluaPartidoSession(model)
    let data = resultPartido.data.message
    setUserForExecel(item)
    let filterData = data

    let result = []
    let hasEva = {
      count: 0,
      totalEva: 0
    }
    // console.log('ITEMD FILTER ', item)
    for (let index = 0; index < filterData.length; index++) {
      let element = filterData[index]

      if (element.sessionID === item.sessionID && element.rival === item.rival) {
        let objInfo = evaPartidoFilterDatos

        objInfo.nombre = element.nombreAtleta
        objInfo.rival = element.rival
        objInfo.torneo = element.torneoName
        objInfo.fecha = element.fecha
        setEvaPartidoFilterDatos(objInfo)

        // VALIDAMOS QUE LOS CAMPOS NO SEAN NULL
        hasEva.totalEva++
        let entries = Object.entries(element)
        for (let index = 0; index < entries.length; index++) {
          let task = entries[index]

          if (task[0] === 'p1') {
            if (task[1] === null) hasEva.count++
          }
          if (task[0] === 'p2') {
            if (task[1] === null) hasEva.count++
          }
          if (task[0] === 'p3') {
            if (task[1] === null) hasEva.count++
          }
          if (task[0] === 'p4') {
            if (task[1] === null) hasEva.count++
          }
          if (task[0] === 'p5') {
            if (task[1] === null) hasEva.count++
          }
          if (task[0] === 'p6') {
            if (task[1] === null) hasEva.count++
          }
          if (task[0] === 'p7') {
            if (task[1] === null) hasEva.count++
          }
          if (task[0] === 'p8') {
            if (task[1] === null) hasEva.count++
          }
          if (task[0] === 'p9') {
            if (task[1] === null) hasEva.count++
          }
          if (task[0] === 'p10') {
            if (task[1] === null) hasEva.count++
          }
        }

        if (hasEva.count >= 10) {
          hasEva = {
            count: 0,
            totalEva: 0
          }
        } else {
          result.push(element)
          hasEva = {
            count: 0,
            totalEva: 0
          }
        }

      }

    }

    let header = {
      header: ['Nombre del atleta', 'Puntaje ofensivo', 'Puntaje defensivo', 'Puntaje de actitud', 'Minutos jugados', 'Goles', 'Asistencias', 'Tarjetas Amarillas', 'Tarjetas Rojas', 'Paradas de gol', 'Paradas de Penales'],
      content: []
    }

    let obj = {
      nombre: '',
      p1: 0,
      p2: 0,
      p3: 0,
      p4: 0,
      p5: 0,
      p6: 0,
      p7: 0,
      p8: 0,
      p9: 0,
      p10: 0,
    }
    obj.nombre = result[0].nombreAtleta
    obj.p1 = result[0].p1
    obj.p2 = result[0].p2
    obj.p3 = result[0].p3
    obj.p4 = result[0].p4
    obj.p5 = result[0].p5
    obj.p6 = result[0].p6
    obj.p7 = result[0].p7
    obj.p8 = result[0].p8
    obj.p9 = result[0].p9
    obj.p10 = result[0].p10

    header.content.push(obj)
    let ovr = Math.round(((result[0].p1 + result[0].p2 + result[0].p3) / 3) * 10)
    setOvrAthlete(ovr)
    // console.log('HEADER ', header)
    setEvaPartidoUser(header)
    setShowModal(true)
  }

  const dowloaddf = (item) => {

    if (!item.status) {
      //   console.log('estoy en el if')
      mutationGraphTecnicaForUser(item)
    } else {
      
      setIsReportLoading(true)
      // TRAER LAS EVALUACIO EN CUSRO
      const { currentData, evaluacion, flagClub } = data.message.dataEvaForReport
      let list = currentData
      //  console.log('flagClub',flagClub )
      let pdfData = list.filter((task) => {
        if (task.date === item.date) {
          return task
        }
      })

      pdfData = pdfData.filter((task) => {
        if (task.sessionID === item.extractor) {
          return task
        }
      })

      //console.log('Data for Dowload PDF ', pdfData)

      let component1 = pdfData.filter((task) => {
        if (task.tecnicaTypeID === 1) {
          return task
        }
      })
      let component2 = pdfData.filter((task) => {
        if (task.tecnicaTypeID === 2) {
          return task
        }
      })
      let component3 = pdfData.filter((task) => {
        if (task.tecnicaTypeID === 3) {
          return task
        }
      })

      let currentCategori = JSON.parse(window.localStorage.getItem('currentCategori'))
      
      console.log('List ', list)
      setReportData([{
        component1: component1,
        component2: component2,
        component3: component3,
        evaluacion: evaluacion,
        categoryID: currentCategori,
        sportPosition: list[0].sport_positionID,
        flag: flagClub
      }])
      setIsOpen(true)
      setIsReportLoading(false)
    }
  }

  const handlerUserGraph = async (model) => {
    try {
      let result = await MutationTecnicasGraph(model)
      //  console.log({ result })
      return result
    } catch (error) {

      return error
    }

  }

  // MUTATION
  const changeDataForFilterTableCompeticion = useMutation('tecnicaDataTabs', {
    mutationFn: handlerFilterChartData,
    onSuccess: (response) => {
      queryClient.setQueryData('tecnicaDataTabs', (oldData) => {
        return {
          ...oldData,
          message: response.message
        }
      })
    }
  })

  const changeDateForTableTecnicas = useMutation('tecnicaDataTabs', {
    mutationFn: handlerDateTableChange,
    onSuccess: (response) => {
      //  console.log('resposne ', response)
      queryClient.setQueryData('tecnicaDataTabs', (oldData) => {
        return {
          ...oldData,
          message: response.message.message
        }
      })
    }
  })

  const changeDateGraphTecnicasForUser = useMutation('tecnicaDataTabs', {
    mutationFn: handlerUserGraph,
    onSuccess: (response) => {
      //  console.log('resposne ', response)
      queryClient.setQueryData('tecnicaDataTabs', (oldData) => {
        return {
          ...oldData,
          message: response.message.message
        }
      })
    }
  })

  const ChangeTableCompetenciaFIlter = async (item, ref) => {

    let clubSession = JSON.parse(window.localStorage.getItem('_gHtk'))
    let currentCategory = JSON.parse(window.localStorage.getItem('currentCategori'))

    let dataForProcess = data.message

    switch (ref) {
      case 'torneo':
        dataForProcess.activeFilter.filterValue.torneoID = item.id
        dataForProcess.activeFilter.filterPlacholder.torneo = item.value
        break
      case 'sessionID':
        dataForProcess.activeFilter.filterValue.sessionID = item.id
        dataForProcess.activeFilter.filterPlacholder.nombre = item.value
        break
      case 'rival':
        dataForProcess.activeFilter.filterValue.rival = item.id
        dataForProcess.activeFilter.filterPlacholder.rival = item.value
      default:
        break
    }
    let modelApi = {
      clubSession: clubSession.sessionID,
      categoryID: currentCategory.categoriesID
    }

    let model = {
      oldData: dataForProcess,
      model: modelApi
    }
    // console.log('model ',model)
    console.log('data process', dataForProcess, 'Ref ', ref)
    changeDataForFilterTableCompeticion.mutate(model)
  }

  const ChangeTableTecnicaEvaDateFilter = async (date) => {

    let clubSession = JSON.parse(window.localStorage.getItem('_gHtk'))
    let currentCategory = JSON.parse(window.localStorage.getItem('currentCategori'))
    let dataForProcess = data

    let model = {
      oldData: dataForProcess,
      date: date.id,
      clubSession: clubSession.sessionID,
      categoryID: currentCategory.categoriesID,
    }

    //  console.log({ model })
    changeDateForTableTecnicas.mutate(model)
  }

  const DowloadExcelForPartido = async (item) => {
    let currentCategori = JSON.parse(
      window.localStorage.getItem("currentCategori")
    )
    let category = currentCategori.categoriesID
    let curretTorneo = JSON.parse(window.localStorage.getItem("curretTorneo"))

    let arrayPartido = []

    let unifyModel = {
      nombre: "",
      posicion: "",
      torneo: "",
      rival: "",
      puntaje_ofensivo: "",
      puntaje_defensivo: "",
      puntaje_actitud: "",
      minutos_jugados: "",
      goles: "",
      asistencias: "",
      tarjertas_amarillas: "",
      tarjetas_rojas: "",
      paradas_de_gol: "",
      paradas_de_penales: "",
    }

    let session = JSON.parse(window.localStorage.getItem("_gHtk"))

    let model = {
      clubSessionID: session.sessionID
    }
    let resultPartido = await apiMain.GetEvaluaPartidoSession(model)
    let data = resultPartido.data.message

    for (let index = 0; index < data.length; index++) {
      let element = data[index]

      if (element.categoryID === category && element.rival === item.rival && element.sessionID === item.sessionID) {
        //console.log('data partido', element)
        unifyModel.nombre = element.nombreAtleta
        unifyModel.posicion = element.position
        unifyModel.torneo = element.torneoName
        unifyModel.rival = element.rival
        unifyModel.puntaje_ofensivo = (element.p1 * 10)
        unifyModel.puntaje_defensivo = (element.p2 * 10)
        unifyModel.puntaje_actitud = (element.p3 * 10)
        unifyModel.minutos_jugados = element.p4
        unifyModel.goles = element.p5
        unifyModel.asistencias = element.p6
        unifyModel.tarjertas_amarillas = element.p7
        unifyModel.tarjetas_rojas = element.p8
        unifyModel.paradas_de_gol = element.p9
        unifyModel.paradas_de_penales = element.p10

        arrayPartido.push(unifyModel)

        unifyModel = {
          nombre: "",
          posicion: "",
          torneo: "",
          rival: "",
          puntaje_ofensivo: "",
          puntaje_defensivo: "",
          puntaje_actitud: "",
          minutos_jugados: "",
          goles: "",
          asistencias: "",
          tarjertas_amarillas: "",
          tarjetas_rojas: "",
          paradas_de_gol: "",
          paradas_de_penales: "",
        }
      }
    }

    let name = "Evaluaciones de Competencia"

    api.dowloadexcel(arrayPartido, name)
  }

  const mutationGraphTecnicaForUser = async (item) => {
    let clubSession = JSON.parse(window.localStorage.getItem('_gHtk'))
    let currentCategory = JSON.parse(window.localStorage.getItem('currentCategori'))
    setSessionAtleta(item.extractor)
    let oldData = data

    let model = {
      oldData: oldData,
      sessionID: item.extractor
    }

    changeDateGraphTecnicasForUser.mutate(model)

  }

  const getOVRColor = (score) => {
    if (score <= 54) return "bad"
    if (score >= 55 && score <= 74) return "medium"
    if (score >= 75 && score <= 89) return "little"
    if (score >= 90) return "good"
  }

  const ItemScore = ({
    title = "",
    score = 0,
    showOVR = true,
  }) => (
    <div className="container__header__score">
      <div style={{ color: 'black' }}>
        <b>{title}</b> {showOVR && <small>(ovr)</small>}
      </div>
      <div className={`spanLable ${getOVRColor(score)}`}>{parseInt(score)}%</div>
    </div>
  )

  if (isIdle) {

    if (active) {
      refetch()
    }

    return (
      <div>

      </div>
    )
  }

  if (isFetching) {
    return (
      <Row>
        <Col xs="12">
          <CardSkeleton />
        </Col>
        <Col xs="12">
          <TableSkeleton title="Evaluaciones Técnicas" />
        </Col>
        <Col xs="12">
          <TableSkeleton title="Evaluaciones de Competencias" />
        </Col>
      </Row>
    )
  }

  if (isError) {
    return (<h3>Lo sentimos no se puede cargar la informacion</h3>)
  }

  if (isSuccess) {
    const {
      clubTorneoList,
      torneoAtletaList,
      dataEva,
      curretTorneo,
      activeFilter,
      evaPartidoAtletaList,
      evaPartidoRivalList,
      dataTableFilter,
      chartData,
      charLabel,
      chartFilterRange,
      CharEvatecnica
    } = data.message
    const { arrDateTable, activeFilterTable } = dataTableFilter

    return (
      <>
        <Row>
          <Col lg="6" md="6">
            <Col className="text-left" sm="6">
              <CardTitle tag="h1" className="tittlestyle">
                {" "}
                Evaluaciones Técnicas
              </CardTitle>
            </Col>
            <Row className="mr-1">
              <DataTable
                filterTotal={1}
                filterType={[{
                  type: 'select',
                  multiselect: true,
                  placheolder: activeFilterTable.hasOwnProperty('id') ? activeFilterTable.value : "Seleccione una fecha",
                  data: arrDateTable
                }]}
                idRef={'dataEva'}
                dataTable={dataEva}
                handlerButton={(item) => dowloaddf(item)}
                handlerOnChage={(date) => ChangeTableTecnicaEvaDateFilter(date)}
              />
            </Row>
          </Col>
          {
            dataEva.content.length > 0 ?

              <Col lg="6" md="6">
                <Row style={{ marginTop: '120px' }}>
                  <Card style={{
                    height: "220px",
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column'
                  }}>
                    <BarcharComponent
                      dataConten={[CharEvatecnica]}
                    />
                  </Card>

                </Row>

              </Col> :

              <Col lg="6" md="6">
                <Row style={{ marginTop: '120px' }}>
                  <Card style={{
                    height: "180px",
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column'
                  }}>
                    <span className="spanNoData">Sin datos para mostrar</span>
                  </Card>

                </Row>

              </Col>
          }

        </Row>
        <Row>
          <Col xs="12">
            <Col className="text-left" sm="4">
              <CardTitle tag="h1" className="tittlestyle">

                Evaluaciones de Competencias
              </CardTitle>
            </Col>
            <Row className="mr-1">
              <CustomizableDataTable
                filter={true}
                filterData={[
                  {
                    type: "select",
                    multiselect: false,
                    placheolder: activeFilter.filterPlacholder.torneo <= 0 ? 'Seleccione un torneo' : activeFilter.filterPlacholder.torneo,
                    dataForFilterList: clubTorneoList === undefined ? [] : clubTorneoList,
                    keyExtractor: "torneo",
                  },
                  {
                    type: "select",
                    multiselect: false,
                    placheolder: activeFilter.filterPlacholder.nombre,
                    dataForFilterList: evaPartidoAtletaList,
                    keyExtractor: "sessionID",
                  },
                  {
                    type: "select",
                    multiselect: false,
                    placheolder: activeFilter.filterPlacholder.rival,
                    dataForFilterList: evaPartidoRivalList,
                    keyExtractor: "rival",
                  },
                ]}
                tableType="regular"
                showAction={true}
                showActionExecel={true}
                headerData={[
                  "Nombre del atleta",
                  "Posicion",
                  "Torneo",
                  "Rival",
                  "Fecha de Partido",
                  "Action",
                ]}
                data={torneoAtletaList}
                filterComponent={(item, ref) => ChangeTableCompetenciaFIlter(item, ref)
                  //ChangeDataForFilter(item, ref)
                }
                handlerOnClik={(item) => _loadEvaPartidoByAtletaID(item)
                }
                handlerActionOnCLick={(item) => {
                  if (item === "excel") {
                    DowloadExcelForPartido()
                  }
                }}
              />
            </Row>
          </Col>
        </Row>

        <ModalComponent
          show={isOpen}
          onClose={() => setIsOpen(false)}
          height={'100%'}
          width={'96%'}
          left={false}
          top={'2%'}
          backdropStyle={{ 'backdropFilter': 'blur(6px)' }}

        >
          <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Reportes props={reportData} />
          </Row>

        </ModalComponent>

        <ModalComponent show={showModal} onClose={() => setShowModal(false)} width={'90%'} height={'90%'}>
          <div className="row p-5">
            <div>
              <h2 style={{ color: 'black', marginBottom: '8px' }}>{evaPartidoFilterDatos.nombre}</h2>
              <div className="mb-3">
                <ItemScore
                  title="evaluaciones de competencia"
                  score={ovrAthlete}
                  showOVR={true}
                />
              </div>

            </div>
            <DataTable
              filterTotal={0}
              filterType={{}}
              filterLabel={{
                label: evaPartidoFilterDatos.torneo,
                subLabel: evaPartidoFilterDatos.rival,
              }}
              showFilterLabel={true}
              dataTable={evaPartidoUser}
              idRef={"evaPartidoUser"}
              handlerButton={(data) => {
                DowloadExcelForPartido(userForExcel)
              }}
            />
          </div>
        </ModalComponent>
      </>

    )
  }

}

export default TecnicasTabs
