import httpClient from '../service/index';
import GetDefinition from "../definition";
class MapperService {
    constructor() { }
    async MapperModel(model) {
        // console.log('MODEL ', model)
        try {
            let url = GetDefinition('MapperRoute')

            let result = await this.postData(url[0], model)

           // console.log('Result BD ', result)

            return result;

        } catch (error) {
            console.log('Error en service GetPosition ', error)
            this.response.error = `Error en service GetPosition ${error}`
            this.response.success = false
            return this.response;
        }
    }

    // HTTP SERVICE
    postData(url, model) {
        // console.log(`URL `, url , 'Model ', model)
        return new Promise((resolve) => {
            httpClient.post(url, model)
                .then((result) => {
                    //console.log('API RESULT ', result)
                    resolve(
                        {
                            success: true,
                            error: '',
                            data: result
                        }
                    )
                })
                .catch(error => {
                    console.log('ERROR', error)
                    resolve({
                        success: false,
                        error: error,
                        data: []
                    })
                })
        })
    }

    getData(url, req) {
        return new Promise((resolve) => {
            httpClient.get(url, req)
                .then((result) => {
                    // console.log('Result Get Service ', result)
                    if (result.message.success) {
                        resolve(result)
                    } else {
                        resolve(result)
                    }

                })
                .catch(error => {
                    console.log('ERROR', error)
                    resolve({
                        success: false,
                        error: error,
                        data: []
                    })
                })
        })
    }

    putData(url, model) {
        return new Promise((resolve) => {
            httpClient.put(url, model)
                .then((result) => {
                    //console.log('API RESULT ', result)
                    resolve(
                        {
                            success: true,
                            error: '',
                            data: result
                        }
                    )
                })
                .catch(error => {
                    console.log('ERROR', error)
                    resolve({
                        success: false,
                        error: error,
                        data: []
                    })
                })
        })
    }
}

export default MapperService