export default (state, action) => {
    const { type, payload } = action;
    //console.log('PAYLOAD ',payload)
    switch (type) {
        case "FETCHING_INITIAL_DATA":
            return {
                ...state,
                isFetching: true
            }
        case 'FETCHING_INITIAL_DATA_FAILURE':
            return {
                ...state,
                isFetching: false
            }
        case 'FETCHING_CREDENTIAL_CLUB_DATA':
            return {
                ...state,
                userInfo: payload,
                isFetching: false
            }
        case 'FETCHING_GET_POSITION':
            return {
                ...state,
                positionList: payload,
                isFetching: false
            }
        case 'FETCHING_GET_INFO_CLUB':
            return {
                ...state,
                userInfo: payload,
                isFetching: false
            }
        case 'FETCHING_GET_RANGE_WEEK_RANGE':
            return {
                ...state,
                weekRange: payload,
                isFetching: false
            }
        case "GET_REPORT_BY_CLUB_DETAILS":
            return {
                ...state,
                clubDetails: payload,
                isFetching: false
            }

        case 'FETCHING_GET_FISICAL_TEST':
            return {
                ...state,
                fisicaEvaluacionList: payload,
                isFetching: false
            }
        case 'FETCHING_GET_CATEGORI_LIST':
            return {
                ...state,
                categoryListClub: payload,
                isFetching: false
            }
        case 'FETCHING_GET_RESUM_EVA':
            // console.log('payload', payload)
            return {
                ...state,
                evaluacionesAtletasOver: payload,
                isFetching: false

            }
        case 'FETCHING_GET_CORP_INFO':
            return {
                ...state,
                corpInfo: payload,
                isFetching: false
            }
        case 'FETCHING_GET_MENTAL_INFO':
            return {
                ...state,
                mentalInfo: payload,
                isFetching: false
            }
        case 'FETCHING_GET_MONOCICLO_DATA':
            return {
                ...state,
                microciclo: payload,
                isFetching: false
            }
        case 'FETCHING_GET_LESIONADOS_LIST':
            return {
                ...state,
                lesionadosList: payload,
                isFetching: false
            }
        case 'FETCHING_GET_CORP_INFO_ATLETA':
            return {
                ...state,
                corpInfoAtleta: payload,
                isFetching: false
            }
        case 'FETCHING_GET_EVALUATEC':
            return {
                ...state,
                evaluaTecAtleta: payload,
                isFetching: false
            }
        case 'FETCHING_GET_WIDGET':
            return {
                ...state,
                widGetAtleta: payload,
                isFetching: false
            }
        case 'FETCHING_GET_ATLETA_INFO':
            return {
                ...state,
                atletaInfo: payload,
                isFetching: false
            }
        case 'FETCHING_GET_SPORTID':
            return {
                ...state,
                sportID: payload,
                isFetching: false
            }
        case 'FETCHING_GET_EVALUACION':
            // console.log('payload', payload)
            return {
                ...state,
                evaluacionInfo: payload,
                isFetching: false
            }
        case 'FETCHING_GET_EVALUACION_PARTIDO':
            // console.log('payloadPartido', payload)
            return {
                ...state,
                evaluacionesPartidosClub: payload,
                isFetching: false
            }
        case 'FETCHING_GET_EVALUACION_PARTIDO_USER':
            return {
                ...state,
                evaluacionesPartidosUser: payload,
                isFetching: false
            }
        case 'FETCHING_LOAD_DATA_FOR_FILTER':
            return {
                ...state,
                dataForFilter: payload,
                isFetching: false
            }
        case "FETCHING_LOAD_REPORT_DATA":
            return {
                ...state,
                reporteArr: payload,
                isFetching: false
            }

        case "GET_REPORTS_BY_CLUBID_FOR_CHART":
            return {
                ...state,
                chartReports: payload,
                isFetching: false
            }
            case "GET_NO_CATEGORY":
                return {
                    ...state,
                    noCategory: payload,
                    isFetching: false
                }
        default:
            return state
    }
}