import { jsPDF } from "jspdf";
import domtoimage from 'dom-to-image';
import moment from "moment";
import * as XLSX from 'xlsx'




class HelperValidation {
    constructor() { }
    emailValid(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    dowloadpdf(refElemt, nombre) {
        //console.log('Elemen ', refElemt)
        var node = refElemt
        let name = nombre
        domtoimage
            .toPng(node)
            .then(function (dataUrl) {
                var img = new Image();
                img.src = dataUrl;
                var doc = new jsPDF('p', 'pt', 'letter');
                // doc.internal.pageSize.width = 612
                // doc.internal.pageSize.height = 792;

                var width = 612;
                var heigth = 792
                //console.log(doc)
                doc.addImage(img, "PNG", 0, 0);
                const date = moment.utc(new Date()).format('L');

                const filename = 'report_' + name + '_' + date + ".pdf";
                doc.save(filename);
            })
            .catch(function (error) {
                console.error("oops, something went wrong!", error);
            });
    };

    
    dowloadexcel(model,name) {
        let nombre = name
        let ws = XLSX.utils.json_to_sheet(model);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, name);
        XLSX.writeFile(wb, nombre + '.xlsx');
    }

     checkURL(url) {
        return(url.match(/\.(jpeg|jpg|gif|png)$/) !== null);
        }
    
}


    





export default HelperValidation