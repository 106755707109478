import { useState } from "react";
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink
} from "reactstrap";
const TabsComponent = ({ header, content }) => {
  //  console.log('content', content)
    let [state, setState] = useState({
        activeTab: 0,
    });

    let toggle = (tab) => {
        if (state.activeTab !== tab) {
            setState({
                activeTab: tab,
            });
        }
    };

    return (
        <>
            <Nav tabs>
                {header.map((row, index) => (
                    
                    <NavItem key={`${row}-${index}`}>
                        <NavLink
                            key={`${row}-${index}`}
                            className={state.activeTab === index ? "active" : "disactive"}
                            onClick={() => toggle(index)}
                        >
                            {row}
                        </NavLink>
                    </NavItem>
                ))}
            </Nav>
            <br />
            <TabContent activeTab={state.activeTab}>
                {content.props.children.map((row, index) => (
                    <TabPane key={index} tabId={index}>
                        {row}
                    </TabPane>
                ))}
            </TabContent>
        </>
    );
};

export default TabsComponent;
