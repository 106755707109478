import { useStateCallback } from "hook/useStateCallback";
import React from "react";
import { Button, Col, Row } from "reactstrap";
/* import toast, { Toaster } from 'react-hot-toast';
const notify = () => toast('Here is your toast.'); */
const ButtomCustom = ({ handler = () => { }, title = {}, id, activeBtn }) => {
    const [buttonActive, setButtomActive] = useStateCallback([activeBtn])
    const buttonClickEven = (ref, i) => {
        handler(ref, i)
    }
    return (
        <div className="customButton">
            <Row>
                <Col xs={10}>
                    <div className="custom_header">
                        <p className="custom_header_title">{title}</p>
                    </div>
                </Col>
                <Col xs={2}>
                    <Button className={buttonActive.indexOf(id) >= 0 ? "dd-buttonStyleBtn isActiveBtn" : "dd-buttonStyleBtn"}
                        onClick={() => buttonClickEven(title, id)}      
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            id="Capa_1"
                            data-name="Capa 1"
                            viewBox="0 0 12.67 10.9"
                            fill={buttonActive.indexOf(id) >= 0 ? "#fff" : "#000"}
                            width={10}
                            height={10}>
                            <polygon
                                className="cls-1"
                                points="6.35 10.9 12.64 0 0.06 0 6.35 10.9" /></svg>

                       

                    </Button>
                </Col>

            </Row>
        </div>
    )
}


export default ButtomCustom;