import React, { useState } from "react";
import AreaChartComponent from "components/NewChartArea";
import DataTable from "components/dataTable";
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Container, Row } from "reactstrap";
import Proptypes from 'prop-types';
import { useQuery, useMutation, useQueryClient } from 'react-query'
import EvalucionTable from "components/FisicalTab/dataTable";
import { GetFisicalTabsLoad } from "api/AtletaDashboardTabs/FisicalTabs";
import { MutationFisicalTabs } from "api/AtletaDashboardTabs/MutationFisicalTabsAtleta";
import { CardSkeleton, TableSkeleton } from "components/Skeleton";
import uuid from "react-uuid";
import moment from "moment";
import SingleSelect from "components/customSelect/singleSelect";
import { useEffect } from "react";
import { mutationExportablePdf } from "api/FisicaTabs";
import ModalComponent from "components/DowloadReport/Fisica/Modal";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { BsFillCloudArrowDownFill } from "react-icons/bs"
import ReportFisic from "components/DowloadReport/Fisica/ReportFisic";
import Loading from "react-loading";
import HelperManager from "../../../api/main/MainService";
const api = new HelperManager();


const FisicaTabs = ({ enabled }) => {
    const [reportData, setReportData] = useState({
        infoAtleta: {
            nombre: "",
            ovr: 0,
            logoClub: ""
        },
        evaluacionInfo: [],
        recomendations: [],
        nutricionInfo: []
    })
    const [isOpen, setIsOpen] = useState(false);

    const chartRef = React.useRef(null);
    const queryClient = useQueryClient();
    const handlerQuery = async () => {
        let currentSessionID = window.localStorage.getItem('currentUser')

        let model = {
            sessionID: currentSessionID,
            startDate: "00:00:00",
            endDate: "00:00:00"
        }

        let result = await GetFisicalTabsLoad(model)

        window.localStorage.setItem('clubSession', result.message.clubSession)
        window.localStorage.setItem('categoryID', result.message.categoryID)
        window.localStorage.setItem('date', result.message.weekRange[0].value)

        return result
    }

    // CREAMOS EL ESTADO
    const { data, isError, isSuccess, isLoading, error, refetch } = useQuery('fisicaDataTabsAtleta', () => handlerQuery(), {
        enabled: enabled,
        refetchOnWindowFocus: enabled
    })

    useEffect(() => {
        if (enabled) {
            refetch()
        }
    }, [enabled])


    async function FilterChartDataForDate(weekRange) {
        let userToken = window.localStorage.getItem("currentUser");

        let modelApi = {
            sessionID: userToken,
            startDate: "00:00:00",
            endDate: "00:00:00"
        };

        let model = {
            mutateModel: "chart",
            oldModel: data,
            apiModel: modelApi,
            date: weekRange
        }


        try {
            let result = await MutationFisicalTabs(model)

            return result
        } catch (error) {
            return error
        }

    }

    const chartFilter = useMutation('fisicaDataTabsAtleta', {
        mutationFn: FilterChartDataForDate,
        onSuccess: (response) => {
            queryClient.setQueryData('fisicaDataTabsAtleta', (oldData) => {
                return {
                    ...oldData,
                    message: response.message.message
                }
            })
        }
    })

    const handlerButton = async (key) => {

        changeDataExportableMutation(key)

        // setIsLoad(true);
        setIsOpen(true);
        // setTimeout(() => {
        //     setIsLoad(false);
        // }, 1000);
    }

    const handlerExportablePdf = async (model) => {

        let result = await mutationExportablePdf(model)

        let dwData = result.message.message.evaluacion.dwData

        //SE VALIDA QUE LLEGUE LA INFO PARA CARGAR EL PDF Y SE PROCESA
        if (dwData.length > 0) {

            let result = dwData.filter(row => row.key === model.key.extractor);

            let { img = "", clubFlag = "" } = result[0].infoAtleta;

            let imgUrl = ""; //Initialize image
            let clubFlagUrl = ""; //Initialize image

            if (img.length > 0) {
                let imgB64 = await api.GetImageB64({
                    url: img,
                });
                imgUrl = imgB64.message
            }
            if (clubFlag.length > 0) {
                let clubFlagB64 = await api.GetImageB64({
                    url: clubFlag,
                });
                clubFlagUrl = clubFlagB64.message
            }

            //SE PASA EL SPORT AL QUE PERTENECE
            // let global = JSON.parse(window.localStorage.getItem("globalContext"));

            setReportData({
                infoAtleta: { ...result[0].infoAtleta, imgB64: imgUrl, clubFlagB64: clubFlagUrl },
                evaluacionInfo: result[0].evaDatails,
                recomendations: result[0].recomendacion,
                nutricionInfo: result[0].nutricion,
                sportID: parseInt(data.message.sportID)
            })
        } else {
            return false
        }

        return result.message
    }

    const changeDataExportablePdf = useMutation('fisicaDataTabsAtleta', {
        mutationFn: handlerExportablePdf
    })

    const changeDataExportableMutation = (key) => {

        let club = data.message.clubID
        let clubSession = data.message.clubSession
        let currentCategory = data.message.categoryID
        let dateActive = data.message.weekRange[0].value

        let model = {
            oldData: data,
            clubID: club,
            category: currentCategory,
            key: key,
            clubSessionID: clubSession,
            date: dateActive
        }

        changeDataExportablePdf.mutate(model)
    }

    const handlerFilterChartData = (item) => {
        // console.log('ITEM ', item)
        chartFilter.mutate(item)
    }


    // MENSAJE CUANDO EL COMPONENTE ESTE CARGADON
    if (isLoading) {
        return (
            <Row>
                <Col xs="12">
                    <CardSkeleton />
                </Col>
                <Col xs="12">
                    <TableSkeleton title="Reportes de evaluaciones físicas" />
                </Col>
                <Col xs="12">
                    <TableSkeleton title="Composición Corporal" />
                </Col>
            </Row>
        )
    }

    // VALIDACION CUANDO LA CARGA SEA SUCCESS 
    if (isSuccess) {
        const { tableData } = data.message.evaluacion
        const { chartData, corpData, weekRange } = data.message

        return (
            <>
                <Row>
                    <Col xs="12">
                        <Card className="card-chart">
                            <CardHeader>
                                <Row>
                                    <Col className="text-left" sm="3">
                                        <CardTitle tag="h1" className="tittlestyle">
                                            Evaluaciones Físicas
                                        </CardTitle>
                                    </Col>
                                    <Col sm="9">
                                        <Row className="mr-1">
                                            <Col xs="12">
                                                {
                                                    weekRange.lenght > 0 &&
                                                    <SingleSelect title={weekRange[0].value} item={weekRange} onChange={(item) => handlerFilterChartData(item)} />

                                                }
                                                {
                                                    weekRange.lenght <= 0 &&
                                                    <SingleSelect title={weekRange} item={weekRange} onChange={(item) => handlerFilterChartData(item)} />
                                                }

                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <AreaChartComponent
                                    toolTip={{
                                        current: "Evaluación actual",
                                        last: "Evaluación previa",
                                    }}
                                    data={chartData}
                                    idRef={chartRef}
                                />

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-left" sm="4">
                        <CardTitle tag="h1" className="tittlestyle">
                            Reportes de evaluaciones físicas
                        </CardTitle>
                    </Col>
                    <Col lg="12" md="12">
                        <EvalucionTable
                            filterTotal={0}
                            idRef={"fisicas"}
                            dataTable={tableData}
                            handlerButton={(index) => handlerButton(index)}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col className="text-left" sm="4">
                        <CardTitle tag="h1" className="tittlestyle">
                            Composición Corporal
                        </CardTitle>
                    </Col>
                    <Col lg="12" md="12">
                        <DataTable
                            filterTotal={0}
                            data={corpData}
                            idRef={"tableCorpInfo"}
                        />
                    </Col>
                </Row>

                <ModalComponent
                    show={isOpen}
                    onClose={() => setIsOpen(false)}
                    height={'90%'}
                    width={'90%'}
                    backdropStyle={{ 'backdropFilter': 'blur(6px)' }}
                >
                    <PDFDownloadLink
                        document={<ReportFisic data={reportData} />}
                        fileName={`report_${reportData.infoAtleta.nombre}_${moment.utc(new Date()).format("YYYY-MM-DD")}.pdf`}>
                        <button style={{
                            border: 'none',
                            borderRadius: '20px',
                            background: '#0C162B',
                            color: 'white',
                            padding: '5px 15px',
                            position: 'absolute',
                            top: '5px',
                            left: '10px',
                            fontWeight: 'bold',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px'
                        }}>
                            <BsFillCloudArrowDownFill size="20px" color="white"/>
                            PDF
                        </button>
                    </PDFDownloadLink>
                    {
                        changeDataExportablePdf.isLoading ?
                            <Row style={{ justifyContent:'center'}}>
                                <text className="mr-2">Cargando Información</text>
                                 <Loading type="spin" width="20px" height="20px" color="blue"/>
                            </Row>
                            :
                            <PDFViewer style={{ width: '100%', height: '100%' }} showToolbar={false} >
                                <ReportFisic data={reportData} />
                            </PDFViewer>

                    }

                </ModalComponent>
            </>
        )
    }

    if (isError) {
        return (<div><h3>Ocurrio un error {error?.message}</h3></div>)
    }
}

FisicaTabs.prototype = {
    enabled: Proptypes.bool
}
FisicaTabs.defaultProps = {
    enabled: false
}

export default FisicaTabs;