const Item = () => {
    return (
        <div className="main-box" style={{ width: "200px", height: "20px" }}></div>
    );
};

const TableSkeleton = ({ title= "" }) => {
    return (
        <>
            <h1 className="tittlestyle card-title">{title}</h1>
            <div className="table-card">
                <div className="table-card-warpper">
                    <div style={{ height: "49px" }} />
                    <div className="tab-content-table">

                        <div style={{ padding: "50px 20px", display: "flex", gridGap: "0 20px" }}>
                            <Item />
                            <Item />
                            <Item />
                            <Item />
                            <Item />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TableSkeleton;
