import React from "react";
import {
  BrowserRouter as Router,
  useHistory
} from "react-router-dom";

import "./login.css"
import { Alert, Card, CardBody, Col, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import HelperValidation from "source/handler";
import Manager from 'source/SingIn'
import ManagerRecovery from '../../../src/source/auth/index'
import toast from "react-hot-toast";
import Loading from "react-loading";
import IsSuper from "./IsSuper";


const helper = new HelperValidation();
const api = new Manager();
const apiRecovery = new ManagerRecovery()


export default function AuthExample() {

  const history = useHistory();
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [passwordValid, setPasswordValid] = React.useState(false);
  const [passwordInvalid, setPasswordInvalid] = React.useState(false);
  const [emailValid, setEmailValid] = React.useState(false);
  const [emailInvalid, setEmailInvalid] = React.useState(false);
  const [isSigning, setIsSigning] = React.useState(false);
  const [msg, setMsg] = React.useState('Este campo no puede estar vacio..');
  const [isSuper, setIsSuper] = React.useState(false);
  const [clubList, setClubList] = React.useState([])
  const [forgotPass, setForgotPass] = React.useState(false)
  const [visible, setVisible] = React.useState(false);
  const [security, setSecurity] = React.useState(true)

  React.useEffect(() => {

    let cookieExists = localStorage.getItem('remember')

    if (cookieExists) {
      let emailCookie = localStorage.getItem('email')
      setEmail(emailCookie)

    }

    let userExists = window.localStorage.getItem('_gHtk')
    let isSuper = window.localStorage.getItem('isSuper')

    if (userExists != null && isSuper != null) {
      isSuper = JSON.parse(isSuper)
      setIsSuper(true)
      setClubList(isSuper.clubInfo)
    }

  }, [])


  const LoginPage = async () => {
    if (email === "" || password === "") {
      if (email === "") setEmailInvalid(true)
      if (password === "") setPasswordInvalid(true)
    } else {

      let model = {
        email: email.trim(),
        password: password.trim(),
        type: true
      }

      setIsSigning(true)

      let singInResult = await api.SingIn(model);
      
      console.log('SIng In Result ', singInResult)
      
      if (singInResult.message.success) {

        let modelStorage = {
          email: email,
          password: password
        }

        let dataStorage = await api.GetDataCreateLocalStorage(modelStorage)

        let dataFilter = dataStorage.message

        // PROCESS FOR INSTITUCION
        if (singInResult.message.isSuper) {
          setIsSuper(true)
          setClubList(dataFilter.clublist)
          let localModel = {
            institutionName: dataFilter.institutionName,
            institutionSessionID: dataFilter.institutionSessionID,
            clubInfo: dataFilter.clublist
          }
          // SE CREA LOCAL STORAGE 
          window.localStorage.setItem('isSuper', JSON.stringify(localModel))

        }

        // PROCESS FOR ESPECIALIST
        if (singInResult.message.isEspecial) {

          setIsSuper(true)
          setClubList(dataFilter.clublist)

          let localModel = {
            especialSessionID: dataFilter.sessionIDUser,
            clubInfo: dataFilter.clublist
          }
          // SE CREA LOCAL STORAGE 
          window.localStorage.setItem('isSuper', JSON.stringify(localModel))

        }

        //SE VALIDA EL TIPO DE USUSARIO QUE INICIA SESSION, Y SE CREA LOS LOCAL STORAGE CORRESPONDIENTES
        if (singInResult.message.isCouch || singInResult.message.isClub) {
          //SE CREA LOCAL STORAGE CON LISTAS DE CATEGORIAS
          window.localStorage.setItem('currentCategori', JSON.stringify(dataFilter.categoryID[0]))

          let initModel = {
            "sessionID": dataFilter.data[0].sessionID,
            "sessionCouch": dataFilter.sessionCouch,
            "clubID": dataFilter.data[0].clubID,
            "categoryID": dataFilter.categoryID[0].categoriesID,
            "startDate": 0,
            "endDate": 0
          }

          // SE CREA LOCAL STORAGE _GHTK
          window.localStorage.setItem('_gHtk', JSON.stringify(initModel))

          let globalContext = {
            isCouch: dataFilter.isCouch,
            isClub: dataFilter.isClub,
            isSuper: dataFilter.isSuper,
            categoryID: dataFilter.categoryID,
            sportID: dataFilter.data[0].sportID
          }
          // SE CREA LOCAL STORAGE GLOBAL CONTEX
          window.localStorage.setItem('globalContext', JSON.stringify(globalContext))

          // SE CREA LOCAL STORAGE CURRENT TORNEO 
          window.localStorage.setItem('curretTorneo', JSON.stringify(dataFilter.currentTorneo))

          window.localStorage.setItem('dataForFilter', JSON.stringify(dataFilter.infoAllDataPartido))
          let { from } = { from: { pathname: "/admin/dashboard" } };
          history.replace(from);
        }


      } else {

        setVisible(true)
        setTimeout(() => {
          setVisible(false)
        }, 2200);
      }
    }
  }

  const fieldValidator = (value, target) => {

    let isValid = true
    if (target === 1) {
      isValid = helper.emailValid(value);
      console.log('isvalid', isValid)
      if (!isValid) {
        setEmailInvalid(true)
        setMsg('El formato del email es incorrecto...')
      } else {
        setEmail(value)
        setEmailInvalid(false)
        setEmailValid(true)
      }

    } else {
      setPassword(value)
      setPasswordInvalid(false)
      setPasswordValid(true)
    }
  }


  const showScreenForgot = () => {
    setForgotPass(prev => !prev)
  }

  const recovery = async () => {
    let model = {
      email: email.trim(),
      password: password.trim()
    }

    // source metodo RecoveryPassword
    let result = await apiRecovery.RecoveryPassword(model);
    if (result.message.success) {
      toast.success('Actualizacion realizada')
      window.location.reload()
    } else {
      toast.error('Su password no se pudo actualizar contacte al equipo tecnico ')
    }
    //console.log('RESULT', result)  
  }

  const rememberLogin = (target) => {
    //console.log('target', target)
    if (target === 'on') {
      localStorage.setItem('email', email)
      localStorage.setItem('remember', true)
    }

  }
  const showPassword = () => {
    setSecurity(prev => !prev)
  }



  if (forgotPass) {
    return (
      <div className="outer mx-auto">
        <div className="inner justify-content-center" >
          <Card style={{ background: "transparent", boxShadow: "none" }}>
            <CardBody>
              <div>
                <div>
                  <h3>Recuperar contraseña </h3>
                  <FormGroup>
                    <Label></Label>
                    <Input
                      valid={emailValid}
                      invalid={emailInvalid}
                      placeholder="Ingresa Correo"
                      onBlur={(item) => fieldValidator(item.target.value, 1)}
                    />
                    <FormFeedback>
                      {msg}
                    </FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label></Label>
                    <Input
                      valid={passwordValid}
                      type="text"
                      placeholder="Ingresa contraseña"
                      onBlur={(item) => fieldValidator(item.target.value, 2)}
                    />
                    <button className="showPassword">
                      <i className="tim-icons icon-simple-add"></i>
                    </button>
                    <FormFeedback>
                      {msg}
                    </FormFeedback>
                  </FormGroup>

                  <button className="btn btn-dark btn-lg btn-block" onClick={() => recovery()}>Recuperar</button>
                  <button className="forgot-password text-right text-white" style={{ border: 'none', background: 'none' }} onClick={() => showScreenForgot()}>
                    Regresar <a href="#">Ingresar</a>
                  </button>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>

    );
  }


  if (isSuper) {
    return (
      <IsSuper clubList={clubList} />
    )
  }


  return (
    <div className="outer mx-auto">
      <div className="alert_warrper justify-content-center" >
        <Alert
          className='top'
          color="warning"
          isOpen={visible}
        >
          <h4 className="alert-heading text-white">
            Alerta
          </h4>
          <p className="text-white">
            Verifica tu correo electrónico y contraseña e inténtalo de nuevo
          </p>

        </Alert>
      </div>

      <div className="inner justify-content-center" >
        <Card style={{ background: "transparent", boxShadow: "none" }}>
          <CardBody>
            <div>
              <div>
                <h3>Ingreso</h3>
                <FormGroup>
                  <Label></Label>
                  <Input
                    valid={emailValid}
                    invalid={emailInvalid}
                    placeholder="Ingresar correo"
                    // onBlur={(item) => fieldValidator(item.target.value, 1)}
                    onChange={(e) => fieldValidator(e.target.value, 1)}
                  />
                  <FormFeedback>
                    {msg}
                  </FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label></Label>
                  <Input
                    invalid={passwordInvalid}
                    type={security ? "password" : "text"}
                    placeholder="Ingresar contraseña"
                    // onBlur={(item) => fieldValidator(item.target.value, 2)}
                    onChange={(e) => fieldValidator(e.target.value, 2)}
                  />
                  <button className="showPassword" onClick={() => showPassword()}>
                    {
                      security ? <img src={require('../../assets/img/close_eyes.png')} style={{
                        width: '22px',
                        height: '22px'
                      }} /> : <img src={require('../../assets/img/open_eyes.png')} style={{
                        width: '22px',
                        height: '22px'
                      }} />
                    }

                  </button>
                  <FormFeedback>
                    {msg}
                  </FormFeedback>
                </FormGroup>


                <div className="form-group">
                  <div className="custom-control custom-checkbox">
                    <input type="checkbox" onChange={(e) => rememberLogin(e.target.value)} className="custom-control-input" id="customCheck1" />
                    <label className="custom-control-label" htmlFor="customCheck1" >Recordarme</label>
                  </div>
                </div>
                <button className="btn btn-dark btn-lg btn-block d-flex justify-content-center" onClick={() => LoginPage()}>
                  {
                    isSigning ? (
                      <Loading type="spin" width="20px" height="20px" color="white" />
                    ) : (
                      "Iniciar sesión"
                    )
                  }
                </button>
                <button className="forgot-password text-right text-white" style={{ border: 'none', background: 'none' }} onClick={() => showScreenForgot()}>
                  Olvidé mi <a href="#">contraseña</a>
                </button>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>

  );
}



