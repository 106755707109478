const isPromise = obj => {
    return (
        !!obj &&
        (typeof obj === "object" || typeof obj === "function") &&
        typeof obj.then === "function"
    );
}
const asyncDispatch = dispatch => {
    return action => {
        if (isPromise(action.payload)) {
            action.payload.then(v => {
                dispatch({ type: action.type, payload: v });
            });
        } else {
            dispatch(action);
        }
    };
}

export default asyncDispatch;