/*eslint-disable*/
import React from "react";
import { NavLink, Link, useLocation, useHistory } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";

// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// reactstrap components
import { Nav, NavLink as ReactstrapNavLink } from "reactstrap";
import {
  BackgroundColorContext,
  backgroundColors,
} from "contexts/BackgroundColorContext";
import PopupComponent from "components/popup";
import { PiClipboardTextBold } from "react-icons/pi"
import { FaArrowLeft } from "react-icons/fa"
import { RiLogoutCircleLine } from "react-icons/ri"

var ps;

function Sidebar(props) {
  const location = useLocation();
  const sidebarRef = React.useRef(null);
  let isSuper = window.localStorage.getItem('isSuper')
  isSuper = isSuper != null ? true : false;


  const activeRoute = (routeName) => {
    return location.pathname === routeName ? "active" : "";
  };

  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebarRef.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  });

  const linkOnClick = () => {
    document.documentElement.classList.remove("nav-open");
  };

  const { routes, rtlActive, logo } = props;
  let logoImg = null;
  let logoText = null;
  if (logo !== undefined) {
    if (logo.outterLink !== undefined) {
      logoImg = (
        <a
          href={logo.outterLink}
          className="simple-text logo-mini"
          target="_blank"
          onClick={props.toggleSidebar}
        >
          <div className="logo-img">
            <img src={logo.imgSrc} alt="react-logo" />
          </div>
        </a>
      );
      logoText = (
        <a
          href={logo.outterLink}
          className="simple-text logo-normal"
          target="_blank"
          onClick={props.toggleSidebar}
        >
          {logo.text}
        </a>
      );
    } else {
      logoImg = (
        <Link
          to={logo.innerLink}
          className="simple-text logo-mini"
          onClick={props.toggleSidebar}
        >
          <div className="logo-img">
            <img src={logo.imgSrc} alt="react-logo" />
          </div>
        </Link>
      );
      logoText = (
        <Link
          to={logo.innerLink}
          className="simple-text logo-normal"
          onClick={props.toggleSidebar}
        >
          {logo.text}
        </Link>
      );
    }
  }

  const history = useHistory();

  const LogOut = () => {
    window.localStorage.clear();
    window.location.href = "/auth/login/";
    // history.replace({ pathname: "/auth/login" });
  };


  return (
    <BackgroundColorContext.Consumer>
      {({ color }) => (
        <div className="sidebar" data={color}>
          <div className="sidebar-wrapper" ref={sidebarRef}>
            {logoImg !== null || logoText !== null ? (
              <div className="logo">
                {logoImg}
                {logoText}
              </div>
            ) : null}
            <Nav>
              {routes.map((prop, key) => {
                if (prop.redirect) return null;
                return (
                  <li
                    className={
                      activeRoute(prop.path) + (prop.pro ? " active-pro" : "")
                    }
                    key={key}
                  >
                    <NavLink
                      tag={Link}
                      to={prop.layout + prop.path}
                      className="nav-link d-flex align-items-center"
                      activeClassName="active"
                      onClick={() => props.toggleSidebar}
                    >
                      {prop.icon}
                      <p style={{ marginLeft: '10px' }}>{rtlActive ? prop.rtlName : prop.name}</p>
                    </NavLink>
                    <hr className="divider" />
                  </li>
                );
              })}
              <li
                className="">
                <ReactstrapNavLink
                  className="nav-link d-flex align-items-center"
                  onClick={() => window.location.href = process.env.REACT_APP_DASHBOARD_URL !== undefined ? process.env.REACT_APP_DASHBOARD_URL : 'http://localhost:3006/'}
                >
                  <PiClipboardTextBold size="20px" color="white" />
                  <p style={{ marginLeft: '10px' }}>Evaluaciones</p>
                </ReactstrapNavLink >
                <hr className="divider" />
              </li>
              <li className="active-pro">
                {
                  isSuper ? <ReactstrapNavLink className="d-flex align-items-center">
                    <FaArrowLeft size="15px" color="white"/>
                    <p style={{marginLeft: '10px'}} onClick={() => window.location.href = '/auth/login/'}>Regresar</p>
                  </ReactstrapNavLink> :
                    <ReactstrapNavLink className="d-flex align-items-center">
                      <RiLogoutCircleLine size="20px" color="white" />
                      <p style={{marginLeft: '10px'}} onClick={() => LogOut()}>Cerrar sesión</p>
                    </ReactstrapNavLink>
                }
              </li>
            </Nav>
          </div>
        </div>
      )}
    </BackgroundColorContext.Consumer>
  );
}

Sidebar.defaultProps = {
  rtlActive: false,
  routes: [{}],
};

Sidebar.propTypes = {
  // if true, then instead of the routes[i].name, routes[i].rtlName will be rendered
  // insde the links of this component
  rtlActive: PropTypes.bool,
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the text of the logo
    text: PropTypes.node,
    // the image src of the logo
    imgSrc: PropTypes.string,
  }),
};

export default Sidebar;
