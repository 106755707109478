import React, { useState } from "react";
import {
    Row,
    Col,
    Table,
} from 'reactstrap';


import CustomizableSelect from "components/customSelect/customSelect";
import ButtomCustom from 'components/buttom/index';
import { Button, Tooltip, UncontrolledTooltip } from 'reactstrap';
import styled from "styled-components";
// Be sure to include styles at some point, probably during your bootstraping
import '@trendmicro/react-paginations/dist/react-paginations.css';

import DowloadExcel from '../../assets/Iconos/Excel.svg';
import HelperManager from "../../source/handler/index";
import { useStateCallback } from "hook/useStateCallback";
import { element } from "prop-types";
import toast from 'react-hot-toast';

import moment from "moment";
const api = new HelperManager();

const LabelWarrper = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    align-items: center;
    justify-content: space-evenly;
    width: 179px;
    margin-bottom: -7px;
    border-radius: 8px;
`
const DataTable = ({
    showFilterLabel = false,
    isEditable = false,
    fieldEditable = [],
    filterLabel = {},
    filterTotal = 4,
    label,
    filterType = [],
    data = { header: [], content: [] },
    dataForFilter = [],
    idRef = '',
    handlerButton = () => { },
    handlerOnChage = () => { }
}) => {
    const [ëlement, setElement] = React.useState(null);
    const [excelTable, setExcelTable] = React.useState(null);
    const [disablemin, setDisbleMin] = React.useState(true);
    const [disableMax, setDisableMax] = React.useState(false);
    const [disabledSelect, setDisabledSelect] = React.useState(false);
    const [itemData, setItemData] = useStateCallback({ header: [], content: [] })
    const [filterDataForProcess, setFilterDataForProcess] = React.useState([]);
    const [disabledExcel, setDisabledExcel] = React.useState(false);
    const [spanCol, setSpanCol] = useState(0)
    const [buttonFilterActive, setButtonFilterActive] = useStateCallback({
        filterButtonID: 0
    })
    const [totalFilter, setTotalFilter] = React.useState(() => {
        if (filterTotal > 4) filterTotal = 4
        let array = []
        for (let index = 0; index < filterTotal; index++) {
            array.push(index)
        }
        return array
    })

    const [msg, setMsg] = React.useState('No existen datos para cargar')
    const [dowloadEmpty, setDowloadEmpty] = React.useState(false);
    const [states, setState] = useStateCallback({
        page: 0,
        perPage: 5,
        numberOfPages: 0
    });

    const [tooltipOpen, setToolTipOpen] = React.useState({
        f: false,
        e: false,
        p: false
    });

    React.useEffect(() => {
        //  console.log('data filtrda componen', dataForFilter)
        if (dataForFilter.length > 0) {
            setFilterDataForProcess(dataForFilter)
            setDisabledExcel(true)
        }

    }, [dataForFilter])

    React.useEffect(() => {
        if (data.length <= 0) {
        } else {
            setItemData(data)
            if (data.content.length <= 0) {
                setDisabledSelect(true)
            }
            setState({
                page: 0,
                perPage: 5,
                numberOfPages: Math.ceil((data.content.length) / 5)
            })
            setSpanCol(data.header.length);
        }
    }, [data])

    const pdfDowload = () => {
        if (element !== null) setElement(null)
        let newElem = document.getElementById(idRef)
        setElement(newElem)
        if (idRef === 'tableOvrEva' || idRef === 'tableCorpInfo' || idRef === 'tableMetalInfo') {
            api.dowloadpdf(newElem, idRef);
        }
        //console.log('elem', elem) 
    };

    const excelDowload = () => {
        console.log('DATA EXPORT ', data)
        let name = ''
        switch (idRef) {
            case 'tableOvrEva':
                name = 'Informacion de evaluaciones'
                break;

            case 'tableCorpInfo':
                name = 'Informacion corporal'
                break;

            case 'tableMetalInfo':
                name = 'Informacion mental'
                break;
            case 'atletaCorpInfo':
                name = 'Informacion corporal Atleta'
                break;
            case 'dataEva':
                name = `Reporte ${data.content[0].nombre}`
                break;
            case 'tablePartido':
                name = 'Evaluaciones de partidos'
                break;
            case 'evaPartidoUser':
                name = `Reporte ${data.content[0].nombre}`
                break;
            default:
                return
        }
        if (data.content.length <= 0) {
            setDowloadEmpty(true);
            setTimeout(() => {
                setDowloadEmpty(false);
            }, 2000)
        } else {
            let currentCategori = JSON.parse(window.localStorage.getItem('currentCategori'))
            let category = currentCategori.categoriesID
            let curretTorneo = JSON.parse(window.localStorage.getItem('curretTorneo'))
            //console.log('name torneo condicional', curretTorneo)
            let arrayPartido = []

            let unifyModel = {
                nombre: "",
                posicion: "",
                torneo: "",
                rival: "",
                puntaje_ofensivo: "",
                puntaje_defensivo: "",
                puntaje_actitud: "",
                minutos_jugados: "",
                goles: "",
                asistencias: "",
                tarjertas_amarillas: "",
                tarjetas_rojas: "",
                paradas_de_gol: "",
                paradas_de_penales: ""
            }
            if (idRef === 'tablePartido') {
                for (let index = 0; index < dataForFilter.length; index++) {
                    let element = dataForFilter[index];
                    //   console.log('data partido', element)
                    if (element.categoryID === category && element.torneoID === curretTorneo) {

                        unifyModel.nombre = element.nombreAtleta
                        unifyModel.posicion = element.position
                        unifyModel.torneo = element.torneoName
                        unifyModel.rival = element.rival
                        unifyModel.puntaje_ofensivo = element.p1
                        unifyModel.puntaje_defensivo = element.p2
                        unifyModel.puntaje_actitud = element.p3
                        unifyModel.minutos_jugados = element.p4
                        unifyModel.goles = element.p5
                        unifyModel.asistencias = element.p6
                        unifyModel.tarjertas_amarillas = element.p7
                        unifyModel.tarjetas_rojas = element.p8
                        unifyModel.paradas_de_gol = element.p9
                        unifyModel.paradas_de_penales = element.p10

                        arrayPartido.push(unifyModel);

                        unifyModel = {
                            nombre: "",
                            posicion: "",
                            torneo: "",
                            rival: "",
                            puntaje_ofensivo: "",
                            puntaje_defensivo: "",
                            puntaje_actitud: "",
                            minutos_jugados: "",
                            goles: "",
                            asistencias: "",
                            tarjertas_amarillas: "",
                            tarjetas_rojas: "",
                            paradas_de_gol: "",
                            paradas_de_penales: ""
                        }


                    }
                }

                api.dowloadexcel(arrayPartido, name)

            } else {

                api.dowloadexcel(data.content, name);
            }

        }

    };

    const ChangeData = (valuePosition) => {

        let modelEvaPartido = {
            header: ['Nombre del atleta', 'Posicion', 'Torneo', 'Rival', 'Fecha de Partido', 'Action'],
            content: []
        }

        let contentPartido = {
            nombreAtleta: '',
            posicion: '',
            torneo: '',
            rival: '',
            fechaPartido: '',
            action: {
                type: 'buttom',
                key: ''
            }
        }
        ///////////////////////////////////////////////////////////////
        let dataModelHeader = [...filterDataForProcess]

        let category = valuePosition.id

        if (idRef === 'tablePartido') {

            /////// Se sobrescribe modelo local del currentCategori
            window.localStorage.removeItem('curretTorneo')
            window.localStorage.setItem('curretTorneo', JSON.stringify({ categoriesID: valuePosition.id }))

            if (dataModelHeader.length > 0) {
                for (let index = 0; index < dataModelHeader.length; index++) {
                    let elemPartido = dataModelHeader[index];
                    //  console.log('model header', elemPartido)
                    if (elemPartido.torneoID === valuePosition.id && elemPartido.categoryID === category) {
                        contentPartido.nombreAtleta = elemPartido.nombreAtleta
                        contentPartido.posicion = elemPartido.position
                        contentPartido.torneo = elemPartido.torneoName
                        contentPartido.rival = elemPartido.rival
                        contentPartido.fechaPartido = moment(new Date(elemPartido.matchDate)).format('YYYY-MM-DD')
                        contentPartido.action.key = {
                            sessionID: elemPartido.sessionID,
                            rival: elemPartido.rival
                        }
                        modelEvaPartido.content.push(contentPartido)

                        contentPartido = {
                            nombreAtleta: '',
                            posicion: '',
                            torneo: '',
                            rival: '',
                            fechaPartido: '',
                            action: {
                                type: 'buttom',
                                key: ''
                            }
                        }

                    }

                }

                filterType[0].placheolder = valuePosition.value
                setItemData(modelEvaPartido)
                // console.log('model eva partido', modelEvaPartido)
            }



        } else {

            let content = { ...data }
            //   console.log('data excel p', data)
            let newContent = content.content.map((task) => {
                if (task[filterType[0].keyExtractor].indexOf(valuePosition.value) !== -1) {
                    return task
                }

            }).filter((task) => {
                return task !== undefined
            })
            content.content = newContent
            filterType[0].placheolder = valuePosition.value
            //console.log('newContent', content)
            setItemData(content)
        }
    }


    const ChangeDataButtom = (item, id) => {
        let content = { ...data }
        // console.log('contendPerfil', content)
        let list = content.content
        //console.log('list', list)
        if (buttonFilterActive.filterButtonID === id) {
            setItemData({ header: [], content: [] }, (state) => {
                setItemData(content)
                setState({
                    page: 0,
                    perPage: 5,
                    numberOfPages: Math.ceil((content.content.length) / 5)
                })
            })

            setButtonFilterActive({ filterButtonID: 0 })
        } else {
            let newModel = list.filter((task) => {
                if (task.perfil === item) return task
            })

            if (newModel.length === 0) {
                //alert('Perfil no encontrado')
                toast.error('Ups..., no encontramos ningún atleta con este perfil')
                setButtonFilterActive(false)
            } else {
                content.content = newModel
                //console.log('newmodel', newModel)
                setItemData({ header: [], content: [] }, (state) => {
                    setItemData(content)
                    setState({
                        page: 0,
                        perPage: 5,
                        numberOfPages: Math.ceil((content.content.length) / 5)
                    })
                })

                setButtonFilterActive({ filterButtonID: id })
            }


        }


    }

    const returnTr = (data) => {
        //console.log('data table', data)
        data = Object.entries(data);
        return (
            <tr key={parseInt(Math.random(100 * 5).toString().replace('.', 0))}>
                {data.map((task, d) => {
                    if (typeof task[1] === 'object') {
                        return (
                            <td key={parseInt(Math.random(100 * 5).toString().replace('.', 0))} className="text-center">
                                <Button
                                    onClick={() => handlerButton(task[1].key, data)}
                                    id='filter'
                                    className="btn-icon btn-success btn-round" size='sm' >
                                    <i className="tim-icons icon-zoom-split"></i>

                                </Button>
                            </td>
                        )
                    } else {
                        if ((d >= 4 && d <= 5)) {
                            return (
                                <td key={parseInt(Math.random(100 * 5).toString().replace('.', 0))} className="text-center">
                                    {
                                        label ? <span className={(parseInt(task[1]) <= 54) ? 'text-center bad spanLabel' : (parseInt(task[1]) >= 55 && parseInt(task[1]) <= 74) ? 'text-center medium spanLabel' : (parseInt(task[1]) >= 75 && parseInt(task[1]) <= 89) ? "text-center little spanLabel" : "text-center good spanLabel"}>{task[1]}</span> :
                                            task[1]
                                    }
                                </td>
                            )
                        } else {
                            return (
                                <td key={parseInt(Math.random(100 * 5).toString().replace('.', 0))} className={task[0]==="nombre" ? "pl-4" : "text-center"}>
                                    {
                                        label ? <span className={'text-center'}>{task[1]}</span> :
                                            task[1]
                                    }
                                </td>
                            )
                        }
                    }
                })
                }
            </tr>
        )
    }

    const paginations = (action) => {
        let page = states.page
        let totalIndex = states.numberOfPages - 1


        if (action) {
            page = page + 1
            page = page >= totalIndex ? totalIndex : page
        } else {
            page = page - 1
            page = page <= 0 ? 0 : page
        }

        if (page <= 0) {
            setDisbleMin(true)
            setDisableMax(false)
        }
        if (page >= totalIndex) {
            setDisbleMin(false)
            setDisableMax(true)
        }
        if (page >= 0 && page < totalIndex) {
            setDisbleMin(false)
            setDisableMax(false)
        }
        //console.log('Total Index',totalIndex,'PAGE',page)
        setState({ ...states, page });

    }


    return (
        <div className="table-card">
            <div className="table-card-warpper">
                <div>
                    <Row>
                        <Col sm="12">
                            <Row className="flex-row-center ml-3">
                                {
                                    totalFilter.length <= 0 ? <Col xs={3} className='mt-4'>{
                                        showFilterLabel ? <LabelWarrper>
                                            <h5>{filterLabel.label}</h5> <span> - </span> <h5>{filterLabel.subLabel}</h5>
                                        </LabelWarrper> : <Col className="mt-4"></Col>
                                    }</Col> :
                                        totalFilter.map((task, i) => {
                                            //  console.log('filterType', i)
                                            if (filterType.length > 0) {
                                                let config = filterType[i]
                                                if (config.type === 'select') {
                                                    //  console.log(' DATA ', config)
                                                    return (
                                                        <Col key={parseInt(Math.random(100 * 5).toString().replace('.', 0))} xs='3'>
                                                            <CustomizableSelect title={config.placheolder} item={config.data.posicion} multiselect={config.multiselect} noData={disabledSelect} onChange={(item, ref) => ChangeData(item, ref)} />
                                                        </Col>

                                                    )
                                                }
                                                if (config.type === 'buttom') {
                                                    return (

                                                        <Col key={parseInt(Math.random(100 * 5).toString().replace('.', 0))} xs='3'>
                                                            <ButtomCustom title={config.placheolder} id={i + 1} handler={(item, id) => ChangeDataButtom(item, id)} activeBtn={buttonFilterActive.filterButtonID} />
                                                        </Col>
                                                    )
                                                }
                                            } else {
                                                return (
                                                    <Col key={parseInt(Math.random(100 * 5).toString().replace('.', 0))}
                                                        xs='3'>
                                                        <CustomizableSelect title='Selecione un item' item={filterType.length > 0 ? filterType[0].data : []} />
                                                    </Col>
                                                )

                                            }
                                        })
                                }

                            </Row>

                        </Col>
                    </Row>


                    <div className="tab-content-table" id={idRef}>
                        <Table className="tablesorter" responsive>
                            <thead className="text-primary">
                                {
                                    itemData.header.length > 0 ?
                                        <tr>
                                            {
                                                itemData.header.map((task, i) => {
                                                    return (
                                                        <th key={parseInt(Math.random(100 * 5).toString().replace('.', 0))} className="text-center">{task}</th>
                                                    )
                                                })
                                            }
                                        </tr> :
                                        <tr>
                                            <th className="text-center">Name</th>
                                            <th className="text-center">Country</th>
                                            <th className="text-center">City</th>
                                            <th className="text-center">Salary</th>
                                        </tr>
                                }

                            </thead>
                            <tbody>
                                {
                                    itemData.content.length > 0 ?
                                        itemData.content.slice(states.perPage * states.page, states.perPage * (states.page + 1)).map(task => returnTr(task))
                                        :
                                        <tr>
                                            <td colSpan={spanCol}>
                                                <Row className="justify-content-center align-items-center w-100">
                                                    <span style={{ background: '#ff8d7296', color: '#000', padding: '8px', borderRadius: '8px', fontSize: '10px' }}>{msg}</span>
                                                </Row>
                                            </td>
                                        </tr>
                                }


                            </tbody>
                        </Table>
                        <Row style={{ padding: "10px" }}>
                            <Col>
                                <div style={{ display: "flex" }}>
                                    <div style={{ marginRight: "5px" }}>
                                        {/* <Button className="pagination-btn-filter" id={'Tooltip-Filter'}>
                                            <img src={Filter} style={{ width: "20px", height: "20px" }} />
                                        </Button>
                                        <UncontrolledTooltip placement="top" target='Tooltip-Filter' >
                                            Filtros
                                        </UncontrolledTooltip > */}
                                    </div>
                                    {
                                        idRef !== 'evaPartidoUser' && disabledExcel &&
                                        <div style={{ marginRight: "5px" }}>
                                            <Button className="pagination-btn-filter" id={'Tooltip-Excel'} onClick={() => excelDowload()}>
                                                <img src={DowloadExcel} style={{ width: "20px", height: "20px" }} />
                                            </Button>
                                            <UncontrolledTooltip placement="top" target={'Tooltip-Excel'}  >
                                                Descargar Excel
                                            </UncontrolledTooltip >
                                        </div>
                                    }
                                    {/*  <div style={{ marginRight: "5px" }}>
                                        <Button className="pagination-btn-filter" id={'Tooltip-Excel'} onClick={() => excelDowload()}>
                                            <img src={DowloadExcel} style={{ width: "20px", height: "20px" }} />
                                        </Button>
                                        <UncontrolledTooltip placement="top" target={'Tooltip-Excel'}  >
                                            Descargar Excel
                                        </UncontrolledTooltip >
                                    </div> */}
                                    {/* <div style={{ marginRight: "5px" }}>
                                        <Button className="pagination-btn-filter" id={'Tooltip-PDF'} onClick={() => pdfDowload()}>
                                            <img src={DowloadPDF} style={{ width: "20px", height: "20px" }} />
                                        </Button>
                                        <UncontrolledTooltip placement="top" target={'Tooltip-PDF'}  >
                                            Descargar PDF
                                        </UncontrolledTooltip >
                                    </div> */}
                                    {
                                        dowloadEmpty && <div style={{ marginRight: "5px", marginTop: '11px' }}>
                                            <span style={{ background: '#ff8d7296', color: '#000', padding: '8px', borderRadius: '8px', fontSize: '10px' }}>{msg}</span>
                                        </div>

                                    }

                                </div>
                            </Col>
                            <Col className="pagination-Col-Right">
                                <div className="pagination-warpper">
                                    <button className="pagination-btn" onClick={() => paginations(false)}>
                                        {`<`}
                                    </button>
                                    <span>{`${(states.page + 1) === 0 ? 1 : (states.page + 1)} of ${states.numberOfPages === 0 ? 1 : states.numberOfPages}`}</span>
                                    <button className="pagination-btn" onClick={() => paginations(true)}>
                                        {`>`}
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>

            </div>
        </div>
    )
}


export default DataTable;