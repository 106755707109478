import { Row, Col, Button } from "reactstrap";
import { TextSkeleton, ImageSkeleton } from "components/Skeleton";
import overlay from "../../assets/img/flag/escudoOp.png";

const getOVRColor = (score) => {
    if (score <= 54) return "bad";
    if (score >= 55 && score <= 74) return "medium";
    if (score >= 75 && score <= 89) return "little";
    if (score >= 90) return "good";
};

const ItemScore = ({
    title = "",
    score = 0,
    showOVR = true,
    type = "number",
}) => (
    <div className="container__header__score">
        <div className="text-white">
            <b>{title}</b> {showOVR && <small>(ovr)</small>}
        </div>
        {type == "number" ? (
            <div className={`spanLable ${getOVRColor(score)}`}>{parseInt(score)}%</div>
        ) : (
            <div className="spanLable text-white">{score}</div>
        )}
    </div>
);

const AtletaHeaderInfo = ({
    cover = "",
    title = "",
    subtitle = "",
    sportAndposition,
    subtitle1 = "",
    subtitle2 = "",
    roles = {},
    list = [],
    actionButton = undefined,
    isLoading = true,
}) => {
    const imageSRC = overlay;
    const { dt = undefined, pf = undefined } = roles;
    
    return (
        <Row>
            <Row className="headerbackground">
                <img alt="Imagen de perfil" src={imageSRC} height="200" width="200" />
            </Row>
            <Col md="12">
                <div className="header_navbar">
                    {actionButton && (
                        <div className="container__header__button">
                            <Button
                                className="buttonStyleEdit"
                                onClick={() => actionButton()}
                            >
                                <i className="tim-icons icon-pencil"></i>
                            </Button>
                        </div>
                    )}

                    <div className="container__header">
                        <div className="container__header__details">
                            {isLoading ? (
                                <ImageSkeleton opacity="0.2" />
                            ) : (
                                <img
                                    src={cover}
                                    height="150px"
                                    width="150px"
                                    className="Imagestyle"
                                />
                            )}
                            <div>
                                <h1 className="maintitle title-mb">
                                    {isLoading ? (
                                        <TextSkeleton height="20px" opacity="0.2" />
                                    ) : (
                                        title
                                    )}
                                </h1>
                                <h3 className="title-mb">
                                    {isLoading ? (
                                        <TextSkeleton height="20px" opacity="0.2" />
                                    ) : (
                                        subtitle
                                    )}
                                </h3>
                                <h4 className="title-mb">
                                    {isLoading ? (
                                        <TextSkeleton height="20px" opacity="0.2" />
                                    ) : (
                                        subtitle1
                                    )}
                                </h4>
                                <h4 className="title-mb">
                                    {isLoading ? (
                                        <TextSkeleton height="20px" opacity="0.2" />
                                    ) : (
                                        sportAndposition
                                    )}
                                </h4>
                                <h4 className="title-withe">{isLoading ? "" : subtitle2}</h4>
                                <div className="header__dt_pf">
                                    {isLoading ? (
                                        <TextSkeleton width="100px" height="20px" opacity="0.2" />
                                    ) : (
                                        dt && (
                                            <div className="text-white">
                                                <b>DT: </b>
                                                {dt}
                                            </div>
                                        )
                                    )}
                                    {isLoading ? (
                                        <TextSkeleton width="100px" height="20px" opacity="0.2" />
                                    ) : (
                                        pf && (
                                            <div className="text-white">
                                                <b>PF: </b>
                                                {pf}
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="container__header__ovrs">
                            {isLoading ? (
                                <>
                                    <TextSkeleton width="200px" height="20px" opacity="0.2" />
                                    <TextSkeleton width="200px" height="20px" opacity="0.2" />
                                    <TextSkeleton width="200px" height="20px" opacity="0.2" />
                                    <TextSkeleton width="200px" height="20px" opacity="0.2" />
                                    <TextSkeleton width="200px" height="20px" opacity="0.2" />
                                    <TextSkeleton width="200px" height="20px" opacity="0.2" />
                                </>
                            ) : (
                                <>
                                    {list.map((row, index) => {
                                        if (row.score > 0 || row.type == "text") {
                                            return (
                                                <ItemScore
                                                    key={index}
                                                    title={row.title}
                                                    score={row.score}
                                                    type={row.type ? "text" : "number"}
                                                    showOVR={row.quitOVR ? false : true}
                                                />
                                            );
                                        }
                                    })}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default AtletaHeaderInfo;
