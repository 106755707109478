import { Text, View, StyleSheet } from "@react-pdf/renderer";
import React from "react";

const styles = StyleSheet.create({
    content: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: '50px'
    },
    row: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: '10px'
    },
    iris: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '40px',
    },
    irisLine: {
        width: '100%',
        height: '4px',
        backgroundColor: '#dee4ec',
        borderRadius: '2px',
    },
    irisLineActive: {
        position: 'relative',
        height: '4px',
        backgroundColor: 'red',
        borderRadius: '2px',
    },
    irisCircle: {
        position: 'absolute',
        top: '-5px',
        right: '-7px',
        width: '14px',
        height: '14px',
        borderRadius: '7px',
        backgroundColor: 'white',
        border: '3px solid red'
    },
    irisPopup: {
        position: 'absolute',
        top: '-30px',
        left: '100%',
        transform: 'translateX(-19px)'
    },
    irisPopupText: {
        fontSize: '10px',
        padding: '3px 9px',
        backgroundColor: 'red',
        borderRadius: '4px',
    },
    gridArea: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: '5px'
    },
    gridItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '3px'
    },
    gridSeparator: {
        width: '1px',
        height: '3px',
        backgroundColor: 'white'
    },
    msgArea: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: '5px',
        padding: '10px',
        backgroundColor: '#ce2d1259',
        borderRadius: '3px',
        marginTop: '20px'
    }
})

const InputRange = ({ ratio = 0, msg = '' }) => {
    const [percent, setPercent] = React.useState(0);
    const [cr, setCR] = React.useState(0);

    React.useEffect(() => {
        if (ratio > 0) {
            let calc = Math.round((ratio*100)/20)

            setCR(ratio)
            setPercent(calc)
        }
    }, [ratio])

    return (
        <View style={styles.content}>
            <View style={styles.row}>
                <Text>Resistencia</Text>
                <Text>Potencia</Text>
            </View>
            <View style={styles.iris}>
                <View style={styles.irisLine}>
                    <View style={{...styles.irisLineActive, width: `${percent}%)`}}>
                        <View style={styles.irisCircle}></View>
                        <View style={styles.irisPopup}>
                            <Text style={styles.irisPopupText}>{`${cr}cr`}</Text>
                        </View>
                    </View>
                </View>
            </View>
            <View style={styles.gridArea}>
                <View style={styles.gridItem}>
                    <View style={styles.gridSeparator}></View>
                    <Text style={{fontSize: '8px'}}>0</Text>
                </View>
                <View style={styles.gridItem}>
                    <View style={styles.gridSeparator}></View>
                    <Text style={{fontSize: '8px'}}>5</Text>
                </View>
                <View style={styles.gridItem}>
                    <View style={styles.gridSeparator}></View>
                    <Text style={{fontSize: '8px'}}>10</Text>
                </View>
                <View style={styles.gridItem}>
                    <View style={styles.gridSeparator}></View>
                    <Text style={{fontSize: '8px'}}>15</Text>
                </View>
                <View style={styles.gridItem}>
                    <View style={styles.gridSeparator}></View>
                    <Text style={{fontSize: '8px'}}>20</Text>
                </View>
            </View>
            <View style={styles.msgArea}>
                <Text style={{fontSize: '10px'}}>{msg.p1}</Text>
                <Text style={{fontSize: '10px'}}>{msg.p2}</Text>
            </View>
        </View>
    )
}

export default InputRange;