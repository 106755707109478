import './styles.css'
import React, { useEffect, useState } from 'react'
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown
} from "reactstrap"
import PropTypes from "prop-types"
import Modal from "../../Modal"
import Atletaheadercomponent from "components/Header/Atleta-header";
import TabsComponent from "components/Tabs";
import GeneralTabs from "components/ContentTabsAtleta/GeneralTabs";
import FisicaTabs from "components/ContentTabsAtleta/FisicaTabs";
import TecnicasTabs from "components/ContentTabsAtleta/TecnicaTabs";
import ReportTabs from "components/ContentTabsAtleta/ReporteTabs";
import uuid from 'react-uuid/uuid'
import { RiUserSearchFill } from "react-icons/ri"

const GlobalTable = ({ headerData, data, listTitle, searchField, typeButton }) => {
  const [states, setState] = useState({
    page: 0,
    perPage: 5,
    numberOfPages: 0
  })
  const [disableMin, setDisableMin] = useState(true)
  const [disableMax, setDisableMax] = useState(true)
  const [searchAthleteValue, setSearchAthleteValue] = useState('')
  const [selectAll, setSelectAll] = useState(false)
  const [searchedAthletes, setSearchedAthletes] = useState([])
  const [tokenAthlete, setTokenAthlete] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [enabled, setEnabled] = useState(false)
  const [filterActive, setFilterActive] = useState('')
  const filterKeys = [
    {
      id: 'nombre',
      label: 'Nombre'
    },
    {
      id: 'clubName',
      label: 'Club'
    },
    {
      id: 'categoriesName',
      label: 'Categoria'
    },
    {
      id: 'edad',
      label: 'Edad'
    },
    {
      id: 'ovr',
      label: 'Ovr'
    },
    {
      id: 'position',
      label: 'Posición'
    },
  ]

  // LOAD DATA IS CHANGE
  useEffect(() => {
    if (data.length > 0) {
      setState({ ...states, numberOfPages: Math.ceil((data.length) / 5) })
      setSearchedAthletes(data)
    }

    if (data.length > 5) {
      setDisableMax(false)
    }
  }, [data])

  useEffect(() => {
    if (tokenAthlete.length > 0) {
      setSelectAll(true)
    } else {
      setSelectAll(false)
    }
  }, [tokenAthlete])

  // FUNCTION pagination
  const pagination = (action) => {

    let page = states.page
    let totalIndex = states.numberOfPages

    if (action) {
      page = page + 1
      page = page >= totalIndex ? totalIndex : page
    } else {
      page = page - 1
      page = page <= 0 ? 0 : page
    }

    if (page <= 0) {
      setDisableMin(true)
      setDisableMax(false)
    }
    if (page >= (totalIndex - 1)) {
      setDisableMin(false)
      setDisableMax(true)
    }
    if (page > 0 && page < (totalIndex - 1)) {
      setDisableMin(false)
      setDisableMax(false)
    }

    setState({ ...states, page })

  }

  const onChangeSearchAthlete = (e) => {

    setSearchAthleteValue(e.target.value)
    setState({ ...states, page: 0 })

    if (e.target.value) {
      const newData = data.filter(athlete => (
        athlete[filterActive.id].toString().toLowerCase().trim().includes(e.target.value.toLowerCase().trim())
      ))
      setSearchedAthletes(newData)
    } else {
      setSearchedAthletes(data)
    }
  }

  const onClickBtnSend = (athlete) => {
    console.log(athlete)
    window.localStorage.setItem('currentUser', athlete.userSession)
    setTimeout(() => {
      setEnabled(true)
      setShowModal(true)
    }, 200);
  }

  const clickBtn = () => {
    setShowModal(!showModal)
  }

  return (
    <>
      <h1 className="tittlestyle card-title">{`Listado de ${listTitle}`}</h1>
      <div className="table-card">
        <div className="table-card-warpper">
          <div>
            <Row>
              <Col sm="12">
                <Row className="flex-row-center ml-3">
                  <Col xs="3">
                    <div className="d-flex align-items-flex-end">
                      <div className='input-search-athlete-group'>
                        {
                          filterActive && (

                            <input
                              type={(filterActive.id === 'ovr' || filterActive.id === 'edad') ? 'number' : 'search'}
                              size="sm"
                              className="input-search-athlete"
                              placeholder={`Buscar ${filterActive.label}...`}
                              value={searchAthleteValue}
                              onChange={onChangeSearchAthlete}
                            />

                          )
                        }
                        <UncontrolledDropdown
                          style={{ display: 'flex', padding: 0 }}
                          direction="down"
                          size='sm'
                        >
                          <DropdownToggle
                            caret
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              background: 'transparent',
                              border: 'none',
                              borderRadius: '10px',
                              color: 'gray',
                              margin: 0,
                              width: filterActive ? '10px' : '200px',
                              transition: 'none'
                            }}
                          >
                            {filterActive ? 'v' : 'Buscar por...'}
                          </DropdownToggle>
                          <DropdownMenu>
                            {
                              filterKeys.map(n => (
                                <DropdownItem
                                  key={uuid()}
                                  onClick={() => setFilterActive(n)}
                                  style={{ color: filterActive === n && 'black' }}
                                >
                                  {n.label}
                                </DropdownItem>
                              ))
                            }
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            <div className="tab-content-table">
              <Table className="tablesorter" responsive>
                <thead className="text-primary">
                  <tr>
                    {
                      headerData.map((title, index) => (
                        <th
                          scope="col"
                          key={`th-${index}`}
                          style={{ width: `${(100 / headerData.length)}%`, minWidth: 10 }}
                          className="text-center"
                        >
                          {title.label}
                        </th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {
                    searchedAthletes.length > 0 ?
                      searchedAthletes.slice(states.perPage * states.page, states.perPage * (states.page + 1)).map((athlete, index) => (
                        <tr key={`tr-${index}`} onClick={() => console.log(athlete.nombre)}>
                          {headerData.map((title, index) => (
                            <td
                              key={`td-${index}`}
                              className={title?.position ? "pl-4" : "text-center"}
                              style={{ 'whiteSpace': 'nowrap' }}
                            >
                              {
                                title.id === 'button' && typeButton === 'sendButton' ?
                                  <Button
                                    onClick={() => onClickBtnSend(athlete)}
                                    id="filter"
                                    className="btn-icon btn-dark btn-round" size="sm">
                                    <RiUserSearchFill size="20px" color="white" />
                                  </Button>
                                  :
                                  athlete[title.id]
                              }
                            </td>
                          ))}
                        </tr>
                      )
                      )
                      :
                      <tr>
                        <td colSpan={headerData.length > 0 ? headerData.length : 4}>
                          <Row className="justify-content-center align-items-center w-100">
                            <span style={{
                              background: '#ff8d7296',
                              color: '#000',
                              padding: '8px',
                              borderRadius: '8px',
                              fontSize: '10px'
                            }}>No existen datos para cargar</span>
                          </Row>
                        </td>
                      </tr>
                  }
                </tbody>

              </Table>

              <Row style={{ padding: "10px" }}>

                <Col className="pagination-Col-Right">
                  <div className="pagination-warpper">
                    <button
                      className="pagination-btn"
                      onClick={() => pagination(false)}
                    >
                      {`<`}
                    </button>
                    <span>{`${states.page + 1 === 0 ? 1 : states.page + 1} of ${states.numberOfPages === 0 ? 1 : states.numberOfPages
                      }`}</span>
                    <button
                      className="pagination-btn"
                      onClick={() => pagination(true)}
                    >
                      {`>`}
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showModal} height={'90%'} width={'100%'} onClose={() => clickBtn()} className="clickeableModal"
        modalStyle={{ backgroundColor: "#F0F1F7" }}>
        {
          showModal ? 
          <div className="content">
            <Row>
              <Col xs="12">
                <Atletaheadercomponent />
              </Col>
            </Row>
            <TabsComponent
              header={["Generales", "Físicas", "Técnicas", "Aspectos Complementarios"]}
              content={
                <div>
                  <GeneralTabs enabled={enabled} />
                  <FisicaTabs enabled={enabled} />
                  <TecnicasTabs enabled={enabled} />
                  <ReportTabs enabled={enabled} />
                </div>
              }
            />
          </div> : null
        }


      </Modal>
    </>
  )
}

GlobalTable.propTypes = {
  headerData: PropTypes.array,
  data: PropTypes.array,
  listTitle: PropTypes.string,
  searchField: PropTypes.string,
}

GlobalTable.defaultProps = {
  headerData: [],
  data: [],
  listTitle: '',
  searchField: '',
  typeButton: 'sendButton'
}

export default GlobalTable


