import React from "react";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highcharts3d from "highcharts/highcharts-3d";
highcharts3d(Highcharts);

const HighChart3D = ({data=[]}) => {
    const options = {
        chart: {
            type: 'column',
            options3d: {
                enabled: true,
                alpha: 10,
                beta: 25,
                depth: 70
            }
        },
        title: {
            text: 'Microciclos'
        },
        subtitle: {
            text: 'Esta constituido por la sesiones de entrenamieto de la semana '
        },
        plotOptions: {
            column: {
                depth: 25
            }
        },
        xAxis: {
            categories:['Semana 1', 'Semana 2', 'Semana 3', 'Semana 4'],
            labels: {
                skew3d: true,
                style: {
                    fontSize: '16px'
                }
            }
        },
        yAxis: {
            title: {
                text: null
            }
        },
        tooltip: {
            headerFormat: '<b>{point.key}</b><br>',
            pointFormat: '<span style="color:{series.color}">\u25CF</span> {series.name}: {point.y}%'
        },
        series: [{
            name: 'Ciclo',
            data: data
        }]
    }
    return (
        <HighchartsReact highcharts={Highcharts} options={options} />

    )

}

export default HighChart3D;