import React, { useState, useRef } from 'react';
import { Button, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledButtonDropdown, Row, Col } from 'reactstrap';

const Dropdown = ({ title = '', activeItem = {}, item = [], multiSelect = false, onChange = () => { }, noData = false }) => {

    const [open, setOpen] = useState(false);
    const [selection, setSelection] = useState({});
    const [selectedItem, setSelectedItem] = useState('');
    const [data, setData] = React.useState([]);
    const toggle = () => {
        setOpen(prev => !prev)
    };


    //Dropdown.handleClickOutside = () => setOpen(false);
    React.useEffect(() => {
        setSelectedItem(title)
        setData(item)
        setSelection({ ...activeItem })
    }, [])

    React.useEffect(() => {
        if (item.length > 0) {
            if (title.hasOwnProperty('value')) {
              //  console.log('TITLE ', title)
                setSelectedItem(title.value)
            } else {
              //  console.log('TITLE ', title)
                setSelectedItem(title)
            }

            setSelection({ ...activeItem })
            setData(item)
        }
    }, [item])


    const handleOnClick = (item) => {
        let selected = selection
        //console.log('SELECTED ', selected)
        if (selected.id !== item.id) {
            setSelection({ ...item })
            // console.log('ITEM VALUE ', item.value)
            setSelectedItem(item.value)
            // if (!multiSelect) {

            // } else if (multiSelect) {
            //     setSelection([...selection, item]);
            //     setSelectedItem(prev => {
            //         prev = item.value
            //         return prev
            //     })
            // }
        } else {
            let selectionAfterRemoval = selected;
            selectionAfterRemoval = selectionAfterRemoval.filter(
                current => current.id != item.id
            );
            setSelection({ ...selectionAfterRemoval });
        }
        onChange(item)
    }

    const isItemInSelection = (item) => {
        if (selection.id === item.id) {
            return true;
        }
        return false;
    }

    return (
        <UncontrolledButtonDropdown>
            <DropdownToggle caret className="dd-wrapper" disabled={noData}>
                <div className="dd-header">

                    <p className="dd-header__title--bold">{selectedItem}</p>
                    <span
                        className="dd-buttonStyle">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            id="Capa_1"
                            data-name="Capa 1"
                            viewBox="0 0 12.67 10.9"
                            fill="#000"
                            width={10}
                            height={10}>
                            <polygon
                                className="cls-1"
                                points="6.35 10.9 12.64 0 0.06 0 6.35 10.9" /></svg>
                    </span>
                </div>
            </DropdownToggle>
            <DropdownMenu container={'body'}  >
                <Row className='overflow'>
                    {
                        item.length <= 0 || item.length === undefined ?
                            <Row style={{
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '100%'
                            }}>
                                <p>No Data</p>
                            </Row> :
                            item.map(task => (
                                <DropdownItem
                                    key={task.id}
                                    onClick={() => handleOnClick(task)}
                                    className="dd-list-item" >
                                    <Row>
                                        <Col xs={10}>
                                            <span>{task.value}</span>
                                        </Col>
                                        <Col xs={2} style={isItemInSelection(task) ? { marginLeft: '-12px' } : null}>
                                            <span className='icon-plus-container'>
                                                {
                                                    isItemInSelection(task) &&
                                                    <i className="tim-icons icon-check-2 icon-plus"></i>
                                                }
                                            </span>
                                        </Col>
                                    </Row>
                                </DropdownItem>
                            ))}
                </Row>

            </DropdownMenu>
        </UncontrolledButtonDropdown>
    );
}

export default Dropdown;