import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import AdminLayout from "layouts/Admin/Admin.js";
import AuthLayout from "layouts/Auth/Auth.js";

import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";

import ThemeContextWrapper from "./components/ThemeWrapper/ThemeWrapper";
import BackgroundColorWrapper from "./components/BackgroundColorWrapper/BackgroundColorWrapper";
import GlobalProvider from "../src/redux/state/index";
import { Toaster } from "react-hot-toast";
import { useQueryClient, QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ThemeProvider, createTheme } from "@material-ui/core";

// Create a client
const queryClient = new QueryClient();

const theme = createTheme({
  palette: {
    primary: {
      main: "#c73d27",
    },
    secondary: {
      main: "#FFF",
    },
  },
});

ReactDOM.render(
  <ThemeContextWrapper>
    <BackgroundColorWrapper>
      <BrowserRouter>
        <GlobalProvider>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
              <Switch>
                <Route
                  path="/admin"
                  render={(props) => <AdminLayout {...props} />}
                />
                <Route
                  path="/auth"
                  render={(props) => <AuthLayout {...props} />}
                />
                <Redirect from="/" to="/auth/login" />
              </Switch>
              <Toaster />
            </ThemeProvider>
            <ReactQueryDevtools />
          </QueryClientProvider>
        </GlobalProvider>
      </BrowserRouter>
    </BackgroundColorWrapper>
  </ThemeContextWrapper>,
  document.getElementById("root")
);
