import httpClient from "../service/index";
import GetDefinition from "../definition";

class AuthServices {
  constructor() {
    this.response = {
      success: true,
      error: "",
      data: [],
    };
  }

  async Login(model) {
    try {
      let url = GetDefinition("Login");

      let result = await this.postData(url[0], model);

      //console.log('Result BD ', result)
      return result;
    } catch (error) {
      console.log("Error en service SigninProcess ", error);
      this.response.error = `Error en service SigninProcess ${error}`;
      this.response.success = false;
      return this.response;
    }
  }

  async Recovery(model) {
    try {
      let url = GetDefinition("Recovery");
      let result = await this.postData(url, model);

      // console.log('Result BD', result)
      return result;
    } catch (error) {
      console.log("Error en service RecoveryProcess ", error);
      this.response.error = `Error en service RecoveryProcess ${error}`;
      this.response.success = false;
      return this.response;
    }
  }

  async GetDataLocalStorageCreate(model) {
    try {
      let url = GetDefinition("GetDataCreateLocalStorage");
      let result = await this.postData(url, model);

      // console.log('Result BD', result)
      return result;
    } catch (error) {
      console.log("Error en service RecoveryProcess ", error);
      this.response.error = `Error en service RecoveryProcess ${error}`;
      this.response.success = false;
      return this.response;
    }
  }
  
  async GetLoadEspecialistData(model){
    try {
      let url = GetDefinition("GetLoadEspecialistData");
      let result = await this.postData(url, model);

      // console.log('Result BD', result)
      return result;
    } catch (error) {
      console.log("Error en service RecoveryProcess ", error);
      this.response.error = `Error en service RecoveryProcess ${error}`;
      this.response.success = false;
      return this.response;
    }
  }
  
  async GetLoadInstitutiontData(model){
    try {
      let url = GetDefinition("GetLoadDataForInstitution");
      let result = await this.postData(url, model);

      // console.log('Result BD', result)
      return result;
    } catch (error) {
      console.log("Error en service RecoveryProcess ", error);
      this.response.error = `Error en service RecoveryProcess ${error}`;
      this.response.success = false;
      return this.response;
    }
  } 

  // HTTP SERVICE
  postData(url, model) {
    return new Promise((resolve) => {
      httpClient
        .post(url, model)
        .then((result) => {
          //console.log('API RESULT ', result)
          resolve(result);
        })
        .catch((error) => {
          console.log("ERROR", error);
          resolve({
            success: false,
            error: error,
            data: [],
          });
        });
    });
  }

  getData(url, req) {
    return new Promise((resolve) => {
      httpClient
        .get(url, req)
        .then((result) => {
          //console.log('Result Get Service ', result)
          if (result.message.length > 0) {
            resolve({
              success: true,
              error: "",
              data: result,
            });
          } else {
            resolve({
              success: false,
              error: "",
              data: [],
            });
          }
        })
        .catch((error) => {
          console.log("ERROR", error);
          resolve({
            success: false,
            error: error,
            data: [],
          });
        });
    });
  }

  putData(url, model) {
    return new Promise((resolve) => {
      httpClient
        .put(url, model)
        .then((result) => {
          //console.log('API RESULT ', result)
          resolve({
            success: true,
            error: "",
            data: result,
          });
        })
        .catch((error) => {
          console.log("ERROR", error);
          resolve({
            success: false,
            error: error,
            data: [],
          });
        });
    });
  }
}

export default AuthServices;
