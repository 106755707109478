/* import Recovery from '../../api/auth/AuthService'
const Recovery = new Recovery() */

import Manager from '../../api/auth/AuthService';
const api = new Manager();

class AuthServicesFrontEnd{

    constructor(){
        
    }

    async RecoveryPassword(model){
        try {
            //let result = await UserTable.findOneAndUpdate({email: model.email},{password: md5(model.password)})
            let result = await api.Recovery(model)
            return result
        } catch (error) {
            return error
        }
    }
}

export default AuthServicesFrontEnd