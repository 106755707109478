import Manager from "../api/auth/AuthService";
import MainManager from "../api/main/MainService"
const api = new Manager();
const apiMain = new MainManager()
class Auth {
  
  constructor() {
    this.respose = {
      success: true,
      error: "",
      data: [],
    };
  }

  async SingIn(model) {
    try {

      let result = await api.Login(model);
      console.log('result source singIn', result)
      return result;
    } catch (error) {
      return error;
    }
  }

  async GetDataCreateLocalStorage(model) {
    try {
      let result = await api.GetDataLocalStorageCreate(model)
      return result
    } catch (error) {
      return error
    }
  }

  async GetLoadEspecialistData(model) {
    try {
      let result = await api.GetLoadEspecialistData(model)
      return result
    } catch (error) {
      return error
    }
  }
  
  async GetLoadDataInstitution(model) {
    try {
      let result = await api.GetLoadInstitutiontData(model)
      return result
    } catch (error) {
      return error
    }
  }

  async SingUp() { }
}

export default Auth;
