import DragAndDrop from "../components/MercatoDashboard/DragAndDrop";
import { useMutation, useQuery } from "react-query";
import Manager from '../source/Main'
import { toast } from "react-hot-toast";
const api = new Manager()

function MercatoDashboard() {

    ///CARGA INICIAL DE LA CACHE
    const handlerQuery = async () => {
        let clubSessionID = JSON.parse(window.localStorage.getItem('_gHtk')).sessionID;
        let categoryID = JSON.parse(window.localStorage.getItem('currentCategori')).categoriesID;

        let model = {
            sessionID: clubSessionID,
            categoryID: parseInt(categoryID)
        }

        let result = await api.GetAtletasForMercato(model)
        
        return result
    }

    // FUNCION QUE CREA LA CACHE
    const { data, isLoading, isSuccess, refetch } = useQuery('mercatoAthletes', () => handlerQuery(), {
        refetchOnWindowFocus: false
    })

    // Mutación para actualizar el estado "show_in_mercato" de un atleta

    const handleUpdateShowInMercatoQuery = async (model) => {

        const loadingToast = toast.loading('Actualizando atleta...')

        let result = await api.UpdateAtletaForMercato(model)

        if(result.success){
            toast.success('Actualizado con exito', {
                id: loadingToast,
            })
        } else {
            toast.error('No se pudo actualizar', {
                id: loadingToast,
            })
        }

        refetch()
        
        return result
    }

    const UpdateShowInMercatoMutation = useMutation({
        mutationKey: 'mercatoAthletes',
        mutationFn: handleUpdateShowInMercatoQuery,
    })

    const UpdateShowInMercato = (model) => {
        UpdateShowInMercatoMutation.mutate(model)
    }

    if(isSuccess){
        let athletes = data.message.data[0]

        return (
            <div className="content">
                <DragAndDrop data={athletes} UpdateShowInMercato={UpdateShowInMercato} />
            </div>
        )
    }

    if(isLoading){
        return (
            <div>
                Cargando...
            </div>
        )
    }
}

export default MercatoDashboard