import React, { useRef } from "react";
import { Row, Col, Table, } from 'reactstrap';

import DatetimeCustom from "components/dateTime";
import CustomizableSelect from "components/customSelect/customSelect";
import ButtomCustom from 'components/buttom/index';
import { Button, Tooltip, UncontrolledTooltip } from 'reactstrap';
import PropType from "prop-types";

import styled from "styled-components";
import "../dataTable/dataTable.css"
import '@trendmicro/react-paginations/dist/react-paginations.css';
import Filter from '../../assets/Iconos/Filtros.svg';
import DowloadExcel from '../../assets/Iconos/Excel.svg';
import DowloadPDF from '../../assets/Iconos/PDF.svg';
import HelperManager from "../../source/handler/index";
import { useStateCallback } from "hook/useStateCallback";
import { FiInfo } from "react-icons/fi"
import { AiFillWarning } from "react-icons/ai"

const api = new HelperManager();

const LabelWarrper = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    align-items: center;
    justify-content: space-evenly;
    width: 179px;
    height: 50px;
    border-radius: 8px;`

const InError = styled.div`
display: flex;
align-items: center;
justify-content: center;
width: 100%;
padding:20px;
background: #ff00003b;
border-radius: 8px;
`

const CustomizableDataTable = ({
    tableType,
    filter,
    filterData,
    filterRule,
    showAction,
    showActionExecel,
    showActionFilter,
    showActionPdf,
    filterComponent,
    handlerOnClik,
    handlerOfChange,
    handlerActionOnCLick,
    data = [],
    headerData
}) => {



    // CONSTANT 
    const unmounted = useRef(false)
    const [header, setHeader] = React.useState([]);
    const [tbody, setTbody] = React.useState([]);
    const [dowloadEmpty, setDowloadEmpty] = React.useState(false);
    const [disabledSelect, setDisabledSelect] = React.useState(false);
    const [buttonFilterActive, setButtonFilterActive] = useStateCallback({
        filterButtonID: 0
    })
    const [msg, setMsg] = React.useState('No existen datos para cargar');
    const [disablemin, setDisbleMin] = React.useState(true);
    const [disableMax, setDisableMax] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState('');
    const [isError, setIsError] = React.useState(false);
    const [config, setConfig] = React.useState([]);
    const [disableExcel, setDisableExcel] = React.useState(false)
    const [states, setState] = useStateCallback({
        page: 0,
        perPage: 5,
        numberOfPages: Math.ceil((data.length) / 5)
    });

    // LOAD DATA
    React.useEffect(() => {
        let controller = new AbortController();
        setTbody(data)
        setHeader(headerData)
        setConfig(filterData)
        // VALIDACIONES

        return () => { controller.abort() }
    }, [])

    React.useEffect(() => {
        setTbody(data)
    }, [data])

    React.useEffect(() => {
        if (filterData.length > 0) {
            states.numberOfPages = Math.ceil((data.length) / 5)
            setConfig(filterData)
        }else{
            setDisableExcel(true)
            states.numberOfPages = 0
        }
    }, [filterData])


    // FUNCTION
    const paginations = (action) => {
        let page = states.page
        let totalIndex = states.numberOfPages - 1

        if (action) {
            page = page + 1
            page = page >= totalIndex ? totalIndex : page
        } else {
            page = page - 1
            page = page <= 0 ? 0 : page
        }

        if (page <= 0) {
            setDisbleMin(true)
            setDisableMax(false)
        }
        if (page >= totalIndex) {
            setDisbleMin(false)
            setDisableMax(true)
        }
        if (page >= 0 && page < totalIndex) {
            setDisbleMin(false)
            setDisableMax(false)
        }
        setState({ ...states, page });

    }

    // Table Type regular render tr function
    const _TbodyRegular = (item) => {
        let data = Object.entries(item);
        return (
            <tr key={parseInt(Math.random(100 * 5).toString().replace('.', 0))}>
                {data.map((task, d) => {
                    if (typeof task[1] === 'object') {
                        return (
                            <td key={parseInt(Math.random(100 * 5).toString().replace('.', 0))} className="text-center">
                                <Button
                                    onClick={() => handlerOnClik(task[1].key)}
                                    id='filter'
                                    className="btn-icon btn-dark btn-round" size='sm' >
                                    <FiInfo size="20px" color="white" /> 
                                </Button>
                            </td>
                        )
                    } else {
                        return (
                            <td key={parseInt(Math.random(100 * 5).toString().replace('.', 0))} className={(task[0]==="nombreAtleta" || task[0]==="nombre" ) ? "pl-4" : "text-center"}>
                                <span className='text-center' style={{ textTransform: "capitalize" }}>{task[1]}</span>
                            </td>
                        )

                    }
                })
                }
            </tr>
        )
    }

    return (
        <div className="table-card">
            <div className="table-card-warpper">
                <div>
                    <Row>
                        <Col sm="12">
                            <Row className="flex-row-center ml-3">
                                {
                                    filter ?
                                        config.length > 0 &&
                                        config.map((task, i) => {
                                            let configs = task
                                            if (configs.type === 'select') {
                                                return (
                                                    <Col key={parseInt(Math.random(100 * 5).toString().replace('.', 0))} xs='3'>
                                                        <CustomizableSelect title={configs.placheolder} activeItem={configs.filterActive} item={configs.dataForFilterList} multiselect={configs.multiselect} noData={disabledSelect} onChange={(item) => filterComponent(item, configs.keyExtractor)} />
                                                    </Col>

                                                )
                                            }
                                            if (configs.type === 'buttom') {
                                                return (
                                                    <Col key={parseInt(Math.random(100 * 5).toString().replace('.', 0))} xs='3'>
                                                        <ButtomCustom title={configs.placheolder} id={i + 1} handler={(item, id) => filterComponent(item, id)} activeBtn={buttonFilterActive.filterButtonID} />
                                                    </Col>
                                                )
                                            }
                                        })
                                        : <LabelWarrper></LabelWarrper>
                                }
                            </Row>
                        </Col>
                    </Row>
                    <div className="tab-content-table" >
                        {
                            isError ?
                                <Row style={{ padding: "30px 100px" }}>
                                    <InError>
                                        <Col xs={2}>
                                            <AiFillWarning size="20px" color="white" />
                                        </Col>
                                        <Col xs={10}>
                                            <span style={{ fontSize: "12px" }}>{errorMsg}</span>
                                        </Col>
                                    </InError>
                                </Row> : <>
                                    <Table className="tablesorter" responsive>
                                        <thead className="text-primary">
                                            {
                                                header.length > 0 ?
                                                    <tr>
                                                        {
                                                            header.map((task, i) => {
                                                                return (
                                                                    <th key={parseInt(Math.random(100 * 5).toString().replace('.', 0))} className="text-center">{task}</th>
                                                                )
                                                            })
                                                        }
                                                    </tr> :
                                                    <tr>
                                                        <th className="text-center">Name</th>
                                                        <th className="text-center">Country</th>
                                                        <th className="text-center">City</th>
                                                        <th className="text-center">Salary</th>
                                                    </tr>
                                            }

                                        </thead>
                                        {
                                            tableType === "regular" && <tbody>
                                                {
                                                    tbody.length > 0 ?
                                                        tbody.slice(states.perPage * states.page, states.perPage * (states.page + 1)).map(task => _TbodyRegular(task))
                                                        :
                                                        <tr>
                                                            <td colSpan={header.length > 0 ? header.length : 4}>
                                                                <Row className="justify-content-center align-items-center w-100">
                                                                    <span style={{ background: '#ff8d7296', color: '#000', padding: '8px', borderRadius: '8px', fontSize: '10px' }}>{msg}</span>
                                                                </Row>
                                                            </td>
                                                        </tr>
                                                }

                                            </tbody>
                                        }

                                    </Table>
                                    <Row style={{ padding: "10px" }}>
                                        {
                                            showAction && <Col>
                                                <div style={{ display: "flex" }}>
                                                    {
                                                        showActionFilter && <div style={{ marginRight: "5px" }}>
                                                            <Button className="pagination-btn-filter" id={'Tooltip-Filter'} onClick={() => handlerActionOnCLick('filter')} >
                                                                <img src={Filter} style={{ width: "20px", height: "20px" }} />
                                                            </Button>
                                                            <UncontrolledTooltip placement="top" target='Tooltip-Filter' >
                                                                Filtros
                                                            </UncontrolledTooltip >
                                                        </div>
                                                    }

                                                    {
                                                        showActionExecel && disableExcel && <div style={{ marginRight: "5px" }}>
                                                            <Button className="pagination-btn-filter" id={'Tooltip-Excel'} onClick={() => handlerActionOnCLick('excel')}>
                                                                <img src={DowloadExcel} style={{ width: "20px", height: "20px" }} />
                                                            </Button>
                                                            <UncontrolledTooltip placement="top" target={'Tooltip-Excel'}  >
                                                                Descargar Excel
                                                            </UncontrolledTooltip >
                                                        </div>
                                                    }
                                                    {
                                                        showActionPdf && <div style={{ marginRight: "5px" }}>
                                                            <Button className="pagination-btn-filter" id={'Tooltip-PDF'} onClick={() => handlerActionOnCLick('pdf')}>
                                                                <img src={DowloadPDF} style={{ width: "20px", height: "20px" }} />
                                                            </Button>
                                                            <UncontrolledTooltip placement="top" target={'Tooltip-PDF'}  >
                                                                Descargar PDF
                                                            </UncontrolledTooltip >
                                                        </div>
                                                    }

                                                    {
                                                        dowloadEmpty && <div style={{ marginRight: "5px", marginTop: '11px' }}>
                                                            <span style={{ background: '#ff8d7296', color: '#000', padding: '8px', borderRadius: '8px', fontSize: '10px' }}>{msg}</span>
                                                        </div>

                                                    }

                                                </div>
                                            </Col>
                                        }
                                        <Col className="pagination-Col-Right">
                                            <div className="pagination-warpper">
                                                <button className="pagination-btn" onClick={() => paginations(false)}>
                                                    {`<`}
                                                </button>
                                                <span>{`${(states.page + 1) === 0 ? 1 : (states.page + 1)} of ${states.numberOfPages === 0 ? 1 : states.numberOfPages}`}</span>
                                                <button className="pagination-btn" onClick={() => paginations(true)}>
                                                    {`>`}
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>
                                </>
                        }


                    </div>
                </div>

            </div>
        </div>
    )
}

CustomizableDataTable.defaultProps = {
    handlerOnClik: () => { },
    handlerOfChange: () => { },
    handlerActionOnCLick: () => { },
    data: [],
    headerData: [],
    showAction: false,
    showActionExecel: false,
    showActionFilter: false,
    showActionPdf: false,
    filter: false,
    tableType: "regular",
    filterData: [],
    filterRule: () => { },
    filterComponent: () => { }
}

CustomizableDataTable.prototype = {
    tableType: PropType.string.isRequired,
    filter: PropType.bool,
    totalFilter: PropType.number,
    filterData: PropType.array,
    filterRule: PropType.func,
    handlerOnClik: PropType.func,
    handlerOfChange: PropType.func,
    handlerActionOnCLick: PropType.func,
    data: PropType.array.isRequired,
    headerData: PropType.array.isRequired,
    filterComponent: PropType.func,
    showAction: PropType.bool,
    showActionExecel: PropType.bool,
    showActionPdf: PropType.bool,
    showActionFilter: PropType.bool
}

export default CustomizableDataTable;