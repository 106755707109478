import React from "react";
import {
    CircularProgressbar,
    buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

// Animation
import ChangingProgressProvider from "./ChangingProgressProvider";

const ProgressCircle = ({data}) => {
    return (
        <div style={{ padding: "40px 40px 40px 40px", display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
            <div style={{ marginBottom: 80 }}>
                <div style={{ marginTop: 30, display: "flex" }}>
                    <div style={{ width: "90%", paddingRight: 30 }}>
                        <ChangingProgressProvider values={data}>
                            {value => (
                                <div>
                                    <CircularProgressbar
                                        value={value}
                                        text={`${value}%`}
                                        circleRatio={0.75}
                                        styles={buildStyles({
                                            rotation: 1 / 2 + 1 / 8,
                                            strokeLinecap: "butt",
                                            trailColor: "#eee"
                                        })}
                                    />
                                    <span className="labelProgress">Carga</span>
                                </div>

                            )
                            }
                        </ChangingProgressProvider>
                    </div>
                </div>
            </div>
        </div>
    );
} 



export default ProgressCircle;