

import GetDefinition from "api/definition"
import httpClient from 'api/service';

export const MutationFisicalTabs = (model) => {
    let url = GetDefinition('MutationFisicalTabsAtleta')
    let result = postData(url[0],model)
    return result;
}

// HTTP SERVICE
function postData(url, model) {
    // console.log(`URL `, url , 'Model ', model)
    return new Promise((resolve) => {
        httpClient.post(url, model)
            .then((result) => {
                //console.log('API RESULT ', result)
                resolve(result)
            })
            .catch(error => {
                console.log('ERROR', error)
                resolve(error)
            })
    })
}

function getData(url, req) {
    return new Promise((resolve) => {
        httpClient.get(url, req)
            .then((result) => {
                // console.log('Result Get Service ', result)
                if (result.message.success) {
                    resolve(result)
                } else {
                    resolve(result)
                }

            })
            .catch(error => {
                console.log('ERROR', error)
                resolve({
                    success: false,
                    error: error,
                    data: []
                })
            })
    })
}

function putData(url, model) {
    return new Promise((resolve) => {
        httpClient.put(url, model)
            .then((result) => {
                //console.log('API RESULT ', result)
                resolve(
                    {
                        success: true,
                        error: '',
                        data: result
                    }
                )
            })
            .catch(error => {
                console.log('ERROR', error)
                resolve({
                    success: false,
                    error: error,
                    data: []
                })
            })
    })
}
export default { MutationFisicalTabs };