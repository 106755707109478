import { Row, Col, Button } from "reactstrap";
import { TextSkeleton, ImageSkeleton } from "components/Skeleton";
import overlay from "../../assets/img/flag/escudoOp.png";
import Manager from '../../source/Main'
import { GetHeaderClubData } from "api/GeneralTabs";

import { useQuery } from "react-query";
const api = new Manager();
const getOVRColor = (score) => {
    if (score <= 54) return "bad";
    if (score >= 55 && score <= 74) return "medium";
    if (score >= 75 && score <= 89) return "little";
    if (score >= 90) return "good";
};

const ItemScore = ({
    title = "",
    score = 0,
    showOVR = true,
    type = "number",
}) => (
    <div className="container__header__score">
        <div className="text-white">
            <b>{title}</b> {showOVR && <small>(ovr)</small>}
        </div>
        {type == "number" ? (
            <div className={`spanLable ${getOVRColor(score)}`}>{parseInt(score)}%</div>
        ) : (
            <div className="spanLable text-white">{score}</div>
        )}
    </div>
);

const ClubHeader = () => {

    const handlerQuery = async () => {
        let clubID = JSON.parse(window.localStorage.getItem('_gHtk'));
        let categoryID = JSON.parse(window.localStorage.getItem('currentCategori'))
        let model = {
            clubID: clubID.clubID,
            categoryID: categoryID.categoriesID
        }
        try {
            let result = await GetHeaderClubData(model)
            return result
        } catch (error) {
            return error
        }
    }
    const { data, isSuccess, isLoading } = useQuery('headerClub', () => handlerQuery(), {
        refetchOnWindowFocus: false
    })

    if (isLoading) {
        return (
            <div><span>Cargando...</span></div>
        )

    }
   
    if (isSuccess) {
        const { userInfo, stadistic } = data.message
    
        return (
            <Row>
                <Row className="headerbackground">
                    <img alt="Imagen de perfil" src={overlay} height="200" width="200" />
                </Row>
                <Col md="12">
                    <div className="header_navbar">
                        <div className="container__header">
                            <div className="container__header__details">
                                {isLoading ? (
                                    <ImageSkeleton opacity="0.2" />
                                ) : (
                                    <img
                                        src={userInfo[0].imgData}
                                        height="150px"
                                        width="150px"
                                        className="Imagestyle"
                                    />
                                )}
                                <div>
                                    <h1 className="maintitle title-mb">
                                        {isLoading ? (
                                            <TextSkeleton height="20px" opacity="0.2" />
                                        ) : (
                                            userInfo[0].nombre
                                        )}
                                    </h1>
                                    <h3 className="title-mb">
                                        {isLoading ? (
                                            <TextSkeleton height="20px" opacity="0.2" />
                                        ) : (
                                            userInfo[0].divicion
                                        )}
                                    </h3>
                                    <h4 className="title-mb">
                                        {isLoading ? (
                                            <TextSkeleton height="20px" opacity="0.2" />
                                        ) : (
                                            userInfo[0].liga
                                        )}
                                    </h4>

                                    <div className="header__dt_pf">
                                        {isLoading ? (
                                            <TextSkeleton width="100px" height="20px" opacity="0.2" />
                                        ) : (
                                            userInfo[0].dt && (
                                                <div className="text-white">
                                                    <b>DT: </b>
                                                    {userInfo[0].dt}
                                                </div>
                                            )
                                        )}
                                        {isLoading ? (
                                            <TextSkeleton width="100px" height="20px" opacity="0.2" />
                                        ) : (
                                            userInfo[0].pf && (
                                                <div className="text-white">
                                                    <b>PF: </b>
                                                    {userInfo[0].pf}
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="container__header__ovrs">
                                {isLoading ? (
                                    <>
                                        <TextSkeleton width="200px" height="20px" opacity="0.2" />
                                        <TextSkeleton width="200px" height="20px" opacity="0.2" />
                                        <TextSkeleton width="200px" height="20px" opacity="0.2" />
                                        <TextSkeleton width="200px" height="20px" opacity="0.2" />
                                        <TextSkeleton width="200px" height="20px" opacity="0.2" />
                                        <TextSkeleton width="200px" height="20px" opacity="0.2" />
                                    </>
                                ) : (
                                    <>
                                        {stadistic.map((row, index) => {
                                            if (row.score > 0 || row.type == "text") {
                                                return (
                                                    <ItemScore
                                                        key={index}
                                                        title={row.title}
                                                        score={row.score}
                                                        type={row.type ? "text" : "number"}
                                                        showOVR={row.quitOVR ? false : true}
                                                    />
                                                );
                                            }
                                        })}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        );
    }

};

export default ClubHeader;
